import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import styled from '@emotion/styled';
import Textbox from 'common/components/inputField/Textbox';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  selectFundsTermsAndServices,
  selectFundsDetails,
  selectFundStructAndStats,
} from 'store/funds/selectors';
import {
  setFundsDetails,
  setFundStructAndStats,
  setFundTermsAndServices,
  setNewFundID,
} from 'store/funds/slice';
import { useAppSelector, useRQMutation, useTypedDispatch } from 'store/hooks';
import { useEffect, useState } from 'react';
import { encryptDocumentUrl } from 'helpers';
import { ErrorMessage } from 'common/components/errorMessageBox';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import RichTextEditor from 'common/components/richTextEditor/RichEditor';
import LoadingButton from 'common/components/button/LoadingButton';

type Props = {
  handleSubmitBtnClick: any;
  handleBackBtnClick: any;
  editing?: boolean;
};

const StyledWrapper = styled.div`
  .heading {
    font-weight: 500;
  }
  .sub-heading {
    font-size: 18px;
    padding-bottom: 0.5rem;
    margin-bottom: 20px;
    font-weight: 500;
    border-bottom: 2px solid var(--s20);
  }
  .label {
    margin: 0;
    color: #0d0c22;
  }
`;

const InvestmentTSPForm = ({ handleSubmitBtnClick, handleBackBtnClick, editing }: Props) => {
  const [documentData, setDocumentData] = useState<any>({
    doc_name: 'logo.jpeg',
    doc_type: 'logo',
    doc_config_type_id: null,
    doc_category_id: '4bc73402-852e-452a-91fc-cd405d3d48e3',
    doc_classification_name: null,
    doc_category_type_id: 1,
    status: 'Active',
    doc_config_id: null,
    object_key: '4bc73402-852e-452a-91fc-cd405d3d48e3/logo/logo.jpeg',
    document_type: 'FUND',
  });
  const [managersBio, setManagersBio] = useState(() => EditorState.createEmpty());
  const [managersBioText, setManagersBioText] = useState<string>('');

  const storeFundsTandSProviders = useAppSelector(selectFundsTermsAndServices);
  const storeFundDetails = useAppSelector(selectFundsDetails);
  const storeFundStructAndStats = useAppSelector(selectFundStructAndStats);
  const dispatch = useTypedDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split('/')[3];

  useEffect(() => {
    if (storeFundsTandSProviders !== null) {
      setValue('auditor', storeFundsTandSProviders.auditor);
      setValue('administrator', storeFundsTandSProviders.administrator);
      setValue('fund_counsel', storeFundsTandSProviders.fund_counsel);
      setValue('prime_broker', storeFundsTandSProviders.prime_broker);
      setValue('disclaimer', storeFundsTandSProviders.disclaimer);
      setManagersBio(
        EditorState.createWithContent(stateFromHTML(storeFundsTandSProviders.managers_bio)),
      );
    }
    // eslint-disable-next-line
  }, [storeFundsTandSProviders]);

  useEffect(() => {
    const newManagerBioText = managersBio.getCurrentContent().getPlainText();
    setManagersBioText(newManagerBioText);
  }, [managersBio]);

  const onSubmit: SubmitHandler<any> = async (data) => {
    const fundsTermsAndServices = {
      ...data,
      managers_bio:
        managersBioText.length > 0 ? `${stateToHTML(managersBio.getCurrentContent())}` : '',
    };
    await dispatch(setFundTermsAndServices(fundsTermsAndServices));
    if (!editing) {
      const newFundFormData = {
        ...storeFundStructAndStats,
        ...storeFundDetails,
        ...fundsTermsAndServices,
      };
      addNewFund(newFundFormData);
    } else {
      handleSubmitBtnClick();
    }
  };

  const { mutate: updateDocumentData, isLoading: isUploadingDoc } = useRQMutation({
    url: 'qaip/v1/documentmanagement/updatedocumentdata',
    isFormData: true,
  });

  const { mutate: fetchPresignedUrl, isLoading: isFetchingPredesignUrl } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
    },
    {
      enabled: false,
      onSuccess: async (data) => {
        await encryptDocumentUrl(data.url, storeFundDetails.logo_file);
        updateDocumentData({ documentData });
        handleSubmitBtnClick();
      },
    },
  );

  const { mutate: addNewFund, isLoading: isAddingNewFund } = useRQMutation(
    {
      url: 'qaip/v1/fundsmanagement/funds',
    },
    {
      enabled: false,
      onSuccess: async (data) => {
        await dispatch(setNewFundID(data['fund_id']));
        const objectKey = {
          object_key: `${data['fund_id']}/logo/${storeFundDetails.logo}`,
        };
        const newDocumentData = {
          ...documentData,
          doc_name: storeFundDetails.logo,
          doc_category_id: data['fund_id'],
          object_key: objectKey.object_key,
        };
        setDocumentData(newDocumentData);
        fetchPresignedUrl(objectKey);
      },
    },
  );

  const handleChangeManagersBio = (newEditorState: any) => {
    setManagersBio(newEditorState);
  };

  const handleDiscardChanges = () => {
    dispatch(setFundsDetails(null));
    dispatch(setFundStructAndStats(null));
    dispatch(setFundTermsAndServices(null));
    if (editing) {
      navigate(`/investment/${id}`);
    } else {
      navigate(`/investments`);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container maxWidth='md' className='container-lr-padding my-5'>
          <Container maxWidth='lg' className='px-0'>
            <h2 className='heading'>Terms & Service Providers</h2>
          </Container>
          <Container maxWidth='lg' className='my-5 px-0'>
            <h4 className='sub-heading'>Terms & Service Providers</h4>
            <Grid container columnSpacing={3}>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Auditor</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='auditor'
                  onChange={(e: any) => setValue('auditor', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Administrator</h4>
                <Textbox
                  register={register}
                  fullWidth
                  name='administrator'
                  onChange={(e: any) => setValue('administrator', e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Fund Counsel</h4>
                <Textbox
                  register={register}
                  name='fund_counsel'
                  onChange={(e: any) => setValue('fund_counsel', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Prime Broker</h4>
                <Textbox
                  register={register}
                  name='prime_broker'
                  onChange={(e: any) => setValue('prime_broker', e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth='lg' className='my-5 px-0'>
            <h4 className='sub-heading'>Disclaimer Details</h4>
            <Grid container>
              <Grid item xs={12}>
                <h4 className='font-wt-400 label'>Disclaimer</h4>
                <Textbox
                  register={register}
                  name='disclaimer'
                  onChange={(e: any) => setValue('disclaimer', e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  maxLength={5000}
                />
                {errors.disclaimer && errors.disclaimer.type === 'maxLength' && (
                  <ErrorMessage error='Input limit exceeded. Character Limit is 5000' />
                )}
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth='lg' className='my-5 px-0'>
            <h4 className='sub-heading'>Additional Information</h4>
            <Grid container>
              <Grid item xs={12}>
                <h4 className='font-wt-400 label'>Managers Bio</h4>
                <RichTextEditor
                  editorState={managersBio}
                  handleChangeEditorState={(newEditorState: any) =>
                    handleChangeManagersBio(newEditorState)
                  }
                />
                {managersBioText.length > 5000 && (
                  <ErrorMessage error='Input limit exceeded. Character Limit is 5000' />
                )}
              </Grid>
            </Grid>
          </Container>
          <Grid container justifyContent='flex-end'>
            <MuiButton variant='text' buttonClick={handleBackBtnClick}>
              Back
            </MuiButton>
            <MuiButton
              variant='text'
              color='error'
              className='ml-3'
              buttonClick={handleDiscardChanges}
            >
              Discard Changes
            </MuiButton>
            {!editing &&
              (isAddingNewFund || isFetchingPredesignUrl || isUploadingDoc ? (
                <LoadingButton minWidth='150px' />
              ) : (
                <MuiButton minWidth='150px' variant='contained' type='submit' className='ml-3'>
                  Add
                </MuiButton>
              ))}
            {editing && (
              <MuiButton minWidth='150px' variant='contained' type='submit' className='ml-3'>
                Next
              </MuiButton>
            )}
          </Grid>
        </Container>
      </form>
    </StyledWrapper>
  );
};

export default InvestmentTSPForm;
