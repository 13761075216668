import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import { ClickAwayListener, Grid, Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import Ameritrade from 'common/assets/images/custodian/Ameritrade.png';
// import Ameritrade2 from 'common/assets/images/custodian/Ameritrade2.png';
import CharlesSchwab from 'common/assets/images/custodian/CharlesSchwab.png';
import Orion from 'common/assets/images/custodian/Orion.png';
import Fidelity from 'common/assets/images/custodian/Fidelity.png';
import MuiCard from 'common/components/card';
import Textbox from 'common/components/inputField/Textbox';
import { useState } from 'react';

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 884px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 0.75rem !important;
    line-height: 26px;
  }
  h5 {
    color: var(--s30);
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  .custodianGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding-right: 4.25rem;
    border-right: 1px solid var(--s15);

    .MuiCard-root {
      width: 140px;
      height: 112px;
      padding: 22px;
      display: grid;
      place-items: center;
      box-sizing: border-box;
      cursor: pointer;

      &.selected {
        border-color: var(--p150);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .selectedCustodian {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    h5 {
      color: var(--s50);
      margin-bottom: 0.75rem;
    }

    img {
      width: 208px;
      height: 80px;
      margin-bottom: 4rem;
      object-fit: contain;
    }

    .MuiOutlinedInput-root {
      width: 320px;
      margin-bottom: 2rem;
    }
  }
`;

type Props = {
  isModalOpen: boolean;
  handleClose: any;
};

const CUSTODIAN_DATA = [
  {
    name: 'TD Ameritrade',
    img: Ameritrade,
  },
  {
    name: 'Charles Schwab',
    img: CharlesSchwab,
  },
  {
    name: 'Orion',
    img: Orion,
  },
  {
    name: 'Fidelity',
    img: Fidelity,
  },
];

const CustodianIntegrationModal = ({ isModalOpen, handleClose }: Props) => {
  const [selectedCustodianIndex, setSelectedCustodianIndex] = useState<number>(0);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <StyledModal
      title='Custodian Integration'
      isModalOpen={isModalOpen}
      handleClose={() => {
        handleClose();
      }}
      maxWidth='lg'
      className='new-alloc'
    >
      <Grid container>
        <Grid item xs={12} mb={'52px'}>
          <h5>Choose your custodian you wish to start integrating below</h5>
        </Grid>
        <div className='custodianGrid'>
          {CUSTODIAN_DATA.map((data: any, i: number) => (
            <MuiCard
              minHeight='unset'
              key={`Custodian${i}`}
              className={i === selectedCustodianIndex ? 'selected' : ''}
              onClick={() => {
                setSelectedCustodianIndex(i);
              }}
            >
              <img src={data.img} alt={data.name} />
            </MuiCard>
          ))}
        </div>
        <Grid item xs={'auto'} className='selectedCustodian'>
          <img src={CUSTODIAN_DATA[selectedCustodianIndex].img} alt='Ameritrade' />
          <h5>Please enter your {CUSTODIAN_DATA[selectedCustodianIndex].name} Repcode below:</h5>
          <Textbox placeholder='Repcode' />

          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                title={<div className='tooltipContainer'>Coming soon</div>}
                placement='top'
                arrow
                open={open}
                disableFocusListener
                PopperProps={{
                  disablePortal: true,
                }}
                disableHoverListener
                onClose={handleTooltipClose}
                classes={{ tooltip: 'p-0 tooltipParent' }}
              >
                <div onClick={handleTooltipOpen}>
                  <MuiButton variant='contained' minWidth='150px'>
                    Authenticate
                  </MuiButton>
                </div>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Grid>
      </Grid>
    </StyledModal>
  );
};

export default CustodianIntegrationModal;
