import { useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { format, parse } from 'date-fns';
import { Pagination } from '@mui/material';
import TableHeadSkeleton from 'common/components/skeleton/TableHeadSkeleton';
import TableBodySkeleton from 'common/components/skeleton/TableBodySkeleton';
import TableSubHeader from 'common/components/table/TableSubHeader';
import { orderByReturnVal } from 'common/components/table/utils';
import { TableColumn, TableProps } from 'common/components/table/types';
import { MainStyle } from 'common/components/table/styles';
import { MONTHS } from 'components/modals/investments/historicalReturns/constants';

const PreviewErrorTable = ({
  rows = [],
  columns,
  rowClassName = '',
  isLoading = false,
  tableClassName = '',
  rowsPerPage = 10,
  dateFormat = 'MMM-dd-yyyy',
  onRowClick,
  overflowRef,
}: TableProps) => {
  const [orderBy, setOrderBy] = useState<{ id: string; dir: boolean; index: number }>({
    id: '',
    dir: true,
    index: -1,
  });
  const [page, setPage] = useState<number>(0);

  // Column Ordering
  const rowsBeforePagination = useMemo(() => {
    const arr = rows.sort((a: any, b: any) => {
      const val =
        orderBy.id !== '' ? orderByReturnVal(a, b, orderBy, columns[orderBy.index].isDate) : 0;
      return val;
    });
    return [...arr];
    //eslint-disable-next-line
  }, [rows, orderBy]);

  // Pagination
  const finalRows = useMemo(() => {
    return rowsBeforePagination.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [rowsBeforePagination, page, rowsPerPage]);

  const errorCell = (row: any, col: any) => {
    const IS_CURRENT_MONTH =
      parseInt(MONTHS[row?.Month?.toLocaleLowerCase()]) === new Date().getMonth() + 1; // +1 as Date object returns months starting from 0.

    if (IS_CURRENT_MONTH) return true; // Error out current month cell.

    return (
      !row[col.id] ||
      (row[col.id] && col.needsFormatter && isNaN(Number(row[col.id]))) ||
      ((row.validateInvestorAPI || isNaN(Number(row[col.id]))) && col.id === 'investor_id') ||
      ((row.validateFundAPI || isNaN(Number(row[col.id]))) && col.id === 'fund_id') ||
      (row.invalidFundName && col.id === 'fund_name') ||
      (row.invalidInvestorName && col.id === 'account_name') ||
      (col.id === 'dateFormatted' &&
        (row?.dateFormatted?.split('-').length !== 2 ||
          (row?.dateFormatted?.split('-').length === 2 &&
            row?.dateFormatted?.split('-')[1].length !== 4) ||
          !parse(row.dateFormatted, 'MMM-yyyy', new Date()).getTime())) ||
      parse(row.dateFormatted, 'MMM-yyyy', new Date()) > new Date() ||
      (col.id === 'Return' && isNaN(row.Return)) ||
      (col.isDate && !new Date(row[col.id]).getTime())
    );
  };

  return (
    <MainStyle className={tableClassName}>
      <div className='tableWrapper'>
        <div style={{ overflowX: 'auto' }} ref={overflowRef}>
          <Table>
            {isLoading ? (
              <TableHeadSkeleton length={columns?.length} />
            ) : (
              <TableSubHeader
                disableOrderBy
                columns={columns}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
              />
            )}
            <TableBody>
              {isLoading ? (
                <TableBodySkeleton length={columns?.length} />
              ) : (
                <>
                  {finalRows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={columns?.length} className='nullTable'>
                        No Data Available
                      </TableCell>
                    </TableRow>
                  ) : (
                    React.Children.toArray(
                      finalRows.map((row: any, i: number) => (
                        <TableRow
                          onClick={() => {
                            onRowClick && onRowClick();
                          }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          className={rowClassName}
                        >
                          {React.Children.toArray(
                            columns.map((col: TableColumn) => (
                              <TableCell
                                align={col.align}
                                className={`
                            ${col.cellClassName ? col.cellClassName : ''}
                            ${errorCell(row, col) ? 'color-d400' : ''}
                            position-relative
                          `}
                                sx={{ width: `${col.width ? col.width : 'initial'}` }}
                              >
                                {row[col.id]
                                  ? col.needsFormatter
                                    ? `${!isNaN(Number(row[col.id])) ? '$' : ''} ${
                                        isNaN(Number(row[col.id]))
                                          ? '  --  '
                                          : Number(row[col.id])?.toLocaleString()
                                      }`
                                    : col.isDate && !!new Date(row[col.id]).getTime()
                                    ? format(new Date(row[col.id]), dateFormat)
                                    : row[col.id].replace('-invalid', '')
                                  : '--'}
                              </TableCell>
                            )),
                          )}
                        </TableRow>
                      )),
                    )
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </div>
        {rowsPerPage && rowsBeforePagination.length > rowsPerPage && (
          <Pagination
            className='pagination'
            count={Math.ceil(rowsBeforePagination.length / rowsPerPage)}
            page={page + 1}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => setPage(value - 1)}
          />
        )}
      </div>
    </MainStyle>
  );
};

export default PreviewErrorTable;
