import { getUserAuth, getUserDetails } from 'helpers';
import { takeLatest, call, put } from 'redux-saga/effects';
import store from 'store';
import { initiateProfileFetch, setAuth, setProfile } from './slice';
import { setAuthFailed } from './slice';

function* fetchUserProfile(action: any): any {
  try {
    initiateProfileFetch();
    const { payload: username } = action;
    const userProfile = yield call(getUserDetails, username);
    const userData = userProfile.data.getUserDetails;
    const userBasicData = {
      userOktaSubId: userData.userOktaSubId,
      cognitoUserId: userData.cognitoUserId,
      email: userData.userName,
      firstName: userData.firstName,
      userId: userData.userId,
      username: userData.userName,
    };
    const currentUserAuth = yield getUserAuth();
    const userAuth = { ...currentUserAuth, userDetailsResponseDTO: userBasicData };
    store.dispatch(setAuth(userAuth));
    localStorage.setItem('currentUser', JSON.stringify(userAuth));
    yield put(setProfile({ ...userData }));
  } catch (e) {
    console.log('Error Fetching User Profile', e);
    yield put(setAuthFailed());
    store.dispatch(setAuth(null));
    yield (window.location.href = '/login');
  }
}

export default function* profileSaga() {
  yield takeLatest('FETCH_USER_PROFILE', fetchUserProfile);
}
