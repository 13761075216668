import { Grid } from '@mui/material';
import { StyledWrapper } from 'common/components/carousel/styles';
import MuiSkeleton from 'common/components/skeleton/Skeleton';

interface RecommendationsSkeletonLoaderProps {
  showButton?: boolean;
}

function RecommendationsSkeletonLoader({ showButton = true }: RecommendationsSkeletonLoaderProps) {
  return (
    <StyledWrapper className='container-lr-padding' maxWidth='xl'>
      <Grid container justifyContent='space-between'>
        <MuiSkeleton width={580} height={44} variant='text' />
        {showButton && <MuiSkeleton width={168} height={38} variant='rectangular' />}
      </Grid>
      <Grid container gap={3} mt={2} overflow='hidden' flexWrap='nowrap'>
        <MuiSkeleton width={400} height={250} variant='rectangular' />
        <MuiSkeleton width={400} height={250} variant='rectangular' />
        <MuiSkeleton width={400} height={250} variant='rectangular' />
        <MuiSkeleton width={400} height={250} variant='rectangular' />
      </Grid>
    </StyledWrapper>
  );
}

export default RecommendationsSkeletonLoader;
