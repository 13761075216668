export const COLUMNS = [
  {
    id: 'investor_id',
    label: 'Investor ID',
    width: '11%',
  },
  {
    id: 'account_name',
    label: 'Investor Name',
    width: '11%',
  },
  {
    id: 'fund_id',
    label: 'Fund ID',
    width: '11%',
  },
  {
    id: 'fund_name',
    label: 'Fund Name',
    width: '10%',
  },
  {
    id: 'current_market_value',
    label: 'Current Market Value',
    needsFormatter: true,
    align: 'right' as const,
    width: '14.25%',
  },
  {
    id: 'subscription',
    label: 'Subscription',
    needsFormatter: true,
    width: '8%',
  },
  {
    id: 'redemption',
    label: 'Redemption',
    needsFormatter: true,
    width: '8%',
  },
  {
    id: 'income',
    label: 'Income',
    needsFormatter: true,
    width: '5%',
  },
  {
    id: 'roc',
    label: 'ROC',
    needsFormatter: true,
    width: '5%',
  },
  {
    id: 'as_of_date',
    label: 'As Of Date',
    isDate: true,
    width: '10%',
  },
];

export const ERROR_COLUMNS = [
  {
    id: 'investor_id',
    label: 'Investor ID',
    needsFormatter: false,
    width: '11%',
  },
  {
    id: 'account_name',
    label: 'Investor Name',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'fund_id',
    label: 'Fund ID',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'fund_name',
    label: 'Fund Name',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'current_market_value',
    label: 'Current Market Value',
    needsFormatter: true,
    align: 'right' as const,
    width: '14.25%',
  },
  {
    id: 'subscription',
    label: 'Subscription',
    needsFormatter: true,
    width: '8%',
  },
  {
    id: 'redemption',
    label: 'Redemption',
    needsFormatter: true,
    width: '8%',
  },
  {
    id: 'income',
    label: 'Income',
    needsFormatter: true,
    width: '5%',
  },
  {
    id: 'roc',
    label: 'ROC',
    needsFormatter: true,
    width: '5%',
  },
  {
    id: 'as_of_date',
    label: 'As Of Date',
    needsFormatter: false,
    isDate: true,
    width: '10%',
  },
];

// ADD HOLDINGS ID AND ACCOUNT NUMBER HERE WHEN API IS READY
export const holdingHeaders = [
  'Investor_Id',
  'Investor_Name',
  'Fund_Id',
  'Fund_Name',
  'Current_Market_Value_US$',
  'Subscription_US$',
  'Redemption_US$',
  'Income_US$',
  'ROC_US$',
  'As_Of_Date',
];

export const mandatoryFields = ['Investor_Id', 'Fund_Id', 'Current_Market_Value_US$', 'As_Of_Date'];
// export const mandatoryFields = ['investor_id', 'fund_id', 'current_market_value', 'as_of_date'];
