import { read, utils, writeFile } from 'xlsx';
import { holdingHeaders, mandatoryFields } from './constants';

export function processExcel(data: any) {
  var workbook = read(data, {
    type: 'binary',
  });
  var json = to_json(workbook);
  return json;
}

function to_json(workbook: any) {
  var result: any = {};
  let csvHeader: any = [];
  workbook.SheetNames.forEach(function (sheetName: any) {
    var roa = utils.sheet_to_json(workbook.Sheets[sheetName], {
      header: 0,
      raw: false,
    });
    csvHeader = utils.sheet_to_json(workbook.Sheets[sheetName], {
      header: 1,
    });
    if (roa.length) result[sheetName] = roa;
  });
  return {
    result,
    header: csvHeader,
  };
}

export const checkIsValidDate = (value: string) => {
  if (!value) return false; // Takes care of the cases where date cell is empty.
  return value.includes('-') || value.includes('/');
};

export const errorValidation = (data: any) => {
  let validData: any = [];
  let invalidData: any = [];
  let isHeadersValid: any = false;
  const result = data.result[Object.keys(data.result)[0]];
  if (result) {
    for (const obj of result) {
      const isValidDate = checkIsValidDate(obj?.As_Of_Date);
      obj.As_Of_Date =
        obj?.As_Of_Date &&
        isValidDate &&
        !!new Date((obj?.As_Of_Date as string).replace(/-/g, '/')).getTime()
          ? new Date((obj?.As_Of_Date as string).replace(/-/g, '/'))
          : isValidDate
          ? obj?.As_Of_Date
          : `${obj?.As_Of_Date}-invalid`;
      const isValid = mandatoryFields.every(
        (item) =>
          obj.hasOwnProperty(item) &&
          !isNaN(Number(obj?.Investor_Id)) &&
          !isNaN(Number(obj?.Fund_Id)) &&
          !isNaN(Number(obj?.Current_Market_Value_US$)) &&
          obj?.As_Of_Date &&
          !!new Date(obj?.As_Of_Date).getTime() &&
          obj?.As_Of_Date,
      );
      if (!isValid) invalidData.push(obj);
      else validData.push(obj);
    }
  } else {
    Object.keys(data.result).length
      ? data.result?.output?.forEach((obj: any) => {
          invalidData.push(obj);
        })
      : invalidData.push({});
  }
  const headers = data.header[0];
  const stringifiedRequiredHeader = JSON.stringify(holdingHeaders.sort());
  const stringifiedHeaderFromFile = headers && JSON.stringify(headers.sort());

  if (
    headers?.length === holdingHeaders?.length &&
    stringifiedRequiredHeader === stringifiedHeaderFromFile
  ) {
    isHeadersValid = true;
  }
  return {
    validData,
    invalidData,
    isHeadersValid,
  };
};

export const convertDataToStringType = (data: any) => {
  // ADD HOLDINGS ID AND ACCOUNT NUMBER HERE WHEN API IS READY
  return data.map((item: any, index: number) => ({
    client_side_id: index,
    investor_id: item.Investor_Id?.toString(),
    ...(item.Investor_Name && { account_name: item.Investor_Name.toString() }),
    ...(item.Fund_Name && { fund_name: item.Fund_Name.toString() }),
    fund_id: item.Fund_Id?.toString(),
    current_market_value: item.Current_Market_Value_US$?.toString(),
    ...(item.Subscription_US$ && { subscription: item.Subscription_US$.toString() }),
    ...(item.Redemption_US$ && { redemption: item.Redemption_US$.toString() }),
    ...(item.Income_US$ && { income: item.Income_US$.toString() }),
    ...(item.ROC_US$ && { roc: item.ROC_US$.toString() }),
    as_of_date: item.As_Of_Date?.toString(),
  }));
};

export const handleTemplateDownload = () => {
  const fileName = 'Holdings Template.xlsx';
  const templateData = [
    {
      Investor_Id: '',
      Investor_Name: '',
      Fund_Id: '',
      Fund_Name: '',
      Current_Market_Value_US$: '',
      Subscription_US$: '',
      Redemption_US$: '',
      Income_US$: '',
      ROC_US$: '',
      As_Of_Date: '',
    },
  ];
  const ws = utils.json_to_sheet(templateData);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, 'Sheet1');
  writeFile(wb, fileName);
};
