import { Editor, RichUtils } from 'draft-js';
import '../../../../node_modules/draft-js/dist/Draft.css';
import './RichText.css';

type Props = {
  editorState: any;
  handleChangeEditorState: (newEditorState: any) => void;
  readOnly?: boolean;
};

function RichTextEditor({ editorState, handleChangeEditorState, readOnly = false }: Props) {
  const handleKeyCommand = (command: any) => {
    const newEditorState = RichUtils.handleKeyCommand(editorState, command);
    if (newEditorState) {
      handleChangeEditorState(newEditorState);
      return 'handled';
    }
    return 'not-handled';
  };

  // const toggleInlineStyle = (inlineStyle: any) => {
  //   const newEditorState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
  //   if (newEditorState) {
  //     handleChangeEditorState(newEditorState);
  //     return 'handled';
  //   }
  //   return 'not-handled';
  // };

  let className = 'RichEditor-editor';
  var contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  return (
    <div className={className}>
      {/* <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} /> */}
      <Editor
        editorState={editorState}
        onChange={handleChangeEditorState}
        handleKeyCommand={handleKeyCommand}
        readOnly={readOnly}
      />
    </div>
  );
}

// const StyleButton = (props: any) => {
//   const onToggle = (e: any) => {
//     e.preventDefault();
//     props.onToggle(props.style);
//   };

//   let className = 'RichEditor-styleButton';
//   if (props.active) {
//     className += ' RichEditor-activeButton';
//   }

//   return (
//     <span className={className} onMouseDown={onToggle}>
//       {props.label}
//     </span>
//   );
// };

// const INLINE_STYLES = [
//   { label: 'Bold', style: 'BOLD' },
//   { label: 'Italic', style: 'ITALIC' },
//   { label: 'Underline', style: 'UNDERLINE' },
// ];

// const InlineStyleControls = (props: any) => {
//   const currentStyle = props.editorState.getCurrentInlineStyle();

//   return (
//     <div className='RichEditor-controls'>
//       {INLINE_STYLES.map((type) => (
//         <StyleButton
//           key={type.label}
//           active={currentStyle.has(type.style)}
//           label={type.label}
//           onToggle={props.onToggle}
//           style={type.style}
//         />
//       ))}
//     </div>
//   );
// };

export default RichTextEditor;
