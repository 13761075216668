import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import styled from '@emotion/styled';
import Textbox from 'common/components/inputField/Textbox';
import { useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { selectFundStructAndStats } from 'store/funds/selectors';
import { setFundsDetails, setFundStructAndStats, setInvestmentStats } from 'store/funds/slice';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { ErrorMessage } from 'common/components/errorMessageBox';
import MuiDatePicker from 'common/components/inputField/DatePicker';
import { format } from 'date-fns';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ControlledSelectField from 'common/components/inputField/ControlledSelectField';
import { valueFormatter } from 'helpers';
import InvestmentManagerRecordsTable from '../../tables/investmentManagerRecords/InvestmentManagerRecordsTable';
import { INVESTMENT_MANAGER_RECORDS_COLUMNS } from './constants';

type Props = {
  handleSubmitBtnClick: any;
  handleBackBtnClick: any;
  editing?: boolean;
};

const StyledWrapper = styled.div`
  .heading {
    font-weight: 500;
  }
  .sub-heading {
    font-size: 18px;
    padding-bottom: 0.5rem;
    margin-bottom: 20px;
    font-weight: 500;
    border-bottom: 2px solid var(--s20);
  }
  .label {
    margin: 0;
    color: #0d0c22;
  }
`;

const InvestmentStructAndStatsForm = ({
  handleSubmitBtnClick,
  handleBackBtnClick,
  editing,
}: Props) => {
  const [selectedNextClose, setSelectedNextClose] = useState<any>(null);
  const [selectedFinalClose, setSelectedFinalClose] = useState<any>(null);
  const [investmentStatsArr, setInvestmentStatsArr] = useState<any[]>([{}]);
  const [fundAssets, setFundAssets] = useState<string | null>(null);
  const [firmAssets, setFirmAssets] = useState<string | null>(null);
  const [targetFundSize, setTargetFundSize] = useState<string | null>(null);
  const [managementFees, setManagementFees] = useState<string | null>(null);
  const [incentiveFees, setIncentiveFees] = useState<string | null>(null);
  const [preferredReturn, setPreferredReturn] = useState<string | null>(null);
  const [compoundedRateOfReturn, setCompoundedRateOfReturn] = useState<string | null>(null);
  const [standardDeviation, setStandardDeviation] = useState<string | null>(null);
  const [sharpeRatio, setSharpeRatio] = useState<string | null>(null);
  const [profitableMonths, setProfitableMonths] = useState<string | null>(null);
  const [maximumDrawdown, setMaximumDrawdown] = useState<string | null>(null);
  const [cashYield, setCashYield] = useState<string | null>(null);
  const [targetIrr, setTargetIrr] = useState<string | null>(null);
  const [impactScore, setImpactScore] = useState<string | null>(null);
  // Edit Funds state
  const location = useLocation();
  const id = location.pathname.split('/')[3];

  const storeFundStructAndStats = useAppSelector(selectFundStructAndStats);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const appendToList = () => {
    setInvestmentStatsArr([...investmentStatsArr, {}]);
  };

  const deleteFromList = (index: number) => {
    const newArr = investmentStatsArr.filter((x, i) => i !== index);
    setInvestmentStatsArr(newArr.length === 0 ? [{}] : newArr);
  };

  useEffect(() => {
    if (storeFundStructAndStats !== null) {
      setSelectedFinalClose(
        storeFundStructAndStats.final_close &&
          new Date(storeFundStructAndStats.final_close) > new Date()
          ? storeFundStructAndStats.final_close
          : null,
      );
      setSelectedNextClose(
        storeFundStructAndStats.next_close &&
          new Date(storeFundStructAndStats.next_close) > new Date()
          ? storeFundStructAndStats.next_close
          : null,
      );
      setInvestmentStatsArr(
        storeFundStructAndStats.fund_stats?.length > 0
          ? storeFundStructAndStats.fund_stats?.filter((item: any) => {
              const values: any[] = Object.values(item);
              for (let i = 0; i < values.length; i++) {
                if (values[i]) {
                  return true;
                }
              }
              return false;
            })
          : [{}],
      );
      setValue('funds_transfer_due_date', storeFundStructAndStats.funds_transfer_due_date);

      setFundAssets(storeFundStructAndStats.fund_assets?.toString());
      setFirmAssets(storeFundStructAndStats.firm_assets?.toString());
      setPreferredReturn(storeFundStructAndStats.preferred_return?.toString());
      setTargetFundSize(storeFundStructAndStats.target_fund_size?.toString());
      setCompoundedRateOfReturn(storeFundStructAndStats.compounded_rate_of_return?.toString());
      setStandardDeviation(storeFundStructAndStats.standard_deviation?.toString());
      setSharpeRatio(storeFundStructAndStats.sharpe_ratio?.toString());
      setCashYield(storeFundStructAndStats.cash_yield?.toString());
      setTargetIrr(storeFundStructAndStats.target_irr?.toString());
      setImpactScore(storeFundStructAndStats.impact_score?.toString());
      setProfitableMonths(storeFundStructAndStats.profitable_months_percentage?.toString());
      setIncentiveFees(storeFundStructAndStats.incentives_fees?.toString());
      setManagementFees(storeFundStructAndStats.management_fees?.toString());
      setMaximumDrawdown(storeFundStructAndStats.maximum_drawdown);

      setValue('gp_catch_up_term', storeFundStructAndStats.gp_catch_up_term);
      setValue('investment_period', storeFundStructAndStats.investment_period);
      setValue('fund_term', storeFundStructAndStats.fund_term);
      setValue('redemptions', storeFundStructAndStats.redemptions);
      setValue('lockup', storeFundStructAndStats.lockup);
      setValue('bank_details', storeFundStructAndStats.bank_details);
      setValue('s_and_p_500', storeFundStructAndStats.s_and_p_500);
      setValue('funding_type', storeFundStructAndStats.funding_type);
      setValue('lockup_for_summary', storeFundStructAndStats.lockup_for_summary);
      setValue('liquidity_for_summary', storeFundStructAndStats.liquidity_for_summary);
      setValue('income_distribution', storeFundStructAndStats.income_distribution);
    }
    // eslint-disable-next-line
  }, [storeFundStructAndStats]);

  const updateToList = (value: any, index: number) => {
    const oldList = [...investmentStatsArr];
    let current = { ...oldList[index] };
    current = { ...value };
    oldList[index] = current;
    setInvestmentStatsArr(oldList);
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    const currentDate = new Date();
    if (selectedFinalClose !== null || selectedFinalClose !== null) {
      if (editing && (selectedFinalClose < currentDate || selectedNextClose < currentDate)) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Next close and Final close cannot be past dates.',
        };
        dispatch(showToast(toast));
        window.scrollTo(0, 2200);
        return;
      }
    }

    if (selectedFinalClose === null && selectedNextClose !== null) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Final Close date cannot be null.',
      };
      dispatch(showToast(toast));
      window.scrollTo(0, 2200);
      return;
    }

    if (selectedFinalClose !== null && selectedNextClose === null) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Next Close date cannot be null.',
      };
      dispatch(showToast(toast));
      window.scrollTo(0, 2200);
      return;
    }

    const nextClose = selectedNextClose ? format(new Date(selectedNextClose), 'yyyy-MM-dd') : null;
    const finalClose = selectedFinalClose
      ? format(new Date(selectedFinalClose), 'yyyy-MM-dd')
      : null;

    if (nextClose && finalClose) {
      if (nextClose > finalClose) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Final Close date cannot be earlier than next close date.',
        };
        dispatch(showToast(toast));
        return;
      }
    }
    dispatch(setInvestmentStats(investmentStatsArr));

    const fundStructAndStats: any = {
      ...data,
      next_close: nextClose,
      final_close: finalClose,
      fund_stats: investmentStatsArr.filter((item: any) => {
        for (let index in item) {
          if (item[index] && index !== 'fund_stats_id') {
            return true;
          }
        }
        return false;
      }),
      fund_assets: fundAssets,
      firm_assets: firmAssets,
      target_fund_size: targetFundSize,
      management_fees: managementFees,
      incentives_fees: incentiveFees,
      preferred_return: preferredReturn ? Number(preferredReturn) : '',
      compounded_rate_of_return: compoundedRateOfReturn,
      standard_deviation: standardDeviation,
      sharpe_ratio: sharpeRatio,
      cash_yield: cashYield,
      target_irr: targetIrr,
      impact_score: impactScore,
      profitable_months_percentage: profitableMonths,
      maximum_drawdown: maximumDrawdown,
    };

    await dispatch(setFundStructAndStats(fundStructAndStats));
    handleSubmitBtnClick();
  };

  const handleKeyDown = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleDiscardChanges = () => {
    dispatch(setFundsDetails(null));
    dispatch(setFundStructAndStats(null));
    if (editing) {
      navigate(`/investment/${id}`);
    } else {
      navigate(`/investments`);
    }
  };

  const checkKeyDown = (e: any) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const handleKeyDownNumber = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
        <Container maxWidth='md' className='container-lr-padding my-5'>
          <Container maxWidth='lg' className='px-0'>
            <h2 className='heading'>Fund Structure & Stats</h2>
          </Container>
          <Container maxWidth='lg' className='my-5 px-0'>
            <h4 className='sub-heading'>Fund Structure</h4>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h4 className='font-wt-400 label'>Funds Transfer Due Date</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='funds_transfer_due_date'
                  onChange={(e: any) => setValue('funds_transfer_due_date', e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Fund Assets</h4>
                <Textbox
                  fullWidth
                  startAdornment='$'
                  register={register}
                  name='fund_assets'
                  value={fundAssets ? valueFormatter(fundAssets) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setFundAssets(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDownNumber}
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Firm Assets</h4>
                <Textbox
                  fullWidth
                  type='text'
                  keyDownFunc={handleKeyDown}
                  startAdornment='$'
                  register={register}
                  name='firm_assets'
                  value={firmAssets ? valueFormatter(firmAssets) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setFirmAssets(e.target.value.replace(/,/g, ''));
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Target Fund Size</h4>
                <Textbox
                  fullWidth
                  register={register}
                  startAdornment='$'
                  name='target_fund_size'
                  value={targetFundSize ? valueFormatter(targetFundSize) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setTargetFundSize(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Management Fees</h4>
                <Textbox
                  fullWidth
                  startAdornment='%'
                  register={register}
                  name='management_fees'
                  value={managementFees ? valueFormatter(managementFees) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setManagementFees(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Incentive Fees</h4>
                <Textbox
                  fullWidth
                  startAdornment='%'
                  register={register}
                  name='incentives_fees'
                  value={incentiveFees ? valueFormatter(incentiveFees) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setIncentiveFees(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Preferred Return</h4>
                <Textbox
                  fullWidth
                  register={register}
                  startAdornment='%'
                  name='preferred_return'
                  value={preferredReturn ? valueFormatter(preferredReturn) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setPreferredReturn(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>GP Catch-Up Term</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='gp_catch_up_term'
                  onChange={(e: any) => setValue('gp_catch_up_term', e.target.value)}
                  type='text'
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Investment Period (Yrs)</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='investment_period'
                  onChange={(e: any) => setValue('investment_period', e.target.value)}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Fund Term (Yrs)</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='fund_term'
                  onChange={(e: any) => setValue('fund_term', e.target.value)}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Redemptions</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='redemptions'
                  onChange={(e: any) => setValue('redemptions', e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Lockup</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='lockup'
                  onChange={(e: any) => setValue('lockup', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Funding Type</h4>
                <ControlledSelectField
                  control={control}
                  options={['Full Funded', 'Capital Call']}
                  name='funding_type'
                  searchIcon={false}
                  defaultValue={storeFundStructAndStats && storeFundStructAndStats?.funding_type}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Income Distribution</h4>
                <ControlledSelectField
                  control={control}
                  options={['None', 'Monthly', 'Quarterly', 'Semi-Annual', 'Annual']}
                  name='income_distribution'
                  searchIcon={false}
                  defaultValue={
                    storeFundStructAndStats && storeFundStructAndStats?.income_distribution
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Liquidity for Summary</h4>
                <ControlledSelectField
                  control={control}
                  options={['No Early Liquidity', 'Monthly', 'Quarterly', 'Annually']}
                  name='liquidity_for_summary'
                  searchIcon={false}
                  defaultValue={
                    storeFundStructAndStats && storeFundStructAndStats?.liquidity_for_summary
                  }
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Lockup for Summary</h4>
                <ControlledSelectField
                  control={control}
                  options={[
                    'No Lockup',
                    '3 Months',
                    '12 Months',
                    '18 Months',
                    '36 Months',
                    '60 Months',
                    'Fund Term',
                  ]}
                  name='lockup_for_summary'
                  searchIcon={false}
                  defaultValue={
                    storeFundStructAndStats && storeFundStructAndStats?.lockup_for_summary
                  }
                />
              </Grid>
            </Grid>
            <Grid container className='my-5'>
              <Grid item xs={12}>
                <h4 className='font-wt-400 label'>Bank Details</h4>
                <Textbox
                  fullWidth
                  multiline
                  rows={4}
                  register={register}
                  name='bank_details'
                  onChange={(e: any) => setValue('bank_details', e.target.value)}
                  maxLength={5000}
                />
                {errors.bank_details && errors.bank_details.type === 'maxLength' && (
                  <ErrorMessage error='Input limit exceeded. Character Limit is 5000' />
                )}
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth='lg' className='my-5 px-0'>
            <h4 className='sub-heading'>Fund Stats</h4>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Compound Rate of Return</h4>
                <Textbox
                  fullWidth
                  startAdornment='%'
                  register={register}
                  value={compoundedRateOfReturn ? valueFormatter(compoundedRateOfReturn) : ''}
                  name='compounded_rate_of_return'
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setCompoundedRateOfReturn(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Standard Deviation</h4>
                <Textbox
                  fullWidth
                  register={register}
                  startAdornment='%'
                  value={standardDeviation ? valueFormatter(standardDeviation) : ''}
                  name='standard_deviation'
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setStandardDeviation(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Sharpe Ratio</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='sharpe_ratio'
                  value={sharpeRatio ? valueFormatter(sharpeRatio) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setSharpeRatio(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Profitable Months</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='profitable_months_percentage'
                  value={profitableMonths ? valueFormatter(profitableMonths) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setProfitableMonths(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Maximum Drawdown</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='maximum_drawdown'
                  value={maximumDrawdown ? valueFormatter(maximumDrawdown) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setMaximumDrawdown(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>S&P 500 Correlation </h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='s_and_p_500'
                  pattern={{
                    value: /^[-]?(0+\.?|0*\.\d+|0*1(\.0*)?)$/g,
                    message: 'Range should be from "-1.0" to "+1.0',
                  }}
                  type='text'
                />
                {errors?.s_and_p_500?.message && (
                  <ErrorMessage error='Range should be from "-1.0" to "+1.0"' />
                )}
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Cash Yield </h4>
                <Textbox
                  fullWidth
                  startAdornment='%'
                  register={register}
                  name='cash_yield'
                  value={cashYield ? valueFormatter(cashYield) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setCashYield(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Target IRR </h4>
                <Textbox
                  fullWidth
                  startAdornment='%'
                  register={register}
                  name='target_irr'
                  value={targetIrr ? valueFormatter(targetIrr) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setTargetIrr(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Impact Score </h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='impact_score'
                  value={impactScore ? valueFormatter(impactScore) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setImpactScore(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Next close</h4>
                <MuiDatePicker
                  views={['year', 'month', 'day']}
                  inputFormat={'MMM-dd-yyyy'}
                  value={selectedNextClose}
                  setDate={setSelectedNextClose}
                  fullWidth
                  name='next_close'
                  minDate={new Date()}
                  disableKeyDown={false}
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Final Close</h4>
                <MuiDatePicker
                  views={['year', 'month', 'day']}
                  inputFormat={'MMM-dd-yyyy'}
                  value={selectedFinalClose}
                  setDate={setSelectedFinalClose}
                  fullWidth
                  name='final_close'
                  minDate={new Date()}
                  disableKeyDown={false}
                />
              </Grid>
            </Grid>
          </Container>
        </Container>
        <Container maxWidth='lg' className='container-lr-padding my-5'>
          <h4 className='sub-heading'>Manager Track Record</h4>

          <InvestmentManagerRecordsTable
            columns={INVESTMENT_MANAGER_RECORDS_COLUMNS}
            rows={investmentStatsArr}
            setUpdateRow={updateToList}
            setDeleteRow={deleteFromList}
          />
          <MuiButton buttonClick={appendToList} color='primary' className='mt-2'>
            Add Row
          </MuiButton>
          <Grid container justifyContent='flex-end'>
            <MuiButton variant='text' buttonClick={handleBackBtnClick}>
              Back
            </MuiButton>
            <MuiButton
              variant='text'
              color='error'
              buttonClick={handleDiscardChanges}
              className='ml-3'
            >
              Discard changes
            </MuiButton>
            <MuiButton minWidth='150px' variant='contained' type='submit' className='ml-3'>
              Next
            </MuiButton>
          </Grid>
        </Container>
      </form>
    </StyledWrapper>
  );
};

export default InvestmentStructAndStatsForm;
