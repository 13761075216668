import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHeadSkeleton from 'common/components/skeleton/TableHeadSkeleton';
import TableSubHeader from 'common/components/table/TableSubHeader';
import TableBodySkeleton from 'common/components/skeleton/TableBodySkeleton';
import { ChecklistStyle } from 'common/components/table/styles';
import { TableProps } from 'common/components/table/types';
import Textbox from 'common/components/inputField/Textbox';

interface Props extends TableProps {
  handleKeyDownNumber: (e: any) => void;
  firstYearReturn: any;
  setFirstYearReturn: Function;
  thirdYearReturn: any;
  setThirdYearReturn: Function;
  fifthYearReturn: any;
  setFifthYearReturn: Function;
  tenthYearReturn: any;
  setTenthYearReturn: Function;
  sinceInceptionReturn: any;
  setSinceInceptionReturn: Function;
}

const InceptionReturnsTable = ({
  rows = [],
  columns,
  isLoading = false,
  tableClassName = '',
  handleKeyDownNumber,
  firstYearReturn,
  setFirstYearReturn,
  thirdYearReturn,
  setThirdYearReturn,
  fifthYearReturn,
  setFifthYearReturn,
  tenthYearReturn,
  setTenthYearReturn,
  sinceInceptionReturn,
  setSinceInceptionReturn,
}: Props) => {
  const NUM_REGEX = /^-?[0-9]*\.?[0-9]*$/;

  return (
    <ChecklistStyle className={tableClassName}>
      <div className='tableWrapper'>
        <Table>
          {isLoading ? (
            <TableHeadSkeleton length={columns?.length} />
          ) : (
            <TableSubHeader columns={columns} disableOrderBy />
          )}
          <TableBody>
            {isLoading ? (
              <TableBodySkeleton length={columns?.length} />
            ) : (
              <>
                <TableRow>
                  <TableCell sx={{ width: `50%` }}>1 Year</TableCell>
                  <TableCell sx={{ width: `50%` }}>
                    <Textbox
                      className='mt-0'
                      value={firstYearReturn || ''}
                      type='text'
                      keyDownFunc={handleKeyDownNumber}
                      startAdornment='%'
                      onChange={(e: any) => {
                        if (NUM_REGEX.test(e.target.value.replace(/,/g, ''))) {
                          setFirstYearReturn(e.target.value.replace(/,/g, ''));
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: `50%` }}>3 Year</TableCell>
                  <TableCell sx={{ width: `50%` }}>
                    <Textbox
                      className='mt-0'
                      value={thirdYearReturn || ''}
                      type='text'
                      keyDownFunc={handleKeyDownNumber}
                      startAdornment='%'
                      onChange={(e: any) => {
                        if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value.replace(/,/g, ''))) {
                          setThirdYearReturn(e.target.value.replace(/,/g, ''));
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: `50%` }}>5 Year</TableCell>
                  <TableCell sx={{ width: `50%` }}>
                    <Textbox
                      className='mt-0'
                      value={fifthYearReturn || ''}
                      type='text'
                      keyDownFunc={handleKeyDownNumber}
                      startAdornment='%'
                      onChange={(e: any) => {
                        if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value.replace(/,/g, ''))) {
                          setFifthYearReturn(e.target.value.replace(/,/g, ''));
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: `50%` }}>10 Year</TableCell>
                  <TableCell sx={{ width: `50%` }}>
                    <Textbox
                      className='mt-0'
                      value={tenthYearReturn || ''}
                      type='text'
                      keyDownFunc={handleKeyDownNumber}
                      startAdornment='%'
                      onChange={(e: any) => {
                        if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value.replace(/,/g, ''))) {
                          setTenthYearReturn(e.target.value.replace(/,/g, ''));
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: `50%` }}>Since Inception</TableCell>
                  <TableCell sx={{ width: `50%` }}>
                    <Textbox
                      className='mt-0'
                      value={sinceInceptionReturn || ''}
                      type='text'
                      keyDownFunc={handleKeyDownNumber}
                      startAdornment='%'
                      onChange={(e: any) => {
                        if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value.replace(/,/g, ''))) {
                          setSinceInceptionReturn(e.target.value.replace(/,/g, ''));
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </ChecklistStyle>
  );
};

export default InceptionReturnsTable;
