import { ChevronRight } from '@mui/icons-material';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CarouselBtn from 'common/assets/images/CarouselBtn.svg';
import MuiButton from '../button';
import MuiTooltip from '../tooltip';
import { ReactComponent as CompareIcon } from 'common/assets/images/recommendation/Compare.svg';
import { CustomMuiButton, StyledWrapper } from './styles';

interface Props {
  length: number;
  heading: string;
  children: React.ReactNode;
  className?: string;
  disableViewAll?: boolean;
  tooltip?: string | React.ReactNode;
  navLink: string;
  ctaText?: string;
  compare?: any;
  latestFund?: any;
}

const CardCarousel = ({
  length,
  heading,
  children,
  className = 'container-lr-padding',
  disableViewAll = false,
  tooltip = '',
  navLink = '',
  ctaText = 'View All',
  compare,
  latestFund,
}: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false, align: 'start', draggable: false });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.selectedScrollSnap() < length - 3);
  }, [emblaApi, length]);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect, length]);

  const handleAddToCart = () => {
    navigate('/investments', {
      state: {
        showCompareModal: true,
      },
    });
  };

  return (
    <StyledWrapper maxWidth='xl' className={className}>
      <h2 className='font-wt-500 flex-center-between'>
        <div className='flex-center-start'>
          {heading}{' '}
          {!disableViewAll && (
            <CustomMuiButton className='ml-1' buttonClick={() => navigate(navLink)}>
              {ctaText} <ChevronRight fontSize='small' />
            </CustomMuiButton>
          )}
          {tooltip && <MuiTooltip content={tooltip} />}
        </div>

        {true && (
          <MuiButton variant='outlined' buttonClick={handleAddToCart}>
            <CompareIcon className='mr-2' />
            Compare Funds
          </MuiButton>
        )}
      </h2>
      <div className='emblaContainer'>
        <img
          src={CarouselBtn}
          className={`carousel_btn left ${prevBtnEnabled ? '' : 'd-none'}`}
          alt='>'
          onClick={() => {
            scrollPrev();
          }}
        />
        <div className='embla' ref={emblaRef}>
          <div className='embla__container d-flex'>{children}</div>
        </div>
        <img
          src={CarouselBtn}
          className={`carousel_btn right ${nextBtnEnabled ? '' : 'd-none'}`}
          alt='>'
          onClick={() => {
            scrollNext();
          }}
        />
      </div>
    </StyledWrapper>
  );
};

export default CardCarousel;
