import { useAppSelector } from 'store/hooks';
import { recEngineNumber } from 'store/recommendation/selectors';
import Engine1 from './Engine1';
import Engine2 from './Engine2';
import Engine3 from './Engine3';

type Props = {
  backClick: any;
  doneClick: any;
  selectedClient: any;
  clientList: any;
};

const Step3V2 = ({ backClick, doneClick, selectedClient, clientList }: Props) => {
  const selectedEngineNumber = useAppSelector(recEngineNumber);
  return (
    <>
      {selectedEngineNumber === 1 ? (
        <Engine1
          backClick={backClick}
          doneClick={doneClick}
          selectedClient={selectedClient}
          clientList={clientList}
        />
      ) : selectedEngineNumber === 2 ? (
        <Engine2
          backClick={backClick}
          doneClick={doneClick}
          selectedClient={selectedClient}
          clientList={clientList}
        />
      ) : (
        <Engine3 clientList={clientList} />
      )}
    </>
  );
};

export default Step3V2;
