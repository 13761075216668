import styled from '@emotion/styled';
import { formatAumValues } from 'helpers';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { useAppSelector } from 'store/hooks';
import { portfolioValue } from 'store/recommendation/selectors';

const StyledAreaChart = styled(AreaChart)`
  border: 1px solid var(--s20);
  border-radius: 0.5rem;
  overflow: hidden;

  .custom-tooltip {
    background-color: white;
    padding: 0.5rem;
    min-width: 100px;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    h4 {
      font-size: 13px;
      font-weight: 500;
      color: var(--s30);
      span {
        color: var(--p200);
      }
    }
    h5 {
      color: var(--s30);
    }
  }
`;

const StyledAreaChartX = styled(AreaChart)`
  .recharts-xAxis {
    text {
      fill: var(--s30);
      font-weight: 600;
    }

    .recharts-cartesian-axis-tick:first-of-type {
      transform: translateX(10px);
    }
    .recharts-cartesian-axis-tick:last-of-type {
      transform: translateX(-10px);
    }
    .recharts-label {
      fill: var(--s40) !important;
      font-size: 13px;
    }
  }

  .recharts-yAxis {
    text {
      fill: var(--s30);
      font-weight: 600;
    }
    .recharts-label {
      fill: var(--s40) !important;
      font-size: 13px;
    }
  }
`;

interface Props {
  data?: Array<any>;
}

const Engine3WealthChart = ({ data = [] }: Props) => {
  const portfolioVal = useAppSelector(portfolioValue);
  const COLORS = [
    {
      stroke: '#BDBDBD',
      fill: '#F4F4F4',
    },
    {
      stroke: '#7AA6B4',
      fill: '#DBF2FC',
    },
    {
      stroke: '#FFBF5F',
      fill: '#FFF4E3',
    },
    {
      stroke: '#4ACCA9',
      fill: '#E1F6F1',
    },
    {
      stroke: '#A0A3F2',
      fill: '#EFEFFD',
    },
  ];

  const areaChartSort = () => {
    if (data && data?.length > 0) {
      const lastPoint = data?.[data?.length - 1];
      const arr = ['traditional', 'theme1', 'theme2', 'theme3', 'theme4']?.map((item: string) =>
        !!lastPoint[item] ? item : null,
      );
      return arr;
    } else return [];
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload?.length) {
      return (
        <div className='custom-tooltip'>
          {['traditional', 'theme1', 'theme2', 'theme3', 'theme4']
            ?.reverse()
            ?.map((key: any, k: number) => (
              <h4 className='font-wt-400 mb-1'>
                {key === 'theme1'
                  ? 'Diversification'
                  : key === 'theme2'
                  ? 'Income'
                  : key === 'theme3'
                  ? 'Growth'
                  : key === 'theme4'
                  ? 'ESG'
                  : 'Traditional Portfolio'}{' '}
                <span style={{ color: COLORS[COLORS?.length - 1 - k]?.stroke }}>
                  ${Number(payload?.[0]?.payload?.[key]).toLocaleString()}
                </span>
              </h4>
            ))}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className='d-flex'>
        <ResponsiveContainer width={'3%'} height={280}>
          <StyledAreaChartX width={600} height={215} data={data} margin={{ top: 0, left: 10 }}>
            <XAxis fontSize={13} dataKey='age' hide />
            <YAxis
              fontSize={13}
              dx={5}
              axisLine={false}
              tickLine={false}
              ticks={[portfolioVal]}
              domain={['dataMin', 'dataMax']}
              label={{ value: `Portfolio Value`, dx: -30, angle: -90 }}
              tickFormatter={(val: any, i: number) => {
                return formatAumValues(val, 0);
              }}
            ></YAxis>
            <Area
              type='monotone'
              dot={false}
              dataKey='theme1'
              stroke='transparent'
              fill='transparent'
              strokeWidth={1.5}
            />
            <Area
              type='monotone'
              dot={false}
              dataKey='theme2'
              stroke='transparent'
              fill='transparent'
              strokeWidth={1.5}
            />
            <Area
              type='monotone'
              dot={false}
              dataKey='theme3'
              stroke='transparent'
              fill='transparent'
              strokeWidth={1.5}
            />
            <Area
              type='monotone'
              dot={false}
              dataKey='theme4'
              stroke='transparent'
              fill='transparent'
              strokeWidth={1.5}
            />
          </StyledAreaChartX>
        </ResponsiveContainer>

        <ResponsiveContainer width={'90%'} height={280}>
          <StyledAreaChart width={600} height={215} data={data} margin={{ top: 0, left: 0 }}>
            <XAxis
              fontSize={13}
              dataKey='age'
              hide
              stroke='#bdbdbd'
              tickLine={false}
              interval={4}
              dy={10}
              tickFormatter={(val: any, i: number) => {
                return val + 'yrs' + i;
              }}
            />
            <YAxis hide domain={['dataMin', 'dataMax']} padding={{ top: 30 }} />
            <Tooltip content={<CustomTooltip />} />
            {areaChartSort()?.map((item: string | null, i: number) => {
              if (!item) return <></>;
              else
                return (
                  <Area
                    stackId={'test'}
                    type='monotone'
                    dot={false}
                    dataKey={item}
                    stroke={COLORS[i].stroke}
                    fillOpacity={0.9}
                    fill={COLORS[i].fill}
                    strokeWidth={2}
                    z={i}
                  />
                );
            })}
          </StyledAreaChart>
        </ResponsiveContainer>

        <ResponsiveContainer width={'7%'} height={280}>
          <StyledAreaChartX width={600} height={215} data={data} margin={{ top: 0, left: 0 }}>
            <XAxis fontSize={13} dataKey='age' hide />
            <YAxis hide width={100} padding={{ top: 30 }} />
            {areaChartSort()?.map((item: string | null, i: number) => {
              if (!item) return <></>;
              else
                return (
                  <Area
                    stackId={'test1234'}
                    type='monotone'
                    dataKey={item}
                    fillOpacity={0.2}
                    strokeWidth={2}
                    isAnimationActive={false}
                    opacity={0}
                    label={(props: any) => {
                      const lastIndex = data ? data.length - 1 : -1;
                      const lastPoint = data ? data[lastIndex][item] : '';
                      if (i === 0 || i === areaChartSort()?.length - 1)
                        return props.index === lastIndex ? (
                          <text
                            // x={props.x}
                            {...props}
                            y={props.y - 12}
                            textAnchor='end'
                            fontSize={13}
                            fill={COLORS[i]?.stroke}
                            dominantBaseline='central'
                          >
                            {formatAumValues(lastPoint)}
                          </text>
                        ) : null;
                      else return null;
                    }}
                  />
                );
            })}
          </StyledAreaChartX>
        </ResponsiveContainer>
      </div>

      {/* TO SHOW THE X AXIS BELOW THE STYLED CHART */}
      <ResponsiveContainer width={'93%'} height={50} className='mx-auto'>
        <StyledAreaChartX width={600} height={10} data={data} margin={{ top: 0, left: 0 }}>
          <XAxis
            fontSize={13}
            dataKey='age'
            stroke='var(--s40)'
            tickLine={false}
            axisLine={false}
            dy={-15}
            label={{ value: `Client's Age`, dy: 5 }}
            tickFormatter={(val: any, i: number) => {
              if (i === 0) return data[0].age;
              else if (i === data.length - 2) return data[data.length - 1].age;
              else return '';
            }}
          />
          <Area hide type='monotone' dataKey='theme1' stroke='var(--p200)' strokeWidth={1.5} />
          <Area hide type='monotone' dataKey='theme2' stroke='var(--p200)' strokeWidth={1.5} />
          <Area hide type='monotone' dataKey='theme3' stroke='var(--p200)' strokeWidth={1.5} />
          <Area hide type='monotone' dataKey='theme4' stroke='var(--s20)' strokeWidth={1.5} />
        </StyledAreaChartX>
      </ResponsiveContainer>
    </>
  );
};

export default Engine3WealthChart;
