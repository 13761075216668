import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';

export const initialState: any = {
  portfolioValue: 0,
  recommendedAllocation: null,
  engineNumber: 1,
  recommendationNumber: 0,
  activeStep: 0,
  maxAlloc: null,
  client: null,
  isLandingPage: true,
  clientAge: null,
  engine1Result: null,
  engine2Result: null,
  engine1DataForEngine2Result: null,
  isInputDrivingRecButtonVisible: false,
  isSelectedClientNonNaturalPerson: false,
  fundsToCompare: [],
  fundsBasedOn: 'Similarity',
};

const slice = createSlice({
  name: 'recommendation',
  initialState,
  reducers: reducers,
});

export const {
  setPortfolioValue,
  setEngineNumber,
  setRecommendationNumber,
  setRecommendedAllocation,
  setRecommendationActiveStep,
  setRecommendationClient,
  setIsLandingPage,
  setClientAge,
  setEngine1Result,
  setEngine2Result,
  setEngine1DataForEngine2Result,
  setMaxAllocation,
  setIsInputDrivingRecButtonVisible,
  setIsSelectedClientNonNaturalPerson,
  setFundsToCompare,
  setFundsBasedOn,
} = slice.actions;

export default slice.reducer;
