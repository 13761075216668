import userSaga from 'store/user/authSaga';
import { all, takeLatest } from 'redux-saga/effects';
import profileSaga from './user/profileSaga';
import store from 'store';

export default function* rootSaga() {
  yield all([initSaga(), userSaga(), profileSaga()]);
}

function* initSagaHandler() {
  yield store.dispatch({ type: 'INIT_AUTH' });
}

function* initSaga() {
  yield takeLatest('INIT_SAGA_HANDLER', initSagaHandler);
}
