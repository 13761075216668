import React, { useEffect, useState } from 'react';
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import SelectField from 'common/components/inputField/SelectField';
import Textbox from 'common/components/inputField/Textbox';
import { ReactComponent as Delete } from 'common/assets/images/DocDelete.svg';
import { ReactComponent as Edit } from 'common/assets/images/Pencil.svg';

interface Props {
  row: any;
  columns: Array<any>;
  i: number;
  updateRow: (row: any, index: number) => void;
  deleteRow: Function;
  isDeleting: boolean;
}

const SubMasterListTableRow = ({ row, columns, i, updateRow, deleteRow, isDeleting }: Props) => {
  const [edit, setEdit] = useState<boolean>(row?.id ? false : true);
  const [selectedDeleteRow, setSelectedDeleteRow] = useState<any>(null);
  const [fields, setFields] = useState<any>({
    title: row.title || '',
    type: row.type || '',
    visible_to_natural_persons: row.visible_to_natural_persons || '',
    visible_to_entities: row.visible_to_entities || '',
    visible_to_hybrids: row.visible_to_hybrids || '',
  });

  useEffect(() => {
    updateRow({ ...row, ...fields }, i);
    //eslint-disable-next-line
  }, [fields, i]);

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {React.Children.toArray(
        columns.map((col: any) => (
          <TableCell
            align={col.align}
            className={`
        ${col.cellClassName ? col.cellClassName : ''}
      `}
            sx={{ width: `${col.width ? col.width : 'initial'}` }}
          >
            {col.isSelect ? (
              <SelectField
                className={`selectField pt-0 ${edit ? 'edit' : ''}`}
                disabled={!edit}
                searchIcon={false}
                optionId='id'
                optionName='title'
                options={col.selectOptions}
                setSelectedValue={(e: any) => {
                  setFields({ ...fields, [col.id]: e.id });
                }}
                value={fields[col.id]}
                defaultValue={fields[col.id]}
              />
            ) : col.isInput ? (
              <div className='flex-center-between'>
                <Textbox
                  fullWidth
                  placeholder='Document Name'
                  className={`textInput ${fields[col.id] ? '' : 'empty'} ${
                    edit ? 'edit' : ''
                  } mt-0`}
                  disabled={!edit}
                  value={fields[col.id]}
                  onChange={(e: any) => setFields({ ...fields, [col.id]: e.target.value })}
                />
              </div>
            ) : (
              row[col.id]
            )}
          </TableCell>
        )),
      )}
      <TableCell>
        <div className='flex-center-start'>
          <Edit
            className={`penIcon mr-3 cursor-pointer ${edit ? 'edit' : ''}`}
            onClick={() => {
              setEdit(!edit);
            }}
          />
          {isDeleting && row?.id === selectedDeleteRow ? (
            <CircularProgress className='loading' />
          ) : (
            <Delete
              className='cursor-pointer'
              onClick={async () => {
                setSelectedDeleteRow(row?.id);
                deleteRow(
                  row?.id
                    ? {
                        id: row.id,
                        deleteFromServer: true,
                      }
                    : {
                        id: row.UID,
                        deleteFromServer: false,
                      },
                );
              }}
            />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default SubMasterListTableRow;
