import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { TableNoDataProps  } from './types';

export const TableNoData = ({columns, ctaType}: TableNoDataProps) => {
  return (
    <TableRow>
      <TableCell
        colSpan={columns?.length + (ctaType ? 1 : 0)}
        className='nullTable'
      >
        No Data Available
      </TableCell>
    </TableRow>
  )
}