import { Box, Grid, styled } from '@mui/material';
import ChatBotBulb from 'common/assets/images/ChatBotBulb.svg';
import Arrow from 'common/assets/images/InsightArrow.svg';

interface Props {
  setDisplayState: any;
  setJourney: any;
}

const StyledWrapper = styled(Box)`
  margin: 0 1.5rem 0.75rem;

  .insightBulb {
    margin-right: 0.5rem;
  }

  .receiverBubble,
  .senderBubble {
    display: flex;
    align-items: center;
    padding: 1rem 1.25rem;
    background-color: white;
    max-width: 80%;
  }

  .receiverBubble {
    background-color: var(--s10);
    align-self: flex-start;
    margin-bottom: 0.75rem;
    border-radius: 0 1rem 1rem 1rem;
  }

  .senderBubble {
    cursor: pointer;
    align-self: flex-end;
    border: 1px solid var(--p150);
    border-radius: 1rem 0 1rem 1rem;
    span {
      color: var(--p150);
    }
    img {
      margin-left: 0.5rem;
    }
  }
`;

const PLACEHOLDER_DATA = [
  {
    headline: 'Markets Sell-off',
    receiver: 'Did you know the S&P 500 was down 14% in the first four months of the year?',
    sender: ' protect clients against further downside risks',
    journey: 0,
  },
  {
    headline: 'S&P 500 Targets Lowered',
    receiver:
      'Did you know that top Wall Street analysts have lowered growth forecasts for US GDP?',
    sender: ' find investments that invest in growth opportunities',
    journey: 1,
  },
  {
    headline: '10y Treasury Rate Spike',
    receiver: 'Did you know that 10y Treasury yield has risen by almost 50bps in the last month?',
    sender: ' capitalize on rising rates by investing in private credit funds',
    journey: 2,
  },
  {
    headline: 'Impact Powers Performance',
    receiver:
      'Did you know that U.S. sustainable investments have outperformed their traditional peer funds?',
    sender: ' find impact investments',
    journey: 3,
  },
];

const JourneyMenu = ({ setDisplayState, setJourney }: Props) => {
  return (
    <StyledWrapper>
      {PLACEHOLDER_DATA.map((data: any, i: number) => (
        <Grid container mb={5} key={`journey${i}`}>
          <Grid item xs={12} container alignItems='center' mb={1.75}>
            <img src={ChatBotBulb} alt='' className='insightBulb' />
            <h3 className='font-wt-400 mb-0'>{data.headline}</h3>
          </Grid>
          <Grid item xs={12} className='d-flex flex-col'>
            <div className='receiverBubble'>{data.receiver}</div>
            <div
              className='senderBubble'
              onClick={() => {
                setJourney(data.journey);
                setDisplayState();
              }}
            >
              <div>
                I can help you <span>{data.sender}</span>
              </div>
              <img src={Arrow} alt='>' />
            </div>
          </Grid>
        </Grid>
      ))}
    </StyledWrapper>
  );
};

export default JourneyMenu;
