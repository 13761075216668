import { useCallback, useMemo } from 'react';
import { Avatar, Container, Grid } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import MuiButton from 'common/components/button/index';
import MuiCard from 'common/components/card';

import { useGraphQLQuery } from 'store/hooks';
import { formatAumValues } from 'helpers';
import { GET_ACTIVE_CLIENTS } from './queries';
import { UserProfile } from 'store/user/types';
import HeroSkeletonLoader from './SkeletonLoader/HeroSkeletonLoader';

export const StyledHero = styled.div`
  background-color: white;
  padding: 7rem 0;
  margin-top: 4rem;

  .MuiContainer-root {
    padding: 0 200px;
  }

  h2 {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 1rem;
    color: #474747;
    &.mb-0 {
      margin-bottom: 0;
    }
  }

  p {
    width: 150px;
    font-size: 11px;
    margin-bottom: 0;
    line-height: 16px;
    padding-left: 0.5rem;
    &.mainDesc {
      font-size: 13px;
      max-width: 36ch;
      line-height: 20px;
      padding-left: 0;
      width: unset;
      color: #333333;
    }
  }

  p,
  h4 {
    color: #525863;
  }

  h4 {
    font-size: 14px;
    margin-bottom: 0;
    &.mb-12 {
      margin-bottom: 12px;
    }
  }

  button {
    width: 150px;
  }

  .client {
    padding: 5px;
    border-radius: 0.5rem;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #333;

    &:hover {
      background-color: #ebebeb;
    }
  }

  .client + .client {
    margin-top: 12px;
  }

  .MuiAvatar-root {
    border-radius: 0.5rem;
    font-size: 14px;
    font-weight: 500;
    background-color: var(--s15);
    color: var(--s50);
    margin-right: 0.5rem;
    width: 28px;
    height: 27px;
  }

  button {
    border-radius: 6px;
    padding: 8px;
  }

  .subtitle {
    font-size: 13px;
    font-weight: 600;
    color: var(--s40);
    line-height: 1rem;
  }

  .mb-12 {
    margin-bottom: 0.75rem;
  }
`;

interface Props {
  user: UserProfile | null;
}

const Hero = ({ user }: Props) => {
  const navigate = useNavigate();
  const name: string = user?.firstName ?? '';
  const totalAum: number = user?.aum ?? 0;
  const totalClients = user?.totalClients ?? 0;

  const { data: activeClients = [], isLoading: activeClientsLoading } = useGraphQLQuery(
    `activeClientList${user?.userId}`,
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_ACTIVE_CLIENTS,
      variables: {
        userId: user?.userId,
      },
    },
    { enabled: !!user, refetchOnWindowFocus: false },
  );

  const clients = useMemo(() => {
    const activeInvestors = activeClients?.getActiveInvestors ?? [];
    if (Array.isArray(activeInvestors) && activeInvestors.length > 0) {
      return activeInvestors;
    }
    return [];
  }, [activeClients]);

  const clientAccountName = useCallback((clientAccount) => {
    const accountName = clientAccount?.accountName ?? '';

    if (accountName.length > 30) {
      return `${accountName.substring(0, 25)}...${accountName.substring(
        accountName.length - 4,
        accountName.length,
      )}`;
    }

    return accountName;
  }, []);

  if (activeClientsLoading) return <HeroSkeletonLoader />;

  return (
    <StyledHero>
      <Container maxWidth='xl'>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item xs='auto'>
            <h2>
              Hello,
              <br /> {name}
            </h2>
            <p className='mainDesc'>
              Welcome to your dashboard. Start here to explore investments and place subscriptions.
            </p>
          </Grid>
          <Grid
            item
            sx={{ width: 648 }}
            container
            columnSpacing={4}
            className={clients?.length === 0 ? 'flex-center-end' : ''}
          >
            <Grid item xs={6}>
              <MuiCard minHeight='0' sx={{ px: 2, py: '12px', mb: 4 }}>
                <Grid container>
                  <Grid item xs={12} className='flex-start-between' mb={1}>
                    <span className='mt-1 subtitle'>CLIENTS</span>
                    <p>Add Clients, view holdings and more</p>
                  </Grid>
                  <Grid item xs={12} className='flex-start-between'>
                    <h2 className='mb-0'>{totalClients ? totalClients : '--'}</h2>
                    <MuiButton
                      variant='contained'
                      fullWidth
                      minWidth='150px'
                      buttonClick={() => navigate('/clients')}
                    >
                      Clients
                    </MuiButton>
                  </Grid>
                </Grid>
              </MuiCard>

              <MuiCard minHeight='0' sx={{ px: 2, py: '12px' }}>
                <Grid container>
                  <Grid item xs={12} className='flex-start-between' mb={1}>
                    <span className='mt-1 subtitle'>NAV</span>
                    <p>Explore investments and place subscriptions</p>
                  </Grid>
                  <Grid item xs={12} className='flex-start-between'>
                    <h2 className='mb-0'>{totalAum ? formatAumValues(totalAum, 0) : '--'}</h2>
                    <MuiButton
                      variant='contained'
                      fullWidth
                      buttonClick={() => navigate('/investments')}
                    >
                      Investments
                    </MuiButton>
                  </Grid>
                </Grid>
              </MuiCard>
            </Grid>

            {clients?.length > 0 && (
              <Grid item xs={6} className='d-flex'>
                <MuiCard minHeight='0' sx={{ px: 2, pt: '12px', pb: '13px', width: '100%' }}>
                  <span className='subtitle w-100 mb-12'>ACTIVE CLIENTS</span>
                  {clients.slice(0, 4).map((client: any, i: number) => (
                    <div
                      className='client cursor-pointer mt-2'
                      key={`activeClient${i}`}
                      onClick={() => navigate(`/client/${client.investorId}`)}
                    >
                      <Avatar variant='rounded'>
                        {client?.accountName ? client?.accountName[0] : '-'}
                      </Avatar>
                      {clientAccountName(client) ?? '--'}
                      <ChevronRight fontSize='small' sx={{ ml: 'auto' }} />
                    </div>
                  ))}
                </MuiCard>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </StyledHero>
  );
};

export default Hero;
