import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from 'store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

// React Query
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnMount: false } },
});

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
