export const INCEPTION_RTN_COLUMNS = [
  {
    id: '',
    label: 'Period',
  },
  {
    id: '',
    label: 'Return',
  },
];

export const INVESTMENT_MANAGER_RECORDS_COLUMNS = [
  {
    id: 'fund',
    label: 'FUND',
    isSelect: false,
    isInput: true,
    cellClassName: 'primary',
  },
  {
    id: 'vintage',
    label: 'VINTAGE',
    isSelect: false,
    isInput: true,
  },
  {
    id: 'size',
    label: 'SIZE',
    isSelect: false,
    isInput: true,
  },
  {
    id: 'net_dpi',
    label: 'NET DPI',
    isSelect: false,
    isInput: true,
  },
  {
    id: 'net_tvpi',
    label: 'NET TVPI',
    isSelect: false,
    isInput: true,
  },
  {
    id: 'net_irr',
    label: 'NET IRR',
    isSelect: false,
    isInput: true,
  },
];
