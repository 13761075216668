import styled from '@emotion/styled';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

const StyledSVG = styled.svg`
  text {
    font: 30px Inter, sans-serif;
    fill: black;
    text-anchor: middle;
    &.month {
      font-size: 13px;
    }
  }
`;

interface Props {
  date?: string;
  className?: string;
}

const EventCalendar = ({ date, className = '' }: Props) => {
  const [day, setDay] = useState<string>('');
  const [month, setMonth] = useState<string>('');

  useEffect(() => {
    if (date) {
      const formattedDate = format(new Date(date?.replace(/-/g, '/')), 'dd MMMM').split(' ');
      setDay(formattedDate[0]);
      setMonth(formattedDate[1]);
    }
  }, [date]);

  return (
    <StyledSVG
      width='100%'
      viewBox='0 0 105 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_1948_9367)'>
        <path
          d='M104.586 100H0.739746V27.3706H104.586V100ZM4.24847 96.4782H101.079V30.8925H4.24847V96.4782Z'
          fill='black'
        />
        <path d='M20.6182 0H24.1269V16.9013H20.6182V0Z' fill='black' />
        <path d='M81.1885 0H84.6972V16.9013H81.1885V0Z' fill='black' />
        <path
          d='M104.586 30.903H0.739746V6.57153H104.586V30.903ZM4.24847 27.3811H101.079V10.0934H4.24847V27.3811Z'
          fill='black'
        />
        <text x='50%' y='65%'>
          {day}
        </text>
        <text x='50%' y='85%' className='month'>
          {month}
        </text>
      </g>
      <defs>
        <clipPath id='clip0_1948_9367'>
          <rect width='103.846' height='100' fill='white' transform='translate(0.739746)' />
        </clipPath>
      </defs>
    </StyledSVG>
  );
};

export default EventCalendar;
