import TableHeadSkeleton from '../skeleton/TableHeadSkeleton';
import TableSubHeader from './TableSubHeader';
import { TableHeaderProps } from './types';

export const TableHeader = ({isLoading, columns, setOrderBy, orderBy, ctaType}: TableHeaderProps) => {
  return (
    <>
    {isLoading ? (
      <TableHeadSkeleton length={columns?.length} />
    ) : (
      <TableSubHeader
        columns={columns}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        ctaType={ctaType}
      />
    )}
    </>
  )
}