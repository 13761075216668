import React from 'react';
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import { CheckCircle, Close } from '@mui/icons-material';

interface Props {
  row: any;
  columns: Array<any>;
  deleteRow: any;
  isDeleting: boolean;
  clickedDocId: any;
}

const MarketingDocsTableRow = ({
  row,
  columns,
  deleteRow,
  isDeleting = false,
  clickedDocId,
}: Props) => {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {React.Children.toArray(
        columns.map((col: any) => (
          <TableCell
            align={col.align}
            className={`
        ${col.cellClassName ? col.cellClassName : ''}
      `}
            sx={{ width: `${col.width ? col.width : 'initial'}` }}
          >
            {col.id === 'docName' ? (
              <span className='flex-center-start'>
                <CheckCircle className='checkCircle' />
                {row.docName.length > 50
                  ? row.docName.substring(0, 45) +
                    '...' +
                    row.docName.substring(row.docName.length - 8, row.docName.length)
                  : row.docName}
              </span>
            ) : (
              row[col.id]
            )}
          </TableCell>
        )),
      )}
      <TableCell align='right'>
        {isDeleting && row?.documentId === clickedDocId ? (
          <CircularProgress className='close' />
        ) : (
          <Close
            className='close cursor-pointer'
            onClick={() => {
              deleteRow(row.documentId);
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default MarketingDocsTableRow;
