import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import { ClickAwayListener, Grid, Tooltip } from '@mui/material';
import { ReactComponent as Pencil } from 'common/assets/images/Pencil2.svg';
import styled from '@emotion/styled';
import { getHoldingsData } from 'components/recommendations/helper';
import MuiTable from 'common/components/table/Table';
import {
  setEngine1DataForEngine2Result,
  setEngine1Result,
  setEngine2Result,
  setRecommendationActiveStep,
  setRecommendedAllocation,
} from 'store/recommendation/slice';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import {
  clientAge,
  isSelectedClientNonNaturalPerson,
  recEngineNumber,
  recommendationClient,
  recommendedAllocation,
} from 'store/recommendation/selectors';
import {
  HOLDING_COLUMNS,
  HOLDING_COLUMNS_EXCLUDING_FUNDNAME,
} from 'components/recommendations/constants';
import { useMutation } from 'react-query';
import Loading from 'common/components/Loading';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { createHoldingPayload, getRecommendationData, getThemesData } from '../utils';
import { useState } from 'react';

const StyledModal = styled(MuiModal)`
  .title {
    font-family: Inter;
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
  }

  .description {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #828282;
  }

  .holdings-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #333333;
  }

  .MuiPaper-root {
    min-height: 90vh;
  }
`;

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  selectedClient: any;
  data: any;
  isReadOnly?: boolean;
  tickersData?: any;
};

const PortfolioModal = ({
  isModalOpen,
  handleClose,
  selectedClient,
  data,
  isReadOnly = false,
  tickersData,
}: Props) => {
  const [open, setOpen] = useState(false);
  const storedClient: any = useAppSelector(recommendationClient);
  const dispatch = useTypedDispatch();
  const recAllocation = useAppSelector(recommendedAllocation);
  const isNonNaturalPerson = useAppSelector(isSelectedClientNonNaturalPerson);
  const age = useAppSelector(clientAge);
  const engineNo = useAppSelector(recEngineNumber);

  const portfolioVal = data
    ?.filter((item: any) =>
      tickersData?.tickers_name.map((i: any) => i.ticker).includes(item.Ticker),
    )
    .reduce((prev: any, curr: any) => prev + Number(curr.Amount), 0);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const { mutate: triggerEngine1, isLoading: isRecLoading } = useMutation(
    () => getRecommendationData({ data, age, portfolioVal, isNonNaturalPerson }),
    {
      onSuccess: async (resp: any) => {
        await dispatch(
          setRecommendedAllocation({
            recommendedAllocation: resp?.engine_1_stats?.alt_alloc,
          }),
        );

        await dispatch(
          setEngine1DataForEngine2Result({
            engine1DataForEngine2Result: resp,
          }),
        );
        if (engineNo !== 2) {
          const engine1Data = {
            ...resp,
            uploadedFileData: createHoldingPayload(data, portfolioVal),
          };
          await dispatch(
            setEngine1Result({
              engine1Result: engine1Data,
            }),
          );
          await dispatch(setRecommendationActiveStep({ activeStep: 1 }));
        }
        handleClose();
        window.scrollTo(0, 0);
      },
      onError: () => {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Something went wrong. Please try again.',
        };
        dispatch(showToast(toast));
      },
    },
  );

  const { mutate: triggerEngine2, isLoading: isThemesLoading } = useMutation(
    () => getThemesData({ data, age, portfolioVal, isNonNaturalPerson }),
    {
      onSuccess: (resp: any) => {
        const engine2Data = {
          ...resp,
          uploadedFileData: createHoldingPayload(data, portfolioVal),
        };
        dispatch(
          setEngine2Result({
            engine2Result: engine2Data,
          }),
        );
        dispatch(setRecommendationActiveStep({ activeStep: 1 }));
        handleClose();
        window.scrollTo(0, 0);
      },
      onError: () => {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Something went wrong. Please try again.',
        };
        dispatch(showToast(toast));
      },
    },
  );

  const getTableValue = () => {
    return [
      ...getHoldingsData(
        createHoldingPayload(
          data
            ?.filter((item: any) =>
              tickersData?.tickers_name.map((i: any) => i.ticker).includes(item.Ticker),
            )
            .map((item: any) => {
              const existingTicker = tickersData?.tickers_name.find(
                (curr: any) => item.Ticker === curr.ticker,
              );
              return { ...item, ...existingTicker };
            }),
          portfolioVal,
        ) ?? [],
        portfolioVal,
        recAllocation,
      ),
      {
        Ticker: 'Total',
        id: '',
        name: ' ',
        'market-value': portfolioVal,
        tableWeight: '100.00%',
        tableValFormatted: '100.0%',
      },
    ];
  };

  const handleNextButtonClick = async () => {
    if (engineNo === 1) await triggerEngine1();
    if (engineNo === 2) {
      await triggerEngine1();
      triggerEngine2();
    }
    if (engineNo === 3) {
      await triggerEngine1();
      triggerEngine2();
    }
  };

  return (
    <StyledModal
      title=''
      isModalOpen={isModalOpen}
      handleClose={() => {
        handleClose();
      }}
      maxWidth='lg'
    >
      {isRecLoading || isThemesLoading ? (
        <Loading
          className='loader'
          message={`Analyzing ${
            selectedClient?.account_name || storedClient?.account_name || 'client'
          }'s portfolio...`}
        />
      ) : (
        <Grid container>
          <Grid item xs={12} container columnSpacing={2}>
            <Grid item xs={12} className='flex-center-between'>
              <h3 className='mb-0 d-flex title'>
                {selectedClient?.account_name ? `${selectedClient?.account_name}'s` : ''} Portfolio
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <Tooltip
                      title={<div className='tooltipContainer'>Coming soon</div>}
                      placement='top'
                      arrow
                      open={open}
                      disableFocusListener
                      PopperProps={{
                        disablePortal: true,
                      }}
                      disableHoverListener
                      onClose={handleTooltipClose}
                      classes={{ tooltip: 'p-0 tooltipParent' }}
                    >
                      <div className='ml-3 cursor-pointer' onClick={handleTooltipOpen}>
                        <Pencil />
                      </div>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </h3>
            </Grid>
            <Grid item xs={8} mt={2}>
              <h5 className='mb-0 description'>
                You have uploaded the following securities portfolio for{' '}
                {selectedClient?.account_name}. Review the portfolio and when you are ready, click
                'Next' to receive recommended allocation to Private Markets.
              </h5>
            </Grid>
          </Grid>
          {/* <Grid item xs={4} className='position-relative'>
          <span className='donutLabel'>
            REGIONAL
            <br />
            ALLOCATION
          </span>
          <Donut isPercentageValue dataKey='val' nameKey='id' data={getRegionalAllocation()} />
        </Grid>
        <Grid item xs={4} className='position-relative'>
          <span className='donutLabel'>
            ASSET
            <br />
            CLASS
          </span>
          <Donut isPercentageValue dataKey='val' nameKey='id' data={getAssetClass()} />
        </Grid>
        <Grid item xs={4} className='position-relative'>
          <span className='donutLabel'>
            SECTOR
            <br />
            ALLOCATION
          </span>
          <Donut isPercentageValue dataKey='val' nameKey='id' data={SECTOR_DATA} />
        </Grid> */}

          <Grid item xs={12} mt={8}>
            {/* {tickersData?.tickers_not_exist?.length > 0 ? (
              <>
                <h3 className='mb-3 holdings-text'>Holdings (Not Supported)</h3>
                <MuiTable
                  tableClassName='portfolioTable mb-3'
                  columns={NOT_SUPPORTED_HOLDING_COLUMNS}
                  rows={[
                    ...data?.filter((item: any) =>
                      [
                        ...tickersData?.tickers_not_exist,
                        ...tickersData?.ticker_not_supported,
                      ].includes(item.Ticker),
                    ),
                  ]}
                />
              </>
            ) : null} */}
            <h3 className='mb-3 holdings-text'>Holdings</h3>
            <MuiTable
              tableClassName='portfolioTable'
              columns={isReadOnly ? HOLDING_COLUMNS_EXCLUDING_FUNDNAME : HOLDING_COLUMNS}
              rows={isReadOnly ? data : getTableValue()}
            />
          </Grid>
          <Grid item xs={12} mt={8} display='flex' justifyContent='right'>
            {!isReadOnly ? (
              <div>
                <MuiButton color='error' buttonClick={() => handleClose()}>
                  Discard
                </MuiButton>
                <MuiButton
                  buttonClick={() => handleNextButtonClick()}
                  minWidth='150px'
                  variant='contained'
                  className='ml-3'
                >
                  Next
                </MuiButton>
              </div>
            ) : (
              <MuiButton
                minWidth='150px'
                variant='contained'
                className='ml-3'
                buttonClick={() => handleClose()}
              >
                Close
              </MuiButton>
            )}
          </Grid>
        </Grid>
      )}
    </StyledModal>
  );
};

export default PortfolioModal;
