import getAxiosInstance from 'common/utils/axios';
import { FETCH_USER_PROFILE } from 'store/user/query';
import { AuthUser, UserProfile } from 'store/user/types';

export const getUserAuth = () => {
  try {
    const auth: AuthUser | null = JSON.parse(localStorage.getItem('currentUser') as string);
    return auth;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getTokenId = () => {
  try {
    const auth: AuthUser | null = getUserAuth();
    return auth?.idToken as string;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getAccessToken = () => {
  try {
    const auth: AuthUser | null = getUserAuth();
    return auth?.accessToken as string;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getUserId = () => {
  try {
    const auth: AuthUser | null = getUserAuth();
    return auth?.userDetailsResponseDTO?.userId as string;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getUserDetails = async (username: string) => {
  const axios = await getAxiosInstance();
  const user = await getUserAuth();
  const email = user?.userDetailsResponseDTO?.username;
  try {
    const userData = await axios.post(
      `${process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL}/graphql` as string,
      {
        query: FETCH_USER_PROFILE,
        variables: {
          userName: username ?? email,
        },
      },
    );
    const { data } = userData;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const encryptDocumentUrl = async (url: string, uploadedFileData: any) => {
  const buserId = '';
  const axios = await getAxiosInstance(buserId, true);
  await axios.put(url, uploadedFileData, {
    headers: {
      'Content-Type': uploadedFileData.type,
    },
  });
};

export const getBehalfOf = async () => {
  const behalfOf: UserProfile = await JSON.parse(localStorage.getItem('behalfOf') as string);
  return behalfOf;
};

export const formatAumValues = (value: string | number, decimal: number = 2) => {
  return (
    '$' +
    (Math.abs(Number(value)) >= 1.0e9
      ? (Math.abs(Number(value)) / 1.0e9).toFixed(decimal) + 'B'
      : Math.abs(Number(value)) >= 1.0e6
      ? (Math.abs(Number(value)) / 1.0e6).toFixed(decimal) + 'M'
      : Math.abs(Number(value)) >= 1.0e3
      ? (Math.abs(Number(value)) / 1.0e3).toFixed(decimal) + 'K'
      : Math.round(Math.abs(Number(value))))
  );
};

export const valueFormatter = (value: string | number) => {
  if (typeof value === 'string') {
    if (value.length === 1 && value[0] === '-') {
      return value;
    }

    if (value.length === 1 && value[0] === '.') {
      return value;
    }

    const decimalIndex = value.indexOf('.');
    if (decimalIndex >= 0 && decimalIndex === value.length - 1) {
      return `${Number(value.replace(/,/g, '')).toLocaleString()}.`;
    }
    const val = value.split('.');
    if (val.length === 2) {
      return `${Number(val[0].replace(/,/g, '')).toLocaleString()}.${val[1]}`;
    }
    return Number(value.replace(/,/g, '')).toLocaleString();
  }
  return Number(value).toLocaleString();
};

export const regexValidator = (value: string | number, pattern: any) => {
  if (!pattern.test(value)) return true;
  else return false;
};

export const isArrayWithDuplicates = (array: any) => {
  const set = new Set(array.map(JSON.stringify));
  if (set.size !== array.length) {
    return true;
  } else {
    return false;
  }
};

export const fetchPageBackgroundBool = (pathName: any) => {
  if (
    (pathName.includes('/investment/') &&
      !pathName.includes('/investment/edit') &&
      !pathName.includes('/investment/add')) ||
    pathName.includes('/order/')
  )
    return true;
  else return false;
};

export const handleGraphqlMutation = async ({ url, query, variables }: any) => {
  const axios = await getAxiosInstance();
  const data = await axios.post(`${url}/graphql`, {
    query,
    variables,
  });
  return data;
};
