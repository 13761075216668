import styled from '@emotion/styled';
import { ToggleButtonGroup } from '@mui/material';
import React from 'react';

const StyledToggle = styled(ToggleButtonGroup)`
  background-color: var(--p20);

  button {
    border: none;
    color: var(--p600);
    fill: var(--p600);
    padding: 8px 12px;
    &:hover {
      background-color: var(--p15);
    }
    &.Mui-selected {
      background-color: var(--p500);
      border-radius: 4px !important;
      color: white;
      fill: white;
      &:hover {
        background-color: var(--p500);
      }
    }
  }
`;

interface Props {
  value: boolean | string;
  children: React.ReactNode;
}

const MuiToggle = ({ value, children }: Props) => {
  return <StyledToggle value={value}>{children}</StyledToggle>;
};

export default MuiToggle;
