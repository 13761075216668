import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import SuccessTick from 'common/assets/images/SuccessTick2.svg';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  title: string;
  message: string;
};

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 426px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 28px !important;
  }
  p {
    font-size: 0.875rem;
    margin-bottom: 0;
    color: var(--s40) !important;
  }
`;

const UploadHoldingsSuccess = ({ isModalOpen, handleClose, title, message }: Props) => {
  return (
    <StyledModal
      title={title}
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='xs'
      className='new-alloc'
      disableCloseIcon
    >
      <Grid container alignItems='center' mb={5} flexWrap='nowrap'>
        <img src={SuccessTick} alt='' className='mr-3' />
        <p>{message}</p>
      </Grid>

      <Grid container alignItems='center' justifyContent='flex-end'>
        <MuiButton variant='contained' className='ml-3' buttonClick={handleClose} minWidth='150px'>
          Okay
        </MuiButton>
      </Grid>
    </StyledModal>
  );
};

export default UploadHoldingsSuccess;
