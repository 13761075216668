import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import { AuthSlice, RequestLoader } from './types';

export const initialState: AuthSlice = {
  authUser: null,
  requestStatus: RequestLoader.NOT_STARTED,
  userProfile: null,
  behalfOf: null,
  forgotPasswordActiveStepper: 0,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: reducers,
});

export const {
  initiateAuthFetch,
  setAuth,
  initiateProfileFetch,
  setProfile,
  setBehalfOf,
  setAuthFailed,
  updateProfile,
  setForgotPasswordActiveStepper,
} = slice.actions;

export default slice.reducer;
