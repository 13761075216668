import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import { SubmitHandler, useForm } from 'react-hook-form';
import InputField from 'common/components/inputField/Textbox';
import { useAppSelector, useMutationQuery, useTypedDispatch } from 'store/hooks';
import { useEffect, useState } from 'react';
import { selectAuthUser } from 'store/user/selectors';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import styled from '@emotion/styled';
import { CheckCircle, CircleOutlined, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MuiCard from 'common/components/card';
import SuccessTick from 'common/assets/images/ConfirmPasswordSuccess.svg';

const StyledGrid = styled(Grid)`
  h2 {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 0;
  }

  h4 {
    font-weight: 400;
    padding-bottom: 0.25rem;
    margin-bottom: unset;
  }

  .close {
    cursor: pointer;
    color: #949aa6;
  }

  .passwordChecks {
    color: var(--s30);
    font-size: 12px;
    margin-bottom: 3.2rem;
    svg {
      color: var(--p200);
      font-size: 1rem;
      margin-right: 0.5rem;
      &.pass {
        color: #5eb734;
      }
    }
    div {
      display: flex;
      align-items: center;
      margin-bottom: 0.8rem;
    }
  }

  p {
    font-size: 0.875rem;
  }
`;

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const authUser = useAppSelector(selectAuthUser);
  const [success, setSuccess] = useState<boolean>(false);
  const [updatePasswordData, setUpdatePasswordData] = useState<any>(null);
  const [loginCredentials, setLoginCredentials] = useState<any>(null);
  const [inputs, setInputs] = useState<{ old: string; new: string; confirm: string }>({
    old: '',
    new: '',
    confirm: '',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate: changeUserPassword } = useMutationQuery(
    {
      url: 'qaip/v1/usermanagement/changePwd',
      params: updatePasswordData,
    },
    {
      onSuccess: async () => {
        // await dispatch({ type: 'LOGOUT_ATTEMPT' });
        setSuccess(true);
        localStorage.removeItem('currentUser');
      },
      onError: () => {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Password change limit exceeded. Please try again later.',
        };
        dispatch(showToast(toast));
      },
    },
  );

  const { mutate: reAuthorizeUser } = useMutationQuery(
    {
      url: 'qaip/v1/usermanagement/authinit',
      params: {
        username: loginCredentials?.username,
        password: loginCredentials?.oldPassword,
      },
    },
    {
      onSuccess: (data) => {
        const newData = {
          accessToken: data.accessToken,
          challangeName: 'NEW_PASSWORD_REQUIRED',
          newPassword: loginCredentials?.newPassword,
          otp: '',
          password: loginCredentials?.oldPassword,
          username: authUser?.userDetailsResponseDTO?.username,
        };
        setUpdatePasswordData(newData);
        changeUserPassword();
      },
      onError: () => {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Unable to change your password. Please check the passwords again.',
        };
        dispatch(showToast(toast));
      },
    },
  );

  useEffect(() => {
    if (loginCredentials) {
      if (loginCredentials.newPassword === loginCredentials.confirmPassword) {
        reAuthorizeUser();
      } else {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Please ensure you have re-entered the same password.',
        };
        dispatch(showToast(toast));
      }
    }
    //eslint-disable-next-line
  }, [loginCredentials]);

  const onSubmit: SubmitHandler<any> = async (data) => {
    setLoginCredentials({ ...data, username: authUser?.userDetailsResponseDTO?.username });
  };

  return (
    <Container maxWidth='xl' className='container-lr-padding'>
      <StyledGrid container mt={'114px'}>
        <Grid item xs={12} className='flex-center-end' mb='40px'>
          <Close className='close' onClick={() => navigate(-1)} />
        </Grid>
        <Grid item xs={4} className='mx-auto'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} mb={6.5}>
                <h2>Change Password</h2>
              </Grid>
              {!success ? (
                <>
                  <Grid item xs={12} className='passwordChecks flex-col flex-stretch-center'>
                    <div>
                      {inputs.new.length > 7 ? (
                        <CheckCircle className='pass' />
                      ) : (
                        <CircleOutlined />
                      )}
                      At least 8 characters
                    </div>
                    <div>
                      {/[A-Z]/g.test(inputs.new) ? (
                        <CheckCircle className='pass' />
                      ) : (
                        <CircleOutlined />
                      )}
                      At least 1 uppercase letter
                    </div>
                    <div>
                      {/[a-z]/g.test(inputs.new) ? (
                        <CheckCircle className='pass' />
                      ) : (
                        <CircleOutlined />
                      )}
                      At least 1 lowercase letter
                    </div>
                    <div>
                      {/\d/g.test(inputs.new) ? (
                        <CheckCircle className='pass' />
                      ) : (
                        <CircleOutlined />
                      )}
                      At least 1 number
                    </div>
                    <div>
                      {/[^a-zA-Z\d]/g.test(inputs.new) && !/[ ]/g.test(inputs.new) ? (
                        <CheckCircle className='pass' />
                      ) : (
                        <CircleOutlined />
                      )}
                      At least 1 special character
                    </div>
                  </Grid>
                  <Grid item xs={12} mb={3}>
                    <h4>Current Password</h4>
                    <InputField
                      fullWidth
                      passwordPeek
                      type='password'
                      register={register}
                      name='oldPassword'
                      minLength={8}
                      errorMessage='Please enter a valid password.'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setInputs({ ...inputs, old: e.target.value })
                      }
                    />
                    {errors.oldPassword && <ErrorMessage error={'Please enter a valid password'} />}
                  </Grid>
                  <Grid item xs={12} mb={3}>
                    <h4>New Password</h4>
                    <InputField
                      fullWidth
                      type='password'
                      passwordPeek
                      register={register}
                      name='newPassword'
                      minLength={8}
                      errorMessage='Please enter a valid password.'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setInputs({ ...inputs, new: e.target.value })
                      }
                    />
                    {errors.newPassword && <ErrorMessage error={'Please enter a valid password'} />}
                  </Grid>
                  <Grid item xs={12}>
                    <h4>Re-enter Password</h4>
                    <InputField
                      fullWidth
                      passwordPeek
                      minLength={8}
                      type='password'
                      register={register}
                      name='confirmPassword'
                      errorMessage='Please enter a valid password.'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setInputs({ ...inputs, confirm: e.target.value })
                      }
                    />
                    {errors.confirmPassword && (
                      <ErrorMessage error={'Please enter a valid password'} />
                    )}
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 8, pb: 6 }} className='flex-center-center'>
                    {/* This will prevent the submission of form on pressing enter key */}
                    <button type='submit' disabled className='d-none' aria-hidden='true'></button>
                    <MuiButton
                      disableElevation
                      type='submit'
                      variant='contained'
                      color='primary'
                      minWidth='150px'
                      disabled={
                        !(
                          inputs.new.length > 7 &&
                          /[A-Z]/g.test(inputs.new) &&
                          /[a-z]/g.test(inputs.new) &&
                          /\d/g.test(inputs.new) &&
                          /[^a-zA-Z\d]/g.test(inputs.new) &&
                          !/[ ]/g.test(inputs.new)
                        )
                      }
                    >
                      Save
                    </MuiButton>
                  </Grid>{' '}
                </>
              ) : (
                <>
                  <Grid item xs={12} mb={7}>
                    <MuiCard minHeight='unset' className='flex-center-center' sx={{ py: 2, px: 3 }}>
                      <img src={SuccessTick} alt='' />
                      <h4 className='font-wt-400 ml-3'>
                        Your password has been changed successfully.
                      </h4>
                    </MuiCard>
                    <p className='text-center mt-3'>
                      All your active sessions have been invalidated.
                      <br />
                      Please re-login.
                    </p>
                  </Grid>
                  <Grid item xs={12} className='flex-center-center'>
                    <MuiButton
                      minWidth='150px'
                      variant='contained'
                      buttonClick={() => {
                        dispatch({ type: 'LOGOUT_ATTEMPT' });
                      }}
                    >
                      Okay
                    </MuiButton>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </Grid>
      </StyledGrid>
    </Container>
  );
};

export default ChangePassword;
