export const FETCH_USER_PROFILE = `
    query GetUserDetails($userName: String!) {
        getUserDetails(user_name: $userName) {
        userId
        userName
        cognitoUserId
        userOktaSubId
        firstName
        lastName
        category
        totalClients
        active
        organisationId
        phone
        termsAndConditions
        userStatus
        aum
        wealth_advisory_employee_id
        teamsRolesDetails {
            teamId
            teamName
            roleId
            roleName
            privilege {
            approvalaccess
            menu
            viewaccess
            createaccess
            modifyaccess
            }
        }
        }
    }
  `;
