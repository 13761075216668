import * as React from 'react';
import Drawer from '@mui/material/Drawer';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Props {
  children?: React.ReactNode;
  anchor?: Anchor;
  toggleDrawer: () => void;
  isOpen: boolean;
  className: string;
}

export default function MuiDrawer({
  children,
  anchor = 'right',
  toggleDrawer,
  isOpen = false,
  className,
}: Props) {
  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer
          className={className}
          anchor={anchor}
          open={isOpen}
          onClose={toggleDrawer}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {children}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
