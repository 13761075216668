import { CircularProgress } from '@mui/material';
import MuiButton from '.';

type Props = {
  variant?: 'contained' | 'outlined' | 'text';
  className?: string;
  minWidth?: string;
  color?: 'primary' | 'error';
  fullWidth?: boolean;
};

const LoadingButton = ({
  variant = 'contained',
  className = '',
  minWidth = '150px',
  color = 'primary',
  fullWidth,
}: Props) => {
  return (
    <MuiButton
      color={color}
      variant={variant}
      minWidth={minWidth}
      fullWidth={fullWidth}
      className={className}
    >
      <CircularProgress
        sx={{ color: 'inherit', width: '24.5px !important', height: '24.5px !important' }}
      />
    </MuiButton>
  );
};

export default LoadingButton;
