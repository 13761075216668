import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as DragIndicator } from 'common/assets/images/recommendation/DragIndicator.svg';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CompareArrow from 'common/components/icon/CompareArrow';

const StyledCard = styled.div`
  padding: 1.5rem;
  height: 183px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--s15);
  background-color: white;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);

  .recharts-text {
    font-size: 0.75rem;
  }

  .custom-tooltip {
    background-color: var(--s10);
    color: var(--s50);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
  }
`;

const StatCard = forwardRef(({ stat, index, fundData }: any) => {
  const CustomizedLabel = (props: any) => {
    const { x, y, value } = props;

    return (
      <text x={x} y={y - 8} fill={'var(--s30)'} fontSize={12} fontWeight='500' textAnchor='start'>
        {(Number(value) * 100).toFixed(2)}%
      </text>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return <div className='custom-tooltip'>{payload[0]?.payload?.Fund_Name}</div>;
    }

    return null;
  };

  return (
    <Draggable draggableId={`${index}`} index={index}>
      {(provided) => (
        <StyledCard
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <section className='flex-center-between'>
            <div className='flex-center-start'>
              <DragIndicator />
              <h5 className='ml-2 mb-0'>{stat?.label}</h5>
            </div>
            <CompareArrow dir={index === 0 ? 'down' : index === 2 ? 'up' : 'upDown'} />
          </section>

          <section className='px-5'>
            <ResponsiveContainer width='100%' height={100}>
              <BarChart width={500} height={100} data={fundData} barSize={32} margin={{ top: 20 }}>
                <XAxis
                  dataKey='Fund_Name'
                  interval={0}
                  tickLine={false}
                  tickFormatter={(value) => {
                    if (value?.length > 11) {
                      return (
                        value?.substring(0, 4) +
                        '...' +
                        value?.substring(value?.length - 4, value?.length)
                      );
                    } else return value;
                  }}
                  height={16}
                  tickMargin={0}
                />
                <YAxis hide />
                <Tooltip content={CustomTooltip} cursor={{ fill: 'transparent' }} />
                <Bar dataKey={stat?.id} fill='var(--p100)' label={CustomizedLabel} />
              </BarChart>
            </ResponsiveContainer>
          </section>
        </StyledCard>
      )}
    </Draggable>
  );
});

export default StatCard;
