import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ToastMessage {
  message: string;
  type: ToastType;
}

export enum ToastType {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  WARNING = "warning"
}

export type ToastSlice = {
  queue: Array<ToastMessage>;
  history: Array<ToastMessage>;
};

const initialState: ToastSlice = {
  queue: [],
  history: [],
};

const slice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast: (state: ToastSlice, action: PayloadAction<ToastMessage>) => {
      state.queue.push(action.payload);
    },
    dismissToast: (state: ToastSlice) => {
      state.history.push(state.queue[0]);
      state.queue.shift();
    },
  },
});

export const { showToast, dismissToast } = slice.actions;

export default slice.reducer;
