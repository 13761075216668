import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiCard from 'common/components/card';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Icon } from 'common/assets/images/recommendation/Home2.svg';
import { useTypedDispatch } from 'store/hooks';
import {
  setRecommendedAllocation,
  setRecommendationActiveStep,
  setRecommendationClient,
  setEngineNumber,
  setIsLandingPage,
} from 'store/recommendation/slice';
import { ReactComponent as ClientIcon } from 'common/assets/images/recommendation/Client.svg';

const StyledWrapper = styled.div`
  .fundCard {
    height: 100%;
    box-sizing: border-box;
    padding: 1rem 24px;
    min-height: unset;
    cursor: pointer;
    position: relative;

    &:hover {
      border-color: var(--p150);
    }

    .recBanner {
      display: flex;
      align-items: center;
      position: absolute;
      inset: 0 0 auto 0;
      font-size: 12px;
      font-weight: 700;
      color: var(--s40);
      background: var(--p20);
      padding: 6px 12px;
      svg {
        margin-right: 10px;
      }
    }

    .primary-text {
      font-size: 22px;
      font-weight: 500;
      color: var(--s50);
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    .subtitle {
      font-size: 11px;
    }

    .card-div {
      &--outer {
        width: 100%;
        &:first-child {
          margin-right: 2rem;
        }
      }
      &--inner {
        padding: 10px 0px;
        &:first-child {
          border-bottom: 1px solid var(--s15);
        }
      }
    }

    .secondary-number {
      font-size: 1rem;
      color: var(--s50);
      font-weight: 400;
    }

    img,
    .MuiSkeleton-root {
      height: 80px;
      max-width: 40%;
      object-fit: contain;
      object-fit: left center;
      margin-bottom: 1rem;
    }
    h3 {
      color: var(--p500);
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    h4 {
      margin-bottom: 0;
    }

    .link {
      text-decoration: none;
      font-size: 14px;
      color: var(--p300);
    }
  }
`;

const Engine2Card = ({
  clientList,
  startIndex = 0,
  stats = {
    esg: '10%',
    income: '20%',
    growth: '30%',
    div: '40%',
  },
}: any) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const selectedClient = clientList?.length > 0 ? clientList[startIndex] : null;

  return (
    <StyledWrapper>
      <MuiCard
        className='fundCard'
        onClick={() => {
          dispatch(setRecommendedAllocation({ recommendedAllocation: 26 }));
          dispatch(setRecommendationActiveStep({ activeStep: 1 }));
          dispatch(setIsLandingPage({ isLandingPage: false }));
          dispatch(setEngineNumber({ engineNumber: 2 }));
          dispatch(setRecommendationClient({ client: selectedClient }));
          navigate(`/recommendations`);
        }}
      >
        <div className='recBanner'>
          <Icon /> Recommended Allocation to Styles
        </div>

        <Grid item xs={12} mt={'30px'} mb={2} className='flex-center-start'>
          <ClientIcon className='mr-2' />
          <h3 className='primary-text'>
            {selectedClient?.account_name.length > 20
              ? selectedClient?.account_name.substring(0, 15) +
                '...' +
                selectedClient?.account_name.substring(
                  selectedClient?.account_name.length - 5,
                  selectedClient?.account_name.length,
                )
              : selectedClient?.account_name}{' '}
          </h3>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <div className='subtitle'>ESG</div>
            <div className='secondary-number'>{stats.esg}</div>
          </Grid>
          <Grid item xs={6}>
            <div className='subtitle'>INCOME</div>
            <div className='secondary-number'>{stats.income}</div>
          </Grid>
          <Grid item xs={6}>
            <div className='subtitle'>GROWTH</div>
            <div className='secondary-number'>{stats.growth}</div>
          </Grid>
          <Grid item xs={6}>
            <div className='subtitle'>DIVERSIFICATION</div>
            <div className='secondary-number'>{stats.div}</div>
          </Grid>
        </Grid>
        <Grid item xs={12} display='flex' alignItems='flex-end' mt={2}>
          <Link className='link' to='#'>
            View Results
          </Link>
        </Grid>
      </MuiCard>
    </StyledWrapper>
  );
};

export default Engine2Card;
