import Skeleton from '@mui/material/Skeleton';

type Props = {
    width: number | string;
    height: number | string;
    variant?: 'text' | 'circular' | 'rectangular';
}


export default function MuiSkeleton({ width, height, variant }: Props) {
    return (
        <Skeleton animation="wave" width={width} height={height} variant={variant} />
    );
}