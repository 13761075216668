import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { initialState } from './slice';

const recommendationSlice = (state: RootState) => state.recommendation || initialState;

export const portfolioValue = createSelector(
  [recommendationSlice],
  (state) => state.portfolioValue,
);

export const recommendedAllocation = createSelector(
  [recommendationSlice],
  (state) => state.recommendedAllocation,
);

export const recEngineNumber = createSelector([recommendationSlice], (state) => state.engineNumber);

export const recommendationNumber = createSelector(
  [recommendationSlice],
  (state) => state.recommendationNumber,
);

export const recommendationActiveStep = createSelector(
  [recommendationSlice],
  (state) => state.activeStep,
);

export const recommendationClient = createSelector([recommendationSlice], (state) => state.client);

export const isLandingPage = createSelector([recommendationSlice], (state) => state.isLandingPage);

export const clientAge = createSelector([recommendationSlice], (state) => state.clientAge);

export const engine1Result = createSelector([recommendationSlice], (state) => state.engine1Result);

export const engine2Result = createSelector([recommendationSlice], (state) => state.engine2Result);

export const engine1DataForEngine2Result = createSelector(
  [recommendationSlice],
  (state) => state.engine1DataForEngine2Result,
);

export const maxAlloc = createSelector([recommendationSlice], (state) => state.maxAlloc);

export const isSelectedClientNonNaturalPerson = createSelector(
  [recommendationSlice],
  (state) => state.isSelectedClientNonNaturalPerson,
);

export const isInputDrivingRecButtonVisible = createSelector(
  [recommendationSlice],
  (state) => state.isInputDrivingRecButtonVisible,
);

export const selectFundsToCompare = createSelector(
  [recommendationSlice],
  (state) => state.fundsToCompare,
);

export const selectFundsBasedOn = createSelector(
  [recommendationSlice],
  (state) => state.fundsBasedOn,
);
