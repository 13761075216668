import styled from '@emotion/styled';
import { formatAumValues } from 'helpers';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { useAppSelector } from 'store/hooks';
import { portfolioValue } from 'store/recommendation/selectors';

const StyledAreaChart = styled(AreaChart)`
  border: 1px solid var(--s20);
  border-radius: 0.5rem;
  overflow: hidden;

  .custom-tooltip {
    background-color: white;
    padding: 0.5rem;
    min-width: 100px;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    h4 {
      font-size: 13px;
      font-weight: 500;
      color: var(--s30);
      span {
        color: var(--p200);
      }
    }
    h5 {
      color: var(--s30);
    }
  }
`;

const StyledAreaChartX = styled(AreaChart)`
  .recharts-xAxis {
    text {
      fill: var(--s30);
      font-weight: 600;
    }

    .recharts-cartesian-axis-tick:first-of-type {
      transform: translateX(10px);
    }
    .recharts-cartesian-axis-tick:last-of-type {
      transform: translateX(-10px);
    }
    .recharts-label {
      fill: var(--s40) !important;
      font-size: 13px;
    }
  }

  .recharts-yAxis {
    text {
      fill: var(--s30);
      font-weight: 600;
    }
    .recharts-label {
      fill: var(--s40) !important;
      font-size: 13px;
    }
  }
`;

interface Props {
  data?: Array<any>;
}

const ProjectedPerformance = ({ data = [] }: Props) => {
  const portfolioVal = useAppSelector(portfolioValue);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <h4 className='mb-2'>
            With Private Markets: <span>${Number(payload[0].value).toLocaleString()}</span>
          </h4>
          <h5 className='font-wt-400 mb-0'>
            Without Private Markets: ${Number(payload[1].value).toLocaleString()}
          </h5>
        </div>
      );
    }

    return null;
  };

  const renderCustomizedLabel = (props: any) => {
    const lastIndex = data ? data.length - 1 : -1;
    const lastPoint = data ? data[lastIndex].withoutAlts : '';
    return props.index === lastIndex ? (
      <text
        x={props.x}
        y={props.y + 5}
        textAnchor='end'
        fontSize={13}
        fill='var(--s30)'
        dominantBaseline='central'
      >
        {formatAumValues(lastPoint)}
      </text>
    ) : null;
  };

  const renderCustomizedLabel2 = (props: any) => {
    const lastIndex = data ? data.length - 1 : -1;
    const lastPoint = data ? data[lastIndex].withAlts : '';
    return props.index === lastIndex ? (
      <text
        x={props.x}
        y={props.y - 5}
        textAnchor='end'
        fontSize={13}
        fill='var(--p300)'
        dominantBaseline='central'
      >
        {formatAumValues(lastPoint)}
      </text>
    ) : null;
  };

  return (
    <>
      <div className='d-flex'>
        <ResponsiveContainer width={'7%'} height={280}>
          <StyledAreaChartX width={600} height={215} data={data} margin={{ top: 0, left: 10 }}>
            <XAxis fontSize={13} dataKey='age' hide />
            <YAxis
              fontSize={13}
              dx={5}
              dataKey='withAlts'
              axisLine={false}
              tickLine={false}
              ticks={[portfolioVal]}
              domain={['dataMin', 'dataMax']}
              label={{ value: `Portfolio Value`, dx: -30, angle: -90 }}
              tickFormatter={(val: any, i: number) => {
                return formatAumValues(val, 0);
              }}
            ></YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Area
              hide
              type='monotone'
              dot={false}
              dataKey='withAlts'
              stroke='var(--p200)'
              strokeWidth={1.5}
            />
            <Area
              hide
              type='monotone'
              dot={false}
              dataKey='withoutAlts'
              stroke='var(--s20)'
              strokeWidth={1.5}
            />
          </StyledAreaChartX>
        </ResponsiveContainer>

        <ResponsiveContainer width={'86%'} height={280}>
          <StyledAreaChart width={600} height={215} data={data} margin={{ top: 0, left: 0 }}>
            <XAxis
              fontSize={13}
              dataKey='age'
              hide
              stroke='#bdbdbd'
              tickLine={false}
              interval={4}
              dy={10}
              tickFormatter={(val: any, i: number) => {
                return val + 'yrs' + i;
              }}
            />
            <YAxis hide domain={['dataMin', 'dataMax']} padding={{ top: 30 }} />
            <Tooltip content={<CustomTooltip />} />
            <Area
              // stackId={1}
              type='monotone'
              dot={false}
              dataKey='withAlts'
              stroke='var(--p150)'
              fillOpacity={1}
              fill='#c4d8ea'
              strokeWidth={2}
            />
            <Area
              // stackId={1}
              type='monotone'
              dot={false}
              fill='var(--s10)'
              stroke='var(--s20)'
              dataKey='withoutAlts'
              fillOpacity={1}
              strokeWidth={2}
            />
          </StyledAreaChart>
        </ResponsiveContainer>

        <ResponsiveContainer width={'7%'} height={280}>
          <StyledAreaChartX width={600} height={215} data={data} margin={{ top: 0, left: 0 }}>
            <XAxis fontSize={13} dataKey='age' hide />
            <YAxis hide domain={['dataMin', 'dataMax']} padding={{ top: 30 }} />
            <Area
              type='monotone'
              dot={false}
              fill='#c4d8ea'
              stroke='var(--p150)'
              dataKey='withAlts'
              strokeWidth={2}
              opacity={0}
              label={renderCustomizedLabel2}
            />
            <Area
              type='monotone'
              dot={false}
              dataKey='withoutAlts'
              stroke='var(--s20)'
              fill='#eeeeee'
              strokeWidth={2}
              opacity={0}
              label={renderCustomizedLabel}
            />
          </StyledAreaChartX>
        </ResponsiveContainer>
      </div>

      {/* TO SHOW THE X AXIS BELOW THE STYLED CHART */}
      <ResponsiveContainer width={'88%'} height={50} className='mx-auto'>
        <StyledAreaChartX width={600} height={10} data={data} margin={{ top: 0, left: 0 }}>
          <XAxis
            fontSize={13}
            dataKey='age'
            stroke='var(--s40)'
            tickLine={false}
            axisLine={false}
            dy={-15}
            label={{ value: `Client's Age`, dy: 5 }}
            tickFormatter={(val: any, i: number) => {
              if (i === 0) return data[0].age;
              else if (i === data.length - 2) return data[data.length - 1].age;
              else return '';
            }}
          />
          <Area hide type='monotone' dataKey='withoutAlts' stroke='var(--p200)' strokeWidth={1.5} />
          <Area hide type='monotone' dataKey='withAlts' stroke='var(--s20)' strokeWidth={1.5} />
        </StyledAreaChartX>
      </ResponsiveContainer>
    </>
  );
};

export default ProjectedPerformance;
