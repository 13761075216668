import { Box, Grid, styled } from '@mui/material';
import ChatBotBulb from 'common/assets/images/ChatBotBulb.svg';
import Arrow from 'common/assets/images/InsightArrow.svg';

interface Props {
  setDisplayState: any;
}

const StyledWrapper = styled(Box)`
  align-self: flex-start;
  margin: 0 1.5rem 0.75rem;
  padding: 1rem 1.25rem;
  border-radius: 1rem;
  background-color: var(--p15);

  .insightBulb {
    margin-right: 0.5rem;
  }

  h3 {
    display: flex;
    align-items: center;
    color: var(--s40);
  }

  .arrow {
    margin-left: 0.875rem;
  }
`;

const OtherInsights = ({ setDisplayState }: Props) => {
  return (
    <StyledWrapper onClick={setDisplayState} className='cursor-pointer'>
      <Grid container>
        <Grid item xs={12} container alignItems='center'>
          <img src={ChatBotBulb} alt='' className='insightBulb' />
          <h3 className='font-wt-400 mb-0'>
            Other Insights <img src={Arrow} alt='>' className='arrow' />
          </h3>
        </Grid>
      </Grid>
    </StyledWrapper>
  );
};

export default OtherInsights;
