export const GET_FUND_DETAILS_QUERY = `
query GetFundDetails($fundId: String!) {
  getFundDetails(fund_id: $fundId) {
    fundDetails {
      logo
      fund_manager_id
      fund_manager_contact
      fund_manager_phone
      fund_manager_email
      asset_class
      fund_themes
      is_allocation_workflow_enabled
      strategy_description
      iso_currency
      class
      fund_status
      eligibility
      strategy
      rating
      fund_name
      add_to_all_dg
      sub_strategy
      min_investment
      fund_video
      fund_tag_line
      fund_type
      managers_bio
      fund_assets
      firm_assets
      target_fund_size
      management_fees
      incentives_fees
      preferred_return
      gp_catch_up_term
      investment_period
      fund_term
      funding_type
      income_distribution
      redemptions
      status
      liquidity_for_summary
      lockup_for_summary
      lockup
      final_close
      next_close
      funds_transfer_due_date
      compounded_rate_of_return
      standard_deviation
      sharpe_ratio
      profitable_months_percentage
      maximum_drawdown
      cash_yield
      target_irr
      s_and_p_500
      impact_score
      auditor
      administrator
      fund_counsel
      prime_broker
      disclaimer
      bank_details
      one_year_return
      three_years_return
      five_years_return
      ten_years_return
      since_inception_return
    }
    fundStats {
      fund
      netdpi
      nettvpi
      size
      vintage
      netirr
    }
    monthlyReturns {
      return_year
      return_month
      return_value
    }
  }
}
`;

export const MUTATE_FUND_FIRST_STEP_QUERY = `
mutation AddNewFunds(
    $assetClass: String!
    $class: String!
    $fundId: String!
    $fundTagLine: String
    $addToAllDg: Boolean
    $isAllocationWorkflowEnabled: String
    $eligibility: String
    $fundManagerContact: String
    $fundManagerPhone: String
    $fundManagerEmail: String
    $fundManagerId: String
    $fundName: String
    $fundType: String
    $fundThemes: String
    $fundVideo: String
    $logo: String
    $managersBio: String
    $minInvestment: String
    $subStrategy: String
    $strategy: String
    $strategyDescription: String
    $isoCurrency: String
  ) {
    addNewFunds(
      asset_class: $assetClass
      class: $class
      fund_id: $fundId
      fund_tag_line: $fundTagLine
      add_to_all_dg: $addToAllDg
      is_allocation_workflow_enabled: $isAllocationWorkflowEnabled
      eligibility: $eligibility
      fund_manager_contact: $fundManagerContact
      fund_manager_phone: $fundManagerPhone
      fund_manager_email: $fundManagerEmail
      fund_manager_id: $fundManagerId
      fund_type: $fundType
      fund_name: $fundName
      fund_themes: $fundThemes
      fund_video: $fundVideo
      logo: $logo
      managers_bio: $managersBio
      min_investment: $minInvestment
      sub_strategy: $subStrategy
      strategy: $strategy
      strategy_description: $strategyDescription
      iso_currency: $isoCurrency
    ) {
      fund_id
    }
  }
`;

export const MUTATE_FUND_SECOND_STEP_QUERY = `
mutation AddNewFunds(
  $fundAssets: String
  $firmAssets: String
  $fundId: String
  $targetFundSize: String
  $managementFees: String
  $incentivesFees: String
  $preferredReturn: String
  $gpCatchUpTerm: String
  $investmentPeriod: Int
  $fundTerm: Int
  $redemptions: String
  $fundingType: String
  $incomeDistribution: String
  $liquidityForSummary: String
  $lockupForSummary: String
  $finalClose: GenericScalar,
  $nextClose: String
  $lockup: String
  $fundsTransferDueDate: String
  $impactScore: String
  $compoundedRateOfReturn: GenericScalar
  $standardDeviation: String
  $sharpeRatio: GenericScalar
  $profitableMonthsPercentage: String
  $maximumDrawdown: GenericScalar
  $sAndP500: String
  $cashYield: String
  $targetIrr: String
) {
  addNewFunds(
    fund_assets: $fundAssets
    firm_assets: $firmAssets
    fund_id: $fundId
    target_fund_size: $targetFundSize
    management_fees: $managementFees
    incentives_fees: $incentivesFees
    preferred_return: $preferredReturn
    gp_catch_up_term: $gpCatchUpTerm
    investment_period: $investmentPeriod
    fund_term: $fundTerm
    redemptions: $redemptions
    funding_type: $fundingType
    income_distribution: $incomeDistribution
    liquidity_for_summary: $liquidityForSummary
    final_close: $finalClose,
    next_close: $nextClose
    lockup_for_summary: $lockupForSummary
    lockup: $lockup
    funds_transfer_due_date: $fundsTransferDueDate
    impact_score: $impactScore
    compounded_rate_of_return: $compoundedRateOfReturn
    standard_deviation: $standardDeviation
    sharpe_ratio: $sharpeRatio
    profitable_months_percentage: $profitableMonthsPercentage
    maximum_drawdown: $maximumDrawdown
    s_and_p_500: $sAndP500
    cash_yield: $cashYield
    target_irr: $targetIrr
  ){
    fund_id
  }
}
`;

export const MUATATE_MANAGER_TRACK_RECORD = `
mutation AddNewFunds(
  $fundId: String,
  $fundStats: [addFundStats]
) {
  addNewFunds(
    fund_id: $fundId,
    fundStats: $fundStats
  ) {
    fund_id
  }
}
`;

export const MUTATE_FUND_TSP_QUERY = `
mutation AddNewFunds(
  $auditor: String
  $administrator: String
  $fundCounsel: String
  $primeBroker: String
  $disclaimer: String
  $bankDetails: String
  $fundId: String
) {
  addNewFunds(
    auditor: $auditor
    administrator: $administrator
    fund_counsel: $fundCounsel
    prime_broker: $primeBroker
    disclaimer: $disclaimer
    bank_details: $bankDetails
    fund_id: $fundId
  ) {
    fund_id
  }
}
`;

export const MUTATE_HISTORICAL_RETURNS_QUERY = `
mutation AddNewFunds(
  $oneYearReturn: String
  $threeYearsReturn: String
  $fiveYearsReturn: String
  $tenYearsReturn: String
  $sinceInceptionReturn: String
  $fundId: String
  $monthlyReturns: [inputMonthlyReturns]
) {
  addNewFunds(
    one_year_return: $oneYearReturn
    three_years_return: $threeYearsReturn
    five_years_return: $fiveYearsReturn
    ten_years_return: $tenYearsReturn
    since_inception_return: $sinceInceptionReturn
    fund_id: $fundId
    monthly_returns: $monthlyReturns
  ) {
    fund_id
  }
}
`;

export const GET_FUNDS_MASTER_CHECKLIST = `
query GetMasterChecklist($payload: MasterChecklistRequest) {
  getMasterChecklist(payload: $payload) {
    id
    created_at
    last_updated_at
    status
    title
    tooltip
    type
    visible_to_entities
    visible_to_hybrids
    visible_to_natural_persons
    fund_id
  }
}
`;

export const UPDATE_FUNDS_MASTER_CHECKLIST = `
mutation CreateUpdateMasterChecklist($payload: [MasterChecklist]) {
  createUpdateMasterChecklist(payload: $payload) {
    message
    id
  }
}
`;

export const DELETE_FUND_MASTER_CHECKLIST = `
mutation DeleteMasterChecklist($deleteMasterChecklistId: Int!) {
  deleteMasterChecklist(id: $deleteMasterChecklistId)
}
`;
