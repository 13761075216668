import { Chip } from '@mui/material';

type Props = {
  label: string;
  color: string;
  bg: string;
  border?: string;
  className?: string;
};

const Chips = ({ label, bg, color, border = '', className = '' }: Props) => {
  return (
    <Chip
      label={label}
      className={className}
      sx={{
        backgroundColor: bg,
        color: color,
        border: border,
        fontSize: '12px',
        fontWeight: '600',
        height: '24px',
        '& span': { padding: '8px' },
      }}
    />
  );
};

export default Chips;
