import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import styled from '@emotion/styled';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { selectAuthUser } from 'store/user/selectors';
import { selectFundDocuments, selectFundID } from 'store/funds/selectors';
import { useAppSelector, useReactQuery, useRQMutation, useTypedDispatch } from 'store/hooks';
import { setFundDocuments } from 'store/funds/slice';
import { QueryType } from 'common/types';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import SubcriptionDocumentsForm from './SubscriptionDocuments';
import MarketingDocumentsForm from './MarketingDocuments';
import {
  setFundPriceAnnualReturns,
  setFundPriceMonthlyReturns,
  setFundPriceInceptionReturns,
  setFundsDetails,
  setFundStructAndStats,
  setFundTermsAndServices,
} from 'store/funds/slice';

type Props = {
  handleSubmitBtnClick: any;
  handleBackBtnClick: any;
  editing?: boolean;
  handleClose?: any;
  id?: string;
};

const subscriptionDocumentTypes: string[] = [
  'Subscription Document',
  'Additional Subscription Document',
  'Supplemental Subscription Document',
  'Offering Memorandum',
  'Supplemental Offering Memorandum',
  'Partnership Agreement',
  'Investment Management Agreement',
  'Tifin Private Markets Disclosure Statement',
  'Qualified Purchaser Questionaire',
  'Privacy Notice',
  'Form W-9',
  'Form ADV Part 2A',
  'Form ADV Part 2B',
  'Entity Self Certification',
  'Individual Self Certification',
  'Politcally Exposed Persons Form (Entity)',
  'Politcally Exposed Persons Form (Indv.)',
  'Investor Vehicle Summary',
  'Notable Litigation Memorandum',
  'Wire Instructions',
  'ADV Acknowledement Form',
  'Other',
];

const marketingDocumentTypes: string[] = [
  'Fund Presentation',
  'Fact Sheet',
  'Firm Presentation',
  'Investor Letter',
  'Manager Biographies',
  'Track Record',
  'Due Diligence Questionaire',
  'Operational Due Diligence',
  'DD Questionaire Appendix',
  'Private Credit Allocation Policy',
  'Other',
];

const precheckedDocTypes: string[] = [
  'Subscription Document',
  'Tifin Private Markets Disclosure Statement',
];

const StyledWrapper = styled.div`
  .close {
    width: 1em !important;
    height: 1em !important;
    color: var(--s30);
    font-size: 1.2rem;
  }

  .checkCircle {
    margin-right: 6px;
    font-size: 14px;
    color: #5eb734;
  }

  .checkbox {
    display: flex;
    justify-content: center;
    .MuiCheckbox-root {
      padding: 0;
      & svg {
        font-size: 16px;
      }
    }
  }

  th:first-of-type,
  td:first-of-type {
    padding-left: 18px !important;
  }

  th:last-of-type,
  td:last-of-type {
    padding-left: 18px !important;
  }

  .infoIcon {
    color: #525863;
  }
`;

const InvestmentDocumentForm = ({
  handleSubmitBtnClick,
  handleBackBtnClick,
  editing,
  handleClose,
  id,
}: Props) => {
  const queryClient = useQueryClient();
  const [filteredMarketingDocTypes, setFilteredMarketingDocTypes] = useState<any>();
  const [filteredSubDocTypes, setFilteredSubDocTypes] = useState<any>();

  const showPdftronData =
    process.env.REACT_APP_SHOW_PDFTRON_FEATURES?.toString().toLowerCase() === 'true';

  const [documentArr, setDocumentArr] = useState<any[]>([]);
  const [filteredDocArr, setFilteredDocArr] = useState<any[]>([]);
  const [clickedDocId, setClickedDocId] = useState<string | null>(null);

  const authUser = useAppSelector(selectAuthUser);
  const reduxFundID = useAppSelector(selectFundID);
  const fundID = id ? id : reduxFundID;
  const storeFundDocuments = useAppSelector(selectFundDocuments);
  const navigate = useNavigate();

  const dispatch = useTypedDispatch();

  const filterDocTypes = (storeDocuments?: any[]) => {
    const currentDocuments = storeDocuments ? storeDocuments : documentArr;
    if (currentDocuments.length > 0) {
      const existingSubTypes: string[] = [];
      const existingMarketingTypes: string[] = [];
      currentDocuments.forEach((el: any) => {
        if (el.docConfigTypeId === '64a11300-6aa4-11ea-bc55-0242ac130003')
          existingMarketingTypes.push(el.docClassificationName);
        else existingSubTypes.push(el.docClassificationName);
      });
      const filteredSubTypes = subscriptionDocumentTypes.filter(
        (item) => !existingSubTypes.includes(item),
      );
      const filteredMarketingTypes = marketingDocumentTypes.filter(
        (item) => !existingMarketingTypes.includes(item),
      );

      setFilteredMarketingDocTypes(filteredMarketingTypes);
      setFilteredSubDocTypes(filteredSubTypes);
    } else {
      setFilteredMarketingDocTypes(marketingDocumentTypes);
      setFilteredSubDocTypes(subscriptionDocumentTypes);
    }
  };

  useEffect(() => {
    if (storeFundDocuments) {
      filterDocTypes(storeFundDocuments);
    }
    // eslint-disable-next-line
  }, [storeFundDocuments]);

  useEffect(() => {
    filterDocTypes();
    // eslint-disable-next-line
  }, [documentArr]);

  useEffect(() => {
    (async function () {
      if (storeFundDocuments) {
        setDocumentArr(storeFundDocuments);
        const filteredDocs = filterDocuments(storeFundDocuments);
        setFilteredDocArr(filteredDocs);
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async function () {
      const filteredDocs = filterDocuments();
      setFilteredDocArr(filteredDocs);
    })();
    // eslint-disable-next-line
  }, [documentArr]);

  useEffect(() => {
    dispatch(setFundDocuments(documentArr));
    // eslint-disable-next-line
  }, [documentArr]);

  const filterDocuments = (storeDocuments?: any[]) => {
    const documents = storeDocuments ? [...storeDocuments] : [...documentArr];
    return documents;
  };

  const { data: subscriptionChecklistList } = useReactQuery(['masterSubscriptionChecklist'], {
    url: `qaip/v1/documentmanagement/getSubscriptionChecklist`,
    params: { fundId: fundID },
  });

  const { refetch: deleteDocument, isFetching } = useReactQuery(
    ['deleteDocument'],
    {
      url: `qaip/v1/documentmanagement/remove?documentId=${clickedDocId}`,
      method: QueryType.DELETE,
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries();
        deleteFilesFromList();
      },
    },
  );

  useEffect(() => {
    if (clickedDocId) {
      deleteDocument();
    }
    // eslint-disable-next-line
  }, [clickedDocId]);

  const deleteFilesFromList = () => {
    // From docsArr
    const newDocsArr = documentArr.filter((el) => el.documentId !== clickedDocId);
    setDocumentArr(newDocsArr);

    // From filterDocsArr
    const newFilterDocsArr = filteredDocArr.filter((el) => el.documentId !== clickedDocId);
    setFilteredDocArr(newFilterDocsArr);

    setClickedDocId(null);
  };

  const handleDeleteBtnClick = (id: string) => {
    setClickedDocId(id);
  };

  const handlePrefillChange = (id: string) => {
    const newDocsArr = [...documentArr];
    const reqIdx = newDocsArr.findIndex((el: any) => el.documentId === id);
    const newReqDoc = { ...newDocsArr[reqIdx] };
    const prevPrefill = newReqDoc['prefill'];
    newReqDoc['prefill'] = !prevPrefill;
    if (!newReqDoc['prefill']) {
      newReqDoc['prefillMapTo'] = null;
    }
    newDocsArr[reqIdx] = newReqDoc;
    setDocumentArr(newDocsArr);
  };

  const handleMapsToChange = (id: string) => {
    const newDocsArr = [...documentArr];
    const reqIdx = newDocsArr.findIndex((el: any) => el.documentId === id);
    const newReqDoc = { ...newDocsArr[reqIdx] };
    return (event: any) => {
      newReqDoc['prefillMapTo'] = event.target.value;
      newDocsArr[reqIdx] = newReqDoc;
      setDocumentArr(newDocsArr);
      saveMapsTo(newDocsArr);
    };
  };

  const { mutate: saveMapsTo } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/prefill',
      method: QueryType.PUT,
    },
    {
      enabled: false,
    },
  );
  const handleDiscardChanges = () => {
    dispatch(setFundsDetails(null));
    dispatch(setFundStructAndStats(null));
    dispatch(setFundTermsAndServices(null));
    dispatch(setFundPriceAnnualReturns(null));
    dispatch(setFundPriceMonthlyReturns(null));
    dispatch(setFundPriceInceptionReturns(null));
    navigate(`/investment/${fundID}`);
  };

  // Sticky ctaContainer handling
  const ctaRef = useRef(null);
  const [ctaSticky, setCtaSticky] = useState<boolean>(false);
  useLayoutEffect(() => {
    const el = ctaRef?.current;
    const observer = new IntersectionObserver(([e]) => setCtaSticky(!e.isIntersecting), {
      threshold: [1],
    });
    if (el) {
      observer.observe(el);
    }

    // Cleanup
    return () => {
      if (el) observer.unobserve(el);
    };
  }, [ctaRef]);

  return (
    <StyledWrapper>
      <Container maxWidth='xl' className={`${id ? 'px-0' : 'container-lr-padding'} investmentForm`}>
        {/* Sub Docs */}
        <Grid container justifyContent='space-between'>
          <Grid item xs={id ? 12 : 4}>
            <h2>Upload Subscription Documents</h2>
          </Grid>
          <Grid item xs={id ? 12 : 7}>
            <SubcriptionDocumentsForm
              filteredSubDocTypes={filteredSubDocTypes}
              filteredDocArr={filteredDocArr?.filter(
                (doc: any) => doc.docConfigTypeId === 'f40f45b2-6a99-11ea-bc55-0242ac130003',
              )}
              showPdftronData={showPdftronData}
              fundID={fundID}
              id={id}
              subscriptionChecklistList={subscriptionChecklistList}
              handleMapsToChange={handleMapsToChange}
              isFetching={isFetching}
              clickedDocId={clickedDocId}
              handleDeleteBtnClick={handleDeleteBtnClick}
              handlePrefillChange={handlePrefillChange}
              documentArr={documentArr}
              setDocumentArr={setDocumentArr}
              authUser={authUser}
              precheckedDocTypes={precheckedDocTypes}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent='space-between'>
          <Grid item xs={id ? 12 : 4}>
            <h2>Upload Marketing Documents</h2>
          </Grid>
          <Grid item xs={id ? 12 : 7}>
            <MarketingDocumentsForm
              filteredMarketingDocTypes={filteredMarketingDocTypes}
              filteredDocArr={filteredDocArr?.filter(
                (doc: any) => doc.docConfigTypeId === '64a11300-6aa4-11ea-bc55-0242ac130003',
              )}
              fundID={fundID}
              isFetching={isFetching}
              clickedDocId={clickedDocId}
              handleDeleteBtnClick={handleDeleteBtnClick}
              authUser={authUser}
              documentArr={documentArr}
              setDocumentArr={setDocumentArr}
              precheckedDocTypes={precheckedDocTypes}
            />
          </Grid>
        </Grid>
      </Container>

      {/* CTAs */}
      <div className={`ctaContainer ${ctaSticky ? 'ctaSticky' : ''}`} ref={id ? null : ctaRef}>
        <Container
          maxWidth='xl'
          className={`${id ? 'px-0' : 'container-lr-padding'} flex-center-end`}
        >
          {!id && (
            <MuiButton variant='text' buttonClick={handleBackBtnClick}>
              Back
            </MuiButton>
          )}
          <MuiButton
            variant='text'
            color='error'
            className='ml-3'
            buttonClick={() => {
              handleClose && handleClose();
              handleDiscardChanges();
            }}
          >
            Exit Without Saving
          </MuiButton>

          <MuiButton
            variant='contained'
            className='ml-3'
            minWidth='150px'
            buttonClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              dispatch(setFundDocuments(documentArr));
              saveMapsTo(documentArr)
              handleClose && handleClose();
              handleSubmitBtnClick();
            }}
          >
            {id ? 'Close' : 'Save & Next'}
          </MuiButton>
        </Container>
      </div>
    </StyledWrapper>
  );
};

export default InvestmentDocumentForm;
