import styled from '@emotion/styled';
import { Container } from '@mui/material';
import MuiButton from '../button';

export const StyledWrapper = styled(Container)`
  margin-bottom: 3.5rem;

  svg.infoIcon {
    margin-left: 0.5rem;
  }

  &.recommendedFunds {
    padding-top: 29px;
    padding-bottom: 29px;
    border-top: 1px solid rgb(189, 189, 189);
    border-bottom: 1px solid rgb(189, 189, 189);
    margin-bottom: 29px;
  }

  .emblaContainer {
    position: relative;
    .carousel_btn {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
      opacity: 1;
      transition: opacity 400ms;
      &.left {
        left: -25px;
        transform: translateY(-50%) rotate(180deg);
      }
      &.right {
        right: -25px;
      }
    }
    &:hover .carousel_btn {
      opacity: 1;
    }
  }

  .embla {
    overflow: hidden;
    position: relative;
    .d-flex > div {
      flex: 0 0 30.1%;
      margin-right: 1.5rem;
    }
  }

  button svg path {
    stroke: currentColor;
  }
`;

export const CustomMuiButton = styled(MuiButton)`
  height: fit-content;
  display: flex;
  align-items: center;
  margin-top: 7px;
`;
