import { useGraphQLQuery, useTypedDispatch } from 'store/hooks';
import { setMasterData } from 'store/investor/slice';
import { GET_MASTER_DATA } from './queries';

export const useMasterDataQuery = (page: string = '1') => {
  const dispatch = useTypedDispatch();
  const { refetch } = useGraphQLQuery(
    `getMasterData-${page}`,
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_MASTER_DATA,
      variables: {},
    },
    {
      enabled: false,
      select: (res) => res.getMasterData,
      onSuccess: (res) => {
        dispatch(setMasterData(res));
      },
    },
  );

  return { refetch };
};
