import { Grid, Tooltip } from '@mui/material';
import Textbox from 'common/components/inputField/Textbox';
import SelectField from 'common/components/inputField/SelectField';
import { useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Pencil } from 'common/assets/images/Pencil2.svg';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import {
  setClientAge,
  setFundsBasedOn,
  setIsInputDrivingRecButtonVisible,
  setIsSelectedClientNonNaturalPerson,
  setMaxAllocation,
  setRecommendationClient,
} from 'store/recommendation/slice';
import {
  clientAge as selectedClientAge,
  engine1Result,
  engine2Result,
  isSelectedClientNonNaturalPerson,
  maxAlloc,
  portfolioValue,
  recommendationClient,
  recommendationNumber,
  recommendedAllocation,
  selectFundsBasedOn,
} from 'store/recommendation/selectors';
import { formatAumValues } from 'helpers';
import PortfolioModal from 'components/modals/recommendations/PortfolioModal';
import { NonNaturalClientTypeList } from '../constants';

const StyledContainer = styled(Grid)`
  .container-style {
    background: #ffffff;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);
    padding: 24px;
    border-radius: 0.5rem;

    & > div + div {
      padding-left: 1.5rem;
    }
  }

  .boxContainer {
    height: 100%;
    border: 1px solid #b1d1ed;
    border-radius: 0.5rem;

    .accordion-header {
      padding: 0.75rem 1rem;
      background: var(--p20);
      border-radius: 0.5rem;
      color: var(--s50);
      font-size: 0.875rem;
      font-weight: 500;
    }

    .accordian-details {
      padding: 0.75rem 1rem;
    }
  }

  .list {
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .second-list {
    margin-top: 20px;
  }

  .portfolio-amount {
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--s40);
    margin-bottom: 1.125rem;

    svg {
      position: relative;
      top: 2px;
      margin-left: 0.5rem;
    }
  }

  .portfolio-row {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.04em;
    color: #828282;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e4dddd;
    span {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  .last-row {
    margin-top: 10px;
  }

  .more {
    font-weight: 500;
    font-size: 13px;
    color: #4590d3;
    margin-top: 20px;
  }
`;

const InputDrivingRec = ({
  clientList = [],
  selectedClient,
  isCapAllocationCardVisible = false,
  isEngine3 = false,
}: any) => {
  const dispatch = useTypedDispatch();
  const [isPortfolioModalOpen, setIsPortfolioModalOpen] = useState<boolean>(false);
  const client = useAppSelector(recommendationClient);
  const clientAge = useAppSelector(selectedClientAge);
  const portfolioVal = useAppSelector(portfolioValue);
  const engine1Data = useAppSelector(engine1Result);
  const engine2Data = useAppSelector(engine2Result);
  const recommendationNo = useAppSelector(recommendationNumber);
  const maxAllocation = useAppSelector(maxAlloc);
  const altAlloc = useAppSelector(recommendedAllocation);
  const isNonNaturalPerson = useAppSelector(isSelectedClientNonNaturalPerson);
  const selectedFundCriteria = useAppSelector(selectFundsBasedOn);

  const getAllocation = () => {
    if (recommendationNo === 0) return engine1Data?.engine_1_stats?.alt_alloc;
    else if (recommendationNo === 1)
      return engine1Data?.engine_1_stats?.recommendations?.Addl_alloc?.alt_alloc;
    else return engine1Data?.engine_1_stats?.recommendations?.Improve_liquidity?.alt_alloc;
  };

  const getEngineData = () => {
    const data = engine1Data ?? engine2Data;
    const currentPortfolio =
      data?.engine_1_stats?.current_port ?? data?.engine_2_stats?.current_port;
    return currentPortfolio ?? [];
  };

  const findClientType = async (selectedClient: any) => {
    const isNonNaturalPerson = await NonNaturalClientTypeList.includes(selectedClient.entity_type);
    if (isNonNaturalPerson) {
      await dispatch(
        setIsSelectedClientNonNaturalPerson({ isSelectedClientNonNaturalPerson: true }),
      );
      await dispatch(setClientAge({ clientAge: 35 }));
    } else {
      dispatch(setIsSelectedClientNonNaturalPerson({ isSelectedClientNonNaturalPerson: false }));
    }
  };

  return (
    <StyledContainer>
      <Grid item xs={12} container className='container-style'>
        <Grid item xs={4} sx={{ pl: '0 !important' }}>
          <div className='boxContainer'>
            <div className='accordion-header'>Client Details</div>
            <div className='accordian-details'>
              <p className='list'>1. Select Client Name </p>
              <SelectField
                options={
                  clientList &&
                  clientList.sort((a: any, b: any) => a.account_name.localeCompare(b.account_name))
                }
                optionId='investor_id'
                optionName='account_name'
                searchIcon={false}
                setSelectedValue={async (data: any) => {
                  await findClientType(data);
                  dispatch(setRecommendationClient({ client: data }));
                }}
                defaultValue={client?.investor_id}
                value={client?.investor_id}
                placeholder='Select Client'
              />
              {!isNonNaturalPerson && (
                <>
                  <p className='list second-list'>2. What is the client's age in years</p>
                  <Textbox
                    fullWidth
                    value={clientAge ?? 40}
                    onChange={async (e: any) => {
                      await dispatch(setClientAge({ clientAge: e.target.value }));
                      dispatch(
                        setIsInputDrivingRecButtonVisible({ isInputDrivingRecButtonVisible: true }),
                      );
                    }}
                    type='text'
                  />
                </>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='boxContainer'>
            <div className='accordion-header'>Traditional Portfolio</div>

            <div className='accordian-details'>
              <h2 className='portfolio-amount'>
                Portfolio Size: {formatAumValues(portfolioVal)}
                <Tooltip
                  title={<div className='tooltipContainer'>Coming soon</div>}
                  placement='top'
                  arrow
                  classes={{ tooltip: 'p-0 tooltipParent' }}
                >
                  <Pencil />
                </Tooltip>
              </h2>
              {[getEngineData()[0], getEngineData()[1]].map((port_data) => (
                <div className='portfolio-row'>
                  <span>{port_data?.Ticker}</span>{' '}
                  <span>{formatAumValues(port_data?.Weight * portfolioVal)}</span>
                </div>
              ))}
              <div onClick={() => setIsPortfolioModalOpen(true)} className='more cursor-pointer'>
                {`+${getEngineData()?.length - 2} More`}
              </div>
            </div>
          </div>
        </Grid>
        {isCapAllocationCardVisible && (
          <Grid item xs={4}>
            <div className='boxContainer'>
              <div className='accordion-header'>
                {!isEngine3 && 'Cap '}Allocation to Private Markets
              </div>

              <div className='accordian-details'>
                {!isEngine3 && (
                  <h2 className='portfolio-amount'>
                    Recommended Allocation: {(altAlloc * 100).toFixed(2) + '%'}
                  </h2>
                )}
                <p className={!isEngine3 ? 'list second-list' : 'list'}>
                  Maximum allocation to Private Markets:
                </p>
                <Textbox
                  fullWidth
                  value={maxAllocation ?? getAllocation()}
                  onChange={async (e: any) => {
                    await dispatch(setMaxAllocation({ maxAlloc: e.target.value }));
                    dispatch(
                      setIsInputDrivingRecButtonVisible({ isInputDrivingRecButtonVisible: true }),
                    );
                  }}
                  type='text'
                />
                {isEngine3 && (
                  <>
                    <p className='list second-list'>Select funds based on:</p>
                    <SelectField
                      value={selectedFundCriteria}
                      setSelectedValue={(e: any) => {
                        dispatch(
                          setIsInputDrivingRecButtonVisible({
                            isInputDrivingRecButtonVisible: true,
                          }),
                        );
                        dispatch(setFundsBasedOn({ fundsBasedOn: e }));
                      }}
                      options={['Similarity', 'Complementarity']}
                      searchIcon={false}
                    />
                  </>
                )}
              </div>
            </div>
          </Grid>
        )}
        <PortfolioModal
          selectedClient={selectedClient}
          isModalOpen={isPortfolioModalOpen}
          handleClose={() => setIsPortfolioModalOpen(false)}
          data={engine1Data?.uploadedFileData ?? engine2Data?.uploadedFileData}
          isReadOnly
        />
      </Grid>
    </StyledContainer>
  );
};

export default InputDrivingRec;
