export const GET_HOLDINGS = `
query GetHoldings($organisationId: String) {
  getHoldings(organisation_id: $organisationId) {
      current_value
      fund_id
      redemption
      qaip_holdings_history_id
      investor_id
      investor_name
      fund_name
      subscription
      income
      roc
      period_to_date
      year_to_date
      inception
      as_of
    }
  }
`;

export const GET_HOLDINGS_BY_INVESTOR_ID = `
query GetHoldingsByInvestorId($investorId: String) {
  getHoldingsByInvestorId(investor_id: $investorId) {
    qaip_holdings_history_id
    investor_id
    fund_id
    fund_name
    current_value
    subscription
    redemption
    income
    roc
    period_to_date
    year_to_date
    inception
    as_of
  }
}
`;

export const GET_INVESTORS = `
query GetInvestors($payload: investorsRequest) {
  getInvestors(payload: $payload) {
    totalCount
    investors {
      account_name
      account_number
      created_by
      entity_type
      investor_id_simple
      investor_id
      last_updated
      status
      total_holdings
      total_value
    }
  }
}
`;


export const GET_INVESTORS_FOR_DROPDOWN = `
query GetInvestors($payload: investorsRequest) {
  getInvestors(payload: $payload) {
    investors {
      account_name
      investor_id
      entity_type
    }
  }
}
`;

export const GET_INVESTOR_DETAIL = `
query GetInvestorDetail($investorId: String) {
  getInvestorDetail(investor_id: $investorId) {
    investor_id
    account_name
    entity_type
    account_number
    team
    team_name
    accredited_investor
    qualified_client
    qualified_purchaser
    primary_advisor_id
    secondary_advisor_id
    primary_advisor_name
    secondary_advisor_name
    status
    investor_id_simple
    authorizedRepresentativeTypes {
      id
      name
      address
      email
      phone_number
      fax_number
    }
    custodianTypes {
      id
      firm_name
      account_name
      account_number
      behalf_of
    }
    wireTransferBankTypes {
      id
      bank_name
      bank_address
      swift_number
      bank_account_number
      bank_account_name
      behalf_of
      email
      aba_number
      chipsUid
    }
    accountHolderTypes {
      id
      name
      mailing_address
      is_address_same_as_primary_account
      useable_for_finance_and_tax_infomation
      useable_for_notice_and_communications
      phone_number
      fax_number
      email
      ssn
      is_us_citizen
      is_primary_account_holder
      electronic_delivery
    }
    investorEntityTypes {
      id
      name
      address
      phone_number
      fax_number
      email
      tin
      contact_name
      incorporation
      useable_for_notice_and_communications
      useable_for_finance_and_tax_infomation
      useable_for_electronic_delivery_of_statements
    }
  }
}`;

export const MUTATION_ADD_UPDATE_INVESTORS = `
mutation AddUpdateInvestors($payload: addUpdateInvestorRequest) {
  addUpdateInvestors(payload: $payload) {
    investor_id
  }
}
`;
