import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHeadSkeleton from 'common/components/skeleton/TableHeadSkeleton';
import TableSubHeader from 'common/components/table/TableSubHeader';
import TableBodySkeleton from 'common/components/skeleton/TableBodySkeleton';
import SubMasterListTableRow from './SubMasterListTableRow';
import { ChecklistStyle } from 'common/components/table/styles';
import { TableColumn, TableProps } from 'common/components/table/types';

interface Column extends TableColumn {
  isSelect?: boolean;
  isInput?: boolean;
  selectOptions?: Array<any>;
}

interface Props extends TableProps {
  columns: Array<Column>;
  setUpdateRow: (row: any, index: number) => void;
  setDeleteRow: Function;
  isDeleting: boolean;
}

const SubMasterListTable = ({
  rows = [],
  columns,
  isLoading = false,
  tableClassName = '',
  setUpdateRow,
  setDeleteRow,
  isDeleting = false,
}: Props) => {
  return (
    <ChecklistStyle className={tableClassName}>
      <div className='tableWrapper'>
        <Table>
          {isLoading ? (
            <TableHeadSkeleton length={columns?.length} />
          ) : (
            <TableSubHeader columns={columns} ctaType={'test'} disableOrderBy />
          )}
          <TableBody>
            {isLoading ? (
              <TableBodySkeleton length={columns?.length} />
            ) : (
              <>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns?.length + 1} className='nullTable'>
                      No Data Available
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row: any, i: number) => (
                    <SubMasterListTableRow
                      key={row.UID}
                      i={i}
                      row={row}
                      columns={columns}
                      updateRow={(row: any, index: number) => setUpdateRow(row, index)}
                      deleteRow={setDeleteRow}
                      isDeleting={isDeleting}
                    />
                  ))
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </ChecklistStyle>
  );
};

export default SubMasterListTable;
