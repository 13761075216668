import { PayloadAction } from '@reduxjs/toolkit';
import { OrderDetails, OrderDocument } from 'common/types';
import { Order } from './types';

const reducers = {
  setOrderDetails: (state: Order, action: PayloadAction<OrderDetails | null>) => {
    state.orderDetails = action.payload;
  },
  setOrderDocs: (state: Order, action: PayloadAction<OrderDocument | null>) => {
    state.orderDocs = action.payload;
  },
  setOrderActiveStep: (state: Order, action: PayloadAction<number>) => {
    state.orderActiveStep = action.payload;
  },
  setOrderSubmittedModalOpen: (state: Order, action: PayloadAction<boolean>) => {
    state.orderSubmittedModalOpen = action.payload;
  },
  resetOrderData: (state: Order) => {
    state.orderActiveStep = 0;
    state.orderDetails = null;
    state.orderDocs = null;
  },
  // Temporary fix
  resetAllocationOrderDetails: (state: Order, action: PayloadAction<any>) => {
    state.allocationOrdersDetails = action.payload;
  },

  setOrderActivityTab: (state: Order, action: PayloadAction<any>) => {
    state.previousTab = state.currentTab;
    state.currentTab = action.payload;
  },
  hasNewNotifications: (state: Order, action: PayloadAction<boolean>) => {
    state.hasNewNotifications = action.payload;
  },
  setMyOpenOrders: (state: Order, action: PayloadAction<any>) => {
    state.myOpenOrders = action.payload;
  },
  setMyCompletedOrders: (state: Order, action: PayloadAction<any>) => {
    state.myCompletedOrders = action.payload;
  },
  setMyCancelledOrders: (state: Order, action: PayloadAction<any>) => {
    state.myCancelledOrders = action.payload;
  },
};
export default reducers;
