import { UploadFile } from '@mui/icons-material';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import Dropzone from 'react-dropzone';
import { ReactComponent as Warning } from 'common/assets/images/Warning.svg';
import { StyledModal } from './style';
import { handleTemplateDownload } from './utils';

type Props = {
  isModalOpen: boolean;
  handleModalClick: any;
  onFileUpload: any;
  setSelectedFile?: any;
  selectedFile?: any;
  onHandleFileDrop?: any;
  closeModal?: any;
  fileUploadError?: any;
};

const UploadHoldingsModal = ({
  isModalOpen,
  onFileUpload,
  selectedFile,
  onHandleFileDrop,
  closeModal,
  fileUploadError,
}: Props) => {
  return (
    <StyledModal
      title='Upload Holdings'
      isModalOpen={isModalOpen}
      handleClose={closeModal}
      maxWidth='md'
      disableCloseIcon
    >
      <Grid container>
        <Grid item xs={12} mb={'20px'}>
          {fileUploadError ? (
            <h4 className='warn mb-0 flex-center-start'>
              <Warning className='mr-1' /> Invalid File: {fileUploadError} was not uploaded.
            </h4>
          ) : (
            <h4 className='font-wt-400 mb-0'>
              Uploaded file will replace all holdings data on Tifin Private Markets platform
            </h4>
          )}
        </Grid>
        <Grid item xs={12} mb={5}>
          <Dropzone
            maxFiles={1}
            multiple={false}
            accept={
              'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            }
            onDrop={(acceptedFiles, fileRejections) => {
              onHandleFileDrop(fileRejections, acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Grid
                container
                flexDirection='column'
                className='dropzone flex-center-center'
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <UploadFile />
                <p className='mb-1'>Drag & Drop your files here</p>
                <p className='mb-2'>or</p>
                <MuiButton>Browse</MuiButton>
              </Grid>
            )}
          </Dropzone>
        </Grid>
        <Grid item xs={12} container justifyContent='flex-end'>
          <MuiButton buttonClick={() => closeModal()}>Cancel</MuiButton>
          <MuiButton className='ml-3' buttonClick={() => handleTemplateDownload()}>
            Download Template
          </MuiButton>
          <MuiButton
            disabled={!!fileUploadError || !selectedFile}
            variant='contained'
            minWidth='150px'
            className='ml-3'
            buttonClick={onFileUpload}
          >
            {selectedFile ? 'Submit' : 'Upload'}
          </MuiButton>
        </Grid>
      </Grid>
    </StyledModal>
  );
};

export default UploadHoldingsModal;
