import { useState, useEffect } from 'react';

type Props = {
  children: any;
  waitBeforeShow?: number;
  scrollCount: number;
  setScrollCount: any;
};

const ComponentDelayer = ({
  children,
  waitBeforeShow = 500,
  scrollCount,
  setScrollCount,
}: Props) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
      setScrollCount(scrollCount + 1);
      document?.getElementById('test')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, waitBeforeShow);
    return () => clearTimeout(timer);
    //eslint-disable-next-line
  }, [waitBeforeShow]);

  return isShown ? children : <div></div>;
};

export default ComponentDelayer;
