import styled from '@emotion/styled';
import { Box } from '@mui/system';

type Props = {
  children: React.ReactNode;
  delay: number;
};

const Bubble = styled(Box)<Props>`
  align-self: flex-end;
  background-color: var(--p400);
  color: white;
  border-radius: 1rem 0 1rem 1rem;
  animation: fadeIn 500ms ${(props) => props.delay}ms forwards,
    moveUp 500ms ${(props) => props.delay}ms forwards;
`;

const SenderBubble = ({ children, delay }: Props) => {
  return (
    <Bubble className='chatBubble' delay={delay}>
      {children}
    </Bubble>
  );
};

export default SenderBubble;
