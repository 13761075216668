export const VALIDATE_FUNDS = `
  mutation VerifyFunds($fundsId: [String]) {
    verifyFunds(funds_id: $fundsId) {
        valid
        invalid
        validFundDetails {
        fund_name
        fund_id
        fund_id_simple
      }
    }
  }
`;

export const VALIDATE_INVESTORS = `
  mutation VerifyInvestors($investorsId: [String]) {
    verifyInvestors(investors_id: $investorsId) {
        valid
        invalid
        validInvestorDetails {
        investor_id
        account_name
        investor_id_simple
        }
    }
  }
`;
