import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { initialState } from './slice';

const fundsCreationSlice = (state: RootState) => state.funds || initialState;

export const selectFundsActiveStep = createSelector(
  [fundsCreationSlice],
  (state) => state.fundsActiveStep,
);
export const selectFundsDetails = createSelector(
  [fundsCreationSlice],
  (state) => state.fundsDetails,
);
export const selectFundStructAndStats = createSelector(
  [fundsCreationSlice],
  (state) => state.fundStructAndStats,
);
export const selectFundsTermsAndServices = createSelector(
  [fundsCreationSlice],
  (state) => state.fundTermsAndServices,
);
export const selectFundPriceDetails = createSelector(
  [fundsCreationSlice],
  (state) => state.fundPriceDetails,
);
export const selectFundPriceAnnualReturns = createSelector(
  [fundsCreationSlice],
  (state) => state.fundPriceAnnualReturns,
);
export const selectFundPriceMonthlyReturns = createSelector(
  [fundsCreationSlice],
  (state) => state.fundPriceMonthlyReturns,
);
export const selectFundPriceInceptionReturns = createSelector(
  [fundsCreationSlice],
  (state) => state.fundPriceInceptionReturns,
);
export const selectFundDocuments = createSelector(
  [fundsCreationSlice],
  (state) => state.fundDocuments,
);
export const selectFundID = createSelector([fundsCreationSlice], (state) => state.fundID);
export const selectFundLogo = createSelector([fundsCreationSlice], (state) => state.fundLogo);

export const selectInvestmentStats = createSelector(
  [fundsCreationSlice],
  (state) => state.investmentStatsArr,
);
export const selectFundStatus = createSelector([fundsCreationSlice], (state) => state.fundStatus);
