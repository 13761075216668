import { EntityType, OrderSummary, ReactQueryDataFetch } from 'common/types';
import { useEffect } from 'react';
import { useAppSelector, useReactQuery, useTypedDispatch } from 'store/hooks';
import { setHasActivityNotification, setHasOrderBookNotification } from 'store/notification/slice';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { UserProfile } from 'store/user/types';
import CircleIcon from '@mui/icons-material/Circle';
import {
  selectHasActivityNotification,
  selectHasOrderBookNotification,
} from 'store/notification/selectors';

type Props = {
  notifications: any;
  link: string;
};

const HeaderNotificationBubbleGenerator = ({ notifications, link }: Props) => {
  const dispatch = useTypedDispatch();
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const hasOrderbookNotification: boolean = useAppSelector(selectHasOrderBookNotification);
  const hasActivityNotification: boolean = useAppSelector(selectHasActivityNotification);
  const user: any = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const { data = [] } = useReactQuery(
    [`orders-eventId-3`],
    {
      url: 'qaip/v1/ordermanagement/orderEventClub?eventId=3',
    },
    {
      refetchOnMount: true,
      enabled: user?.category === EntityType.FUNDS_MANAGER,
    },
  ) as { data: OrderSummary[] } & ReactQueryDataFetch;

  const { data: orderData = [] } = useReactQuery(['orders'], {
    url: `qaip/v1/ordermanagement/orders`,
  });

  const getNotificationBooleanForOrderBook = async (notificationFundNames: any) => {
    if (data?.length > 0) {
      const orderbookNotification = await data.some((order: any) =>
        notificationFundNames.includes(order.fundName),
      );
      await dispatch(setHasOrderBookNotification(orderbookNotification));
    } else dispatch(setHasOrderBookNotification(false));
  };

  const getNotificationBooleanForActivity = (notificationOrderIds: any) => {
    if (orderData?.length > 0) {
      const activityNotification = orderData?.some((order: any) =>
        notificationOrderIds.includes(order.orderId.toString()),
      );
      dispatch(setHasActivityNotification(activityNotification));
    } else dispatch(setHasActivityNotification(false));
  };

  useEffect(() => {
    if (notifications?.order_emails?.length > 0) {
      (async () => {
        const notificationFundNames = await notifications?.order_emails?.map((n: any) => n.fund);
        if (user?.category === EntityType.FUNDS_MANAGER)
          await getNotificationBooleanForOrderBook(notificationFundNames);
      })();
    } else if (hasOrderbookNotification && notifications?.order_emails?.length < 1) {
      dispatch(setHasOrderBookNotification(false));
    }
    // eslint-disable-next-line
  }, [notifications, data]);

  useEffect(() => {
    if (notifications?.order_emails?.length > 0) {
      (async () => {
        const notificationOrderIds = await notifications?.order_emails?.map((n: any) => n.order_id);
        if (user?.category !== EntityType.FUNDS_MANAGER)
          await getNotificationBooleanForActivity(notificationOrderIds);
      })();
    } else if (hasActivityNotification && notifications?.order_emails?.length < 1) {
      dispatch(setHasActivityNotification(false));
    }
    // eslint-disable-next-line
  }, [notifications, orderData]);

  return (
    <>
      {(link === '/orders' && hasActivityNotification) ||
      (link === '/myorderbooks' && hasOrderbookNotification) ? (
        <CircleIcon className='header-notification-bubble' fontSize='small' />
      ) : null}
    </>
  );
};

export default HeaderNotificationBubbleGenerator;
