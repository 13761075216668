import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, Nunito Sans, sans-serif',
  },
  palette: {
    primary: {
      main: '#2667A1',
    },
    error: {
      main: '#B43A3A',
    },
  },
});

export default theme;
