import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import SubHeader from 'common/components/layout/SubHeader';
import FundCard from './FundCard';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useEffect, useState } from 'react';
import StatCard from './StatCard';
import { COMPARE_STATS, CRUMBS } from './constants';
import { useMutation } from 'react-query';
import { getFundRecommendations } from 'components/modals/utils';
import Loading from 'common/components/Loading';
import { useAppSelector } from 'store/hooks';
import { selectFundsToCompare } from 'store/recommendation/selectors';
import { useNavigate } from 'react-router-dom';

const StyledWrapper = styled(Grid)`
  h5 {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--s40);
  }
`;

const Compare = () => {
  const navigate = useNavigate();
  const [stats, setStats] = useState<Array<any>>(COMPARE_STATS);
  const fundsToCompare: any = useAppSelector(selectFundsToCompare);

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result?.length > 0 ? result : [];
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const newStats = reorder(stats, result.source.index, result.destination.index);

    triggerEngine3({
      selected_funds: fundsToCompare,
      ranked_factor: newStats.map((stat: any) => stat.id),
    });
    setStats(newStats);
  };

  const {
    data,
    mutate: triggerEngine3,
    isLoading: isEngine3Loading,
  } = useMutation((data: any) => {
    return getFundRecommendations({
      selected_funds: data?.selected_funds,
      ranked_factor: data.ranked_factor,
    });
  });

  useEffect(() => {
    triggerEngine3({
      selected_funds: fundsToCompare,
      ranked_factor: stats.map((stat) => stat.id),
    });
    //eslint-disable-next-line
  }, [fundsToCompare]);

  if (fundsToCompare < 1) navigate('/investments');
  if (isEngine3Loading) return <Loading />;
  return (
    <StyledWrapper container className='m-header'>
      <Grid item xs={12} mb={2}>
        <SubHeader crumbs={CRUMBS}>
          <div className='flex-center-between'>
            <h1>Compare Funds</h1>
            <MuiButton buttonClick={() => navigate('/investments')} variant='contained'>
              Done
            </MuiButton>
          </div>
        </SubHeader>
      </Grid>

      <Grid item xs={12}>
        <Container maxWidth='lg'>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <h5>Best selection to reflect your preferences</h5>
              {data?.map((fund: any, f: number) => (
                <FundCard
                  id={fund?.ID}
                  name={fund?.Fund_Name}
                  confidenceScore={
                    fund?.Confidence_score ? +(fund?.Confidence_score * 100).toFixed(2) : 0
                  }
                  key={`FundCard${f}`}
                />
              ))}
            </Grid>
            <Grid item xs={6}>
              <h5>Reorder to reflect what's important to you</h5>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='list'>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {stats.map((stat: string, s: number) => (
                        <StatCard stat={stat} index={s} fundData={data} />
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </StyledWrapper>
  );
};

export default Compare;
