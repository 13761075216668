import { adminLinks } from 'common/constants/adminLinks';
import { headerLinks } from 'common/constants/headerLinks';
import { EntityType, OrderStatusType, PrivilegeMenuType } from 'common/types';
import { Privilege, UserProfile } from 'store/user/types';

export const getUserPrivilegeObject = (menu: string, privileges: Privilege[]) => {
  return privileges.filter((privilege) => privilege.menu === menu);
};

export const getRoleBasedHeader = (userDetails: UserProfile) => {
  const isRecommendationVisible = process.env.REACT_APP_SHOW_RECOMMENDATION_ENGINE_FLOW === 'true';
  if (userDetails.category === EntityType.FUNDS_MANAGER) {
    return headerLinks.fm;
  } else if (userDetails.category === EntityType.DEALERS_GROUP) {
    if (isRecommendationVisible) {
      return headerLinks.dg;
    } else {
      headerLinks.dg.pop();
      return headerLinks.dg;
    }
  } else {
    console.log(isRecommendationVisible);
    if (isRecommendationVisible) {
      return headerLinks.qualis;
    } else {
      headerLinks.qualis.pop();
      return headerLinks.qualis;
    }
  }
};

export const getRoleBasedAdminLinks = (userDetails: UserProfile) => {
  if (userDetails.category === EntityType.FUNDS_MANAGER) {
    const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
    const parsedData = typeOf
      ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
      : userDetails.teamsRolesDetails[0].privilege;
    const userManagement = parsedData.find(
      (privilege: Privilege) => privilege.menu === PrivilegeMenuType.USER_MANAGEMENT,
    );
    if (userManagement && (userManagement.modifyaccess || userManagement.approvalaccess)) {
      return adminLinks.fmWithUserManagement;
    } else {
      return adminLinks.fmWithoutUserManagement;
    }
  } else if (userDetails.category === EntityType.DEALERS_GROUP) {
    const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
    const parsedData = typeOf
      ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
      : userDetails.teamsRolesDetails[0].privilege;
    const userManagement = parsedData.find(
      (privilege: Privilege) => privilege.menu === PrivilegeMenuType.USER_MANAGEMENT,
    );
    if (userManagement && (userManagement.modifyaccess || userManagement.approvalaccess)) {
      return adminLinks.dgWithUserManagement;
    } else {
      return adminLinks.dgWithoutUserManagement;
    }
  } else return adminLinks.qualis;
};

// APPROVE
export const canApproveOrder = (userDetails: UserProfile, id: number, eventId: number) => {
  if (id === OrderStatusType.ORDER_CANCELLED || id === OrderStatusType.ORDER_COMPLETED)
    return false;
  if (
    (userDetails.category === EntityType.DEALERS_GROUP &&
      id === OrderStatusType.SENT_TO_APPROVER) ||
    (eventId !== 3 &&
      userDetails.category === EntityType.FUNDS_MANAGER &&
      id === OrderStatusType.SENT_TO_FUND_MANAGER) ||
    (userDetails.category === EntityType.QUALIS && id === OrderStatusType.SENT_TO_QUALIS)
  ) {
    const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
    const parsedData = typeOf
      ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
      : userDetails.teamsRolesDetails[0].privilege;
    const userManagement = parsedData.find(
      (privilege: Privilege) => privilege.menu === PrivilegeMenuType.ORDERS,
    );
    if (userManagement && userManagement.approvalaccess) {
      return true;
    }
  }
};

export const canAllocateOrder = (userDetails: UserProfile, id: number) => {
  if (id === OrderStatusType.ORDER_CANCELLED || id === OrderStatusType.ORDER_COMPLETED)
    return false;
  if (
    userDetails.category === EntityType.FUNDS_MANAGER &&
    id === OrderStatusType.SENT_TO_FUND_MANAGER
  ) {
    const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
    const parsedData = typeOf
      ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
      : userDetails.teamsRolesDetails[0].privilege;
    const userManagement = parsedData.find(
      (privilege: Privilege) => privilege.menu === PrivilegeMenuType.ORDERS,
    );
    if (userManagement && userManagement.approvalaccess) {
      return true;
    }
  }
};

// REJECT
export const canRejectOrder = (userDetails: UserProfile, id: number) => {
  if (
    id === OrderStatusType.ORDER_CANCELLED ||
    id === OrderStatusType.ORDER_COMPLETED ||
    userDetails.category === EntityType.QUALIS
  )
    return false;
  else {
    if (
      (userDetails.category === EntityType.DEALERS_GROUP &&
        id === OrderStatusType.SENT_TO_APPROVER) ||
      (userDetails.category === EntityType.FUNDS_MANAGER &&
        id === OrderStatusType.SENT_TO_FUND_MANAGER)
    ) {
      const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
      const parsedData = typeOf
        ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
        : userDetails.teamsRolesDetails[0].privilege;
      const userManagement = parsedData.find(
        (privilege: Privilege) => privilege.menu === PrivilegeMenuType.ORDERS,
      );
      if (userManagement && userManagement.modifyaccess) {
        return true;
      }
    }
  }
};

// CANCEL
export const canCancelOrder = (userDetails: UserProfile, id: number) => {
  if (
    id === OrderStatusType.ORDER_CANCELLED ||
    id === OrderStatusType.ORDER_COMPLETED ||
    userDetails.category === EntityType.QUALIS ||
    userDetails.category === EntityType.FUNDS_MANAGER
  )
    return false;
  else {
    if (
      userDetails.category === EntityType.DEALERS_GROUP &&
      (id === OrderStatusType.APPROVAL_REQUESTED ||
        id === OrderStatusType.SENT_TO_APPROVER ||
        id === OrderStatusType.SENT_TO_QUALIS ||
        id === OrderStatusType.NOT_SUBMITTED ||
        id === OrderStatusType.MORE_INFORMATION)
    ) {
      const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
      const parsedData = typeOf
        ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
        : userDetails.teamsRolesDetails[0].privilege;
      const userManagement = parsedData.find(
        (privilege: Privilege) => privilege.menu === PrivilegeMenuType.ORDERS,
      );
      if (userManagement && userManagement.modifyaccess) {
        return true;
      }
    }
  }
};

// MODIFY
export const canEditOrder = (userDetails: UserProfile, id: number) => {
  if (
    id === OrderStatusType.ORDER_CANCELLED ||
    id === OrderStatusType.ORDER_COMPLETED ||
    userDetails?.category === EntityType.FUNDS_MANAGER
  )
    return false;
  if (
    (userDetails?.category === EntityType.DEALERS_GROUP &&
      (id === OrderStatusType.APPROVAL_REQUESTED ||
        id === OrderStatusType.SENT_TO_APPROVER ||
        id === OrderStatusType.SENT_TO_QUALIS ||
        id === OrderStatusType.NOT_SUBMITTED ||
        id === OrderStatusType.MORE_INFORMATION)) ||
    userDetails?.category === EntityType.QUALIS
  ) {
    const typeOf = typeof userDetails?.teamsRolesDetails[0].privilege === 'string';
    const parsedData = typeOf
      ? JSON.parse(userDetails?.teamsRolesDetails[0].privilege as string)
      : userDetails?.teamsRolesDetails[0].privilege;
    const userManagement = parsedData.find(
      (privilege: Privilege) => privilege.menu === PrivilegeMenuType.ORDERS,
    );
    if (userManagement && userManagement.approvalaccess) {
      return true;
    }
  }
};

export const canAddComment = (userDetails: UserProfile) => {
  return userDetails?.category === EntityType.FUNDS_MANAGER;
};

export const canRequestForApproval = (userDetails: UserProfile) => {
  if (userDetails.category !== EntityType.DEALERS_GROUP) return false;
  else {
    const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
    const parsedData = typeOf
      ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
      : userDetails.teamsRolesDetails[0].privilege;
    const userManagement = parsedData.find(
      (privilege: Privilege) => privilege.menu === PrivilegeMenuType.ORDERS,
    );
    if (userManagement && (userManagement.createaccess || userManagement.modifyaccess)) {
      return true;
    }
  }
};

export const canCreateOrder = (userDetails: UserProfile) => {
  if (userDetails?.category !== EntityType.DEALERS_GROUP) return false;
  const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
  const parsedData = typeOf
    ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
    : userDetails.teamsRolesDetails[0].privilege;
  const userManagement = parsedData.find(
    (privilege: Privilege) => privilege.menu === PrivilegeMenuType.ORDERS,
  );
  if (userManagement && (userManagement.createaccess || userManagement.modifyaccess)) {
    return true;
  }
};

export const canUploadHoldings = (userDetails: UserProfile) => {
  if (userDetails?.category !== EntityType.QUALIS) return false;
  const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
  const parsedData = typeOf
    ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
    : userDetails.teamsRolesDetails[0].privilege;
  const userManagement = parsedData.find(
    (privilege: Privilege) => privilege.menu === PrivilegeMenuType.HOLDINGS,
  );
  if (userManagement && (userManagement.modifyaccess || userManagement.createaccess)) {
    return true;
  }
};

export const canDownloadHoldings = (userDetails: UserProfile) => {
  if (userDetails?.category === EntityType.FUNDS_MANAGER) return false;
  const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
  const parsedData = typeOf
    ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
    : userDetails.teamsRolesDetails[0].privilege;
  const userManagement = parsedData.find(
    (privilege: Privilege) => privilege.menu === PrivilegeMenuType.HOLDINGS,
  );
  if (
    userManagement &&
    (userManagement.viewaccess || userManagement.modifyaccess || userManagement.createaccess)
  ) {
    return true;
  } else return false;
};

export const canModifyHoldings = (userDetails: UserProfile) => {
  const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
  const parsedData = typeOf
    ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
    : userDetails.teamsRolesDetails[0].privilege;
  const userManagement = parsedData.find(
    (privilege: Privilege) => privilege.menu === PrivilegeMenuType.HOLDINGS,
  );
  if (userManagement && (userManagement.modifyaccess || userManagement.createaccess)) {
    return true;
  } else return false;
};

export const canConfirmFT = (userDetails: UserProfile, id: number) => {
  if (id !== OrderStatusType.ORDER_ACCEPTED || userDetails.category !== EntityType.DEALERS_GROUP)
    return false;
  const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
  const parsedData = typeOf
    ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
    : userDetails.teamsRolesDetails[0].privilege;
  const userManagement = parsedData.find(
    (privilege: Privilege) => privilege.menu === PrivilegeMenuType.ORDERS,
  );
  if (
    userManagement &&
    (userManagement.modifyaccess || userManagement.createaccess || userManagement.approvalaccess)
  ) {
    return true;
  }
};

export const canConfirmFR = (userDetails: UserProfile, id: number) => {
  if (id !== OrderStatusType.FUNDS_TRANSFERRED || userDetails.category !== EntityType.FUNDS_MANAGER)
    return false;
  const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
  const parsedData = typeOf
    ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
    : userDetails.teamsRolesDetails[0].privilege;
  const userManagement = parsedData.find(
    (privilege: Privilege) => privilege.menu === PrivilegeMenuType.ORDERS,
  );
  if (
    userManagement &&
    (userManagement.modifyaccess || userManagement.createaccess || userManagement.approvalaccess)
  ) {
    return true;
  }
};

export const canAskForNMI = (userDetails: UserProfile, id: number) => {
  if (
    (id === OrderStatusType.SENT_TO_QUALIS && userDetails.category === EntityType.QUALIS) ||
    (id === OrderStatusType.SENT_TO_FUND_MANAGER &&
      userDetails.category === EntityType.FUNDS_MANAGER)
  ) {
    const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
    const parsedData = typeOf
      ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
      : userDetails.teamsRolesDetails[0].privilege;
    const userManagement = parsedData.find(
      (privilege: Privilege) => privilege.menu === PrivilegeMenuType.ORDERS,
    );
    if (
      userManagement &&
      (userManagement.modifyaccess || userManagement.createaccess || userManagement.approvalaccess)
    ) {
      return true;
    }
  }
};

export const canResubmitOrder = (userDetails: UserProfile, id: number) => {
  if (
    id === OrderStatusType.MORE_INFORMATION &&
    userDetails.category === EntityType.DEALERS_GROUP
  ) {
    const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
    const parsedData = typeOf
      ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
      : userDetails.teamsRolesDetails[0].privilege;
    const userManagement = parsedData.find(
      (privilege: Privilege) => privilege.menu === PrivilegeMenuType.ORDERS,
    );
    if (
      userManagement &&
      (userManagement.modifyaccess || userManagement.createaccess || userManagement.approvalaccess)
    ) {
      return true;
    }
  }
};

export const canCreateEditApproveFund = (userDetails: UserProfile, event: string) => {
  if (
    userDetails.category === EntityType.DEALERS_GROUP ||
    userDetails.category === EntityType.FUNDS_MANAGER
  )
    return false;

  const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
  const parsedData = typeOf
    ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
    : userDetails.teamsRolesDetails[0].privilege;
  const userManagement = parsedData.find(
    (privilege: Privilege) => privilege.menu === PrivilegeMenuType.FUND,
  );
  if (event === 'edit' || event === 'create') {
    if (userManagement && (userManagement.createaccess || userManagement.modifyaccess)) {
      return true;
    }
  } else {
    if (userManagement && userManagement.approvalaccess) {
      return true;
    }
  }
};

export const hasClientPermissions = (userDetails: UserProfile, event: string) => {
  if (
    event === 'edit' &&
    [EntityType.FUNDS_MANAGER, EntityType.QUALIS].includes(userDetails.category)
  )
    return false; // Hide edit profile for TPM , TPM Super user as well as Fund Manager.

  if (userDetails.category === EntityType.FUNDS_MANAGER) {
    return false;
  }
  const typeOf = typeof userDetails.teamsRolesDetails[0].privilege === 'string';
  const parsedData = typeOf
    ? JSON.parse(userDetails.teamsRolesDetails[0].privilege as string)
    : userDetails.teamsRolesDetails[0].privilege;
  const userManagement = parsedData.find(
    (privilege: Privilege) => privilege.menu === PrivilegeMenuType.INVESTOR,
  );
  if (event === 'create') {
    return userManagement.createaccess;
  }
  if (event === 'edit') {
    return userManagement.modifyaccess;
  }
  if (event === 'view') {
    return userManagement.viewaccess;
  }
  if (event === 'approval') {
    return userManagement.approvalaccess;
  }

  return false;
};
