import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';

type Props = {
  onClick: any;
  responseDetails: any;
  initDelay: number;
  goBack: () => void;
  index: number;
  scrollCount: number;
  setScrollCount: any;
};

type StyledProps = {
  delay: number;
};

const StyledWrapper = styled(Box)`
  position: sticky;
  padding: 1.25rem 1.5rem 0.75rem;
  bottom: 0;
  background-color: white;
  box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);
`;

const CustomChatBotButton = styled(Button)<StyledProps>`
  padding: 0.5rem 1.25rem;
  border-radius: 2rem;
  background-color: var(--p400);
  margin: 0 0.5rem 0.5rem 0;
  text-transform: none;
  opacity: 0;
  line-height: 1;
  animation: fadeIn 0.5s ${(props) => props.delay}ms forwards;
`;

const Responses = ({
  onClick,
  responseDetails,
  scrollCount,
  setScrollCount,
  initDelay,
  goBack,
  index,
}: Props) => {
  return (
    <StyledWrapper>
      {responseDetails.options.map((option: any, i: number) => (
        <CustomChatBotButton
          color='primary'
          variant='contained'
          disableElevation
          disableRipple
          onClick={() => {
            onClick(option.value);
            setScrollCount(scrollCount + 1);
          }}
          delay={initDelay + i * 400}
          key={`button_${option.value}_${i}_${index}`}
        >
          {option.label}
        </CustomChatBotButton>
      ))}
      <CustomChatBotButton
        color='primary'
        variant='contained'
        disableElevation
        disableRipple
        onClick={goBack}
        key={`button_GO_BACK_${index}`}
        delay={initDelay + responseDetails.options.length * 400}
      >
        Go back
      </CustomChatBotButton>
    </StyledWrapper>
  );
};

export default Responses;
