import ReceiverBubble from '../ReceiverBubble';

interface Props {
  responseDetails: any;
  initDelay: number;
}

const ChatBotLinks = ({ responseDetails, initDelay }: Props) => {
  return responseDetails.componentProps.map((option: any, i: number) => (
    <ReceiverBubble
      isLast={i === responseDetails.componentProps.length - 1}
      delay={initDelay + 1000 * i}
      key={`link${i}`}
    >
      <a href={option.link} rel='noreferrer noopener' target='_blank'>
        {option.label}
      </a>
    </ReceiverBubble>
  ));
};

export default ChatBotLinks;
