import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import authReducer from 'store/user/slice';
import toastReducer from 'store/toast/slice';
import orderReducer from 'store/order/slice';
import fundsReducer from 'store/funds/slice';
import investmentReducer from 'store/investment/slice';
import investorReducer from 'store/investor/slice';
import notificationReducer from 'store/notification/slice';
import recommendationReducer from 'store/recommendation/slice';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  user: authReducer,
  toast: toastReducer,
  order: orderReducer,
  funds: fundsReducer,
  investment: investmentReducer,
  investor: investorReducer,
  notification: notificationReducer,
  recommendation: recommendationReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;

sagaMiddleware.run(rootSaga);
