import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import Engine3WealthChart from 'common/components/charts/Engine3WealthChart';
import Loading from 'common/components/Loading';
import {
  createHoldingPayload,
  getFundSpecificRecommendations,
  getRecommendationData,
} from 'components/modals/utils';
import { formatAumValues } from 'helpers';
import { getEmoji } from 'helpers/investments';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import {
  clientAge,
  engine1Result,
  engine2Result,
  isInputDrivingRecButtonVisible,
  isSelectedClientNonNaturalPerson,
  maxAlloc,
  portfolioValue,
  recommendationClient,
  recommendedAllocation,
  selectFundsBasedOn,
  selectFundsToCompare,
} from 'store/recommendation/selectors';
import {
  setEngine1Result,
  setEngineNumber,
  setIsInputDrivingRecButtonVisible,
  setRecommendationNumber,
  setRecommendedAllocation,
} from 'store/recommendation/slice';
import InputDrivingRec from './cards/InputDrivingRec';
import Recommendation from './cards/RecommendationE3';
import FundList from './FundList';

const StyledContainer = styled(Container)`
  color: var(--s50);

  h2 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .MuiButton-outlined {
    color: #3b85c7;
    border-color: currentColor;
    &:disabled {
      color: #c4c4c4;
      border-color: #c4c4c4;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 1.125rem;
  }

  .graph-container {
    background: #ffffff;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 28px 26px;
    position: relative;

    aside {
      margin: 0 7% 10px 0;
      background: var(--s5);
      font-size: 13px;
      color: var(--s40);

      div {
        padding: 0.5rem;
      }

      hr {
        border-width: 1px;
        width: 72px;
        margin: 0 auto 6px 0;
        border-color: var(--s20);

        &.blue {
          border-color: var(--p300);
        }
      }
    }
  }

  .gradient-text {
    font-weight: 700;
    font-size: inherit;
    background: linear-gradient(95.96deg, #2667a1 -2.77%, #3e89cd 101.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .grey-text {
    font-weight: 600;
    font-size: inherit;
    color: var(--s40);
  }

  .wealth-growth {
    background: #ffffff;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .emoji {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    display: grid;
    place-items: center;
    background-color: var(--s5);
    border: 1px solid var(--s10);
    border-radius: 50%;
    margin-right: 1rem;

    img {
      margin-right: 0;
      width: 22px !important;
      height: 22px !important;
    }
  }

  .emoji2 {
    background-color: transparent;
    border-color: transparent;
    width: unset;
    height: unset;
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--s50);
    margin-bottom: 0;
  }

  h5 {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--s30);
    margin-bottom: 0;
  }

  .heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  .MuiTextField-root.weightInput {
    margin-top: 0;
    .MuiOutlinedInput-root {
      fieldset {
        border-radius: 0.25rem;
        border: 1px solid var(--s15);
      }
      input {
        font-size: 1rem;
        font-weight: 600;
        color: var(--p400);
        width: 3rem;
        padding: 0.125rem 0.5rem;
      }
    }
  }

  .custom-tooltip {
    background-color: white;
    padding: 0.5rem;
    min-width: 100px;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    h4 {
      font-size: 13px;
      font-weight: 500;
      color: var(--s30);
      span {
        color: var(--p200);
      }
    }
    h5 {
      color: var(--s30);
    }
  }

  .stickyApply {
    position: fixed;
    background-color: white;
    z-index: 10;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);
    inset: auto 0 0;
    padding: 1rem 50px;

    button {
      display: block;
    }
  }

  .themeSummary {
    border: 1px solid var(--s15);
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
  }
`;

const Engine3 = ({ clientList }: any) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const selectedClient = useAppSelector(recommendationClient);
  const selectedFundCriteria = useAppSelector(selectFundsBasedOn);
  const portfolioVal = useAppSelector(portfolioValue);
  const alt_alloc = useAppSelector(recommendedAllocation);
  const engine1Data = useAppSelector(engine1Result);
  const engine2Data = useAppSelector(engine2Result);
  const isApplyButtonVisible = useAppSelector(isInputDrivingRecButtonVisible);
  const age = useAppSelector(clientAge);
  const isNonNaturalPerson = useAppSelector(isSelectedClientNonNaturalPerson);
  const fundsToCompare = useAppSelector(selectFundsToCompare);
  const [hover, setHover] = useState<{ theme: string; fund: string }>({ theme: '', fund: '' });
  const [inputVals, setInputVals] = useState<any>([]);
  const [showApply, setShowApply] = useState<boolean>(false);
  const max_alt_alloc = useAppSelector(maxAlloc);
  const uploadedData = engine1Data?.uploadedFileData ?? engine2Data?.uploadedFileData;

  const { mutate: triggerEngine1, isLoading: isEngine1Loading } = useMutation(
    () =>
      getRecommendationData({
        data: uploadedData,
        age,
        portfolioVal,
        max_alt_alloc,
        isNonNaturalPerson,
      }),
    {
      onSuccess: async (data: any) => {
        await dispatch(
          setEngine1Result({
            engine1Result: {
              ...data,
              uploadedFileData: createHoldingPayload(uploadedData, portfolioVal),
            },
          }),
        );
        await dispatch(
          setRecommendedAllocation({
            recommendedAllocation: data?.engine_1_stats?.alt_alloc,
          }),
        );
        await dispatch(setRecommendationNumber({ recommendationNumber: 0 }));
        await dispatch(setEngineNumber({ engineNumber: 1 }));
        window.scrollTo(0, 0);
      },
    },
  );

  const {
    data,
    mutate: triggerEngine3,
    isLoading,
  } = useMutation(
    () =>
      getFundSpecificRecommendations({
        data: engine1Data?.uploadedFileData || engine2Data?.uploadedFileData,
        age,
        portfolioVal,
        isNonNaturalPerson,
        theme_wts: inputVals.map((val: any) => ({ ...val, weight: val.weight / 100 })),
        similar_bool: selectedFundCriteria === 'Similarity',
      }),
    {
      onSuccess: (res: any) => {
        const arr = res?.new_theme_perc_alloc?.map((newTheme: any, n: number) => {
          return { theme: newTheme?.theme, weight: (newTheme?.weight * 100)?.toFixed(2) };
        });
        setInputVals(arr?.length > 0 ? arr : []);
        setShowApply(false);
      },
    },
  );

  useEffect(() => {
    triggerEngine3();
    //eslint-disable-next-line
  }, []);

  const getThemeWeight = (theme: string) => {
    const currentThemeObj = data?.new_theme_perc_alloc?.find((obj: any) => obj?.theme === theme);
    return currentThemeObj?.weight ? +(Number(currentThemeObj?.weight) * 100).toFixed(1) : 0;
  };

  const compareClick = () => {
    navigate('/investments/compare');
  };

  const getWealthGrowth = () => {
    if (data && data?.new_theme_perc_alloc) {
      const theme = data?.new_theme_perc_alloc;
      const theme1InitialValue = theme[0].weight * portfolioVal;
      const theme2InitialValue = theme[1].weight * portfolioVal;
      const theme3InitialValue = theme[2].weight * portfolioVal;
      const theme4InitialValue = theme[3].weight * portfolioVal;
      const traditionalPortfolio = 100 - alt_alloc * 100;
      const initialTPValue = (traditionalPortfolio * portfolioVal) / 100;

      let prevTheme1Value = 0;
      let prevTheme2Value = 0;
      let prevTheme3Value = 0;
      let prevTheme4Value = 0;
      let prevTPValue = 0;
      const chartData = [...new Array(66 - age)].map((item: any, i: number) => {
        let theme1Value = i === 0 ? +theme1InitialValue : +prevTheme1Value;
        let theme2Value = i === 0 ? +theme2InitialValue : +prevTheme2Value;
        let theme3Value = i === 0 ? +theme3InitialValue : +prevTheme3Value;
        let theme4Value = i === 0 ? +theme4InitialValue : +prevTheme4Value;
        let tpValue = i === 0 ? +initialTPValue : +prevTPValue;
        const updatedTheme1Value =
          i === 0 ? theme1Value : theme1Value * (1 + data?.forward_returns_list?.diversification);
        const updatedTheme2Value =
          i === 0 ? theme2Value : theme2Value * (1 + data?.forward_returns_list?.income);
        const updatedTheme3Value =
          i === 0 ? theme3Value : theme3Value * (1 + data?.forward_returns_list?.growth);
        const updatedTheme4Value =
          i === 0 ? theme4Value : theme4Value * (1 + data?.forward_returns_list?.esg);
        const updatedTPValue = i === 0 ? tpValue : tpValue * (1 + 0.0325);
        prevTheme1Value = updatedTheme1Value;
        prevTheme2Value = updatedTheme2Value;
        prevTheme3Value = updatedTheme3Value;
        prevTheme4Value = updatedTheme4Value;
        prevTPValue = updatedTPValue;
        return {
          age: i + +age,
          theme1: +updatedTheme1Value.toFixed(2),
          theme2: +updatedTheme2Value.toFixed(2),
          theme3: +updatedTheme3Value.toFixed(2),
          theme4: +updatedTheme4Value.toFixed(2),
          traditional: +updatedTPValue.toFixed(2),
        };
      });
      return chartData;
    } else return [];
  };

  const getFunds = (theme: any) => {
    const obj = data?.recommended_funds.find((fund: any) => fund?.theme === theme);
    return [obj?.fund_1, obj?.fund_2, obj?.fund_3]?.filter((item: any) => item?.Name);
  };

  if (isLoading) return <Loading />;
  return (
    <StyledContainer maxWidth='lg' className='m-header'>
      <Grid container columnSpacing={2}>
        <Grid item xs={9.6}>
          <h2>
            Based on your inputs for {selectedClient?.account_name}, consider allocating{' '}
            <span className='grey-text'>{formatAumValues(Number(portfolioVal * alt_alloc))}</span> (
            {(alt_alloc * 100).toFixed(1)}% of portfolio) to the following themes and funds.
          </h2>
        </Grid>
        <Grid item xs={2.4} display='flex' alignItems='start' justifyContent={'flex-end'}>
          <MuiButton
            disabled={fundsToCompare?.length === 0}
            buttonClick={compareClick}
            variant='outlined'
            className='ml-3'
          >
            Compare Funds ({fundsToCompare.length})
          </MuiButton>
        </Grid>
        <Grid item xs={12} container spacing={'18px'} mt={'62px'}>
          {data?.new_theme_perc_alloc?.map((item: any, i: number) => (
            <Grid item xs={3}>
              <div className='flex-center-start themeSummary'>
                <div className='emoji emoji2'>{getEmoji(item.theme)}</div>
                <h4 className='mb-0 font-wt-400'>
                  {item.theme}:{' '}
                  <span className='font-wt-600'>
                    {item.weight ? (Number(item.weight) * 100).toFixed(2) + '%' : '0%'}
                  </span>
                </h4>
              </div>
            </Grid>
          ))}
          {data?.new_theme_perc_alloc?.map((obj: any, f: number) => (
            <Grid item xs={6}>
              <FundList
                category={obj.theme}
                weight={inputVals[f].weight}
                amount={formatAumValues((getThemeWeight(obj.theme) / 100) * portfolioVal)}
                funds={getFunds(obj?.theme)}
                initialThemeValues={data?.new_theme_perc_alloc?.map((newTheme: any, n: number) => ({
                  theme: newTheme?.theme,
                  weight: newTheme?.weight * 100,
                }))}
                hover={hover}
                setHover={setHover}
                setInputVals={setInputVals}
                setShowApply={setShowApply}
                index={f}
              />
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12} mt={'52px'}>
          <Recommendation isEngine1Loading={isEngine1Loading} triggerEngine1={triggerEngine1} />
        </Grid>

        <Grid item xs={12} mt={'40px'}>
          <h4 className='heading'>Wealth Growth</h4>
          <div className='box-shadow graph-container'>
            {getWealthGrowth()?.length > 0 && <Engine3WealthChart data={getWealthGrowth()} />}
          </div>
        </Grid>

        <Grid item xs={12} container mt='40px'>
          <Grid
            item
            xs={12}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            mb={'10px'}
          >
            <h4 className='heading mb-0'>Inputs driving these recommendations</h4>
            {isApplyButtonVisible && (
              <MuiButton
                minWidth={'120px'}
                buttonClick={async () => {
                  triggerEngine3();
                  dispatch(
                    setIsInputDrivingRecButtonVisible({ isInputDrivingRecButtonVisible: false }),
                  );
                }}
                className='mb-2'
                variant='contained'
              >
                Apply Changes
              </MuiButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputDrivingRec isCapAllocationCardVisible={true} isEngine3 clientList={clientList} />
          </Grid>
        </Grid>

        {showApply && (
          <div className={'stickyApply'}>
            <Container maxWidth='xl'>
              <MuiButton
                buttonClick={() => triggerEngine3()}
                className='ml-auto'
                variant='contained'
              >
                Apply Changes
              </MuiButton>
            </Container>
          </div>
        )}
      </Grid>
    </StyledContainer>
  );
};

export default Engine3;
