import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import SVG1 from 'common/assets/images/Compare1.svg';
import SVG2 from 'common/assets/images/Compare2.svg';
import SVG3 from 'common/assets/images/Compare3.svg';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { ArrowForward } from '@mui/icons-material';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
};

const StyledModal = styled(MuiModal)`
  p {
    font-size: 13px;
    text-align: center;
    &.desc {
      font-size: 14px;
      text-align: start;
    }
  }

  .arrow {
    font-size: 1.75rem;
    color: var(--s30);
    margin-bottom: 2rem;
  }

  .position {
    position: relative;
    top: 1rem;
  }
`;

const CompareInfoModal = ({ isModalOpen, handleClose }: Props) => {
  return (
    <StyledModal
      title='Compare Funds - How It Works'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='sm'
      className='new-alloc'
    >
      <Grid container columns={11} columnSpacing={2} alignItems='center' sx={{ mt: 5, mb: 6 }}>
        <Grid item xs={3} container className='flex-col flex-center-center'>
          <img src={SVG1} alt='' className='mb-3' />
          <p>Select funds you would like to compare</p>
        </Grid>
        <Grid item xs={1} className='flex-center-center'>
          <ArrowForward className='arrow' />
        </Grid>
        <Grid item xs={3} container className='flex-col flex-center-center position'>
          <img src={SVG2} alt='' className='mb-3' />
          <p>Rank comparison metrics according to what's important to you</p>
        </Grid>
        <Grid item xs={1} className='flex-center-center'>
          <ArrowForward className='arrow' />
        </Grid>
        <Grid item xs={3} container className='flex-col flex-center-center'>
          <img src={SVG3} alt='' className='mb-3' />
          <p>View fund rankings and confidence score</p>
        </Grid>
      </Grid>

      <Grid container alignItems='center' justifyContent='flex-end'>
        <MuiButton variant='contained' buttonClick={handleClose} minWidth='150px'>
          Ok, Got it
        </MuiButton>
      </Grid>
    </StyledModal>
  );
};

export default CompareInfoModal;
