import Cash from 'common/assets/images/emoji/cash.png';
import Rocket from 'common/assets/images/emoji/rocket.png';
import Recycle from 'common/assets/images/emoji/recycle.png';
import Umbrella from 'common/assets/images/emoji/umbrella.png';
import Hourglass from 'common/assets/images/emoji/hourglass.png';
import { formatAumValues } from 'helpers';

export const getEmoji = (theme: string) => {
  switch (theme) {
    case 'Income':
      return <img src={Cash} alt='💵' className='mr-1' style={{ width: 11, height: 11 }} />;
    case 'Growth':
      return <img src={Rocket} alt='🚀' className='mr-1' style={{ width: 11, height: 11 }} />;
    case 'ESG':
    case 'Impact':
      return <img src={Recycle} alt='♻️' className='mr-1' style={{ width: 11, height: 11 }} />;
    case 'Diversification':
    case 'Downside Protection':
      return <img src={Umbrella} alt='☔️️' className='mr-1' style={{ width: 11, height: 11 }} />;
    case 'Alloc':
      return <img src={Hourglass} alt='⏳️️' className='mr-1' style={{ width: 11, height: 11 }} />;
    default:
      return <></>;
  }
};

export const transformFundReturns = (fundDetails: any) => {
  return {
    '1_year': fundDetails?.one_year_return,
    '3_years': fundDetails?.three_years_return,
    '5_years': fundDetails?.five_years_return,
    '10_years': fundDetails?.ten_years_return,
    since_inception: fundDetails?.since_inception_return,
  };
};

export const getAnnualReturns = (fundDetailsData: any) => {
  return fundDetailsData?.annualReturns
    ? fundDetailsData?.annualReturns
        ?.map((item: any) => ({
          ...item,
          return_value: Number(item.return_value).toFixed(2),
        }))
        .filter((item: any) => item.return_value !== '0.00')
    : [];
};

export const getMonthlyReturns = (monthlyReturns: any) => {
  return Object.values(
    monthlyReturns.reduce((acc: any, elem: any) => {
      const { return_year, return_month, return_value } = elem;
      acc[return_year] = acc[return_year] || {};
      acc[return_year][return_month] = Number(return_value).toFixed(2) + '%';
      acc[return_year]['Year'] = return_year;
      return acc;
    }, {}),
  ).filter((item: any) => {
    for (let curr in item) {
      if (curr !== 'Year' && item[curr] !== '0.00%') {
        return true;
      }
    }
    return false;
  });
};

export const getFundStats = (fundStats: any) => {
  return fundStats
    ?.map((item: any) => {
      return {
        ...item,
        dpi: Number(item.net_dpi?.replace('x', '')),
        tvpi: Number(item.net_tvpi?.replace('x', '')),
        irr: Number(item?.net_irr),
        primaryBar: 'DPI',
        secondaryBar: 'TVPI',
        tertiaryBar: 'IRR',
        secondaryXAxis: `${item?.vintage} • $ ${item?.size}`,
      };
    })
    .map((item: any) => {
      if (item.fund || item.net_dpi || item.net_irr || item.net_tvpi || item.size || item.vintage) {
        return { ...item, size: item.size ? formatAumValues(item.size, 1) : '' };
      }
      return false;
    });
};

export const getIsManagerTableEmpty = (fundStats: any) => {
  const fundStatsLength = fundStats?.length;
  for (let index = 0; index < fundStatsLength; index++) {
    const element = fundStats[index];
    if (element.fund) {
      return true;
    }
  }
  return false;
};

export const getKeyStatsDiv = (fundDetails: any, fundReturns: any, annualReturns: any) => {
  const assetClass = fundDetails?.asset_class;
  if (assetClass === 'Hedge Funds') {
    if (
      fundDetails?.fund_video ||
      annualReturns.length > 0 ||
      fundReturns['1_year'] ||
      fundReturns['since_inception'] ||
      (fundDetails?.fund_assets && !isNaN(fundDetails?.fund_assets)) ||
      fundDetails?.s_and_p_500
    ) {
      return true;
    }
  } else {
    if (
      fundDetails?.fund_video ||
      fundDetails?.target_irr ||
      fundDetails?.target_fund_size ||
      fundDetails?.investment_period ||
      fundDetails?.fund_term ||
      fundDetails?.s_and_p_500
    ) {
      return true;
    }
  }

  return false;
};

const checkInceptionReturnsValue = (fundReturns: any) => {
  if (fundReturns) {
    const returns = Object.values(fundReturns);
    for (let index = 0; index < returns.length; index++) {
      const element = Number(returns[index]);
      if (element) {
        return true;
      }
    }
  }
};

export const checkFundsPerformanceTab = (fundDetails: any, fundReturns: any) => {
  if (
    checkInceptionReturnsValue(fundReturns) ||
    fundDetails?.standard_deviation !== null ||
    fundDetails?.sharpe_ratio !== null ||
    fundDetails?.profitable_months_percentage !== null ||
    fundDetails?.maximum_drawdown !== null ||
    (fundDetails?.s_and_p_500 !== null && fundDetails?.s_and_p_500 !== '')
  ) {
    return false;
  }

  return true;
};

export const checkStructureAndTermsTab = (fundDetails: any) => {
  if (
    (fundDetails?.fund_assets && !isNaN(fundDetails?.fund_assets)) ||
    (fundDetails?.min_investment && !isNaN(fundDetails?.min_investment)) ||
    fundDetails?.management_fees ||
    fundDetails?.incentives_fees ||
    fundDetails?.redemptions ||
    fundDetails?.lockup ||
    (fundDetails?.asset_class !== 'Hedge Funds' &&
      (fundDetails?.final_close ||
        fundDetails?.next_close ||
        fundDetails?.target_fund_size ||
        fundDetails?.preferred_return ||
        fundDetails?.gp_catch_up_term ||
        fundDetails?.fund_term ||
        fundDetails?.funding_type))
  ) {
    return false;
  }

  return true;
};

export const checkServiceProviders = (fundDetails: any) => {
  if (
    fundDetails?.prime_broker ||
    fundDetails?.fund_counsel ||
    fundDetails?.auditor ||
    fundDetails?.administrator
  ) {
    return false;
  }

  return true;
};
