import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import ChatLoader from './ChatLoader';

type Props = {
  children: React.ReactNode;
  delay: number;
  isLast: boolean;
};

const Bubble = styled(Box)<Props>`
  align-self: flex-start;
  background-color: var(--s10);
  color: #4f4f4f;
  border-radius: 0 1rem 1rem 1rem;
  animation: fadeIn 500ms ${(props) => props.delay}ms forwards,
    moveUp 500ms ${(props) => props.delay}ms forwards;

  a {
    color: var(--p300);
    text-transform: none;
  }
`;

const ReceiverBubble = ({ children, delay, isLast }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      <Bubble className='chatBubble' delay={delay} isLast={isLast}>
        {children}
      </Bubble>
      {!isLast && isLoading ? <ChatLoader /> : null}
    </>
  );
};

export default ReceiverBubble;
