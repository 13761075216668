import { Grid } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { useRQMutation } from 'store/hooks';
import { encryptDocumentUrl } from 'helpers';
import SelectField from 'common/components/inputField/SelectField';
import Textbox from 'common/components/inputField/Textbox';
import MuiUpload from 'common/components/inputField/Upload';
import Loading from 'common/components/Loading';
import MarketingDocsTable from 'components/tables/Investments/Documents/MarketingDocsTable';
import { MKT_DOCS_COLUMNS } from './constants';

type Props = {
  filteredMarketingDocTypes: any[];
  filteredDocArr: any[];
  fundID: String;
  isFetching: boolean;
  handleDeleteBtnClick: any;
  clickedDocId: any;
  authUser: any;
  documentArr: any[];
  setDocumentArr: any;
  precheckedDocTypes: any[];
};

const MarketingDocuments = ({
  filteredMarketingDocTypes,
  filteredDocArr,
  fundID,
  isFetching,
  clickedDocId,
  handleDeleteBtnClick,
  authUser,
  documentArr,
  setDocumentArr,
  precheckedDocTypes,
}: Props) => {
  const [docName, setDocName] = useState<string>('');
  const [docTitle, setDocTitle] = useState<string>('');
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);
  const uploadedFileName = useRef<any>(null);
  const [docObjectKey, setDocObjKey] = useState<any>(null);
  const [documentObject, setDocumentObject] = useState<any>({});
  const [uploadedDoc, setUploadedDoc] = useState<any>(null);
  const [docConfigObj, setDocConfigObj] = useState<any>(null);
  const [documentData, setDocumentData] = useState<any>({
    docName: '',
    docConfigTypeId: '',
    docType: 'file',
    docCategoryId: '',
    docClassificationName: '',
    docCategoryTypeId: 2,
    status: 'Active',
    docConfigId: '',
    objectKey: '',
    documentType: 'FUND_DOCUMENT',
  });

  useEffect(() => {
    const docConfigObject = {
      createdBy: authUser?.userDetailsResponseDTO?.userId,
      docConfigList: [
        {
          docConfigTypeId: '64a11300-6aa4-11ea-bc55-0242ac130003',
          docList: [docTitle ? docTitle : docName],
        },
      ],
      fundId: fundID,
    };
    setDocConfigObj(docConfigObject);
  }, [authUser, docName, fundID, docTitle]);

  const { mutate: updateDocumentData } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/updatedocumentdata',
      isFormData: true,
    },
    {
      onSuccess: (data) => {
        const newDocumentObject = {
          ...documentObject,
          ...documentData,
          filePath: data.filePath,
          documentId: data.documentId,
        };
        const newDocumentObjArr = [...documentArr];
        newDocumentObjArr.push(newDocumentObject);
        setFileUploadLoading(false);
        setDocName('');
        setDocTitle('');
        setDocumentArr(newDocumentObjArr);
      },
    },
  );

  const { mutate: fetchPresignedUrl } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
    },
    {
      enabled: false,
      onSuccess: async (data) => {
        await encryptDocumentUrl(data.url, uploadedDoc);
        updateDocumentData({
          documentData: {
            doc_name: documentData.docName,
            doc_config_type_id: documentData.docConfigTypeId,
            doc_type: documentData.docType,
            doc_category_id: documentData.docCategoryId,
            doc_classification_name: documentData.docClassificationName,
            doc_category_type_id: documentData.docCategoryTypeId,
            status: documentData.status,
            doc_config_id: documentData.docConfigId,
            object_key: documentData.objectKey,
            document_type: documentData.documentType,
          },
        });
      },
    },
  );

  const { mutate: docConfigManagment } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/docconfigmanagement',
    },
    {
      enabled: false,
      onSuccess: async (data) => {
        const newDocObject = {
          ...documentData,
          docConfigId: data[0].docConfigId,
          docName: uploadedFileName.current,
          docConfigTypeId: '64a11300-6aa4-11ea-bc55-0242ac130003',
          docCategoryId: fundID,
          docClassificationName: docTitle ? docTitle : docName,
          objectKey: `${fundID}/file/${docTitle ? docTitle : docName}/${uploadedFileName.current}`,
          prefill: precheckedDocTypes.includes(docName),
        };
        setDocumentData(newDocObject);
        fetchPresignedUrl({ ...docObjectKey });
      },
    },
  );

  const handleOnFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileUploadLoading(true);
    const uploadedFile: any = e.target.files;
    const newUploadedFileName: string = uploadedFile.item(0).name;
    const docKey = {
      object_key: `${fundID}/file/${docTitle ? docTitle : docName}/${newUploadedFileName}`,
    };
    uploadedFileName.current = newUploadedFileName;

    const documentObj = {
      documentType: 'Marketing Documents',
      documentName: docTitle ? docTitle : docName,
      documentFileName: newUploadedFileName,
    };

    setDocumentObject(documentObj);

    await setDocObjKey(docKey);
    setUploadedDoc(uploadedFile[0]);
    docConfigManagment(docConfigObj);
    e.target.value = '';
    e.target.files = null;
  };

  return (
    <Grid item xs={12} container mb={5.75}>
      {/* Document Type & Upload*/}
      <Grid container alignItems='center' columnSpacing={2} mb={2.5}>
        <Grid item xs={12}>
          <h4>Document Type</h4>
        </Grid>
        <Grid item xs={5.5}>
          <SelectField
            options={filteredMarketingDocTypes}
            required={docName === '' ? true : false}
            onChange={(e) => setDocName(e.target.value)}
            name='class'
            setSelectedValue={setDocName}
            value={docName}
            disabled={fileUploadLoading}
            searchIcon={false}
            placeholder='Select Document Type'
          />
        </Grid>
        <Grid item xs='auto'>
          <MuiUpload
            id='marketing'
            className='mt-2'
            accept="application/pdf"
            onFileUpload={handleOnFileUpload}
            error={docName === '' ? true : false}
            disable={
              fileUploadLoading
                ? true
                : docName === ''
                ? true
                : docName === 'Other' && docTitle === ''
                ? true
                : false
            }
          >
            {fileUploadLoading ? <Loading /> : 'Click to Browse'}
          </MuiUpload>
        </Grid>
      </Grid>

      {/* Document Title for "Other" doc type */}
      {docName === 'Other' && (
        <Grid container alignItems='center' columnSpacing={2} sx={{ mb: '20px' }}>
          <Grid item xs={12}>
            <h4>Document Title</h4>
          </Grid>
          <Grid item xs={5.5} className='mb-3'>
            <Textbox
              fullWidth
              placeholder='Document Title'
              name='fund_manager_contact'
              onChange={(e: any) => setDocTitle(e.target.value)}
              errorMessage='This field is required'
              required={true}
              value={docTitle}
              disabled={fileUploadLoading}
            />
          </Grid>
        </Grid>
      )}

      {/* Table */}
      {filteredDocArr?.length > 0 && (
        <Grid item xs={12}>
          <MarketingDocsTable
            isDeleting={isFetching}
            clickedDocId={clickedDocId}
            rows={filteredDocArr}
            columns={MKT_DOCS_COLUMNS}
            deleteRow={handleDeleteBtnClick}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default MarketingDocuments;
