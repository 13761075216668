import styled from '@emotion/styled';
import { Add, Remove } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useState } from 'react';

const StyledAccordion = styled(Accordion)`
  margin-bottom: 0.8rem;
  &::before {
    background-color: unset !important;
  }

  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    padding-left: unset;
    padding-right: unset;
  }

  .MuiAccordionSummary-root {
    min-height: unset;
    border-bottom: 1px solid #ddd;
    .MuiAccordionSummary-content {
      margin: 0.5rem 0;
    }
  }
`;

interface Props {
  title: string | React.ReactFragment;
  children: React.ReactNode;
  defaultExpanded?: boolean;
}

const MuiAccordion = ({ title, children, defaultExpanded = false }: Props) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <StyledAccordion disableGutters square elevation={0} expanded={expanded}>
      <AccordionSummary
        onClick={() => setExpanded(!expanded)}
        expandIcon={expanded ? <Remove /> : <Add />}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

export default MuiAccordion;
