import { useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { format } from 'date-fns';
import { Pagination, PaginationItem } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import TableSubHeader from 'common/components/table/TableSubHeader';
import TableHeadSkeleton from 'common/components/skeleton/TableHeadSkeleton';
import TableBodySkeleton from 'common/components/skeleton/TableBodySkeleton';
import { MainStyle } from 'common/components/table/styles';
import { TableColumn, TableProps } from 'common/components/table/types';

const PreviewReturnsTable = ({
  rows = [],
  columns,
  rowClassName = '',
  isLoading = false,
  ctaType = '',
  tableClassName = '',
  rowsPerPage = 12,
  activityTable = false,
  notificationArray = [],
  dateFormat = 'MMM-dd-yyyy',
  overflowRef,
}: TableProps) => {
  const [orderBy, setOrderBy] = useState<{ id: string; dir: boolean; index: number }>({
    id: '',
    dir: true,
    index: -1,
  });
  const [page, setPage] = useState<number>(0);
  const [uniqueYears, setUniqueYears] = useState<Array<any>>([]);
  const [selectedYear, setSelectedYear] = useState<any>('');

  // Column Ordering
  const rowsBeforePagination = useMemo(() => {
    const arr: Array<any> = [];
    rows.forEach((row: any) => {
      if (!arr.includes(row.Year)) {
        arr.push(row.Year);
      }
    });
    setUniqueYears(arr.sort((a: any, b: any) => b - a));
    setSelectedYear(arr[0]);
    return [...rows];
  }, [rows]);

  // Pagination
  const finalRows = useMemo(() => {
    return rowsBeforePagination.filter((row: any) => row.Year === selectedYear);
  }, [rowsBeforePagination, selectedYear]);

  return (
    <MainStyle className={tableClassName}>
      <div className='tableWrapper'>
        <div style={{ overflowX: 'auto' }} ref={overflowRef}>
          <Table>
            {isLoading ? (
              <TableHeadSkeleton length={columns?.length} />
            ) : (
              <TableSubHeader
                columns={columns}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                ctaType={ctaType}
                disableOrderBy
              />
            )}
            <TableBody>
              {isLoading ? (
                <TableBodySkeleton length={columns?.length} />
              ) : (
                <>
                  {finalRows.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={columns?.length + (ctaType ? 1 : 0)}
                        className='nullTable'
                      >
                        No Data Available
                      </TableCell>
                    </TableRow>
                  ) : (
                    React.Children.toArray(
                      finalRows.map((row: any, i: number) => (
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          className={rowClassName}
                        >
                          {React.Children.toArray(
                            columns.map((col: TableColumn) => (
                              <TableCell
                                align={col.align}
                                className={`
                            ${activityTable && col.id === 'eventAmount' && 'italic'}
                            ${col.cellClassName ? col.cellClassName : ''}
                            position-relative
                          `}
                                sx={{ width: `${col.width ? col.width : 'initial'}` }}
                              >
                                {row[col.id] ? (
                                  col.needsFormatter ? (
                                    `${!isNaN(Number(row[col.id])) ? '$' : ''} ${
                                      isNaN(Number(row[col.id]))
                                        ? '  --  '
                                        : Number(row[col.id])?.toLocaleString()
                                    }`
                                  ) : col.isDate ? (
                                    format(new Date(row[col.id]), dateFormat)
                                  ) : col.percent ? (
                                    row[col.id] + '%'
                                  ) : activityTable &&
                                    col.label === 'ID' &&
                                    notificationArray.includes(row['orderId'].toString()) ? (
                                    <div className='d-flex'>
                                      <CircleIcon
                                        className='notification-bubble row-bubble'
                                        fontSize='small'
                                      />{' '}
                                      {row[col.id]}
                                    </div>
                                  ) : (
                                    <span>{row[col.id]}</span>
                                  )
                                ) : (
                                  '--'
                                )}
                              </TableCell>
                            )),
                          )}
                        </TableRow>
                      )),
                    )
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </div>
        <Pagination
          className='pagination'
          count={uniqueYears.length}
          page={page + 1}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            setPage(value - 1);
            setSelectedYear(uniqueYears[value - 1]);
          }}
          renderItem={(item) => {
            return (
              <>
                {item.type === 'page' && (
                  <button
                    onClick={(e: React.SyntheticEvent<Element, Event>) => {
                      item.onClick(e);
                    }}
                    className={item.selected ? 'Mui-selected' : ''}
                  >
                    {uniqueYears[item.page - 1]}
                  </button>
                )}
                <PaginationItem className={item.type === 'page' ? 'd-none' : ''} {...item} />
              </>
            );
          }}
        />
      </div>
    </MainStyle>
  );
};

export default PreviewReturnsTable;
