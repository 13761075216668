import styled from '@emotion/styled';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Crumb } from 'common/types';

const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--p300);
  font-size: 12px;
  padding-bottom: 0.2rem;
  margin-bottom: 1.2rem;

  & > * + * {
    margin-left: 1.5rem;
  }

  nav {
    flex-grow: 1;
    color: inherit;
    font-size: inherit;
  }

  .MuiBreadcrumbs-separator {
    margin: unset;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

interface Props {
  crumbs?: Array<Crumb>;
  children?: React.ReactNode;
}

const MuiBreadcrumbs = ({ crumbs, children }: Props) => {
  return (
    <StyledWrapper>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='inherit' />}>
        {crumbs?.map((crumb: Crumb, c: number) =>
          crumb.link && crumb.link !== '' ? (
            <Link to={crumb.link} key={`crumb${c}`}>
              {crumb.label}
            </Link>
          ) : (
            <span key={`crumb${c}`}>{crumb.label}</span>
          ),
        )}
      </Breadcrumbs>
      {children}
    </StyledWrapper>
  );
};

export default MuiBreadcrumbs;
