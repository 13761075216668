import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { TableColumn } from './types';
import React from 'react';
import MuiTooltip from '../tooltip';

type Props = {
  columns: Array<any>;
  orderBy?: any;
  setOrderBy?: any;
  ctaType?: string;
  disableOrderBy?: boolean;
};

const TableSubHeader = ({
  columns = [],
  orderBy,
  setOrderBy,
  ctaType,
  disableOrderBy = false,
}: Props) => {
  return (
    <TableHead>
      <TableRow>
        {React.Children.toArray(
          columns?.map((col: TableColumn, c: number) => (
            <TableCell
              align={col.align}
              sortDirection={'asc'}
              sx={{ width: `${col.width ? col.width : 'initial'}` }}
            >
              <TableSortLabel
                hideSortIcon={disableOrderBy}
                active={orderBy?.id === col?.id}
                direction={orderBy?.dir ? 'asc' : 'desc'}
                onClick={() => {
                  orderBy?.id === col.id
                    ? setOrderBy && setOrderBy({ id: col.id, dir: !orderBy?.dir, index: c })
                    : setOrderBy && setOrderBy({ id: col.id, dir: true, index: c });
                }}
              >
                <span className='flex-center-start'>
                  {col.label}
                  {col.tooltip && <MuiTooltip content={col.tooltip} />}
                </span>
              </TableSortLabel>
            </TableCell>
          )),
        )}
        {ctaType ? <TableCell></TableCell> : null}
      </TableRow>
    </TableHead>
  );
};

export default TableSubHeader;
