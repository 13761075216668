import { useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Pagination } from '@mui/material';
import React from 'react';

import { MainStyle } from './styles';
import { TableProps, OrderBy } from './types';
import { orderByReturnVal } from './utils';
import { TableHeader } from './TableHeader';
import { TableBodySection } from './TableBody';

const MuiTable = ({
  rows = [],
  columns,
  rowClassName = '',
  isLoading = false,
  ctaType = '',
  tableClassName = '',
  redirectURLKey = '',
  redirectURLPrefix = '',
  canRedirect = false,
  rowsPerPage = 10,
  activityTable = false,
  clearNotification,
  notificationArray = [],
  dateFormat = 'MMM-dd-yyyy',
  onRowClick,
  overflowRef,
  handleSetPage,
  page,
  pageSize = 10,
  orderbooksTable = false,
}: TableProps & { page: number }) => {
  const [orderBy, setOrderBy] = useState<OrderBy>({
    id: '',
    dir: true,
    index: -1,
  });

  // Column Ordering
  const finalRows = useMemo(() => {
    const arr = rows.sort((a: any, b: any) => {
      const val =
        orderBy.id !== '' ? orderByReturnVal(a, b, orderBy, columns[orderBy.index].isDate) : 0;
      return val;
    });
    return [...arr];
    //eslint-disable-next-line
  }, [rows, orderBy]);

  return (
    <MainStyle className={tableClassName}>
      <div className='tableWrapper'>
        <div style={{ overflowX: 'auto' }} ref={overflowRef}>
          <Table>
            <TableHeader
              isLoading={isLoading}
              columns={columns}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              ctaType={ctaType}
            />

            <TableBody>
              <TableBodySection
                isLoading={isLoading}
                columns={columns}
                ctaType={ctaType}
                finalRows={finalRows}
                rowClassName={rowClassName}
                activityTable={activityTable}
                dateFormat={dateFormat}
                notificationArray={notificationArray}
                clearNotification={clearNotification}
                redirectURLPrefix={redirectURLPrefix}
                redirectURLKey={redirectURLKey}
                canRedirect={canRedirect}
                onRowClick={onRowClick}
                orderbooksTable={orderbooksTable}
              />
            </TableBody>
          </Table>
        </div>
        {rowsPerPage > 1 && finalRows.length > 0 && (
          <Pagination
            className='pagination'
            count={rowsPerPage}
            page={page}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => handleSetPage(value)}
          />
        )}
      </div>
    </MainStyle>
  );
};

export default MuiTable;
