import styled from '@emotion/styled';
import { Container, Grid, Tab, Tabs } from '@mui/material';
import SearchBar from 'common/components/inputField/SearchBar';
import SubHeader from 'common/components/layout/SubHeader';
import MuiTable from 'common/components/table/Table';
import TabPanel from '@mui/lab/TabPanel';
import { TabContext } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useReactQuery } from 'store/hooks';
import { CLIENT_COLUMNS, CRUMBS, FUND_COLUMNS, ORDER_COLUMNS } from './constants';

const StyledGrid = styled(Grid)`
  .MuiTabs-root {
    padding-top: 22px;
    margin-bottom: 2rem;
    min-height: 0;
    margin-right: 1rem;
    .MuiTabs-flexContainer {
      padding: 0 34px;
      border-bottom: 1px solid #d7d7d7;
    }
    .MuiTabs-indicator {
      height: 5px;
      background-color: var(--p300);
      border-radius: 5px 5px 0 0;
    }
    button {
      color: var(--s50);
      font-size: 18px;
      font-weight: 400;
      padding: 0 1rem;
      min-height: 0;
      margin-bottom: 10px;
      text-transform: none;
      &.Mui-selected {
        color: var(--p500);
        font-weight: 600;
      }
    }
    button + button {
      margin-left: 2rem;
    }
  }

  .MuiTabPanel-root {
    padding: 2rem 0;
  }
`;

const Activity = () => {
  const [fundRows, setFundRows] = useState<Array<any>>([]);
  const [orderRows, setOrderRows] = useState<Array<any>>([]);
  const [clientRows, setClientRows] = useState<Array<any>>([]);
  const [filteredFundRows, setFilteredFundRows] = useState<Array<any>>([]);
  const [filteredOrderRows, setFilteredOrderRows] = useState<Array<any>>([]);
  const [filteredClientRows, setFilteredClientRows] = useState<Array<any>>([]);
  const [activityTab, setActivityTab] = useState<string>('0');

  const { data: fundData, isLoading } = useReactQuery(['funds'], {
    url: `qaip/v1/notificationmanagement/activity/funds`,
  });

  const { data: orderData, isLoading: isOrderLoading } = useReactQuery(['allOrders'], {
    url: `qaip/v1/notificationmanagement/activity/orders`,
  });

  const { data: clientData, isLoading: isClientLoading } = useReactQuery(['clients'], {
    url: `qaip/v1/notificationmanagement/activity/investors`,
  });

  useEffect(() => {
    if (fundData) {
      setFundRows(
        fundData.sort((a: any, b: any) => {
          if (a.time_of_action > b.time_of_action) return -1;
          else if (a.time_of_action < b.time_of_action) return 1;
          else return 0;
        }),
      );
    }
    if (orderData) {
      setOrderRows(
        orderData.sort((a: any, b: any) => {
          if (a.time_of_action > b.time_of_action) return -1;
          else if (a.time_of_action < b.time_of_action) return 1;
          else return 0;
        }),
      );
    }
    if (clientData) {
      setClientRows(
        clientData
          .map((client: any) => {
            const timeUnformatted = client.time_of_action.split(' ');
            const [month, day, year] = timeUnformatted[0].split('-');
            return { ...client, timeFormatted: `${year}-${month}-${day} ${timeUnformatted[1]}` };
          })
          .sort((a: any, b: any) => {
            if (a.timeFormatted > b.timeFormatted) return -1;
            else if (a.timeFormatted < b.timeFormatted) return 1;
            else return 0;
          }),
      );
    }
  }, [fundData, orderData, clientData]);

  // --- Search ---
  const [searchQuery, setSearchQuery] = useState<string>('');
  useEffect(() => {
    let fundArr: Array<any> = fundRows;
    let orderArr: Array<any> = orderRows;
    let clientArr: Array<any> = clientRows;

    if (fundRows) {
      setFilteredFundRows(
        fundArr.filter((row: any) => {
          for (let prop in row) {
            if (String(row[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
          }
          return false;
        }),
      );
    }
    if (orderRows) {
      setFilteredOrderRows(
        orderArr.filter((row: any) => {
          for (let prop in row) {
            if (String(row[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
          }
          return false;
        }),
      );
    }
    if (clientRows) {
      setFilteredClientRows(
        clientArr.filter((row: any) => {
          for (let prop in row) {
            if (String(row[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
          }
          return false;
        }),
      );
    }
    // eslint-disable-next-line
  }, [searchQuery, fundRows, orderRows, clientRows]);

  return (
    <StyledGrid container mt={8}>
      <Grid item xs={12}>
        <SubHeader crumbs={CRUMBS}>
          <Grid container justifyContent='space-between' alignItems='flex-end'>
            <Grid item xs='auto'>
              <h1 className='mr-20'>Activity Logs</h1>
            </Grid>
            <Grid item xs='auto'>
              <SearchBar
                minWidth='275px'
                className='mr-20'
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12} sx={{ pt: 1 }}>
        <TabContext value={activityTab}>
          <Container maxWidth='xl' className='px-0'>
            <Grid container>
              <Grid item xs={12}>
                <Tabs
                  value={activityTab}
                  onChange={(e: React.SyntheticEvent, val: string) => setActivityTab(val)}
                >
                  <Tab label='Funds' value={'0'} />
                  <Tab label='Orders' value={'1'} />
                  <Tab label='Investors' value={'2'} />
                </Tabs>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth='xl' className='container-lr-padding'>
            <Grid container>
              <Grid item xs={12}>
                <TabPanel value={'0'}>
                  <MuiTable
                    columns={FUND_COLUMNS}
                    rows={filteredFundRows}
                    isLoading={isLoading}
                    dateFormat='MMM-dd-yyyy HH:mm:ss'
                  />
                </TabPanel>
              </Grid>
              <Grid item xs={12}>
                <TabPanel value={'1'}>
                  <MuiTable
                    columns={ORDER_COLUMNS}
                    rows={filteredOrderRows}
                    isLoading={isOrderLoading}
                    dateFormat='MMM-dd-yyyy HH:mm:ss'
                  />
                </TabPanel>
              </Grid>
              <Grid item xs={12}>
                <TabPanel value={'2'}>
                  <MuiTable
                    columns={CLIENT_COLUMNS}
                    rows={filteredClientRows}
                    isLoading={isClientLoading}
                    dateFormat='MMM-dd-yyyy HH:mm:ss'
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </Container>
        </TabContext>
      </Grid>
    </StyledGrid>
  );
};

export default Activity;
