export const GET_FUNDS_LIST = `
query GetFundList($payload: AllFundsRequest) {
    getFunds(payload: $payload) {
      totalCount
      data {
        fund_type
        is_allocation_workflow_enabled
        fund_id
        logo
        fund_name
        asset_class
        min_investment
        fund_assets
        target_fund_size
        fund_themes
        strategy_description
        one_year_return
        three_years_return
        five_years_return
        ten_years_return
        since_inception_return
      }
    }
  }
`;

export const GET_FUND_DETAILS = `
query GetFundDetails($fundId: String!) {
  getFundDetails(fund_id: $fundId) {
    fundDetails {
      fund_id
      fund_name
      fund_manager_id
      asset_class
      strategy
      class
      sub_strategy
      fund_id_simple
      rating
      eligibility
      status
      logo
      strategy_description
      fund_assets
      firm_assets
      min_investment
      management_fees
      incentives_fees
      redemptions
      lockup
      compounded_rate_of_return
      standard_deviation
      sharpe_ratio
      sortino_ratio
      profitable_months_percentage
      maximum_drawdown
      managers_bio
      auditor
      administrator
      fund_counsel
      prime_broker
      disclaimer
      final_close
      funds_transfer_due_date
      bank_details
      fund_manager_title
      fund_manager_contact
      fund_manager_phone
      fund_manager_email
      one_year_return
      three_years_return
      five_years_return
      ten_years_return
      since_inception_return
      fund_template_id
      gp_catch_up_term
      target_fund_size
      performance_return
      preferred_return
      fund_tag_line
      term
      fund_video
      correlation
      next_close
      funding_type
      s_and_p_500
      lockup_for_summary
      fund_status
      liquidity_for_summary
      iso_currency
      fund_themes
      is_allocation_workflow_enabled
      investment_period
      fund_term
      income_distribution
      fund_attributes_normalized_score
      qaip_fund_stats
      qaip_funds_annualreturn
      qaip_funds_monthlyreturn
      qaip_funds_watchlist
      cash_yield
      target_irr
      impact_score
      fund_type
      returns_since_inception
    }
    fundStats {
      fund
      netdpi
      netirr
      nettvpi
      size
      vintage
    }
    annualReturns {
      return_year
      return_value
    }
    monthlyReturns {
      return_year
      return_month
      return_value
    }
  }
}
`;

export const GET_FUND_MANAGER_DETAILS = `
query GetOrgDetails($organisationId: String!) {
  getOrgDetails(organisationId: $organisationId) {
    organisation_id
    organisation_name
  }
}
`;
