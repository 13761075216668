import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import SelectField from 'common/components/inputField/SelectField';
import LoadingButton from 'common/components/button/LoadingButton';
import Textbox from 'common/components/inputField/Textbox';
import {
  DOC_REQ,
  DOC_TYPE,
} from 'components/investments/investmentForms/investmentCheckList/constants';
import {
  ENTITIES_TOOLTIP_CONTENT,
  HYBRIDS_TOOLTIP_CONTENT,
  MAPS_TO_TOOLTIP_CONTENT,
  NATURAL_PERSONS_TOOLTIP_CONTENT,
} from 'common/constants/tooltipContent';
import MuiTooltip from 'common/components/tooltip';

type Props = {
  isModalOpen: boolean;
  isSaving: boolean;
  handleClose: any;
  addFundRow: any;
  setSaveCtaClicked: any;
  saveCtaClicked: string;
  fundID: string;
  docOptions: Array<any>;
  saveMapsTo: Function;
  editDetails: any;
  selectedDocIdsObj: Array<any>;
  selectedDocIds: Array<string>;
  setToBeMappedDoc: any;
};

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 656px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 0.5rem !important;
    line-height: 26px;
    & > div {
      flex-wrap: nowrap;
      align-items: start;
    }
  }
  h4 {
    color: var(--s50);
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  input::placeholder {
    color: var(--s30);
    -webkit-text-fill-color: var(--s30);
    opacity: 1;
  }

  .infoIcon {
    display: inline;
    position: relative;
    top: 3px;
    color: var(--s30);
    font-size: 18px;
    margin-left: 6px;
  }
`;

const ChecklistItemModal = ({
  isModalOpen,
  isSaving,
  handleClose,
  addFundRow,
  fundID,
  docOptions,
  saveMapsTo,
  setSaveCtaClicked,
  saveCtaClicked,
  editDetails,
  selectedDocIds,
  selectedDocIdsObj,
  setToBeMappedDoc,
}: Props) => {
  const [count, setCount] = useState<number>(0);
  const [selectedDoc, setSelectedDoc] = useState<any>({});
  const [filteredDocOptions, setFilteredDocOptions] = useState<any[]>([]);

  useEffect(() => {
    if (selectedDocIds.length > 0 && selectedDocIdsObj.length > 0) {
      const requiredRowIdIdx = selectedDocIdsObj.findIndex(
        (el: any) => el.rowId === editDetails?.id,
      );
      if (requiredRowIdIdx === -1) {
        const res = docOptions.filter((item) => !selectedDocIds.includes(item.documentId));
        setFilteredDocOptions(res);
      } else {
        const currentDocId = selectedDocIdsObj[requiredRowIdIdx].docId;
        const filteredSelectedDocIds = selectedDocIds.filter((id: string) => id !== currentDocId);
        const res = docOptions.filter((item) => !filteredSelectedDocIds.includes(item.documentId));
        setFilteredDocOptions(res);
      }
    } else {
      setFilteredDocOptions(docOptions);
    }
    //eslint-disable-next-line
  }, [selectedDocIds, selectedDocIdsObj, docOptions]);

  const nullFields = {
    title: '',
    type: '',
    visible_to_natural_persons: '',
    visible_to_entities: '',
    visible_to_hybrids: '',
  };
  const [fields, setFields] = useState<any>(nullFields);

  useEffect(() => {
    if (editDetails) {
      setFields(editDetails);
      setSelectedDoc(docOptions?.find((doc: any) => editDetails?.id === doc.prefillMapTo));
    }
    //eslint-disable-next-line
  }, [editDetails]);

  return (
    <StyledModal
      title={editDetails ? 'Edit Checklist Item' : 'Add Checklist Item'}
      isModalOpen={isModalOpen}
      handleClose={() => {
        setFields(nullFields);
        handleClose();
      }}
      maxWidth='md'
      className='new-alloc'
    >
      <Grid container mt={3} mb={'52px'}>
        <Grid item xs={8.4} mb={4}>
          <h4>Document Name</h4>
          <Textbox
            placeholder='Please type document type here e.g. Driving License'
            fullWidth
            value={fields.title}
            disabled={editDetails && !editDetails?.fund_id ? true : false}
            onChange={(e: any) => setFields({ ...fields, title: e.target.value })}
          />
        </Grid>
        <Grid container item xs={12} mb={3} columnSpacing={2} className='flex-center-between'>
          <Grid item xs={8.4}>
            <h4>Save this document to the Client's profile and re-use for future submissions?</h4>
          </Grid>
          <Grid item xs={3.6}>
            <SelectField
              placeholder='Select One'
              options={DOC_TYPE}
              searchIcon={false}
              optionId='id'
              disabled={editDetails && !editDetails?.fund_id ? true : false}
              optionName='title'
              value={fields.type}
              setSelectedValue={(e: any) => {
                setFields({ ...fields, type: e.id });
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={3} columnSpacing={2} className='flex-center-between'>
          <Grid item xs={8.4}>
            <h4>
              Is the document type required for natural persons?
              <MuiTooltip content={NATURAL_PERSONS_TOOLTIP_CONTENT} />
            </h4>
          </Grid>
          <Grid item xs={3.6}>
            <SelectField
              placeholder='Select One'
              options={DOC_REQ}
              searchIcon={false}
              disabled={editDetails && !editDetails?.fund_id ? true : false}
              optionId='id'
              optionName='title'
              value={fields.visible_to_natural_persons}
              setSelectedValue={(e: any) => {
                setFields({ ...fields, visible_to_natural_persons: e.id });
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={3} columnSpacing={2} className='flex-center-between'>
          <Grid item xs={8.4}>
            <h4>
              Is the document type required for entities?
              <MuiTooltip content={ENTITIES_TOOLTIP_CONTENT} />{' '}
            </h4>
          </Grid>
          <Grid item xs={3.6}>
            <SelectField
              placeholder='Select One'
              options={DOC_REQ}
              disabled={editDetails && !editDetails?.fund_id ? true : false}
              searchIcon={false}
              optionId='id'
              optionName='title'
              value={fields.visible_to_entities}
              setSelectedValue={(e: any) => {
                setFields({ ...fields, visible_to_entities: e.id });
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={3} columnSpacing={2} className='flex-center-between'>
          <Grid item xs={8.4}>
            <h4>
              Is the document type required for trusts or other similar investor types?
              <MuiTooltip content={HYBRIDS_TOOLTIP_CONTENT} />{' '}
            </h4>
          </Grid>
          <Grid item xs={3.6}>
            <SelectField
              placeholder='Select One'
              options={DOC_REQ}
              searchIcon={false}
              disabled={editDetails && !editDetails?.fund_id ? true : false}
              optionId='id'
              optionName='title'
              value={fields.visible_to_hybrids}
              setSelectedValue={(e: any) => {
                setFields({ ...fields, visible_to_hybrids: e.id });
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} columnSpacing={2} className='flex-center-between'>
          <Grid item xs={8.4}>
            <h4>
              Map checklist item to a fund document uploaded in the previous step
              <MuiTooltip content={MAPS_TO_TOOLTIP_CONTENT} />
            </h4>
          </Grid>
          <Grid item xs={3.6}>
            <SelectField
              placeholder='Select Document'
              options={[...filteredDocOptions, { documentId: null, docName: 'None' }]}
              searchIcon={false}
              optionId='documentId'
              optionName='docName'
              disabled={!(docOptions.length > 0)}
              value={selectedDoc?.documentId}
              defaultValue={selectedDoc?.documentId}
              setSelectedValue={(e: any) => {
                setSelectedDoc(e);
                editDetails
                  ? setToBeMappedDoc([
                      {
                        ...e,
                        prefillMapTo: editDetails.id,
                      },
                    ])
                  : setToBeMappedDoc([
                      {
                        ...e,
                      },
                    ]);
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignItems='center' justifyContent='flex-end'>
        <MuiButton
          color='error'
          buttonClick={() => {
            setFields(nullFields);
            handleClose();
          }}
        >
          Discard Changes
        </MuiButton>
        {editDetails ? (
          isSaving ? (
            <LoadingButton minWidth='150px' className='ml-3' />
          ) : (
            <MuiButton
              variant='contained'
              minWidth='150px'
              disabled={Object.values(fields).some((val: any) => val === '')}
              className='ml-3'
              buttonClick={() => {
                addFundRow(
                  {
                    ...fields,
                    UID: `${count}-${new Date().getTime()}`,
                    fund_id: editDetails.fund_id,
                    isUpdate: true,
                  },
                  true,
                );
              }}
            >
              Save
            </MuiButton>
          )
        ) : (
          <>
            {isSaving && saveCtaClicked === 'Save & Exit' ? (
              <LoadingButton variant='text' minWidth='90px' className='ml-3' />
            ) : (
              <MuiButton
                minWidth='90px'
                className='ml-3'
                buttonClick={async () => {
                  await setSaveCtaClicked('Save & Exit');
                  addFundRow({
                    ...fields,
                    UID: `${count}-${new Date().getTime()}`,
                    fund_id: fundID,
                  });
                  // setFields(nullFields);
                  setCount(count + 1);
                }}
                disabled={Object.values(fields).some((val: any) => val === '')}
              >
                Save & Exit
              </MuiButton>
            )}
            {isSaving && saveCtaClicked === 'Save & Add More' ? (
              <LoadingButton minWidth='150px' className='ml-3' />
            ) : (
              <MuiButton
                variant='contained'
                className='ml-3'
                minWidth='150px'
                disabled={Object.values(fields).some((val: any) => val === '')}
                buttonClick={async () => {
                  await setSaveCtaClicked('Save & Add More');
                  setCount(count + 1);
                  addFundRow({
                    ...fields,
                    UID: `${count}-${new Date().getTime()}`,
                    fund_id: fundID,
                  });
                  setFields(nullFields);
                }}
              >
                Save & Add More
              </MuiButton>
            )}
          </>
        )}
      </Grid>
    </StyledModal>
  );
};

export default ChecklistItemModal;
