// import {
//   ENTITIES_TOOLTIP_CONTENT,
//   HYBRIDS_TOOLTIP_CONTENT,
//   NATURAL_PERSONS_TOOLTIP_CONTENT,
// } from 'common/constants/tooltipContent';

export enum DocRequirementType {
  MANDATORY = '1',
  OPTIONAL = '2',
  'N/A' = '3',
}

export const DOC_TYPE = [
  { id: 'Investor', title: 'Yes, save to Client profile' },
  { id: 'Subscription', title: 'No, only use for this subscription' },
];
export const DOC_REQ = [
  { id: DocRequirementType.MANDATORY, title: 'Mandatory' },
  { id: DocRequirementType.OPTIONAL, title: 'Optional' },
  { id: DocRequirementType['N/A'], title: 'N/A' },
];

export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '',
    label: 'Subscription Master Checklist',
  },
];

export const FUND_COLUMNS = [
  {
    id: 'title',
    label: 'Document Type',

    isSelect: false,
    isInput: true,
  },
  {
    id: 'type',
    label: 'SAVE DOCUMENT TO CLIENT PROFILE?',

    isSelect: true,
    selectOptions: DOC_TYPE,
    width: '17%',
  },
  {
    id: 'visible_to_natural_persons',
    label: 'IS DOCUMENT REQUIRED FOR NATURAL PERSONS?',

    isSelect: true,
    selectOptions: DOC_REQ,
    width: '17%',
    // tooltip: NATURAL_PERSONS_TOOLTIP_CONTENT,
  },
  {
    id: 'visible_to_entities',
    label: 'IS DOCUMENT REQUIRED FOR ENTITIES?',

    isSelect: true,
    selectOptions: DOC_REQ,
    width: '17%',
    // tooltip: ENTITIES_TOOLTIP_CONTENT,
  },
  {
    id: 'visible_to_hybrids',
    label: 'IS DOCUMENT REQUIRED FOR HYBRIDS?',

    isSelect: true,
    selectOptions: DOC_REQ,
    width: '17%',
    // tooltip: HYBRIDS_TOOLTIP_CONTENT,
  },
];

export const MASTER_COLUMNS = [
  {
    id: 'title',
    label: 'Document Type',

    cellClassName: 'font-wt-500 color-p500',
    width: '20%',
  },
  {
    id: 'type',
    label: 'SAVE DOCUMENT TO CLIENT PROFILE?',
    cellClassName: 'font-wt-500 color-s30',

    width: '17%',
  },
  {
    id: 'visible_to_natural_persons',
    label: 'IS DOCUMENT REQUIRED FOR NATURAL PERSONS?',

    width: '17%',
    cellClassName: 'font-wt-500 color-s30',
    // tooltip: NATURAL_PERSONS_TOOLTIP_CONTENT,
  },
  {
    id: 'visible_to_entities',
    label: 'IS DOCUMENT REQUIRED FOR ENTITIES?',

    width: '17%',
    cellClassName: 'font-wt-500 color-s30',
    // tooltip: ENTITIES_TOOLTIP_CONTENT,
  },
  {
    id: 'visible_to_hybrids',
    label: 'IS DOCUMENT REQUIRED FOR HYBRIDS?',
    cellClassName: 'font-wt-500 color-s30',

    width: '17%',
  },
];

export const PLACEHOLDER_COLS = [
  {
    id: 'docType',
    label: 'Document Type',
  },
  {
    id: 'fileName',
    label: 'Was Document received from the fund?',
    isSelect: true,
    selectOptions: [
      { id: 1, title: 'f1.pdf' },
      { id: 2, title: 'f2.pdf' },
    ],
  },
];
