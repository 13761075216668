import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import { Order } from './types';

export const initialState: Order = {
  orderDetails: null,
  orderDocs: null,
  orderActiveStep: 0,
  orderSubmittedModalOpen: false,
  allocationOrdersDetails: {
    fundName: '',
    orderIds: [],
  },
  previousTab: -1,
  currentTab: 0,
  hasNewNotifications: false,
  myOpenOrders: [],
  myCompletedOrders: [],
  myCancelledOrders: [],
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: reducers,
});

export const {
  setOrderDetails,
  setOrderDocs,
  setOrderActiveStep,
  setOrderSubmittedModalOpen,
  resetOrderData,
  resetAllocationOrderDetails,
  setOrderActivityTab,
  hasNewNotifications,
  setMyCancelledOrders,
  setMyCompletedOrders,
  setMyOpenOrders,
} = slice.actions;

export default slice.reducer;
