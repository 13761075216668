import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepIconProps } from '@mui/material/StepIcon';
import { ReactComponent as CheckCircle } from 'common/assets/images/CTA/Check.svg';
import Circle from '@mui/icons-material/Circle';

const StyledStepper = styled(Stepper)`
  padding: 0 6rem;

  svg {
    font-size: 1.25rem;
    color: transparent;
    stroke: var(--s20);
    stroke-width: 3;

    &.tick {
      transform: scale(1.5);
      & path {
        stroke: var(--p150);
      }
    }
  }

  .MuiStepLabel-label {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 11px;
    color: var(--s50);
    margin-top: 0.875rem;

    &.Mui-active,
    &.Mui-completed {
      font-weight: initial;
    }
  }

  .connector {
    position: absolute;
    top: 9px;
    left: calc(-50% + 20px);
    right: calc(50% + 20px);

    &:after {
      content: '';
      display: block;
      background-color: var(--s20);
      width: 100%;
      height: 2px;
      border-radius: 10px;
    }
  }

  .active {
    svg {
      color: var(--p150);
      stroke: var(--p150);
    }
    .connector:after {
      background-color: var(--p150);
    }
  }

  .Mui-completed .connector:after {
    background-color: var(--p150);
  }
`;

const StepIcon = ({ completed }: StepIconProps) => {
  return completed ? <CheckCircle className='tick' /> : <Circle />;
};

type Props = {
  steps: string[];
  activeStep: number;
};

const BYOFstepper = ({ steps, activeStep }: Props) => {

  return (
    <Stack sx={{ width: '100%' }} spacing={4} className='byofStepper'>
      <StyledStepper
        activeStep={activeStep}
        alternativeLabel
        className='flex-center-between'
        connector={<div className='connector'></div>}
      >
        {steps.map((label: string, l: number) => (
          <Step key={label} className={activeStep === l ? 'active' : ''}>
            <StepLabel
              className={activeStep === 0 ? '' : 'cursor-pointer'}
              StepIconComponent={StepIcon}
            >
              <span>{label}</span>
            </StepLabel>
          </Step>
        ))}
      </StyledStepper>
    </Stack>
  );
};

export default BYOFstepper;
