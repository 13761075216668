export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/investments',
    label: 'Investments',
  },
  {
    link: '',
    label: 'Compare Funds',
  },
];

export const TEMP_DATA = [
  {
    fund_id: '77aff27b-cd3f-4978-83f2-31ad8e807a7b',
    logo: 'logo.jpeg',
    fund_name: 'Hexa Lower Middle Market Investment Fund',
    Return: 0.3512,
    Risk: 0.2378,
    Fee: 0.01,
    Confidence_score: 0.688,
  },
  {
    fund_id: '77aff27b-cd3f-4978-83f2-31ad8e807a7b',
    logo: 'logo.jpeg',
    fund_name: 'Earth 2.0 ESG Special Situations',
    Return: 0.1321,
    Risk: 0.0203,
    Fee: 0.02,
    Confidence_score: 0.4724,
  },
  {
    fund_id: '77aff27b-cd3f-4978-83f2-31ad8e807a7b',
    logo: 'logo.jpeg',
    fund_name: 'Circle Midcap Situations',
    Return: 0.1562,
    Risk: 0.1464,
    Fee: 0.02,
    Confidence_score: 0.333,
  },
  {
    fund_id: '77aff27b-cd3f-4978-83f2-31ad8e807a7b',
    logo: 'logo.jpeg',
    fund_name: 'Hexa Lower Middle Market Investment Fund 1',
    Return: 0.1562,
    Risk: 0.1464,
    Fee: 0.02,
    Confidence_score: 0.333,
  },
  {
    fund_id: '77aff27b-cd3f-4978-83f2-31ad8e807a7b',
    logo: 'logo.jpeg',
    fund_name: 'Hexa Lower Middle Market Investment Fund 2',
    Return: 0.1562,
    Risk: 0.1464,
    Fee: 0.02,
    Confidence_score: 0.333,
  },
  //   {
  //     fund_id: '77aff27b-cd3f-4978-83f2-31ad8e807a7b',
  //     logo: 'logo.jpeg',
  //     fund_name: 'Hexa Lower Middle Market Investment Fund 3',
  //     Return: 0.1562,
  //     Risk: 0.1464,
  //     Fee: 0.02,
  //     Confidence_score: 0.333,
  //   },
  //   {
  //     fund_id: '77aff27b-cd3f-4978-83f2-31ad8e807a7b',
  //     logo: 'logo.jpeg',
  //     fund_name: '08 fund 07',
  //     Return: 0.1562,
  //     Risk: 0.1464,
  //     Fee: 0.02,
  //     Confidence_score: 0.333,
  //   },
];

export const COMPARE_STATS = [
  {
    id: 'Return',
    label: 'Returns',
  },
  {
    id: 'Risk',
    label: 'Risk',
  },
  {
    id: 'Fee',
    label: 'Fees',
  },
];
