import { Container } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiStepper from 'common/components/stepper';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { selectFundsActiveStep } from 'store/funds/selectors';
import { setFundsActiveStep } from 'store/funds/slice';
import InvestmentDetialsForm from './investmentForms/InvestmentDetailsForm';
import InvestmentStructAndStatsForm from './investmentForms/InvestmentStructAndStatsForm';
import InvestmentTSPForm from './investmentForms/InvestmentTSPForm';
import InvestmentHistoricalReturnsForm from './investmentForms/InvestmentHistorialReturnsForm';
import InvestmentPriceForm from './investmentForms/InvestmentPriceForm';
import InvestmentDocumentForm from './investmentForms/InvestmentDocumentForm';
import { useLocation, useNavigate } from 'react-router-dom';
import InvestmentChecklistForm from './investmentForms/investmentCheckList/InvestmentChecklistForm';
import styled from '@emotion/styled';

const StyledContainer = styled(Container)`
  .MuiStepLabel-label {
    font-size: 11px !important;
  }
  .MuiStepLabel-iconContainer svg {
    font-size: 18px;
  }
`;

const EditFunds = () => {
  const dispatch = useTypedDispatch();

  const activeStep = useAppSelector(selectFundsActiveStep);

  const navigate = useNavigate();

  const location = useLocation();
  const id = location.pathname.split('/')[3];

  const steps = [
    'Fund Details',
    'Fund Structure & Stats',
    'Terms & Service Providers',
    'Historical Returns',
    'Price',
    'Subscription Checklist',
    'Fund Documents',
  ];

  const handleSubmitBtnClick = async () => {
    await dispatch(setFundsActiveStep(activeStep + 1));
    window.scrollTo(0, 0);
  };

  const handleBackBtnClick = async () => {
    await dispatch(setFundsActiveStep(activeStep - 1));
    window.scrollTo(0, 0);
  };

  return (
    <StyledContainer maxWidth='xl' className='container-lr-padding container-t-margin'>
      <div className='d-flex'>
        <MuiStepper steps={steps} activeStep={activeStep} />
        <CloseIcon
          style={{ marginLeft: 10, cursor: 'pointer' }}
          onClick={() => navigate(`/investment/${id}`)}
        />
      </div>
      {activeStep === 0 && (
        <InvestmentDetialsForm handleSubmitBtnClick={handleSubmitBtnClick} editing={true} />
      )}
      {activeStep === 1 && (
        <InvestmentStructAndStatsForm
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={true}
        />
      )}
      {activeStep === 2 && (
        <InvestmentTSPForm
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={true}
        />
      )}
      {activeStep === 3 && (
        <InvestmentHistoricalReturnsForm
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={true}
        />
      )}
      {activeStep === 4 && (
        <InvestmentPriceForm
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
        />
      )}
      {activeStep === 5 && (
        <InvestmentChecklistForm
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={true}
        />
      )}
      {activeStep === 6 && (
        <InvestmentDocumentForm
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={true}
        />
      )}
    </StyledContainer>
  );
};

export default EditFunds;
