import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import TableSkeleton from '../skeleton/Skeleton';

type Props = {
    length: number;
}

const TableHeadSkeleton = ({ length }: Props) => {
    return (
        <TableHead>
            <TableRow>
                {React.Children.toArray(Array(length).fill('').map(() => (
                    <TableCell>
                        <TableSkeleton width="auto" height={40} variant="text" />
                    </TableCell>
                )))}
            </TableRow>
        </TableHead>
    );
};

export default TableHeadSkeleton;
