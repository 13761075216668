import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useAppSelector, useRQMutation, useTypedDispatch, useGraphQLQuery } from 'store/hooks';
import InvestmentMasterListTable from '../../../tables/Investments/Checklist/InvestmentChecklistTable';
import { selectFundDocuments, selectFundID } from 'store/funds/selectors';
import LoadingButton from 'common/components/button/LoadingButton';
import ChecklistItemModal from 'components/modals/investments/ChecklistItemModal';
import { QueryType } from 'common/types';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { setFundDocuments } from 'store/funds/slice';
import {
  GET_FUNDS_MASTER_CHECKLIST,
  UPDATE_FUNDS_MASTER_CHECKLIST,
  DELETE_FUND_MASTER_CHECKLIST,
} from '../queries';
import { handleGraphqlMutation } from 'helpers';
import { ToastMessage, ToastType, showToast } from 'store/toast/slice';

type Props = {
  handleSubmitBtnClick: any;
  handleBackBtnClick: any;
  editing?: boolean;
};

const COLUMNS = [
  {
    id: 'title',
    label: 'CHECKLIST DOCUMENT NAME',
    width: '40%',
  },
  {
    id: '_select',
    label: 'MAP CHECKLIST ITEM TO A FUND DOCUMENT',
    isSelect: true,
    width: '33%',
  },
];

const InvestmentChecklistForm = ({ handleSubmitBtnClick, handleBackBtnClick, editing }: Props) => {
  const [masterRows, setMasterRows] = useState<Array<any>>([]);
  const [isEditDetailsModal, setIsEditDetailsModal] = useState<boolean>(false);
  const [toBeMappedDoc, setToBeMappedDoc] = useState<any>(null);
  const [saveCtaClicked, setSaveCtaClicked] = useState<string>('');
  const navigate = useNavigate();
  // const [fundRows, setFundRows] = useState<Array<any>>([]);
  // eslint-disable-next-line
  // const [newFundRows, setNewFundRows] = useState<Array<any>>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // eslint-disable-next-line
  // const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const location = useLocation();
  // const navigate = useNavigate();
  const id = location.pathname.split('/')[3];
  const [docEditingId, setDocEditingId] = useState<any>(null);
  const [currentSubscriptionDocs, setCurrentSubscriptionDocs] = useState<any[]>([]);
  const [currentMarketingDocs, setCurrentMarketingDocs] = useState<any[]>([]);
  const [currentRestSubscriptionDocs, setCurrentRestSubscriptionDocs] = useState<any[]>([]);
  const [selectedDocIdsObj, setSelectedDocIdsObj] = useState<any[]>([]); //* This is the state to keep track of the mapped documents with their rowIDs ( Array of object)
  const [selectedDocIds, setSelectedDocIds] = useState<string[]>([]); //* This is the state to keep track of the mapped documents Ids ( Array of strings)
  const queryClient = useQueryClient();
  const dispatch = useTypedDispatch();

  const fundID = useAppSelector(selectFundID);
  const fundDocuments = useAppSelector(selectFundDocuments);

  const {
    data: { getMasterChecklist: fundMasterChecklist } = {},
    isLoading: isFetchingMasterChecklist,
    isRefetching,
  } = useGraphQLQuery(
    `fundsMasterChecklist${id}`,
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_FUNDS_MASTER_CHECKLIST,
      variables: {
        payload: {
          fundId: fundID ?? id,
        },
      },
    },
    { refetchOnWindowFocus: false },
  );

  const { mutate: mutateFundsMasterChecklist, isLoading: mutatingChecklist } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
        query: data.query,
        variables: { ...data.variables },
      });
    },
    {
      onSuccess(response: any) {
        const data = response?.data?.data as any;
        const { id: rowId, message } = data?.createUpdateMasterChecklist;
        if (isEditDetailsModal) {
          if (message === 'Updated' && toBeMappedDoc) {
            const mappedToDoc = { ...toBeMappedDoc[0] };
            const docArr: any[] = [mappedToDoc];
            docArr[0].documentId && saveMapsTo(docArr);
            handleMapTo(docArr, false);
          }
          setDocEditingId(null);
          setModalOpen(false);
          setIsEditDetailsModal(false);
          const toast: ToastMessage = {
            type: ToastType.SUCCESS,
            message: 'Changes have been saved.',
          };
          dispatch(showToast(toast));
          queryClient.invalidateQueries(`fundsMasterChecklist${id}`);
          return;
        }
        if (message === 'Updated' && toBeMappedDoc) {
          const mappedToDoc = { ...toBeMappedDoc[0], prefillMapTo: rowId };
          const docArr: any[] = [mappedToDoc];
          docArr[0].documentId && saveMapsTo(docArr);
          handleMapTo(docArr, false);
        }
        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'Changes have been saved.',
        };
        dispatch(showToast(toast));
        saveCtaClicked === 'Save & Add More' ? setModalOpen(true) : setModalOpen(false);
        queryClient.invalidateQueries(`fundsMasterChecklist${id}`);
      },
    },
  );

  const { mutate: deleteFundMasterChecklist, isLoading: deletingFundChecklist } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
        query: data.query,
        variables: { ...data.variables },
      });
    },
    {
      onSuccess(response: any) {
        queryClient.invalidateQueries(`fundsMasterChecklist${id}`);
        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'Item deleted Successfully',
        };
        dispatch(showToast(toast));
      },
    },
  );

  const { mutate: saveMapsTo, isLoading } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/prefill',
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        setToBeMappedDoc(null);
        queryClient.invalidateQueries(`documents-${id ?? fundID}`);
        // queryClient.invalidateQueries(`fundsMasterChecklist${id}`);
      },
    },
  );

  useEffect(() => {
    if (fundDocuments) {
      const subDocs = fundDocuments.filter(
        (doc: any) =>
          doc.docConfigTypeId === 'f40f45b2-6a99-11ea-bc55-0242ac130003' && doc.prefill === true,
      );
      const subRestDocs = fundDocuments.filter(
        (doc: any) =>
          doc.docConfigTypeId === 'f40f45b2-6a99-11ea-bc55-0242ac130003' && doc.prefill === false,
      );
      const marketingDocs = fundDocuments.filter(
        (doc: any) => doc.docConfigTypeId === '64a11300-6aa4-11ea-bc55-0242ac130003',
      );

      handleMapTo(subDocs, true);
      setCurrentRestSubscriptionDocs(subRestDocs);
      setCurrentSubscriptionDocs(subDocs);
      setCurrentMarketingDocs(marketingDocs);
    }
    // eslint-disable-next-line
  }, [fundDocuments]);

  useEffect(() => {
    if (fundMasterChecklist && fundMasterChecklist.length > 0) {
      const arr = fundMasterChecklist.map((row: any, i: number) => ({
        ...row,
        UID: `${i}-${new Date().getTime()}`,
      }));
      arr.sort((a: any, b: any) => a.created_at.localeCompare(b.created_at));
      setMasterRows(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundMasterChecklist]);

  const handleDeleteRow = (data: any) => {
    deleteFundMasterChecklist({
      query: DELETE_FUND_MASTER_CHECKLIST,
      variables: {
        deleteMasterChecklistId: data.id,
      },
    });
  };

  const switchCurrentSubscriptionDocs = (
    docId: string,
    rowId: string | null,
    save: boolean,
    mutatedCurrentSubDocs?: any[],
  ) => {
    const toBeUsedSubDocs = mutatedCurrentSubDocs ? mutatedCurrentSubDocs : currentSubscriptionDocs;
    const currentSubscriptionDocuments = [...toBeUsedSubDocs];
    const previousDocIdx = currentSubscriptionDocuments.findIndex(
      (doc: any) => doc.documentId === docId,
    );
    let previousDoc = currentSubscriptionDocuments[previousDocIdx];
    previousDoc = { ...previousDoc, prefillMapTo: rowId };
    currentSubscriptionDocuments[previousDocIdx] = previousDoc;
    save && saveMapsTo([previousDoc]);
    return currentSubscriptionDocuments;
  };

  //* This function handles the state of prefill docs and rows
  const handleMapTo = async (documentObjectArr: any[], firstRender: boolean) => {
    let mutatedCurrentSubDocs = [...currentSubscriptionDocs];
    const documentObjectArrLength = documentObjectArr.length;
    let deletePreviousDoc: boolean = false;
    let previousDocDocId: string = '';
    const currentSelectedDocIdsObj = [...selectedDocIdsObj];
    for (let index = 0; index < documentObjectArrLength; index++) {
      const currentDocId = documentObjectArr[index].documentId;
      const currentRowId = documentObjectArr[index].prefillMapTo ?? null;
      const requiredRowIdx = currentSelectedDocIdsObj.findIndex(
        (el: any) => el.rowId === currentRowId,
      );
      if (requiredRowIdx === -1) {
        if (currentRowId === null && firstRender) {
          continue;
        } else if (currentDocId === null && !firstRender) {
          break;
        } else {
          currentSelectedDocIdsObj.push({
            docId: currentDocId,
            rowId: currentRowId,
          });
        }
      } else {
        const currentSelectedDoc = { ...currentSelectedDocIdsObj[requiredRowIdx] };
        if (currentSelectedDoc.docId === currentDocId) {
          return;
        } else {
          if (currentDocId === null) {
            deletePreviousDoc = true;
            previousDocDocId = currentSelectedDoc.docId;
            currentSelectedDocIdsObj.splice(requiredRowIdx, 1);
            break;
          } else {
            deletePreviousDoc = true;
            previousDocDocId = currentSelectedDoc.docId;
            currentSelectedDoc.docId = currentDocId;
            currentSelectedDocIdsObj[requiredRowIdx] = currentSelectedDoc;
          }
        }
      }

      if (!firstRender) {
        const currentSubscriptionDocuments = await switchCurrentSubscriptionDocs(
          currentDocId,
          currentRowId,
          false,
        );
        mutatedCurrentSubDocs = currentSubscriptionDocuments;
        await setCurrentSubscriptionDocs(currentSubscriptionDocuments);
      }
    }
    if (deletePreviousDoc) {
      const currentSubscriptionDocuments = await switchCurrentSubscriptionDocs(
        previousDocDocId,
        null,
        true,
        mutatedCurrentSubDocs,
      );
      await setCurrentSubscriptionDocs(currentSubscriptionDocuments);
    }
    const currentSelectedDocIds = currentSelectedDocIdsObj.map((el: any) => el.docId);
    setSelectedDocIds(currentSelectedDocIds);
    setSelectedDocIdsObj(currentSelectedDocIdsObj);
  };

  const handleUpdateMasterChecklist = (rows: any[], isEditDetails: boolean) => {
    const payloadRows = rows.map((row: any) => {
      const { UID, isNewRow, ...rest } = row;
      return rest;
    });
    mutateFundsMasterChecklist({
      query: UPDATE_FUNDS_MASTER_CHECKLIST,
      variables: {
        payload: payloadRows,
      },
      isEditDetails,
    });
  };

  // Sticky ctaContainer handling
  const ctaRef = useRef(null);
  const [ctaSticky, setCtaSticky] = useState<boolean>(false);
  useLayoutEffect(() => {
    const el = ctaRef?.current;
    const observer = new IntersectionObserver(([e]) => setCtaSticky(!e.isIntersecting), {
      threshold: [1],
    });
    if (el) {
      observer.observe(el);
    }

    // Cleanup
    return () => {
      if (el) observer.unobserve(el);
    };
  }, [ctaRef]);

  return (
    <>
      <Container maxWidth='xl' className='container-lr-padding investmentForm'>
        <Grid container justifyContent='space-between'>
          <Grid item xs={3}>
            <h2>Order Submission Checklist</h2>
            <p>
              This checklist is the set of documents required when submitting a new order. You can
              customize this list by adding or removing documents.
              <br />
              <br />
              You can also map checklist items to fund documents uploaded in the previous step. This
              enables document pre-filling and downloading from within a new order form.
            </p>
          </Grid>
          <Grid item xs={8}>
            <InvestmentMasterListTable
              tableClassName='mb-4'
              columns={COLUMNS}
              saveMapsTo={(docArr: any[]) => {
                docArr[0].documentId && saveMapsTo(docArr);
                handleMapTo(docArr, false);
              }}
              setIsEditDetailsModal={setIsEditDetailsModal}
              isDeleting={deletingFundChecklist}
              selectedDocIdsObj={selectedDocIdsObj}
              selectedDocIds={selectedDocIds}
              rows={[...masterRows]}
              setDeleteRow={(index: any) => handleDeleteRow(index)}
              isLoading={isFetchingMasterChecklist || isRefetching}
              addChecklist={() => {
                setSaveCtaClicked('');
                setModalOpen(true);
              }}
              selectOptions={currentSubscriptionDocs}
              setDocEditingId={setDocEditingId}
              setModalOpen={setModalOpen}
            />
          </Grid>
        </Grid>
      </Container>

      {/* CTAs */}
      <div className={`ctaContainer ${ctaSticky ? 'ctaSticky' : ''}`} ref={ctaRef}>
        <Container maxWidth='xl' className='container-lr-padding flex-center-end'>
          <MuiButton
            variant='text'
            buttonClick={() => {
              dispatch(
                setFundDocuments([
                  ...currentSubscriptionDocs,
                  ...currentMarketingDocs,
                  ...currentRestSubscriptionDocs,
                ]),
              );
              handleBackBtnClick();
            }}
          >
            Back
          </MuiButton>
          <MuiButton
            variant='text'
            color='error'
            className='ml-3'
            buttonClick={() => navigate(`/investment/${fundID}`)}
          >
            Exit Without Saving
          </MuiButton>
          {false ? (
            <LoadingButton minWidth='150px' className='ml-3' />
          ) : (
            <MuiButton
              variant='contained'
              minWidth='150px'
              className='ml-3'
              disabled={isLoading || deletingFundChecklist || mutatingChecklist}
              buttonClick={() => {
                setFundDocuments([
                  ...currentSubscriptionDocs,
                  ...currentMarketingDocs,
                  ...currentRestSubscriptionDocs,
                ]);
                navigate(`/investment/${fundID}`);
              }}
            >
              Save & Exit
            </MuiButton>
          )}
        </Container>
      </div>

      {modalOpen && (
        <ChecklistItemModal
          addFundRow={(newRow: any, isEditDetails: boolean) => {
            handleUpdateMasterChecklist([newRow], isEditDetails);
            // update([...masterRows, newRow]);
          }}
          setToBeMappedDoc={setToBeMappedDoc}
          setSaveCtaClicked={setSaveCtaClicked}
          saveCtaClicked={saveCtaClicked}
          isSaving={mutatingChecklist}
          selectedDocIdsObj={selectedDocIdsObj}
          selectedDocIds={selectedDocIds}
          isModalOpen={modalOpen}
          handleClose={() => {
            setModalOpen(false);
            setDocEditingId(null);
          }}
          fundID={fundID}
          saveMapsTo={(docArr: any[]) => {
            docArr[0].documentId && saveMapsTo(docArr);
            handleMapTo(docArr, false);
          }}
          editDetails={[...masterRows].find((row: any) => row.id === docEditingId)}
          docOptions={currentSubscriptionDocs}
        />
      )}
    </>
  );
};

export default InvestmentChecklistForm;
