import styled from '@emotion/styled';
import MuiButton from 'common/components/button';
import Textbox from 'common/components/inputField/Textbox';
import { getEmoji } from 'helpers/investments';
import { ReactComponent as CompareIcon } from 'common/assets/images/recommendation/Compare.svg';
import { ReactComponent as CompareSelected } from 'common/assets/images/recommendation/CompareSelected.svg';
import MuiTooltip from 'common/components/tooltip';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { recommendedAllocation, selectFundsToCompare } from 'store/recommendation/selectors';
import { setFundsToCompare } from 'store/recommendation/slice';
import FundCharacteristicsChart from 'common/components/charts/FundCharacteristicsChart';
import { Tooltip as MuiTooltip2 } from '@mui/material';

const StyledWrapper = styled.div`
  border: 1px solid var(--s15);
  border-radius: 0.5rem;
  padding: 1.25rem;
  position: relative;
  min-height: 221.5px;

  h4 .infoIcon {
    margin-left: 0.25rem;
  }

  h4 .MuiTextField-root {
    margin: 0 0.25rem;
  }

  .fundName {
    font-size: 13px;
    font-weight: 500;
    color: var(--s50);
    margin-bottom: 0;
  }

  section {
    padding-bottom: 0.5rem;
    margin-bottom: 1.25rem;
  }

  .weightInput {
    position: relative;
    bottom: 0.25rem;
    height: 0;
  }

  button + button {
    margin-left: 0.5rem;
  }

  .compareBtn {
    padding: 5px;
    box-sizing: border-box;
    width: 34px;
    height: 35px;
  }

  .compare path {
    stroke: currentColor;
  }

  .fundList {
    padding-left: 20px;
    div + div {
      margin-top: 0.5rem;
      padding-top: 0.5rem;
      border-top: 1px solid var(--s15);
    }
  }

  .infoIcon {
    position: relative;
    font-size: 0.875rem;
    color: var(--s30);
  }

  .themeChart {
    position: absolute;
    inset: 0;
    top: -1px;
    z-index: 5;
    width: 570px;
    box-sizing: border-box;
    height: 264px;
    padding: 10px 1rem;
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);

    &.right {
      left: -584px;
    }
    &.left {
      left: 584px;
    }

    h5 {
      font-size: 13px;
      font-weight: 400;
      color: var(--s50);
      margin-bottom: 0.25rem;
    }

    h6 {
      font-size: 11px;
      font-weight: 400;
      color: var(--s30);
      margin-bottom: 24px;
    }
  }

  .chartTooltip {
    background-color: var(--s10);
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    color: var(--s50);
    line-height: 1.5em;
    border-radius: 0.25rem;
  }

  .recharts-label,
  .recharts-xAxis text {
    fill: #707070;
    font-size: 11px;
    font-weight: 400;
  }

  .recharts-cartesian-axis-line {
    stroke: var(--s20);
  }
  .xAxis2 {
    color: var(--s40);
    margin: 0 auto !important;
    text-align: center;
  }

  .noFunds {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 190px;

    .emoji {
      margin-bottom: 28px;
    }
  }
`;

interface Props {
  category: string;
  funds: any;
  weight: string | number;
  amount: string;
  hover: any;
  setHover: any;
  index: number;
  setInputVals: any;
  setShowApply: any;
  initialThemeValues: any;
}

const FundList = ({
  category,
  funds,
  weight,
  amount,
  hover,
  setHover,
  index,
  setInputVals,
  setShowApply,
  initialThemeValues,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const fundsToCompare = useAppSelector(selectFundsToCompare);
  const alt_alloc = useAppSelector(recommendedAllocation);

  const getTooltipContent = () => {
    switch (category) {
      case 'Growth':
        return 'Growth Funds target above average capital appreciation, often with little or no payouts.';
      case 'Downside Protection':
      case 'Diversification':
        return 'These funds limit a decrease in the value of  investment in the event of broader market downturns.';
      case 'Income':
        return 'These funds aim to generate current income for investors (e.g. through interest payments or capital repayments). ';
      case 'Impact':
        return 'These funds incorporate environmental, social and governance factors into the investment process.';
      default:
        return '';
    }
  };

  const getYaxisLabel = () => {
    switch (category) {
      case 'Growth':
        return 'Rate of Return';
      case 'Downside Protection':
      case 'Diversification':
        return 'Downside Protection';
      case 'Income':
        return 'Cash Yield';
      case 'Impact':
      case 'ESG':
        return 'ESG Impact';
      default:
        return '';
    }
  };

  const getDesc = () => {
    switch (category) {
      case 'Growth':
        return 'Expected or realized rate of return';
      case 'Downside Protection':
      case 'Diversification':
        return 'Alignment with ESG or Impact principles, as stated by the Fund Manager';
      case 'Income':
        return 'Expected or realized cash yield';
      case 'Impact':
        return 'Monthly historical return downside correlation to SP500';
      default:
        return '';
    }
  };

  const detailsClick = (id: string) => {
    navigate(`/investment/${id}`);
  };

  const orderClick = (id: string, name: string) => {
    navigate('/create-order', {
      state: {
        fundDetails: { fundName: name, fundsId: id },
      },
    });
  };

  const handleAddToCart = (selectedFund: string) => {
    const fundIds = fundsToCompare?.length > 0 ? [...fundsToCompare] : [];
    const isFilter = fundIds?.includes(selectedFund);
    if (isFilter) {
      const filteredFunds = fundIds.filter((id) => id !== selectedFund);
      dispatch(setFundsToCompare({ fundsToCompare: filteredFunds }));
    } else if (fundsToCompare.length < 5) {
      const updatedCompareList = fundIds.concat(selectedFund);
      dispatch(setFundsToCompare({ fundsToCompare: updatedCompareList }));
    }
  };

  const splitThemeValues = (userInput: any) => {
    if (userInput === '') {
      setInputVals((prev: any) =>
        prev?.map((item: any) => ({
          ...item,
          weight: item?.theme === category ? userInput : item?.weight,
        })),
      );
    } else if (!isNaN(userInput) && +userInput < alt_alloc * 100) {
      const sum = initialThemeValues.reduce((prev: any, current: any, i: any) => {
        if (i !== index) return prev + +current?.weight;
        else return prev + 0;
      }, 0);
      const arr = initialThemeValues?.map((obj: any, x: number) => {
        if (x === index) return { ...obj, weight: +userInput };
        else
          return {
            ...obj,
            weight: Number(
              ((+alt_alloc * 100 - +userInput) * +initialThemeValues[x]?.weight) / +sum,
            ).toFixed(2),
          };
      });

      setInputVals(arr);
    }
  };

  return (
    <StyledWrapper
      onMouseOver={() => setHover((prev: any) => ({ ...prev, theme: category }))}
      onMouseLeave={() => setHover({ theme: '', fund: '' })}
    >
      <section className={`flex-center-start`}>
        <div className='emoji'>{getEmoji(category)}</div>
        <div>
          <h4 className='mb-0 font-wt-400'>
            {category} Funds{' '}
            <Textbox
              type='number'
              value={weight}
              className='weightInput'
              onWheel={(e: any) => e.target.blur()}
              onChange={(e: any) => {
                setShowApply(true);
                splitThemeValues(e.target.value);
              }}
            />{' '}
            % of overall portfolio, or {amount} <MuiTooltip content={getTooltipContent()} />
          </h4>
        </div>
      </section>

      <div className='fundList'>
        {funds?.length === 0 && (
          <h5 className='fundName ml-5'>
            Change allocation to this theme to view fund recommendations
          </h5>
        )}
        {funds?.map((fund: any, f: number) => (
          <div
            className='flex-center-between'
            onMouseOver={() => setHover((prev: any) => ({ ...prev, fund: fund?.ID }))}
            onMouseLeave={() => setHover((prev: any) => ({ ...prev, fund: '' }))}
          >
            <h5 className='fundName'>
              {fund?.Name?.length > 40 ? fund?.Name?.substring(0, 37) + '...' : fund?.Name}
            </h5>
            <div>
              <MuiButton buttonClick={() => detailsClick(fund?.ID ?? '')}>Details</MuiButton>
              <MuiTooltip2
                title={
                  <div className='tooltipContainer'>
                    {fundsToCompare?.includes(fund?.ID)
                      ? 'Remove From Compare'
                      : fundsToCompare?.length > 4
                      ? 'You can only compare up to 5 funds at a time'
                      : 'Add To Compare'}
                  </div>
                }
                placement='top'
                arrow
                classes={{ tooltip: 'p-0 tooltipParent' }}
              >
                <span className='mx-2' onClick={() => handleAddToCart(fund.ID)}>
                  <MuiButton variant='outlined' className='compareBtn'>
                    {fundsToCompare?.includes(fund?.ID) ? (
                      <CompareSelected />
                    ) : (
                      <CompareIcon className='compare' />
                    )}
                  </MuiButton>
                </span>
              </MuiTooltip2>
              <MuiButton
                variant='contained'
                buttonClick={() => orderClick(fund?.ID ?? '', fund?.Name ?? '')}
              >
                Add Order
              </MuiButton>
            </div>
          </div>
        ))}
      </div>
      {funds?.length > 0 && (
        <FundCharacteristicsChart
          data={funds}
          yKey={category}
          hover={hover}
          currentTheme={category}
          index={index}
          yAxisLabel={getYaxisLabel()}
          desc={getDesc()}
        />
      )}
    </StyledWrapper>
  );
};

export default FundList;
