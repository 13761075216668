import MuiModal from 'common/components/modal';
import styled from '@emotion/styled';
import { useState } from 'react';
import { ClickAwayListener, Grid, Tooltip } from '@mui/material';
import MuiCard from 'common/components/card';
import MuiButton from 'common/components/button';
import Redtail from 'common/assets/images/crm/Redtail.png';
import Wealthbox from 'common/assets/images/crm/Wealthbox.png';
import Hubspot from 'common/assets/images/crm/Hubspot.png';
import Textbox from 'common/components/inputField/Textbox';

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 560px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 0.75rem !important;
    line-height: 26px;
  }
  h5 {
    color: var(--s30);
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  .crmGrid {
    padding-bottom: 2.5rem;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid var(--s15);

    .MuiCard-root {
      width: 140px;
      height: 112px;
      padding: 22px;
      display: grid;
      place-items: center;
      box-sizing: border-box;
      cursor: pointer;

      &.selected {
        border-color: var(--p150);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .selectedCRM {
    padding: 0 5.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h5 {
      text-align: center;
      color: var(--s50);
      margin-bottom: 2.5rem;
    }

    h4 {
      font-weight: 400;
      margin-bottom: 0.5rem;
    }

    img {
      width: 208px;
      height: 80px;
      margin-bottom: 4rem;
      object-fit: contain;
    }

    .MuiOutlinedInput-root {
      width: 100%;
      margin-bottom: 0.75rem;
    }

    .MuiButton-root {
      display: block;
      margin: 2rem auto 0;
    }
  }
`;

type Props = {
  isModalOpen: boolean;
  handleClose: any;
};

const CRMIntegrationModal = ({ isModalOpen, handleClose }: Props) => {
  const [selectedCRMIndex, setSelectedCRMIndex] = useState<number>(0);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <StyledModal
      title='CRM Integrations'
      isModalOpen={isModalOpen}
      handleClose={() => {
        handleClose();
      }}
      maxWidth='sm'
      className='new-alloc disabled'
    >
      <Grid container>
        <Grid item xs={12} mb={'52px'}>
          <h5>Connect your CRM to read in your client data.</h5>
        </Grid>
        <Grid item xs={12} className='flex-center-between crmGrid'>
          {CRM_DATA.map((data: any, i: number) => (
            <MuiCard
              minHeight='unset'
              key={`CRM${i}`}
              className={i === selectedCRMIndex ? 'selected' : ''}
              onClick={() => {
                setSelectedCRMIndex(i);
              }}
            >
              <img src={data.img} alt={data.name} />
            </MuiCard>
          ))}
        </Grid>
        <Grid item xs={'auto'} className='selectedCRM'>
          <h5>Connect your {CRM_DATA[selectedCRMIndex].name} account to read in client list</h5>
          <h4>{CRM_DATA[selectedCRMIndex].name} Username</h4>
          <Textbox />

          <h4>Password</h4>
          <Textbox />
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                title={<div className='tooltipContainer'>Coming soon</div>}
                placement='top'
                arrow
                open={open}
                disableFocusListener
                PopperProps={{
                  disablePortal: true,
                }}
                disableHoverListener
                onClose={handleTooltipClose}
                classes={{ tooltip: 'p-0 tooltipParent' }}
              >
                <div onClick={handleTooltipOpen}>
                  <MuiButton variant='contained' minWidth='150px'>
                    Authenticate
                  </MuiButton>
                </div>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Grid>
      </Grid>
    </StyledModal>
  );
};

const CRM_DATA = [
  {
    name: 'Redtail',
    img: Redtail,
  },
  {
    name: 'Wealthbox',
    img: Wealthbox,
  },
  {
    name: 'HubSpot',
    img: Hubspot,
  },
];

export default CRMIntegrationModal;
