import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import SVG1 from 'common/assets/images/NewAllocRequest1.svg';
import SVG2 from 'common/assets/images/NewAllocRequest2.svg';
import SVG3 from 'common/assets/images/NewAllocRequest3.svg';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { ArrowForward } from '@mui/icons-material';
import { useAppSelector } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { EntityType } from 'common/types';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  buttonClick?: any;
  disableCTA?: boolean;
};

const StyledModal = styled(MuiModal)`
  p {
    font-size: 13px;
    text-align: center;
    &.desc {
      font-size: 14px;
      text-align: start;
    }
  }

  .arrow {
    font-size: 1.75rem;
    color: var(--s30);
    margin-bottom: 2rem;
  }
`;

const AllocationDetailsModal = ({ isModalOpen, handleClose, buttonClick, disableCTA }: Props) => {
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isFM = user?.category === EntityType.FUNDS_MANAGER;

  return (
    <StyledModal
      title='Allocation Request - How It Works'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='sm'
      className='new-alloc'
    >
      <Grid container>
        {!isFM && (
          <p className='desc'>
            This fund is collecting interest ahead of an upcoming close. Follow these steps to
            request an allocation in this fund.
          </p>
        )}
      </Grid>
      <Grid container columns={11} columnSpacing={2} alignItems='center' sx={{ mt: 5, mb: 6 }}>
        <Grid item xs={3} container className='flex-col flex-center-center'>
          <img src={SVG1} alt='' className='mb-3' />
          <p>
            {!isFM
              ? 'Submit a request for allocation in the fund through Tifin Private Markets'
              : 'Investor submits a request for allocation in your fund through Tifin Private Markets'}
          </p>
        </Grid>
        <Grid item xs={1} className='flex-center-center'>
          <ArrowForward className='arrow' />
        </Grid>
        <Grid item xs={3} container className='flex-col flex-center-center'>
          <img src={SVG2} alt='' className='mb-3' />
          <p>
            {!isFM
              ? 'Fund Manager allocates an amount against your order'
              : 'You allocate an amount against the order'}
          </p>
        </Grid>
        <Grid item xs={1} className='flex-center-center'>
          <ArrowForward className='arrow' />
        </Grid>
        <Grid item xs={3} container className='flex-col flex-center-center'>
          <img src={SVG3} alt='' className='mb-3' />
          <p>
            {!isFM
              ? 'You are notified in Tifin Private Markets when allocation is received'
              : 'Investor is notified of their allocation'}
          </p>
        </Grid>
      </Grid>

      {!disableCTA && (
        <Grid container alignItems='center' justifyContent='flex-end'>
          <MuiButton variant='contained' buttonClick={buttonClick} minWidth='150px'>
            Start Allocation Request
          </MuiButton>
        </Grid>
      )}
    </StyledModal>
  );
};

export default AllocationDetailsModal;
