import MuiModal from 'common/components/modal';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import MuiTable from 'common/components/table/Table';
import {
  ENGINE_2_INFO_MODAL_COLUMNS,
  ENGINE_2_INFO_MODAL_ROWS,
} from 'components/recommendations/constants';
import MuiButton from 'common/components/button';

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 884px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 1.25rem !important;
    line-height: 26px;
  }

  h4,
  ul {
    line-height: 1.125rem;
    color: var(--s50);
    font-weight: 400;
    font-size: 13px;
    margin: 0;
  }

  .mt-5 {
    margin-top: 12px;
  }

  .mb-5 {
    margin-bottom: 12px;
  }

  .mt-10 {
    margin-top: 24px;
  }

  a {
    font-weight: 600;
    color: var(--p300);
    text-decoration: none;
  }

  .MuiTableContainer-root {
    margin: 0.5rem 0;

    .MuiTableRow-root td:first-of-type {
      font-weight: 600;
    }

    .MuiTableRow-head th:first-of-type {
      background-color: white;
    }

    tr {
      background-color: var(--s10);
    }

    th {
      font-size: 13px;
      font-weight: 600;
      text-transform: none;
    }

    td {
      background-color: white;
      border-color: var(--s15);
      background-clip: padding-box;

      &:first-of-type {
        padding: 1rem 30px;
        background-color: unset;
      }
    }
  }

  h5 {
    margin: 1.25rem 0 0;
    font-size: 11px;
    font-weight: 600;
    color: var(--s40);
  }
`;

type Props = {
  isModalOpen: boolean;
  handleClose: any;
};

const StylesInfoModal = ({ isModalOpen, handleClose }: Props) => {
  return (
    <StyledModal
      title='How We Calculate Exposure to Styles'
      isModalOpen={isModalOpen}
      handleClose={() => {
        handleClose();
      }}
      maxWidth='lg'
      className='new-alloc'
    >
      <Grid container>
        <Grid item xs={12} mb={'52px'}>
          <h4>1. We use four broad indexes as stand ins for styles</h4>
          <ul>
            <li>
              Diversification:{' '}
              <a
                href='https://www.spglobal.com/spdji/en/indices/multi-asset/sp-500-dynamic-veqtor-index/#overview'
                target={'_blank'}
                rel='noopener noreferrer'
              >
                S&P 500 Dynamic VEQTOR Index
              </a>
            </li>
            <li>
              Income: Bloomberg U.S. Aggregate Index or PIMIX,{' '}
              <a
                href='https://www.pimco.com/en-us/investments/mutual-funds/income-fund/inst'
                target={'_blank'}
                rel='noopener noreferrer'
              >
                PIMCO Income Fund Institutional Class
              </a>
            </li>
            <li>
              Growth:{' '}
              <a
                href={
                  'https://research.ftserussell.com/Analytics/FactSheets/temp/3e8525ee-4302-4406-968d-0f4ab4e8cf0a.pdf'
                }
                target={'_blank'}
                rel='noopener noreferrer'
              >
                Russell 1000 Growth Index
              </a>
            </li>
            <li>
              ESG:{' '}
              <a
                href='https://www.spglobal.com/spdji/en/indices/esg/sp-500-esg-index/#overview'
                target={'_blank'}
                rel='noopener noreferrer'
              >
                S&P 500 ESG Index
              </a>{' '}
              or{' '}
              <a
                href='https://research.ftserussell.com/Analytics/Factsheets/Home/DownloadSingleIssue?issueName=FGCUSAC&IsManual=false'
                target={'_blank'}
                rel='noopener noreferrer'
              >
                FTSE USA All Cap Choice Total Return Index
              </a>
            </li>
          </ul>

          <h4 className='mt-5 mb-5'>
            2. Next, we regress monthly returns of the Theme indexes on the S&P 500 to find the
            “pure” returns of the Themes
          </h4>
          <MuiTable columns={ENGINE_2_INFO_MODAL_COLUMNS} rows={ENGINE_2_INFO_MODAL_ROWS} />
          <h4 className='mt-10 mb-5'>
            3. We calculate client traditional portfolio's beta to each Theme, using historical
            monthly returns (return lookback = as long as possible) and multiple linear regression
          </h4>
          <h4 className='mt-5 mb-5'>
            4. We remove negative betas and non-significant (at the 5% level) betas
          </h4>
          <h4 className='mt-5 mb-5'>
            5. Finally, we determine allocation to each theme. Most funds have a minimum investment
            requirement of $250K. If a Theme has a 10% weight within the alternative sleeve and the
            alternative sleeve has a 20% weight, the final weight of the Theme would be 2% = 20% *
            10%. This would require a portfolio balance of $12.5M to meet the $250K minimum. If the
            lowest weighted Theme's dollar allocation does not meet the $250K minimum we will
            reallocate that Theme's weight to the other Themes
          </h4>
          <h5>Contact the Tifin Wealth team for further details on our methodology.</h5>
        </Grid>

        <Grid item xs={12} mt={4} className='flex-center-end'>
          <MuiButton variant='contained' minWidth='150px' buttonClick={handleClose}>
            OK
          </MuiButton>
        </Grid>
      </Grid>
    </StyledModal>
  );
};

export default StylesInfoModal;
