import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { ReactComponent as Bulb } from 'common/assets/images/Bulb2.svg';
import { useTypedDispatch } from 'store/hooks';
import { setEngineNumber, setRecommendationNumber } from 'store/recommendation/slice';

const StyledContainer = styled(Grid)`
  .container-style {
    padding: 20px 24px;
    background: #fafafa;
    border-radius: 8px;
  }

  .cta {
    width: 150px;
  }

  .hr-line {
    height: 80px;
    border: 0.5px solid #dddddd;
  }

  p {
    color: var(--s40);
  }

  svg {
    position: relative;
    top: -3px;
    margin-right: 0.5rem;
  }
`;

const Recommendation = ({ isEngine1Loading, triggerEngine1 }: any) => {
  const dispatch = useTypedDispatch();

  const handleRec1Click = async () => {
    await triggerEngine1();
  };

  const handleRec2Click = () => {
    dispatch(setRecommendationNumber({ recommendationNumber: 0 }));
    dispatch(setEngineNumber({ engineNumber: 2 }));
    window.scrollTo(0, 0);
  };

  return (
    <StyledContainer>
      <Grid item xs={12} container className='container-style' columnGap={10}>
        <Grid item xs={12} mb={2} className='flex-center-start'>
          <Bulb />
          <h3 className='heading mb-0'>See Other Recommendations</h3>
        </Grid>
        <Grid item xs={5} display='flex' flexDirection='column' justifyContent='space-between'>
          <p>
            1. Allocation to Private Markets - determine how much of the client's portfolio should
            be allocated to Private Markets
          </p>
          <MuiButton
            buttonClick={() => {
              handleRec1Click();
            }}
            className='cta'
            variant='outlined'
          >
            {isEngine1Loading ? 'Loading...' : 'View Details'}
          </MuiButton>
        </Grid>
        <div className='hr-line'></div>

        <Grid item xs={5} display='flex' flexDirection='column' justifyContent='space-between'>
          <p>
            2. Investment styles - align the client's investment in Private Markets with their
            overall portfolio
          </p>
          <MuiButton
            buttonClick={() => {
              handleRec2Click();
            }}
            className='cta'
            variant='outlined'
          >
            View Details
          </MuiButton>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Recommendation;
