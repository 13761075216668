export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '',
    label: 'Activity Logs',
  },
];

export const FUND_COLUMNS = [
  {
    id: 'fund_name',
    label: 'Fund Name',

    width: '30%',
  },
  {
    id: 'action_performed',
    label: 'Action Performed',

    width: '18%',
  },
  {
    id: 'action_performed_by',
    label: 'Action Performed By',

    width: '20%',
  },
  {
    id: 'behalf_of',
    label: 'Behalf Of',

    width: '12%',
  },
  {
    id: 'time_of_action',
    label: 'Time of Action',

    isDate: true,
    width: '25%',
  },
];

export const ORDER_COLUMNS = [
  {
    id: 'order_id',
    label: 'Order ID',

    width: '8%',
  },
  {
    id: 'dealer_group',
    label: 'Dealer Group',

    width: '15%',
  },
  {
    id: 'fund',
    label: 'Fund',

    width: '15%',
  },
  {
    id: 'investor',
    label: 'Investor Name',

    width: '12.5%',
  },
  {
    id: 'action_performed',
    label: 'Action Performed',

    width: '10%',
  },
  {
    id: 'action_performed_by',
    label: 'Action Performed By',

    width: '14.5%',
  },
  {
    id: 'behalf_of',
    label: 'Behalf Of',

    width: '12.5%',
  },
  {
    id: 'time_of_action',
    label: 'Time of Action',

    isDate: true,
    width: '12.5%',
  },
];

export const CLIENT_COLUMNS = [
  {
    id: 'investor_account_name',
    label: 'Account Name',
  },
  {
    id: 'dealer_group',
    label: 'Dealer Group',
  },
  {
    id: 'team',
    label: 'Team',
  },
  {
    id: 'action_performed',
    label: 'Action Performed',
  },
  {
    id: 'action_performed_by',
    label: 'Action Performed By',
  },
  {
    id: 'behalf_of',
    label: 'Behalf Of',
  },
  {
    id: 'timeFormatted',
    label: 'Time of Action',

    isDate: true,
  },
];

export const CLIENT_APPROVAL_COLUMNS = [
  {
    id: 'account_name',
    label: 'Account Name',

    width: '20%',
  },
  {
    id: 'account_number',
    label: 'Account Number',

    width: '20%',
  },
  {
    id: 'entity_type',
    label: 'Entity Type',

    width: '25%',
  },
  {
    id: 'status',
    label: 'Status',

    width: '15%',
  },
  {
    id: 'lastUpdatedFormatted',
    label: 'Last Updated',

    isDate: true,
    width: '20%',
  },
];
