import React, { useEffect, useState } from 'react';
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import SelectField from 'common/components/inputField/SelectField';
import { ReactComponent as Delete } from 'common/assets/images/DocDelete.svg';
import { ReactComponent as Edit } from 'common/assets/images/Pencil.svg';

interface Props {
  row: any;
  columns: Array<any>;
  selectOptions: Array<any>;
  selectedDocIdsObj: Array<any>;
  selectedDocIds: Array<string>;
  deleteRow: any;
  saveMapsTo: any;
  isDeleting: Boolean;
  setIsEditDetailsModal: any;
  setDocEditingId: Function;
  setModalOpen: Function;
}

const InvestmentMasterListTableRow = ({
  row,
  columns,
  deleteRow,
  selectOptions,
  saveMapsTo,
  setDocEditingId,
  setIsEditDetailsModal,
  setModalOpen,
  selectedDocIds,
  isDeleting,
  selectedDocIdsObj,
}: Props) => {
  const [selectedDoc, setSelectedDoc] = useState<any>({});
  const [selectedDeleteRow, setSelectedDeleteRow] = useState<any>(null);
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  useEffect(() => {
    if (selectedDocIds.length > 0 && selectedDocIdsObj.length > 0) {
      setSelectedDoc(selectOptions?.find((doc: any) => row.id === doc.prefillMapTo));
      const requiredRowIdIdx = selectedDocIdsObj.findIndex((el: any) => el.rowId === row.id);
      if (requiredRowIdIdx === -1) {
        const res = selectOptions.filter((item) => !selectedDocIds.includes(item.documentId));
        setFilteredOptions(res);
      } else {
        const currentDocId = selectedDocIdsObj[requiredRowIdIdx].docId;
        const filteredSelectedDocIds = selectedDocIds.filter((id: string) => id !== currentDocId);
        const res = selectOptions.filter(
          (item) => !filteredSelectedDocIds.includes(item.documentId),
        );
        setFilteredOptions(res);
      }
    } else {
      setFilteredOptions(selectOptions);
    }
    //eslint-disable-next-line
  }, [selectedDocIds, selectedDocIdsObj, selectOptions]);

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {React.Children.toArray(
        columns.map((col: any) => (
          <TableCell
            align={col.align}
            className={`
        ${col.cellClassName ? col.cellClassName : ''}
      `}
            sx={{ width: `${col.width ? col.width : 'initial'}` }}
          >
            {col.isSelect ? (
              <SelectField
                className={`selectField pt-0`}
                searchIcon={false}
                optionId='documentId'
                optionName='docName'
                disabled={!(selectOptions.length > 0)}
                options={[...filteredOptions, { documentId: null, docName: 'None' }]}
                placeholder={'Select Document'}
                setSelectedValue={(e: any) => {
                  setSelectedDoc(e);
                  saveMapsTo([
                    {
                      ...e,
                      prefillMapTo: row.id,
                    },
                  ]);
                }}
                value={selectedDoc?.documentId}
                defaultValue={selectedDoc?.documentId}
              />
            ) : (
              row[col.id]
            )}
          </TableCell>
        )),
      )}
      <TableCell align='right'>
        <div className='flex-center-end'>
          <Edit
            className={`penIcon mr-3 cursor-pointer`}
            onClick={() => {
              setModalOpen(true);
              setIsEditDetailsModal(true);
              setDocEditingId(row.id);
            }}
          />
          {isDeleting && row?.id === selectedDeleteRow ? (
            <CircularProgress className='loading' />
          ) : (
            <Delete
              className={`${row.fund_id ? 'cursor-pointer' : 'disabled'}`}
              onClick={() => {
                setSelectedDeleteRow(row.id);
                if (row.fund_id) {
                  deleteRow(
                    row?.id
                      ? {
                          id: row.id,
                          deleteFromServer: true,
                        }
                      : {
                          id: row.UID,
                          deleteFromServer: false,
                        },
                  );
                  if (selectedDoc?.documentId) {
                    setSelectedDoc({ documentId: null, docName: 'None' });
                    saveMapsTo([
                      {
                        ...{ documentId: null, docName: 'None' },
                        prefillMapTo: row.id,
                      },
                    ]);
                  }
                }
              }}
            />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default InvestmentMasterListTableRow;
