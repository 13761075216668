import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { COLUMNS, MONTHS } from './constants';
import { StyledModal } from 'components/modals/holdings/style';
import PreviewErrorTable from 'components/tables/Holdings/PreviewErrorTable';
import PreviewReturnsTable from 'components/tables/Investments/HistoricalReturns/PreviewReturnsTable';
import { setFundPriceMonthlyReturns } from 'store/funds/slice';
import { useTypedDispatch } from 'store/hooks';

type Props = {
  selectedFile: any;
  incorrectDataList: any;
  acceptedFileData: any;
  isModalOpen: boolean;
  handleModalClose: any;
};

const PreviewModal = ({
  incorrectDataList,
  acceptedFileData,
  isModalOpen,
  handleModalClose,
  selectedFile,
}: Props) => {
  const dispatch = useTypedDispatch();

  const handleUpdate = () => {
    const arr = acceptedFileData.map((data: any) => ({
      return_month: MONTHS[data.Month?.toLowerCase()],
      return_year: Number(data.Year),
      return_value: data.Return,
    }));
    dispatch(setFundPriceMonthlyReturns(arr));
    handleModalClose();
  };

  return (
    <>
      <StyledModal
        title='Preview Uploaded Data'
        isModalOpen={isModalOpen}
        handleClose={handleModalClose}
        maxWidth='md'
        disableCloseIcon
      >
        {incorrectDataList?.length > 0 && (
          <Grid container mb={4}>
            <Grid item xs={12} mb={'20px'}>
              <h4 className='warn font-wt-400 mb-0'>
                Error: Uploaded data has not been parsed successfully. Review following rows prior
                to upload to platform.
              </h4>
            </Grid>
            <Grid item xs={12}>
              <PreviewErrorTable
                tableClassName={`errorTable`}
                columns={COLUMNS}
                rows={incorrectDataList}
                rowsPerPage={4}
              />
            </Grid>
          </Grid>
        )}
        {acceptedFileData?.length > 0 && (
          <Grid container mb={4}>
            <Grid item xs={12} mb={'20px'}>
              <h4 className='font-wt-400 mb-0'>
                Uploaded data has been parsed successfully. Review data prior to upload to platform.
              </h4>
            </Grid>
            <PreviewReturnsTable
              rows={acceptedFileData.sort(
                (a: any, b: any) =>
                  Number(a.Year) - Number(b.Year) ||
                  Number(MONTHS[b.Month?.toLowerCase()]) - Number(MONTHS[a.Month?.toLowerCase()]),
              )}
              columns={COLUMNS}
            />
          </Grid>
        )}

        <Grid item xs={12} container justifyContent='flex-end'>
          <MuiButton
            // disabled={isUploading}
            buttonClick={() => handleModalClose()}
          >
            Discard Changes
          </MuiButton>
          <MuiButton
            variant='contained'
            minWidth='150px'
            className='ml-3'
            buttonClick={() => handleUpdate()}
            disabled={acceptedFileData?.length === 0}
          >
            Upload
          </MuiButton>
        </Grid>
      </StyledModal>
    </>
  );
};

export default PreviewModal;
