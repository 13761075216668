// Column Ordering
export const orderByReturnVal = (
  a: any,
  b: any,
  orderBy: { id: string; dir: boolean; index: number },
  colIsDate: boolean | undefined,
) => {
  /* Props: 
    a,b - rows which are being compared
    orderBy - object for ordering. We require only two props from it:
        dir - true = ascending, false = descending
        id - property of the rows to compare
    colIsDate - check if the property is a date or not
  */

  // Date comparison
  if (colIsDate) {
    if (orderBy.dir) {
      const c: any = new Date(a[orderBy.id]);
      const d: any = new Date(b[orderBy.id]);
      return c - d;
    } else {
      const c: any = new Date(a[orderBy.id]);
      const d: any = new Date(b[orderBy.id]);
      return d - c;
    }
  }
  // Comparison for types other than Date and Number
  else if (isNaN(a[orderBy.id]) && isNaN(b[orderBy.id])) {
    if (!a[orderBy.id]) {
      return 1;
    } else if (orderBy.dir) {
      return a[orderBy.id]?.localeCompare(b[orderBy.id]);
    } else {
      return a[orderBy.id]?.localeCompare(b[orderBy.id]) * -1;
    }
  }
  // Number comparison
  else {
    if (orderBy.dir) {
      return a[orderBy.id] - b[orderBy.id];
    } else {
      return b[orderBy.id] - a[orderBy.id];
    }
  }
};
