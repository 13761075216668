const reducers = {
  setPortfolioValue: (state: any, action: any) => {
    state.portfolioValue = action?.payload?.totalAmount;
  },
  setRecommendedAllocation: (state: any, action: any) => {
    state.recommendedAllocation = action?.payload?.recommendedAllocation;
  },
  setEngineNumber: (state: any, action: any) => {
    state.engineNumber = action?.payload?.engineNumber;
  },
  setRecommendationNumber: (state: any, action: any) => {
    state.recommendationNumber = action?.payload?.recommendationNumber;
  },
  setRecommendationActiveStep: (state: any, action: any) => {
    state.activeStep = action?.payload?.activeStep;
  },
  setRecommendationClient: (state: any, action: any) => {
    state.client = action?.payload?.client;
  },
  setIsLandingPage: (state: any, action: any) => {
    state.isLandingPage = action?.payload?.isLandingPage;
  },
  setClientAge: (state: any, action: any) => {
    state.clientAge = action?.payload?.clientAge;
  },
  setMaxAllocation: (state: any, action: any) => {
    state.maxAlloc = action?.payload?.maxAlloc;
  },
  setEngine1Result: (state: any, action: any) => {
    state.engine1Result = action?.payload?.engine1Result;
  },
  setEngine2Result: (state: any, action: any) => {
    state.engine2Result = action?.payload?.engine2Result;
  },
  setEngine1DataForEngine2Result: (state: any, action: any) => {
    state.engine1DataForEngine2Result = action?.payload?.engine1DataForEngine2Result;
  },
  setIsInputDrivingRecButtonVisible: (state: any, action: any) => {
    state.isInputDrivingRecButtonVisible = action?.payload?.isInputDrivingRecButtonVisible;
  },
  setIsSelectedClientNonNaturalPerson: (state: any, action: any) => {
    state.isSelectedClientNonNaturalPerson = action?.payload?.isSelectedClientNonNaturalPerson;
  },
  setFundsToCompare: (state: any, action: any) => {
    state.fundsToCompare = action?.payload?.fundsToCompare;
  },
  setFundsBasedOn: (state: any, action: any) => {
    state.fundsBasedOn = action?.payload?.fundsBasedOn;
  },
};
export default reducers;
