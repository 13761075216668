import styled from '@emotion/styled';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

const StyledTimePicker = styled(TimePicker)`
  margin-top: 0.5rem;
  width: 100%;

  .rc-time-picker-input {
    font-family: Inter, sans-serif;
    font-size: 13px;
    height: unset;
    line-height: 1.5em;
    padding: 10px 18px;
    background-color: var(--s7);
    border: none;
    border-radius: 0.2rem;
    color: rgba(0, 0, 0, 0.87);
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: none;
    }
  }

  .rc-time-picker-clear {
    display: none;
  }
`;

interface Props {
  name: string;
  register: any;
  required?: boolean;
  format?: string;
  placeholder?: string;
  showSecond?: boolean;
  use12Hours?: boolean;
  minuteStep?: number;
  onChange?: any;
  value?: any;
}

const RCTimePicker = ({
  format = 'hh:mm A',
  placeholder = '--:-- AM/PM',
  showSecond = false,
  use12Hours = true,
  minuteStep = 10,
  register,
  name,
  required,
  onChange,
  value,
}: Props) => {
  return (
    <StyledTimePicker
      format={format}
      showSecond={showSecond}
      use12Hours={use12Hours}
      placeholder={placeholder}
      minuteStep={minuteStep}
      {...register(name, { required: required })}
      onChange={onChange}
      defaultValue={value ? moment(value) : null}
    />
  );
};

export default RCTimePicker;
