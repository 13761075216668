import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { SubmitHandler, useForm } from 'react-hook-form';
import { selectFundsTermsAndServices, selectFundID } from 'store/funds/selectors';
import { setFundsDetails, setFundStructAndStats, setFundTermsAndServices } from 'store/funds/slice';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ErrorMessage } from 'common/components/errorMessageBox';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingButton from 'common/components/button/LoadingButton';
import { MUTATE_FUND_TSP_QUERY } from './queries';
import { handleGraphqlMutation } from 'helpers';
import { useMutation, useQueryClient } from 'react-query';
import { keysToCamel } from './utils';
import ControlledTextBox from 'common/components/inputField/ControlledTextBox';
import { ToastMessage, ToastType, showToast } from 'store/toast/slice';

type Props = {
  handleSubmitBtnClick: any;
  handleBackBtnClick: any;
  editing?: boolean;
};

const InvestmentTSPFormGQL = ({ handleSubmitBtnClick, handleBackBtnClick, editing }: Props) => {
  const storeFundsTandSProviders = useAppSelector(selectFundsTermsAndServices);
  const newFundId = useAppSelector(selectFundID);
  const dispatch = useTypedDispatch();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split('/')[3];

  useEffect(() => {
    if (storeFundsTandSProviders) {
      reset(storeFundsTandSProviders);
    }
    // eslint-disable-next-line
  }, [storeFundsTandSProviders]);

  const { mutate: mutateFundThirdStep, isLoading: mutatingFundTSP } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
        query: data.query,
        variables: { ...data.variables },
      });
    },
    {
      onSuccess(response: any) {
        if (response?.status === 500 || response?.status === 503) {
          const toast: ToastMessage = {
            type: ToastType.ERROR,
            message: 'Something went wrong. Please try again.',
          };
          dispatch(showToast(toast));
          return;
        }
        queryClient.invalidateQueries(`getFundDetails-${id ?? newFundId}`, {
          refetchInactive: true,
        });
        queryClient.invalidateQueries(`fundDetailsGQl${id ?? newFundId}`, {
          refetchInactive: true,
        });
        handleSubmitBtnClick();
      },
      onError(error: any) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Something went wrong. Please try again.',
        };
        dispatch(showToast(toast));
        return;
      },
    },
  );

  const onSubmit: SubmitHandler<any> = async (data) => {
    const fundsTermsAndServices = {
      ...data,
      fundId: id ? id : newFundId,
    };
    const camelCaseFundsTSP = keysToCamel(fundsTermsAndServices);
    mutateFundThirdStep({
      query: MUTATE_FUND_TSP_QUERY,
      variables: camelCaseFundsTSP,
    });
    dispatch(setFundTermsAndServices(fundsTermsAndServices));
  };

  const handleGoBack = () => {
    handleSubmit(async (data) => {
      const fundsTermsAndServices = {
        ...data,
        fundId: id ? id : newFundId,
      };
      dispatch(setFundTermsAndServices(fundsTermsAndServices));
      handleBackBtnClick();
    })();
  };

  const handleDiscardChanges = () => {
    dispatch(setFundsDetails(null));
    dispatch(setFundStructAndStats(null));
    dispatch(setFundTermsAndServices(null));
    navigate(`/investment/${id ?? newFundId}`);
  };

  // Sticky ctaContainer handling
  const ctaRef = useRef(null);
  const [ctaSticky, setCtaSticky] = useState<boolean>(false);
  useLayoutEffect(() => {
    const el = ctaRef?.current;
    const observer = new IntersectionObserver(([e]) => setCtaSticky(!e.isIntersecting), {
      threshold: [1],
    });
    if (el) {
      observer.observe(el);
    }

    // Cleanup
    return () => {
      if (el) observer.unobserve(el);
    };
  }, [ctaRef]);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container maxWidth='xl' className='container-lr-padding investmentForm'>
        <Grid container justifyContent='space-between'>
          <Grid item xs={4}>
            <h2>Terms and Service Providers</h2>
            <p>Add corporate service providers here.</p>
          </Grid>
          <Grid item xs={7} container rowSpacing={4}>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Auditor</h4>
                <ControlledTextBox name='auditor' control={control} />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Administrator</h4>
                <ControlledTextBox control={control} fullWidth name='administrator' />
              </Grid>
            </Grid>

            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Fund Counsel</h4>
                <ControlledTextBox name='fund_counsel' control={control} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Prime Broker</h4>
                <ControlledTextBox name='prime_broker' control={control} fullWidth />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <h4 className='font-wt-400 label'>Bank Details</h4>
              <ControlledTextBox
                fullWidth
                multiline
                rows={4}
                control={control}
                name='bank_details'
                maxLength={5000}
              />
              {errors.bank_details && errors.bank_details.type === 'maxLength' && (
                <ErrorMessage error='Input limit exceeded. Character Limit is 5000' />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent='space-between'>
          <Grid item xs={4}>
            <h2>Disclaimer Details</h2>
            <p>Add fund disclaimer - this disclaimer is displayed the fund's detail page.</p>
          </Grid>
          <Grid item xs={7}>
            <ControlledTextBox
              name='disclaimer'
              fullWidth
              control={control}
              multiline
              rows={4}
              maxLength={5000}
            />
            {errors.disclaimer && errors.disclaimer.type === 'maxLength' && (
              <ErrorMessage error='Input limit exceeded. Character Limit is 5000' />
            )}
          </Grid>
        </Grid>
      </Container>

      {/* CTAs */}
      <div className={`ctaContainer ${ctaSticky ? 'ctaSticky' : ''}`} ref={ctaRef}>
        <Container maxWidth='xl' className='container-lr-padding flex-center-end'>
          <MuiButton variant='text' buttonClick={handleGoBack}>
            Back
          </MuiButton>
          <MuiButton
            variant='text'
            color='error'
            className='ml-3'
            buttonClick={handleDiscardChanges}
          >
            Exit Without Saving
          </MuiButton>
          {mutatingFundTSP ? (
            <LoadingButton minWidth='150px' className='ml-3' />
          ) : (
            <MuiButton minWidth='150px' variant='contained' type='submit' className='ml-3'>
              Save & Next
            </MuiButton>
          )}
        </Container>
      </div>
    </form>
  );
};

export default InvestmentTSPFormGQL;
