import getAxiosInstance from "common/utils/axios";

export const loginHandler = async (inputData: object) => {
    try {
        const axios = await getAxiosInstance();
        const { data } = await axios.post(`qaip/v1/usermanagement/authinit`, { ...inputData });
        return data;
    } catch (error) {
        return (error as any).response;
    }
};