import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

const Loading = ({ message }: any) => {
  return (
    <StyledWrapper className='loader-wrapper'>
      <CircularProgress color='inherit' />
      {message && <h4>{message}</h4>}
    </StyledWrapper>
  );
};

export default Loading;

const StyledWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);

  h4 {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--s30);
    margin-top: 2rem;
  }

  .loader-img {
    width: 100px;
    height: 100px;
  }
`;
