import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    confirmationCode: Yup.string()
        .required('Confirmation code is required')
        .min(6, 'Enter your 6 digit confirmation code'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .test("passwordRequirements", "Password must contain at least 8 characters, one upper case, one lowercase and one special character.", (value) =>
            [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every((pattern) =>
                pattern.test(value as string)
            )
        ),
    confPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match')
});