import { Tooltip } from '@mui/material';
import { Help } from '@mui/icons-material';

interface Props {
  content: string | React.ReactFragment;
}

const MuiTooltip = ({ content }: Props) => {
  return (
    <Tooltip
      title={<div className='tooltipContainer'>{content}</div>}
      placement='top'
      arrow
      classes={{ tooltip: 'p-0 tooltipParent' }}
    >
      <Help className='infoIcon' />
    </Tooltip>
  );
};

export default MuiTooltip;
