import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import AlertIcon from 'common/assets/images/Alert.svg';

type Props = {
  isModalOpen: boolean;
  handleClose?: any;
  buttonClick?: any;
  fileName: string;
};

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 426px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 0.5rem !important;
  }
  p {
    font-size: 0.875rem;
    color: var(--s30) !important;
    &.desc {
      color: var(--s40) !important;
    }
  }
`;

const UploadHoldingsError = ({ isModalOpen, handleClose, buttonClick, fileName }: Props) => {
  return (
    <StyledModal
      title='Invalid File!'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='xs'
      className='new-alloc'
      disableCloseIcon
    >
      <Grid container mb={4}>
        <p className='mb-0'>{fileName} was not uploaded.</p>
      </Grid>
      <Grid container alignItems='center' mb={4} flexWrap='nowrap'>
        <img src={AlertIcon} alt='' className='mr-3' />
        <p className='desc mb-0'>
          Uploaded file did not match expected format. Check file layout and upload again
        </p>
      </Grid>

      <Grid container alignItems='center' justifyContent='flex-end'>
        <MuiButton variant='text' buttonClick={handleClose}>
          Close
        </MuiButton>
        <MuiButton variant='contained' className='ml-3' buttonClick={buttonClick} minWidth='177px'>
          Retry Upload
        </MuiButton>
      </Grid>
    </StyledModal>
  );
};

export default UploadHoldingsError;
