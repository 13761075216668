import { PayloadAction } from '@reduxjs/toolkit';

const reducers = {
  setHasOrderBookNotification: (state: any, action: PayloadAction<boolean | null>) => {
    state.hasOrderBookNotification = action.payload;
  },
  setHasActivityNotification: (state: any, action: PayloadAction<boolean | null>) => {
    state.hasActivityNotification = action.payload;
  },
};
export default reducers;
