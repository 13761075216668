import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiCard from 'common/components/card';
import EventCalendar from 'common/components/icon/EventCalendar';
import format from 'date-fns/format';

const StyledWrapper = styled.div`
  .eventCard {
    min-height: unset;
    padding: 22px 24px;
    cursor: pointer;

    h3 {
      color: var(--p500);
      margin-bottom: 6px;
      font-weight: 500;
    }

    h5 {
      font-weight: 300;
      color: #4f4f4f;
      margin-bottom: 0;
    }

    p {
      min-height: 14ch;
      font-size: 13px;
      margin-bottom: 0;
    }

    &:hover {
      border-color: var(--p150);
    }
  }
`;

const EventCard = (props: any) => {
  return (
    <StyledWrapper>
      <MuiCard
        className='eventCard'
        onClick={() => {
          props.setSelectedEvent(props);
          props.setModalOpen(true);
        }}
      >
        <Grid container>
          <Grid item xs={12} container mb={2}>
            <Grid item xs={3} pr={2}>
              <EventCalendar date={props.starTime} />
            </Grid>
            <Grid item xs={9} flexGrow={1}>
              <h3>{props.name}</h3>
              <h5>
                {props?.starTime &&
                  format(new Date(props?.starTime.replace(/-/g, '/')), 'MMMM dd, yyyy, pp')}
                {props?.time_zone && ' ' + props?.time_zone}
              </h5>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <p>
              {props.description && props.description.length > 100
                ? props.description.substring(0, 320) + '...'
                : props.description}{' '}
              <span className='color-p500 font-wt-600'>View More</span>
            </p>
          </Grid>
        </Grid>
      </MuiCard>
    </StyledWrapper>
  );
};

export default EventCard;
