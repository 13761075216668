import styled from '@emotion/styled';
import { Container, Divider, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import MuiButton from 'common/components/button';
import InputDrivingRec from './cards/InputDrivingRec';
import Recommendation from './cards/RecommendationE2';
import ThemeCard from './cards/ThemeCard';
import Loading from 'common/components/Loading';
import { useState } from 'react';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import {
  clientAge,
  engine1DataForEngine2Result,
  engine2Result,
  isInputDrivingRecButtonVisible,
  isSelectedClientNonNaturalPerson,
  maxAlloc,
  portfolioValue,
  recEngineNumber,
  recommendationClient,
  recommendationNumber,
} from 'store/recommendation/selectors';
import ProjectedPerformance from 'common/components/charts/ProjectedPerformance';
import StylesInfoModal from 'components/modals/recommendations/StylesInfoModal';
import {
  setEngine1Result,
  setEngine2Result,
  setEngineNumber,
  setIsInputDrivingRecButtonVisible,
  setRecommendationActiveStep,
} from 'store/recommendation/slice';
import { createHoldingPayload, getThemesData } from 'components/modals/utils';
import { useMutation } from 'react-query';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';

const StyledContainer = styled(Container)`
  color: var(--s50);
  h2 {
    font-size: 22px;
    font-weight: 500;
  }
  h4 {
    font-weight: 500;
    font-size: 18px;
  }

  .allocation-value {
    color: var(--s50);
    font-size: 20px;
    font-weight: 600;
  }

  .value-styles {
    width: 100px;
  }

  .theme-value {
    font-size: 15px;
    font-weight: unset;
    color: #434343;
    display: flex;
    justify-content: space-between;
  }

  .value-box {
    display: flex;
    align-items: flex-start;
    padding: 8px 12px;
    background: #f8f8f8;
    border: 1px solid #dddddd;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 700;

    &.fixedWidth {
      width: 72px;
      padding: 4px 12px;
      font-size: 20px;
      font-weight: 600;
    }

    &.theme-value-box {
      top: unset !important;
      background: unset !important;
      border: unset !important;
      font-weight: 600;
      font-size: 20px;
      padding-right: 2.25rem;
    }
  }

  .recharts-responsive-container {
    border-left: none;
  }

  .box-shadow {
    background: #ffffff;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 28px 26px;
    position: relative;
  }

  .graph-container {
    aside {
      margin: 0 7% 10px 0;
      background: var(--s5);
      font-size: 13px;
      color: var(--s40);

      div {
        padding: 0.5rem;
      }

      hr {
        border-width: 1px;
        width: 72px;
        margin: 0 auto 6px 0;
        border-color: var(--s20);

        &.blue {
          border-color: var(--p300);
        }
      }
    }
  }

  .theme-data {
    display: flex;
    justify-content: space-between;

    &:first-of-type {
      margin-top: 42px;
    }
  }

  .bottom-border {
    border: 0.5px solid #dddddd;
  }

  .theme-emoji {
    font-size: 1.125rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .theme-emoji img {
    height: 18px !important;
    width: 18px !important;
  }

  .theme-name {
    font-size: 1.125rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: auto;
    margin-left: 12px;
  }

  .heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  .donut-label {
    font-size: 15px;
    font-weight: 500;
    color: var(--s40);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15ch;
    z-index: 20;
    transform: translate(-50%, -50%);
  }

  .donut-label + .recharts-responsive-container {
    position: absolute;
    top: 50%;
    transform: translateY(-52%);
  }

  .MuiToggleButtonGroup-root {
    width: 450px;
    border: 1px solid var(--p200);
    border-radius: 0.5rem;

    button {
      width: 50%;
      font-size: 1rem;
      padding: 2px 12px;
      border: none;
      color: var(--s40);
      text-transform: unset;
      background-color: transparent;
      margin-left: 0 !important;

      &.Mui-selected {
        font-weight: 600;
        color: white;
        background-color: var(--p200);
      }
    }
  }

  button.infoButton {
    padding: 0;
    font-size: 0.875rem;
  }

  .perctage-value {
    color: #2e7cc2;
  }

  .stylesBanner {
    background-color: var(--s7);
    border-radius: 0.5rem;
    padding: 1rem 1.25rem;
    h4 {
      font-weight: 500;
      color: var(--s40);
      margin-bottom: 0;
    }
  }
`;

type Props = {
  backClick: any;
  doneClick: any;
  selectedClient: any;
  clientList: any;
};

const Engine2 = ({ backClick, doneClick, selectedClient, clientList }: Props) => {
  const dispatch = useTypedDispatch();
  const portfolioVal = useAppSelector(portfolioValue);
  const [toggle, setToggle] = useState<string>('withoutAlts');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const storedClient = useAppSelector(recommendationClient);
  const engine2Data = useAppSelector(engine2Result);
  const age = useAppSelector(clientAge);
  const engineNo = useAppSelector(recEngineNumber);
  const recommendationNo = useAppSelector(recommendationNumber);
  const isApplyButtonVisible = useAppSelector(isInputDrivingRecButtonVisible);
  const [loading, setLoading] = useState<boolean>(false);
  const [isRec1, setIsRec1] = useState(true);
  const [isRec2, setIsRec2] = useState(true);
  const isNonNaturalPerson = useAppSelector(isSelectedClientNonNaturalPerson);
  const supportingData = useAppSelector(engine1DataForEngine2Result);
  const maxAllocation = useAppSelector(maxAlloc);

  const triggerLoader = () => {
    setTimeout(() => {
      setLoading(false);
      window.scrollTo(0, 0);
    }, 4000);
  };

  const getWealthGrowth = () => {
    let prevValueWithAlts = 0;
    let prevValueWithoutAlts = 0;

    const chartData = [...new Array(66 - age)].map((item: any, i: number) => {
      let valWithAlts = i === 0 ? +portfolioVal : +prevValueWithAlts;
      let valWithoutAlts = i === 0 ? +portfolioVal : +prevValueWithoutAlts;
      let altPortReturn = +supportingData?.engine_1_stats?.alt_port_ret;
      if (recommendationNo === 1)
        altPortReturn = +supportingData?.engine_1_stats?.recommendations?.Addl_alloc?.alt_port_ret;
      if (recommendationNo === 2)
        altPortReturn =
          +supportingData?.engine_1_stats?.recommendations?.Improve_liquidity?.alt_port_ret;
      const updatedValueWithAlts = i === 0 ? valWithAlts : valWithAlts * (1 + altPortReturn);
      const updatedValueWithoutAlts =
        i === 0
          ? valWithoutAlts
          : valWithoutAlts * (1 + +engine2Data?.engine_2_stats?.current_port_ret);
      prevValueWithoutAlts = updatedValueWithoutAlts;
      prevValueWithAlts = updatedValueWithAlts;
      return {
        ...item,
        age: i + +age,
        withoutAlts: updatedValueWithoutAlts.toFixed(2),
        withAlts: updatedValueWithAlts.toFixed(2),
      };
    });
    return chartData;
  };

  const getThemeDataWithAlts = () => {
    const defaultData = engine2Data?.theme_opt_results.total_exp_w_alts.map((el: any) => ({
      Ticker: el.theme,
      Weight: el.weight,
    }));
    if (engineNo === 2) {
      if (recommendationNo === 1) {
        const R1DATA =
          engine2Data?.engine_2_stats?.recommendations?.Increase_returns?.new_theme_port_wts;
        const newArray = R1DATA.map((el: any) => {
          const prevValue = engine2Data?.theme_opt_results.raw_wts.find(
            (item: any) => item.theme === el.theme,
          );

          return {
            Ticker: el.theme,
            Weight: (1 - recAllocation()) * prevValue.weight + el.final_wt * recAllocation(),
          };
        });
        return newArray;
      }
      if (recommendationNo === 2) {
        const R2DATA =
          engine2Data?.engine_2_stats?.recommendations?.Decrease_volatility?.new_theme_port_wts;
        const newArray = R2DATA.map((el: any) => {
          const prevValue = engine2Data?.theme_opt_results.raw_wts.find(
            (item: any) => item.theme === el.theme,
          );
          return {
            Ticker: el.theme,
            Weight: (1 - recAllocation()) * prevValue.weight + el.final_wt * recAllocation(),
          };
        });

        return newArray;
      } else return defaultData;
    } else return defaultData;
  };

  const getThemeData = () => {
    const defaultData = engine2Data?.theme_opt_results.raw_wts.map((el: any) => ({
      Ticker: el.theme,
      Weight: el.weight,
    }));
    return defaultData;
  };

  const { mutate: triggerEngine2, isLoading: isThemesLoading } = useMutation(
    () =>
      getThemesData({
        data: engine2Data?.uploadedFileData,
        age,
        portfolioVal,
        maxAllocation,
        isNonNaturalPerson,
      }),
    {
      onSuccess: async (resp: any) => {
        const engineData = {
          ...resp,
          uploadedFileData: createHoldingPayload(engine2Data?.uploadedFileData, portfolioVal),
        };
        await dispatch(
          setEngine2Result({
            engine2Result: engineData,
          }),
        );
        await dispatch(
          setEngine1Result({
            engine1Result: null,
          }),
        );
        await dispatch(setEngineNumber({ engineNumber: 2 }));
        await dispatch(setRecommendationActiveStep({ activeStep: 1 }));
        window.scrollTo(0, 0);
      },
      onError: () => {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Something went wrong. Please try again.',
        };
        dispatch(showToast(toast));
      },
    },
  );

  const recAllocation = () => {
    if (recommendationNo === 1)
      return +supportingData?.engine_1_stats?.recommendations?.Addl_alloc?.alt_alloc;
    else if (recommendationNo === 2)
      return +supportingData?.engine_1_stats?.recommendations?.Improve_liquidity?.alt_alloc;
    else return +supportingData?.engine_1_stats?.alt_alloc;
  };

  if (loading || isThemesLoading)
    return (
      <Loading
        message={`Making changes to ${
          selectedClient?.account_name || storedClient?.account_name
        } portfolio`}
      />
    );
  return (
    <StyledContainer maxWidth='lg' className='m-header'>
      <Grid item xs={12} mb={'35px'}>
        <h2 className='mb-0'>Private Market Investment Styles</h2>
      </Grid>
      <Grid container className='box-shadow' mb={5}>
        <Grid item xs={12}>
          <h4 className='flex-center-start mb-0'>
            Based on the client’s traditional portfolio, and an allocation to Private Market
            invesments of {(recAllocation() * 100).toFixed()}%, consider the following style weights
            for your Private Markets investments:
          </h4>
        </Grid>
        <ThemeCard
          data={engine2Data?.theme_opt_results?.portfolio_wts.map((el: any) => ({
            Ticker: el.theme,
            Weight: el.weight,
          }))}
          isInput
        />
      </Grid>
      <Grid item xs={12} container className='flex-center-between stylesBanner' mb={5}>
        <Grid item xs={9}>
          <h4>Get specific recommendations within each investment style for this client</h4>
        </Grid>
        <Grid item xs={3} className='flex-center-end'>
          <MuiButton
            variant='contained'
            buttonClick={() => {
              dispatch(setEngineNumber({ engineNumber: 3 }));
            }}
          >
            View Fund Recommendations
          </MuiButton>
        </Grid>
      </Grid>
      <h4 className='heading'>Wealth Growth</h4>
      <Grid item xs={12} className='box-shadow graph-container' mb={5}>
        <section className='flex-center-end'>
          <aside className='flex-center-between'>
            <div>
              <Divider className='blue' />
              Wealth Growth After Allocation to Private Markets
            </div>
            <div>
              <Divider />
              Wealth Growth Before Allocation to Private Markets
            </div>
          </aside>
        </section>
        <ProjectedPerformance data={getWealthGrowth()} />
      </Grid>
      <Grid mb={5}>
        <Recommendation
          handleClick={() => {
            setLoading(true);
            triggerLoader();
          }}
          setIsRec1={setIsRec1}
          setIsRec2={setIsRec2}
          isRec1={isRec1}
          isRec2={isRec2}
        />
      </Grid>
      <h3 className='heading'>
        Investment Styles in {selectedClient?.account_name || storedClient?.account_name}'s
        Traditional Portfolio
      </h3>
      <Grid className='box-shadow' mb={5}>
        <Grid item xs={12}>
          <ToggleButtonGroup
            color='primary'
            value={toggle}
            exclusive
            defaultValue={toggle}
            onChange={(e: any) => {
              setToggle(e.target.value);
            }}
          >
            <ToggleButton value='withoutAlts'>Without Private Markets</ToggleButton>
            <ToggleButton value='withAlts'>With Private Markets</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <ThemeCard
          data={toggle === 'withoutAlts' ? getThemeData() : getThemeDataWithAlts()}
          isInput={false}
          toggle={toggle}
        />
      </Grid>
      <Grid item xs={12} mb={5}>
        <Grid item xs={12} display='flex' justifyContent='space-between'>
          <h3 className='heading'>Inputs driving these recommendations</h3>
          {isApplyButtonVisible && (
            <MuiButton
              minWidth={'120px'}
              buttonClick={async () => {
                await triggerEngine2();
                dispatch(
                  setIsInputDrivingRecButtonVisible({ isInputDrivingRecButtonVisible: false }),
                );
              }}
              className='mb-2'
              variant='contained'
            >
              Apply Changes
            </MuiButton>
          )}
        </Grid>
        <InputDrivingRec clientList={clientList} selectedClient={selectedClient || storedClient} />
      </Grid>
      <Grid item xs={12}>
        <MuiButton className='infoButton' buttonClick={() => setIsModalOpen(true)}>
          How We Calculate Exposure to Styles
        </MuiButton>
      </Grid>
      <Grid item xs={12} className='flex-center-end' mt={'122px'}>
        <MuiButton buttonClick={backClick}>Back</MuiButton>
        <MuiButton buttonClick={doneClick} minWidth='150px' variant='contained' className='ml-3'>
          Done
        </MuiButton>
      </Grid>
      <StylesInfoModal isModalOpen={isModalOpen} handleClose={() => setIsModalOpen(false)} />
    </StyledContainer>
  );
};

export default Engine2;
