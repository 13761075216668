export const toCamel = (s: any) => {
   return s.replace(/([-_][a-z])/gi, ($1: any) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
};

export const isArray = function (a: any) {
    return Array.isArray(a);
};

export const isObject = function (o: any) {
    return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const keysToCamel = function (o: any) {
    if (isObject(o)) {
        const n: any = {};

        Object.keys(o).forEach((k: any) => {
            n[toCamel(k)] = keysToCamel(o[k]);
        });

        return n;
    } else if (isArray(o)) {
        return o.map((i: any) => {
            return keysToCamel(i);
        });
    }

    return o;
};