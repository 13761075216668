import styled from '@emotion/styled';
import { Container, Grid, Slider, Stack } from '@mui/material';
import MuiButton from 'common/components/button';
import SelectField from 'common/components/inputField/SelectField';
import Textbox from 'common/components/inputField/Textbox';
import { processExcel } from 'components/modals/holdings/utils';
import CustodianIntegrationModal from 'components/modals/recommendations/CustodianIntegrationModal';
import PortfolioModal from 'components/modals/recommendations/PortfolioModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useTypedDispatch, useRQMutation } from 'store/hooks';
import {
  clientAge as selectedClientAge,
  isSelectedClientNonNaturalPerson,
  recommendationClient,
} from 'store/recommendation/selectors';
import {
  setClientAge,
  setIsSelectedClientNonNaturalPerson,
  setPortfolioValue,
  setRecommendationClient,
} from 'store/recommendation/slice';
import { NonNaturalClientTypeList } from './constants';
import json from './demo.json';

const StyledContainer = styled(Container)`
  color: var(--s50);
  padding: 0 108px !important;

  h3 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }

  h4 {
    font-weight: 400;
    margin-bottom: 0.75rem;
  }

  h5 {
    font-weight: 500;
    color: var(--s30);
    margin-bottom: 0;
  }

  .MuiOutlinedInput-root {
    max-width: 400px;
  }
  .MuiTextField-root {
    width: 192px;
    flex-shrink: 0;
    margin-top: 0;
    input {
      height: 1.25em;
      font-size: 30px;
      font-weight: 300;
      text-align: center;
    }
  }

  .sliderWrapper {
    margin-left: 60px;
  }

  .label {
    font-size: 13px;
    flex-shrink: 0;
  }

  .MuiSlider-root {
    width: 320px;
    color: var(--p20);
    .MuiSlider-track,
    .MuiSlider-thumb {
      color: var(--p150);
    }
    .MuiSlider-thumb {
      width: 8px;
      border-radius: 0.25rem;
    }
    &.Mui-disabled > .MuiSlider-track,
    &.Mui-disabled > .MuiSlider-thumb {
      display: none;
    }
  }

  .loader-wrapper {
    z-index: 20;
  }
`;

type Props = {
  buttonClick: any;
  selectedClient: any;
  setSelectedClient: Function;
  clientList: any;
  nextClick: any;
};

const Step1 = ({
  buttonClick,
  selectedClient,
  setSelectedClient,
  clientList,
  nextClick,
}: Props) => {
  const dispatch = useTypedDispatch();
  const clientAge = useAppSelector(selectedClientAge);
  const client = useAppSelector(recommendationClient);
  const isNonNaturalPerson = useAppSelector(isSelectedClientNonNaturalPerson);
  const [custodianOpen, setCustodianOpen] = useState<boolean>(false);
  const [isPortfolioModalOpen, setIsPortfolioModalOpen] = useState<boolean>(false);
  const [portfolioData, setPortfolioData] = useState<any>(null);
  const navigate = useNavigate();

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    dispatch(setClientAge({ clientAge: newValue }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(event.target.value))) return;
    dispatch(
      setClientAge({ clientAge: event.target.value === '' ? '' : Number(event.target.value) }),
    );
  };

  const handleBlur = () => {
    if (Number(clientAge) < 0) {
      setClientAge(0);
    } else if (clientAge > 100) {
      setClientAge(100);
    }
  };

  const {
    mutate: checkTickerExists,
    data: tickersData,
    isLoading,
  } = useRQMutation(
    {
      url: `https://dev.magnifi.com/api/user/check-ticker-exist`,
      isTickerCheck: true,
    },
    {
      enabled: false,
      onSuccess: () => {
        setIsPortfolioModalOpen(true);
      },
    },
  );

  async function uploadFile(evt: any) {
    if (evt) {
      var r = new FileReader();
      r.readAsBinaryString(evt);
      r.onload = async (e: any) => {
        const json = await processExcel(e.target.result);
        const portVal = await json?.result?.Sheet1?.reduce(function (a: any, b: any) {
          return +a + +b.Amount;
        }, 0);
        await dispatch(setPortfolioValue({ totalAmount: portVal }));
        if (json) {
          setPortfolioData(json);
          const tickers = json.result.Sheet1.map((item: any) => item.Ticker);
          checkTickerExists({ tickers });
        } else {
          setIsPortfolioModalOpen(false);
        }
      };
    }
  }

  const findClientType = async (selectedClient: any) => {
    await dispatch(setRecommendationClient({ client: selectedClient }));
    const isNonNaturalPerson = await NonNaturalClientTypeList.includes(selectedClient.entity_type);
    if (isNonNaturalPerson) {
      await dispatch(
        setIsSelectedClientNonNaturalPerson({ isSelectedClientNonNaturalPerson: true }),
      );
      await dispatch(setClientAge({ clientAge: 35 }));
    } else {
      dispatch(setIsSelectedClientNonNaturalPerson({ isSelectedClientNonNaturalPerson: false }));
    }
  };
  return (
    <StyledContainer maxWidth='lg'>
      <Grid container rowSpacing={6.5}>
        <Grid item xs={12} mt={6.5} pt={0}>
          <h3>How much of my client's portfolio should I invest in Private Markets?</h3>
          <h5>Input client parameters to get started.</h5>
        </Grid>
        <Grid item xs={8} pt={0}>
          <h4>1. Select Client Name</h4>
          <SelectField
            options={
              clientList &&
              clientList.sort((a: any, b: any) => a.account_name.localeCompare(b.account_name))
            }
            optionId='investor_id'
            optionName='account_name'
            className='pt-0'
            searchIcon={false}
            setSelectedValue={(data: any) => {
              findClientType(data);
            }}
            defaultValue={client?.investor_id}
            value={client?.investor_id}
            placeholder='Select Client'
          />
        </Grid>
        {!isNonNaturalPerson && (
          <Grid item xs={8}>
            <h4>2. What is the client's age in years (e.g. 25)?</h4>
            <div className='flex-center-start'>
              <Textbox
                onChange={handleInputChange}
                value={clientAge}
                onBlur={handleBlur}
                placeholder='e.g. 25'
              />
              <Stack spacing={2} direction='row' alignItems='center' className='sliderWrapper'>
                <span className='label'>18 years</span>
                <Slider
                  value={typeof clientAge === 'number' ? clientAge : 0}
                  min={18}
                  max={80}
                  onChange={handleSliderChange}
                />
                <span className='label'>80 years</span>
              </Stack>
            </div>
          </Grid>
        )}
        <Grid item xs={8}>
          <Grid item xs={12} mb={6.5}>
            <h4>
              {isNonNaturalPerson ? '2.' : '3.'} Create, upload or import the Client's traditional
              securities portfolio
            </h4>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={4} sx={{ pb: '25px', borderRight: '1px solid var(--s15)' }}>
              <MuiButton
                disabled={isLoading}
                buttonClick={(e: any) => {
                  e.target.value = null;
                  e.target.files = null;
                }}
                component='label'
                variant='outlined'
                className='mb-3'
              >
                <input
                  onChange={(e: any) => uploadFile(e.target.files[0])}
                  disabled={isLoading}
                  type='file'
                  hidden
                  id='icon-button-file'
                />
                {isLoading ? 'Loading...' : 'Upload Portfolio'}
              </MuiButton>
              <br />
              <MuiButton
                disabled={!selectedClient || isLoading}
                buttonClick={() => {
                  dispatch(setPortfolioValue({ totalAmount: 10000000 }));
                  checkTickerExists({ tickers: json.map((el: any) => el.Ticker) });
                  setIsPortfolioModalOpen(true);
                }}
              >
                Use Demo Portfolio
              </MuiButton>
            </Grid>
            <Grid item xs={4} className='d-flex' alignItems='flex-start' justifyContent='flex-end'>
              <MuiButton
                variant='outlined'
                buttonClick={() => {
                  setCustodianOpen(true);
                }}
              >
                Connect to Custodian
              </MuiButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className='flex-center-end'>
          <MuiButton
            buttonClick={() => {
              navigate('/');
            }}
          >
            Exit Without Saving
          </MuiButton>
          {/* <MuiButton
            buttonClick={() => {
              buttonClick();
              handleSetRecAllocation();
            }}
            minWidth='150px'
            variant='contained'
            className='ml-3'
            disabled={!(selectedClient && clientAge && percent) || loading}
          >
            Next
          </MuiButton> */}
          <CustodianIntegrationModal
            isModalOpen={custodianOpen}
            handleClose={() => setCustodianOpen(false)}
          />
          {tickersData && (
            <PortfolioModal
              selectedClient={selectedClient}
              isModalOpen={isPortfolioModalOpen}
              handleClose={() => setIsPortfolioModalOpen(false)}
              data={portfolioData?.result.Sheet1 ?? json}
              tickersData={tickersData}
            />
          )}
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Step1;
