import { Container } from '@mui/material';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { selectFundsActiveStep } from 'store/funds/selectors';
import { setFundsActiveStep } from 'store/funds/slice';
import InvestmentDetialsFormGQL from './investmentForms/investmentDetailsFormGQL';
import InvestmentDocumentForm from './investmentForms/InvestmentDocumentForm';
import InvestmentChecklistForm from './investmentForms/investmentCheckList/InvestmentChecklistForm';
import { StyledWrapper } from './styles';
import BYOFstepper from 'common/components/stepper/BYOFstepper';
import InvestmentStructAndStatsFormGQL from './investmentForms/InvestmentStructAndStatsFormGQL';
import InvestmentTSPFormGQL from './investmentForms/InvestmentTSPFormGQL';
import InvestmentHistoricalReturnsGQL from './investmentForms/investmentHistoricalReturnsGQL';
import { ADD_EDIT_FUND_STEPS } from './constants';

const NewFundsV2 = () => {
  const dispatch = useTypedDispatch();
  const activeStep = useAppSelector(selectFundsActiveStep);

  const handleSubmitBtnClick = async () => {
    await dispatch(setFundsActiveStep(activeStep + 1));
    window.scrollTo(0, 0);
  };

  const handleBackBtnClick = async () => {
    await dispatch(setFundsActiveStep(activeStep - 1));
    window.scrollTo(0, 0);
  };

  return (
    <StyledWrapper>
      <Container maxWidth='xl' className='container-lr-padding container-t-margin'>
        <BYOFstepper steps={ADD_EDIT_FUND_STEPS} activeStep={activeStep} />
      </Container>
      {activeStep === 0 && (
        <InvestmentDetialsFormGQL
          handleSubmitBtnClick={handleSubmitBtnClick}
          editing={false}
          status='Inactive'
        />
      )}
      {activeStep === 1 && (
        <InvestmentStructAndStatsFormGQL
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
        />
      )}
      {activeStep === 2 && (
        <InvestmentTSPFormGQL
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={false}
        />
      )}
      {activeStep === 3 && (
        <InvestmentHistoricalReturnsGQL
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={false}
        />
      )}
      {activeStep === 4 && (
        <InvestmentDocumentForm
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={false}
        />
      )}
      {activeStep === 5 && (
        <InvestmentChecklistForm
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={false}
        />
      )}
    </StyledWrapper>
  );
};

export default NewFundsV2;
