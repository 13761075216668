import { useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import TableHeadSkeleton from '../skeleton/TableHeadSkeleton';
import TableBodySkeleton from '../skeleton/TableBodySkeleton';
import OrderStatusBar from '../orderStatusBar';
import { format } from 'date-fns';
import { statusList } from 'common/constants';
import { canModifyHoldings } from 'helpers/roles';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks';
import TableSubHeader from './TableSubHeader';
import { Pagination } from '@mui/material';
import ViewDocumentModal from '../modal/ViewDocumentModal';
import CTAgenerator from './CTAgenerator';
import { Help } from '@mui/icons-material';
import AllocationDetailsModal from 'components/modals/AllocationDetailsModal';
import CircleIcon from '@mui/icons-material/Circle';
import { MainStyle } from './styles';
import { TableColumn, TableProps } from './types';
import { orderByReturnVal } from './utils';

const MuiTable = ({
  rows = [],
  columns,
  rowClassName = '',
  isLoading = false,
  ctaType = '',
  tableClassName = '',
  redirectURLKey = '',
  redirectURLPrefix = '',
  canRedirect = false,
  isViewDocModalOpen = false,
  handleDocViewOnclickOpen,
  handleDocViewOnclickClose,
  docLink = { url: '' },
  isDocumentLinkLoading = false,
  handleDocReuploadClick,
  handleDocDeleteClick,
  rowsPerPage = 10,
  clientData,
  activityTable = false,
  handleCtaClick,
  clearNotification,
  notificationArray = [],
  dateFormat = 'MMM-dd-yyyy',
  onRowClick,
  appendText = '',
  overflowRef,
}: TableProps) => {
  const [allocModalOpen, setAllocModalOpen] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<{ id: string; dir: boolean; index: number }>({
    id: '',
    dir: true,
    index: -1,
  });
  const [index, setIndex] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [docTitle, setDocTitle] = useState<string>('');
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const navigate = useNavigate();
  const userCanDelete = user && canModifyHoldings(user);

  // Column Ordering
  const rowsBeforePagination = useMemo(() => {
    const arr = [...rows]?.sort((a: any, b: any) => {
      const val =
        orderBy.id !== '' ? orderByReturnVal(a, b, orderBy, columns[orderBy.index].isDate) : 0;
      return val;
    });
    return [...arr];
    //eslint-disable-next-line
  }, [rows, orderBy]);

  // Pagination
  const finalRows = useMemo(() => {
    return rowsBeforePagination.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [rowsBeforePagination, page, rowsPerPage]);

  return (
    <MainStyle className={tableClassName}>
      <div className='tableWrapper'>
        <div style={{ overflowX: 'auto' }} ref={overflowRef}>
          <Table>
            {isLoading ? (
              <TableHeadSkeleton length={columns?.length} />
            ) : (
              <TableSubHeader
                columns={columns}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                ctaType={ctaType}
              />
            )}
            <TableBody>
              {isLoading ? (
                <TableBodySkeleton length={columns?.length} />
              ) : (
                <>
                  {finalRows.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={columns?.length + (ctaType ? 1 : 0)}
                        className='nullTable'
                      >
                        No Data Available
                      </TableCell>
                    </TableRow>
                  ) : (
                    React.Children.toArray(
                      finalRows.map((row: any, i: number) => (
                        <TableRow
                          onClick={() => {
                            !!clearNotification && clearNotification([row[redirectURLKey]]);
                            canRedirect && navigate(`/${redirectURLPrefix}${row[redirectURLKey]}`);
                            onRowClick && onRowClick();
                          }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          className={rowClassName}
                        >
                          {React.Children.toArray(
                            columns.map((col: TableColumn) => (
                              <TableCell
                                align={col.align}
                                className={`
                            ${activityTable && col.id === 'eventAmount' && 'italic'}
                            ${col.cellClassName ? col.cellClassName : ''}
                            ${
                              activityTable &&
                              col.id === 'eventAmount' &&
                              row['eventName'] === 'Allocation Request'
                                ? 'alloc'
                                : ''
                            }
                            position-relative
                          `}
                                sx={{ width: `${col.width ? col.width : 'initial'}` }}
                              >
                                {col.showProgress && (
                                  <OrderStatusBar
                                    value={
                                      statusList.filter(
                                        (val: any) => val.statusString === row[col.id],
                                      )[0].width
                                    }
                                  />
                                )}
                                {row[col.id] ? (
                                  col.needsFormatter ? (
                                    `${!isNaN(Number(row[col.id])) ? '$' : ''} ${
                                      isNaN(Number(row[col.id]))
                                        ? '  --  '
                                        : Number(row[col.id])?.toLocaleString()
                                    }`
                                  ) : col.isDate ? (
                                    format(new Date(row[col.id]), dateFormat)
                                  ) : col.percent ? (
                                    row[col.id] + '%'
                                  ) : activityTable &&
                                    col.label === 'ID' &&
                                    notificationArray.includes(row['orderId'].toString()) ? (
                                    <div className='d-flex'>
                                      <CircleIcon
                                        className='notification-bubble row-bubble'
                                        fontSize='small'
                                      />{' '}
                                      {row[col.id]}
                                    </div>
                                  ) : (
                                    <span>
                                      {row[col.id]}{' '}
                                      {activityTable &&
                                        col.id === 'eventName' &&
                                        row[col.id] === 'Allocation Request' && (
                                          <Help
                                            className='allocTooltip'
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setAllocModalOpen(true);
                                            }}
                                          />
                                        )}
                                    </span>
                                  )
                                ) : (
                                  '--'
                                )}
                              </TableCell>
                            )),
                          )}
                          {ctaType !== '' && (
                            <CTAgenerator
                              ctaType={ctaType}
                              user={user}
                              clientData={clientData}
                              row={row}
                              finalRows={finalRows}
                              handleCtaClick={handleCtaClick}
                              setIndex={setIndex}
                              setDocTitle={setDocTitle}
                              handleDocViewOnclickOpen={handleDocViewOnclickOpen}
                              isDocumentLinkLoading={isDocumentLinkLoading}
                              index={index}
                              i={i}
                            />
                          )}
                        </TableRow>
                      )),
                    )
                  )}
                </>
              )}
              {appendText ? (
                <TableRow>
                  <TableCell>{appendText}</TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </div>
        {rowsPerPage && rowsBeforePagination.length > rowsPerPage && (
          <Pagination
            className='pagination'
            count={Math.ceil(rowsBeforePagination.length / rowsPerPage)}
            page={page + 1}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => setPage(value - 1)}
          />
        )}
      </div>
      <ViewDocumentModal
        isModalOpen={isViewDocModalOpen}
        title={docTitle}
        handleClose={handleDocViewOnclickClose}
        docLink={docLink?.url}
        handleDocReuploadClick={handleDocReuploadClick}
        handleDocDeleteClick={handleDocDeleteClick}
        deletable={user && userCanDelete}
      />
      <AllocationDetailsModal
        disableCTA
        isModalOpen={allocModalOpen}
        handleClose={() => setAllocModalOpen(!allocModalOpen)}
      />
    </MainStyle>
  );
};

export default MuiTable;
