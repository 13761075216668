import { Card } from '@mui/material';

interface Props {
  children: React.ReactNode;
  variant?: 'elevation' | 'outlined' | undefined;
  elevation?: number;
  className?: string;
  minHeight?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  sx?: any;
}

const MuiCard = ({
  children,
  variant = 'elevation',
  elevation = 0,
  className = '',
  minHeight = '50vh',
  onClick,
  sx,
}: Props) => {
  return (
    <Card
      variant={variant}
      sx={{
        px: 5,
        py: 4,
        minHeight: minHeight,
        borderRadius: '0.5rem',
        border: '1px solid #ddd',
        ...sx,
      }}
      elevation={elevation}
      className={className}
      onClick={onClick}
    >
      {children}
    </Card>
  );
};

export default MuiCard;
