import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import CircleIcon from '@mui/icons-material/Circle';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { TableBodyProps, TableColumn } from './types';
import TableBodySkeleton from '../skeleton/TableBodySkeleton';
import OrderStatusBar from '../orderStatusBar';
import { statusList } from 'common/constants';
import { TableNoData } from './TableNoData';
import CTAgenerator from './CTAgenerator';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks';

export const TableBodySection = ({
  isLoading,
  columns,
  finalRows,
  ctaType = '',
  rowClassName,
  activityTable,
  dateFormat = 'MMM-dd-yyyy',
  notificationArray = [],
  appendText = '',
  redirectURLKey = '',
  redirectURLPrefix = '',
  clearNotification,
  canRedirect,
  onRowClick,
  orderbooksTable,
}: TableBodyProps) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState<number>(0);
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  return (
    <>
      {isLoading ? (
        <TableBodySkeleton length={columns?.length} />
      ) : (
        <>
          {finalRows.length === 0 ? (
            <TableNoData columns={columns} ctaType={ctaType} />
          ) : (
            React.Children.toArray(
              finalRows.map((row: any, i: number) => (
                <TableRow
                  onClick={() => {
                    !!clearNotification && clearNotification([row[redirectURLKey]]);
                    canRedirect && navigate(`/${redirectURLPrefix}${row[redirectURLKey]}`);
                    onRowClick && onRowClick();
                  }}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className={rowClassName}
                >
                  {React.Children.toArray(
                    columns.map((col: TableColumn) => (
                      <TableCell
                        align={col.align}
                        className={`
                      ${activityTable && col.id === 'eventAmount' && 'italic'}
                      ${col.cellClassName ? col.cellClassName : ''}
                      ${
                        activityTable &&
                        col.id === 'eventAmount' &&
                        row['eventName'] === 'Allocation Request'
                          ? 'alloc'
                          : ''
                      }
                      position-relative
                    `}
                        sx={{ width: `${col.width ? col.width : 'initial'}` }}
                      >
                        {col.showProgress && (
                          <OrderStatusBar
                            value={
                              statusList.filter((val: any) => val.statusString === row[col.id])[0]
                                ?.width
                            }
                          />
                        )}
                        {row[col.id] ? (
                          col.needsFormatter ? (
                            `${!isNaN(Number(row[col.id])) ? '$' : ''} ${
                              isNaN(Number(row[col.id]))
                                ? '  --  '
                                : Number(row[col.id])?.toLocaleString()
                            }`
                          ) : col.isDate && row[col.id] ? (
                            format(new Date(Number(row[col.id])), dateFormat)
                          ) : col.percent ? (
                            row[col.id] + '%'
                          ) : activityTable &&
                            col.label === 'ID' &&
                            notificationArray.includes(row['orderId'].toString()) ? (
                            <div className='d-flex'>
                              <CircleIcon
                                className='notification-bubble row-bubble'
                                fontSize='small'
                              />{' '}
                              {row[col.id]}
                            </div>
                          ) : orderbooksTable && col.id === 'fundName' && row['notified'] ? (
                            <div className='d-flex'>
                              <CircleIcon
                                className='notification-bubble row-bubble'
                                fontSize='small'
                              />{' '}
                              {row[col.id]}
                            </div>
                          ) : (
                            <span>{row[col.id]} </span>
                          )
                        ) : (
                          '--'
                        )}
                      </TableCell>
                    )),
                  )}
                  {ctaType !== '' && (
                    <CTAgenerator
                      ctaType={ctaType}
                      user={user}
                      row={row}
                      finalRows={finalRows}
                      setIndex={setIndex}
                      index={index}
                      i={i}
                    />
                  )}
                </TableRow>
              )),
            )
          )}
        </>
      )}
      {appendText ? (
        <TableRow>
          <TableCell>{appendText}</TableCell>
        </TableRow>
      ) : null}
    </>
  );
};
