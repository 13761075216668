import { Container } from '@mui/material';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { selectFundsActiveStep } from 'store/funds/selectors';
import { setFundsActiveStep } from 'store/funds/slice';
import InvestmentDocumentForm from './investmentForms/InvestmentDocumentForm';
import InvestmentChecklistForm from './investmentForms/investmentCheckList/InvestmentChecklistForm';
import BYOFstepper from 'common/components/stepper/BYOFstepper';
import InvestmentDetialsFormGQL from './investmentForms/investmentDetailsFormGQL';
import { StyledWrapper } from './styles';
import InvestmentStructAndStatsFormGQL from './investmentForms/InvestmentStructAndStatsFormGQL';
import InvestmentTSPFormGQL from './investmentForms/InvestmentTSPFormGQL';
import InvestmentHistoricalReturnsGQL from './investmentForms/investmentHistoricalReturnsGQL';
import { ADD_EDIT_FUND_STEPS } from './constants';
import { Close } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

const EditFundsV2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const id = location.pathname.split('/')[3];
  const activeStep = useAppSelector(selectFundsActiveStep);

  const handleSubmitBtnClick = async () => {
    await dispatch(setFundsActiveStep(activeStep + 1));
    window.scrollTo(0, 0);
  };

  const handleBackBtnClick = async () => {
    await dispatch(setFundsActiveStep(activeStep - 1));
    window.scrollTo(0, 0);
  };

  return (
    <StyledWrapper>
      <Container
        maxWidth='xl'
        className='container-lr-padding container-t-margin position-relative'
      >
        <BYOFstepper steps={ADD_EDIT_FUND_STEPS} activeStep={activeStep} />
        <Close className='close_flow' onClick={() => navigate(`/investment/${id}`)} />
      </Container>
      {activeStep === 0 && (
        <InvestmentDetialsFormGQL handleSubmitBtnClick={handleSubmitBtnClick} editing={true} />
      )}
      {activeStep === 1 && (
        <InvestmentStructAndStatsFormGQL
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={true}
        />
      )}
      {activeStep === 2 && (
        <InvestmentTSPFormGQL
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={true}
        />
      )}
      {activeStep === 3 && (
        <InvestmentHistoricalReturnsGQL
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={true}
        />
      )}
      {activeStep === 4 && (
        <InvestmentDocumentForm
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={true}
        />
      )}
      {activeStep === 5 && (
        <InvestmentChecklistForm
          handleSubmitBtnClick={handleSubmitBtnClick}
          handleBackBtnClick={handleBackBtnClick}
          editing={true}
        />
      )}
    </StyledWrapper>
  );
};

export default EditFundsV2;
