import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHeadSkeleton from 'common/components/skeleton/TableHeadSkeleton';
import TableSubHeader from 'common/components/table/TableSubHeader';
import TableBodySkeleton from 'common/components/skeleton/TableBodySkeleton';
import InvestmentManagerRecordTableRow from './InvestmentManagerRecordTableRow';
import { TableProps } from 'common/components/table/types';
import { InputSelectStyle } from 'common/components/table/styles';
import MuiButton from 'common/components/button';

interface Props extends TableProps {
  setUpdateRow: (row: any, index: number) => void;
  setDeleteRow: Function;
  appendToList?: (e: React.MouseEvent<HTMLElement>) => void;
}

const InvestmentManagerRecordTable = ({
  rows = [],
  columns,
  isLoading = false,
  tableClassName = '',
  setUpdateRow,
  setDeleteRow,
  appendToList,
}: Props) => {
  return (
    <InputSelectStyle className={tableClassName}>
      <div className='tableWrapper'>
        <Table>
          {isLoading ? (
            <TableHeadSkeleton length={columns?.length} />
          ) : (
            <TableSubHeader setOrderBy={null} columns={columns} ctaType={'test'} />
          )}
          <TableBody>
            {isLoading ? (
              <TableBodySkeleton length={columns?.length} />
            ) : (
              <>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns?.length + 1} className='nullTable'>
                      No Data Available
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row: any, i: number) => (
                    <InvestmentManagerRecordTableRow
                      key={i}
                      i={i}
                      row={row}
                      noOfRows={rows.length}
                      updateRow={(row: any, index: number) => setUpdateRow(row, index)}
                      deleteRow={setDeleteRow}
                    />
                  ))
                )}
              </>
            )}
            <TableRow>
              <TableCell>
                <MuiButton buttonClick={appendToList}>Add Row</MuiButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </InputSelectStyle>
  );
};

export default InvestmentManagerRecordTable;
