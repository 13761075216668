import { PayloadAction } from '@reduxjs/toolkit';
import { Investment } from './types';

const reducers = {
  setIsListView: (state: Investment, action: PayloadAction<boolean>) => {
    state.isListView = action.payload;
  },
  setActiveSort: (state: Investment, action: PayloadAction<number>) => {
    state.activeSort = action.payload;
    state.page = 0;
  },
  setPage: (state: Investment, action: PayloadAction<number>) => {
    state.page = action.payload;
  },
  setSearchQuery: (state: Investment, action: PayloadAction<string>) => {
    state.searchQuery = action.payload;
    state.page = 0;
  },
  setDefaultFilterList: (state: Investment, action: PayloadAction<any>) => {
    state.defaultFilters = action.payload;
  },
  setCurrentFilters: (state: Investment, action: PayloadAction<any>) => {
    state.currentFilters = action.payload;
  },
};
export default reducers;