import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import MuiButton from '../button';
import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 0.5rem;
    &.dark {
      background-color: #f4f4f4;
    }
  }
  &.terms-of-use {
    h2 {
      padding: 28px 28px;
    }
    .MuiDialogContent-root {
      padding: 0 28px 32px;
    }
  }
  &.new-alloc {
    h2 {
      padding: 28px 2rem 0.75rem;
    }
    .MuiDialogContent-root {
      padding: 0 28px 32px;
    }
  }

  svg.closeIcon {
    color: #949aa6;
    cursor: pointer;
  }
`;

type Props = {
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
  isModalOpen: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  docView?: {
    handleDocReuploadClick?: () => void;
    handleDocDeleteClick: () => void;
    handleDocDownload?: () => void;
  };
  className?: string;
  downloadable?: boolean;
  deletable?: boolean;
  disableCloseIcon?: boolean;
  disableHeader?: boolean;
  zIndex?: number;
};

export default function MuiModal({
  handleClose,
  children,
  isModalOpen,
  title,
  maxWidth = 'xs',
  docView,
  className = '',
  downloadable = false,
  deletable = true,
  disableCloseIcon = false,
  disableHeader = false,
  zIndex = 1300,
}: Props) {
  return (
    <StyledDialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={isModalOpen}
      onClose={handleClose}
      className={className}
      sx={{
        zIndex: zIndex,
      }}
    >
      {!disableHeader && (
        <DialogTitle className='flex-center-between'>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>{title}</Grid>
            {!disableCloseIcon && (
              <Grid item className='flex-center-between'>
                <Close onClick={handleClose} className='closeIcon' />
              </Grid>
            )}
          </Grid>
        </DialogTitle>
      )}
      <DialogContent sx={{ pb: 5 }}>
        {children}
        {docView && (
          <Grid container mt={2} justifyContent='flex-end'>
            <Grid item>
              {deletable && (
                <MuiButton color='error' buttonClick={docView?.handleDocDeleteClick}>
                  DELETE
                </MuiButton>
              )}
              {downloadable && (
                <MuiButton
                  color='primary'
                  className='ml-3'
                  buttonClick={docView?.handleDocDownload}
                >
                  DOWNLOAD
                </MuiButton>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </StyledDialog>
  );
}
