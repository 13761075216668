import { gaCustomEventTrack, gaFileDownloadTrack, initGA } from "./google-analytics";

export const initializeAnalytics = () => {
    initGA();
}

export const trackPdfDownload = (property: any) => {
    gaFileDownloadTrack(property);
}

export const customEventTrack = (name: string, property: any) => {
    gaCustomEventTrack(name, property);
}