import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';

export const initialState: any = {
  hasOrderBookNotification: false,
  hasActivityNotification: false,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: reducers,
});

export const {
  setHasOrderBookNotification,
  setHasActivityNotification,
} = slice.actions;

export default slice.reducer;
