import { Grid } from '@mui/material';
import MuiCheckbox from 'common/components/inputField/Checkbox';
import { useEffect, useState } from 'react';

export const PdfDownloadCheckboxOptions = ({
  doc,
  i,
  downloadDetails,
  setDownloadDetails,
  selectAll,
  prefilledDocs,
  setSelectAllState,
}: any) => {
  const [checked, setChecked] = useState(true);

  const handleCheckboxClick = (id: any) => {
    let filteredData;
    const isValid = downloadDetails.some((detail: any) => detail.documentId === id);
    if (isValid) {
      filteredData = downloadDetails.filter((detail: any) => detail.documentId !== id);
    } else {
      filteredData = [...downloadDetails, { ...doc }];
    }
    setDownloadDetails(filteredData);
  };

  useEffect(() => {
    if (!selectAll && downloadDetails.length === prefilledDocs.length) {
      setChecked(true);
    } else if (downloadDetails.length === prefilledDocs.length) {
      setSelectAllState(false);
    } else {
      if (downloadDetails.length === 0) setChecked(false);
      setSelectAllState(true);
    }
    // eslint-disable-next-line
  }, [downloadDetails]);

  return (
    <Grid item xs={12} className='flex-center-start' mb={2.5} key={`docGrid${i}`}>
      <MuiCheckbox
        defaultValue={checked}
        name={`doc${i}`}
        disableRipple
        onChange={() => {
          setChecked(!checked);
          handleCheckboxClick(doc.documentId);
        }}
        className='flex-center-start'
      />
      <h5>
        {doc?.docClassificationName}
        <span className='prefill'>{doc?.prefill && '*'}</span>
      </h5>
    </Grid>
  );
};
