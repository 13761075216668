import styled from '@emotion/styled';
import { Container } from '@mui/material';
import { useState } from 'react';
import MuiButton from '../button';
import MuiModal from '../modal';

const StyledFooter = styled.footer`
  border-top: 1px solid #b6bacd;
  font-size: 13px;
  padding: 20px;
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: space-between;

  h5 {
    margin: unset;
    color: #adadad;
  }
`;

const StyledModal = styled(MuiModal)`
  li {
    font-size: 13px;
    margin-bottom: 1em;
  }

  p,
  li {
    line-height: 1.45em;
  }
`;

const Footer = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const modalToggle = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <Container maxWidth='xl' className='container-lr-padding'>
      <StyledFooter>
        <MuiButton buttonClick={() => setModalOpen(true)} className='p-0 font-wt-400'>
          Disclaimers
        </MuiButton>
        <h5>© Tifin Private Markets 2022</h5>
      </StyledFooter>
      <StyledModal
        title='Private Fund Risk And Other Disclosures'
        maxWidth='md'
        isModalOpen={modalOpen}
        handleClose={modalToggle}
      >
        <p>
          Privately offered investment vehicles are unregistered private investment funds or pools
          (“Private Funds”) that may invest and trade in and employ many different markets,
          strategies, hedging, leverage, arbitrage and instruments (including securities, underlying
          companies, real estate, futures, non-securities and derivatives). Private Funds are not
          subject to the same regulatory requirements as mutual funds or UCITS, including to provide
          certain periodic and standardized pricing and valuation information to investors. There
          are substantial risks in investing in Private Funds. Prospective investors should note
          carefully the following:
        </p>

        <ul>
          <li>
            Private Funds represent speculative investments and involve a high degree of risk. An
            investor could lose all or a substantial portion of their investment. Investors must
            have the financial ability, sophistication, experience and willingness to bear the risks
            of an investment in a Private Fund.
          </li>

          <li>
            An investment in a Private Fund is not suitable or desirable for all investors. Only
            qualified eligible investors may invest in Private Funds.
          </li>

          <li>
            An investment in a Private Fund should be made with discretionary capital set aside
            strictly for speculative purposes.
          </li>

          <li>
            Private Fund offering documents are not reviewed or approved by federal or state
            regulators and the offering of Private Fund interests will not be federally or state
            registered.
          </li>

          <li>
            A Private Fund may have little or no operating history or performance and may use
            hypothetical or pro forma performance which may not reflect actual trading done by the
            manager or advisor and should be reviewed carefully. Investors should not place undue
            reliance on hypothetical or pro forma performance. A Private Fund’s actual performance
            may differ substantially and may be volatile.
          </li>

          <li>
            A Private Fund’s fees (including advisory fees, performance-based compensation and other
            fees such as structuring or asset acquisition fees) and expenses, which may be
            substantial regardless of any positive return, will offset the Private Fund’s investment
            profits. If a Private Fund’s investments are not successful, these payments and expenses
            may, over a period of time, deplete the net asset value of the Private Fund.
          </li>

          <li>
            The net asset value of a Private Fund may be determined by its administrator and/or its
            manager. Certain portfolio assets may be illiquid and without a readily ascertainable
            market value. The value assigned to such securities may differ from the value a Private
            Fund is able to realize. Instances of mispriced portfolios, due to fraud or negligence,
            have occurred in the industry.
          </li>

          <li>
            A Private Fund may be leveraged (including highly leveraged), may engage in other
            speculative investment practices and may have volatile performance. Such practices
            generally increase risk and the risk of loss.
          </li>

          <li>
            An investment in a Private Fund is generally illiquid and there may be significant
            restrictions on transferring interests in a Private Fund. There is no established
            secondary market for an investor’s investment in a Private Fund and none is expected to
            develop.
          </li>

          <li>
            A Private Fund’s manager or advisor has total trading authority over the Private Fund.
            The death or disability of the manager or advisor, or their departure, may have a
            material adverse effect on the Private Fund.
          </li>

          <li>
            Some Private Funds which make private equity type investments generally have certain
            additional or different risks, which include, among other things, no or very limited
            redemption rights, illiquid underlying portfolios, valuation difficulties, asset,
            credit, market or industry concentration, portfolio company or property risks,
            operational and control risks, particular industry risks; and financing or funding
            risks.
          </li>

          <li>
            Private Funds which make private equity type investments can provide no assurances that
            the portfolio companies that it invests in will be profitable and there is a substantial
            risk that the Private Fund’s losses and expenses will exceed the Private Fund’s income
            and gains.
          </li>

          <li>
            Some Private Funds which make private equity real estate related investments have
            certain additional or different risks, generally, which include, among other things,
            illiquid and long-term investment risk, concentrated investment risk, property
            acquisition and development risk, general credit risks, including that investment assets
            may be pledged to secure repayment of indebtedness, fluctuation in real estate value
            risk, environmental risk and natural disaster causing total loss risk.
          </li>

          <li>
            A Private Fund may execute a substantial portion of trades on foreign exchanges, which
            could mean higher risk. Material economic conditions and/or events involving those
            exchanges and markets may affect future results.
          </li>

          <li>
            A Private Fund may trade futures and commodity interests. The risk of loss in trading
            futures and commodity interests is substantial.
          </li>

          <li>
            A Private Fund may enter into swaps, futures, forwards, option and other derivative
            transactions for various hedging and/or speculative purposes that can result in volatile
            performance. The use of derivative instruments involves a variety of material risks,
            including the high degree of leverage often embedded in such instruments and the
            possibility of counterparty non-performance as well as of material and prolonged
            deviations between the actual and the theoretical value of a derivative instrument.
          </li>

          <li>
            Some Private Funds may provide little or no transparency regarding their underlying
            investments to investors.
          </li>

          <li>
            Some Private Funds which make private equity type investments and intend periodic
            distributions can provide no assurances that the Private Fund will be able to generate
            operating cash flow sufficient to make distributions to investors. Thus, there is no
            guarantee that such Private Fund will pay any particular amount of distributions, if at
            all, or pay such distributions consistently. Furthermore, in the event that the Private
            Fund does intend to make a distribution, such distribution may not be made, in whole or
            in part, from net operating income and may come from other sources, including, but not
            limited to, borrowings, other investors invested capital, general working capital and
            other alternative sources.
          </li>

          <li>
            Some Private Funds which make private equity type investments may be required to make
            additional capital contributions to portfolio company investments. Such additional
            contributions may be necessary to protect the Private Fund’s interest in portfolio
            company investments that require additional financing to carry out their business plans.
            There is no assurance that the Private Fund will make such additional contributions or
            that the Private Fund will have the ability to do so. The failure to make such
            additional contributions may jeopardize the continued operations of the underlying
            portfolio business, may impact the Private Fund’s ability to realize a meaningful return
            and may impact the recovery of the Private Fund’s contribution.
          </li>

          <li>
            Private Funds which make private equity type investments may need to finance the
            acquisition of portfolio company investments. The expenses of such financing may be
            substantial. Such financing may not be available on acceptable terms, if at all.
          </li>

          <li>
            Private Funds and their managers/general partners/advisors may be subject to various
            conflicts of interest, including with respect to decisions which may affect their
            compensation and the net asset value of the Private Fund.
          </li>

          <li>
            A Private Fund may use a single trading advisor or employ a single strategy, which could
            mean a lack of diversification and higher risk.
          </li>

          <li>
            A Private Fund (for example, a fund of funds or multi-manager fund) and its managers or
            advisors may rely on the trading expertise and experience of third-party managers or
            trading advisors, the identity of which may not be disclosed to investors.
          </li>

          <li>
            A Private Fund may involve a complex tax structure, which should be reviewed carefully,
            and may involve structures or strategies that may cause delays in important tax
            information being sent to investors.
          </li>

          <li>
            A Private Fund may employ investment strategies or techniques aimed to reduce the risk
            of loss which may not be successful.
          </li>

          <li>
            Private Funds are not required to provide periodic pricing or valuation information to
            investors.
          </li>

          <li>
            A Private Fund may be domiciled in a country whose laws may not permit ready or easy
            enforcement of investor rights or interests or which has lesser investor protections.
          </li>

          <li>
            Legal, tax and regulatory changes could occur during the term of a Private Fund that may
            adversely affect the fund, its manager or advisor or its service providers.
          </li>
        </ul>

        <p className='font-wt-500'>
          The above summary is not a complete list of the risks and other important disclosures
          involved in investing in Private Funds and is subject to the more complete disclosures
          contained in a specific Private Fund’s confidential offering documents, which must be
          reviewed carefully prior to making any decision to invest.
        </p>

        <p className='font-wt-500'>
          The values attributed to the securities or other investments shown are provided for your
          information only and are not intended for any other purpose, including, financial
          disclosure, marketing, reporting (regulatory or otherwise) or the determination of net
          asset value. Values displayed may not represent current market prices or actual prices
          that the securities or other investments may be bought or sold, nor may they include all
          relevant costs (i.e., fees, charges, duties, commissions, and taxes).
        </p>

        <p className='font-wt-500'>
          This data does not constitute a periodic statement, or any other form of official
          statement related to your account. The managers books and records are the definitive
          records of any holdings.
        </p>

        <p className='font-wt-500'>
          The information has been prepared in good faith for you from sources believed to be
          reliable. It is not your monthly account statement.
        </p>
      </StyledModal>
    </Container>
  );
};

export default Footer;
