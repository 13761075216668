import { Box, Grid, styled } from '@mui/material';
import ChatBotBulb from 'common/assets/images/ChatBotBulb.svg';
import Arrow from 'common/assets/images/InsightArrow.svg';

interface Props {
  setDisplayState: () => void;
  setJourneyNo: any;
}

const StyledWrapper = styled(Box)`
  margin: 0 1.5rem 0.75rem;
  padding: 1rem 1.25rem;
  border-radius: 1rem;
  background-color: var(--p15);

  .insightBulb {
    margin-right: 0.5rem;
  }

  .receiverBubble,
  .senderBubble {
    display: flex;
    align-items: center;
    padding: 1rem 1.25rem;
    background-color: white;
    max-width: 80%;
  }

  .receiverBubble {
    align-self: flex-start;
    margin-bottom: 0.75rem;
    border-radius: 0 1rem 1rem 1rem;
  }

  .senderBubble {
    cursor: pointer;
    align-self: flex-end;
    border: 1px solid var(--p150);
    border-radius: 1rem 0 1rem 1rem;
    span {
      color: var(--p150);
      margin-left: 0.25em;
    }
    img {
      margin-left: 0.5rem;
    }
  }
`;

const LastLoggedIn = ({ setDisplayState, setJourneyNo }: Props) => {
  return (
    <StyledWrapper>
      <Grid container>
        <Grid item xs={12} container alignItems='center' mb={2.5}>
          <img src={ChatBotBulb} alt='' className='insightBulb' />
          <h3 className='font-wt-400 mb-0'>Since you last logged in</h3>
        </Grid>
        <Grid item xs={12} className='d-flex flex-col'>
          <div className='receiverBubble'>
            Did you know the S&P 500 was down 14% in the first four months of the year?
          </div>
          <div
            className='senderBubble'
            onClick={() => {
              setDisplayState();
              setJourneyNo(0);
            }}
          >
            <div>
              I can help you
              <span>protect clients against further downside risks.</span>
            </div>
            <img src={Arrow} alt='>' />
          </div>
        </Grid>
      </Grid>
    </StyledWrapper>
  );
};

export default LastLoggedIn;
