import { CircularProgress } from '@mui/material';
import MuiUpload from 'common/components/inputField/Upload';
import { DocumentOwnerType } from 'common/types';
import { encryptDocumentUrl } from 'helpers';
import { generateDataForDocumentUpload, generateDocumentRelatedNames } from 'helpers/document';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useMutationQuery } from 'store/hooks';

type Props = {
  isLoading?: boolean;
};

const PerformanceSummaryDocUpload = ({ isLoading }: Props) => {
  const queryClient = useQueryClient();
  const [uploadedFileData, setUploadedFileData] = useState<any>(null);
  const [documentKey, setDocumentKey] = useState<{ object_key: string } | null>(null);
  const [documentData, setDocumentData] = useState<Object | null>({});

  const { mutate: fetchPresignedUrl, isLoading: isFetchingPresignedUrl } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
      params: documentKey,
    },
    {
      onSuccess: async (data) => {
        await encryptDocumentUrl(data.url, uploadedFileData);
        await updateDocumentData();
      },
    },
  );

  const { mutate: updateDocumentData, isLoading: isUpdatingDocuments } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/updatedocumentdata',
      params: { documentData: documentData },
      isFormData: true,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('performanceSummary');
      },
    },
  );

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile: any = event.target.files;
    setUploadedFileData(uploadedFile[0]);
    const docRelatedNames = await generateDocumentRelatedNames(uploadedFile);
    const data = await generateDataForDocumentUpload({
      uploadedFileName: docRelatedNames.uploadedFileName,
      formattedFileName: docRelatedNames.formattedFileName,
      id: 'PerformanceSummary',
      documentType: DocumentOwnerType.PERFORMANCE_SUMMERY,
      docType: 'pdf',
      status: 'Active',
    });
    setDocumentKey(data.uploaddocumentKey);
    setDocumentData({ ...data.documentData, doc_classification_name: null });
    fetchPresignedUrl();
    event.target.value = '';
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <MuiUpload
          disable={isUpdatingDocuments || isFetchingPresignedUrl}
          onFileUpload={onFileUpload}
        >
          {isUpdatingDocuments || isFetchingPresignedUrl ? 'Loading...' : 'Upload Performance'}
        </MuiUpload>
      )}
    </>
  );
};

export default PerformanceSummaryDocUpload;
