import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import { Investment } from './types';

export const initialState: Investment = {
  isListView: true,
  activeSort: 0,
  page: 1,
  searchQuery: '',
  currentFilters: {},
  defaultFilters: {},
};

const slice = createSlice({
  name: 'investments',
  initialState,
  reducers: reducers,
});

export const { setIsListView, setActiveSort, setPage, setSearchQuery, setCurrentFilters, setDefaultFilterList } =
  slice.actions;

export default slice.reducer;
