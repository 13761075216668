import { lazyLoad } from 'common/utils/loadable';

export const MyOrderbooksPage = lazyLoad(
  () => import('./index'),
  module => module.default,
);
 
export const FundOrdersPage = lazyLoad(
  () => import('./FundOrders'),
  module => module.default,
);
 