import { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import Textbox from 'common/components/inputField/Textbox';
import { ReactComponent as Delete } from 'common/assets/images/DocDelete.svg';
import { regexValidator, valueFormatter } from 'helpers';

interface Props {
  row: any;
  i: number;
  noOfRows: number;
  updateRow: (row: any, index: number) => void;
  deleteRow: any;
}

const InvestmentManagerRecordTableRow = ({ row, i, updateRow, deleteRow, noOfRows }: Props) => {
  const [fund, setFund] = useState('');
  const [vintage, setVintage] = useState('');
  const [size, setSize] = useState('');
  const [netdpi, setNetDpi] = useState('');
  const [nettvpi, setNetTvpi] = useState('');
  const [netirr, setNetIrr] = useState('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      updateRow({ fund, vintage, size, netdpi, nettvpi, netirr }, i);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    //eslint-disable-next-line
  }, [fund, vintage, size, netdpi, nettvpi, netirr]);

  useEffect(() => {
    if (row) {
      setFund(row['fund'] || '');
      setVintage(row['vintage'] || '');
      setSize(row['size'] || '');
      setNetDpi(row['netdpi'] || '');
      setNetTvpi(row['nettvpi'] || '');
      setNetIrr(row['netirr'] || '');
    }
  }, [row]);

  return (
    <TableRow>
      <TableCell align='left' className='py-3 table-row' sx={{ width: 'initial' }}>
        <div className='flex-center-between'>
          <Textbox
            fullWidth
            name={`fund-${i}`}
            onChange={(e: any) => {
              setFund(e.target.value);
            }}
            value={fund}
            type='text'
          />
        </div>
      </TableCell>
      <TableCell align='left' className='py-3 table-row' sx={{ width: 'initial' }}>
        <div className='flex-center-between'>
          <Textbox
            fullWidth
            name={`fund-${i}`}
            onChange={(e: any) => {
              setVintage(e.target.value);
            }}
            value={vintage}
            type='text'
          />
        </div>
      </TableCell>
      <TableCell align='left' className='py-3 table-row' sx={{ width: 'initial' }}>
        <div className='flex-center-between'>
          <Textbox
            fullWidth
            name={`fund-${i}`}
            onChange={(e: any) => {
              if (regexValidator(e.target.value.replace(/,/g, ''), /^[0-9,]*$/i)) return;
              setSize(e.target.value.replace(/,/g, ''));
            }}
            value={!size ? '' : valueFormatter(size)}
            type='text'
          />
        </div>
      </TableCell>
      <TableCell align='left' className='py-3 table-row' sx={{ width: 'initial' }}>
        <div className='flex-center-between'>
          <Textbox
            fullWidth
            name={`fund-${i}`}
            onChange={(e: any) => {
              if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                setNetDpi(e.target.value);
              }
            }}
            value={netdpi}
            type='text'
          />
        </div>
      </TableCell>
      <TableCell align='left' className='py-3 table-row' sx={{ width: 'initial' }}>
        <div className='flex-center-between'>
          <Textbox
            fullWidth
            name={`fund-${i}`}
            onChange={(e: any) => {
              if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                setNetTvpi(e.target.value);
              }
            }}
            value={nettvpi}
            type='text'
          />
        </div>
      </TableCell>
      <TableCell align='left' className='py-3 table-row' sx={{ width: 'initial' }}>
        <div className='flex-center-between'>
          <Textbox
            fullWidth
            name={`fund-${i}`}
            onChange={(e: any) => {
              if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                setNetIrr(e.target.value);
              }
            }}
            value={netirr}
            type='text'
          />
        </div>
      </TableCell>
      {i > 0 && (
        <TableCell>
          <div className='flex-center-start'>
            <Delete
              className='cursor-pointer'
              onClick={() => {
                deleteRow(i);
              }}
            />
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};

export default InvestmentManagerRecordTableRow;
