import { parse } from 'date-fns';
import { read, utils, writeFile } from 'xlsx';
import { MANDATORY_FIELDS, MONTHS } from './constants';

function to_json(workbook: any) {
  var result: any = {};
  let csvHeader: any = [];
  workbook.SheetNames.forEach(function (sheetName: any) {
    var roa = utils.sheet_to_json(workbook.Sheets[sheetName], {
      header: 0,
      raw: false,
    });
    csvHeader = utils.sheet_to_json(workbook.Sheets[sheetName], {
      header: 1,
    });
    if (roa.length) result[sheetName] = roa;
  });
  return {
    result,
    header: csvHeader,
  };
}

export function processExcel(data: any) {
  var workbook = read(data, {
    type: 'binary',
  });
  var json = to_json(workbook);
  return json;
}

const removeAllSpaces = (string: string) => string.replace(/\s/g, '');

export const errorValidation = (data: any) => {
  let validData: any = [];
  let invalidData: any = [];
  let isHeadersValid: any = false;
  const result = data.result.Sheet1;
  if (result) {
    const modifiedMonthlyReturnsRawData = result.map((monthlyReturn: any) => ({
      ...monthlyReturn,
      Month: removeAllSpaces(monthlyReturn.Month), // Replace the accidental spaces added in a CSV row. For Ex: Jul-2021 could be Jul -2022. This would cause checks below to fail when trying to spilt.
      Year: removeAllSpaces(monthlyReturn.Year),
    }));

    for (const obj of modifiedMonthlyReturnsRawData) {
      obj.dateFormatted = `${obj?.Month}-${obj?.Year}`;

      const IS_CURRENT_MONTH =
        parseInt(MONTHS[obj?.Month.toLocaleLowerCase()]) === new Date().getMonth() + 1; // +1 as Date object returns months starting from 0.
      const IS_CURRENT_YEAR = parseInt(obj?.Year) === new Date().getFullYear();

      const isValid =
        MANDATORY_FIELDS.every(
          (item) =>
            obj.hasOwnProperty(item) &&
            !isNaN(Number(obj?.Return)) &&
            obj.dateFormatted &&
            obj.dateFormatted.split('-').length === 2 &&
            obj.dateFormatted.split('-')[1].length === 4 &&
            !!parse(obj.dateFormatted, 'MMM-yyyy', new Date()).getTime() &&
            parse(obj.dateFormatted, 'MMM-yyyy', new Date()) < new Date(),
        ) && !validData.some((item: any) => item.dateFormatted === obj.dateFormatted);
      // Above line checks for unique vals

      // Excluding the current running month as well.
      if ((IS_CURRENT_MONTH && IS_CURRENT_YEAR) || !isValid) invalidData.push(obj);
      else validData.push(obj);
    }
  } else {
    Object.keys(data.result).length
      ? data.result?.output?.forEach((obj: any) => {
          invalidData.push(obj);
        })
      : invalidData.push({});
  }
  const headers = data.header[0];
  const stringifiedRequiredHeader = JSON.stringify(MANDATORY_FIELDS.sort());
  const stringifiedHeaderFromFile = headers && JSON.stringify(headers.sort());

  if (
    headers?.length === MANDATORY_FIELDS?.length &&
    stringifiedRequiredHeader === stringifiedHeaderFromFile
  ) {
    isHeadersValid = true;
  }
  return {
    validData,
    invalidData,
    isHeadersValid,
  };
};

export const handleTemplateDownload = () => {
  const fileName = 'Historical Returns Template.xlsx';
  const templateData = [
    {
      Month: '',
      Year: '',
      Return: '',
    },
  ];
  const ws = utils.json_to_sheet(templateData);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, 'Sheet1');
  writeFile(wb, fileName);
};
