import styled from '@emotion/styled';

export const StyledWrapper = styled.div`
  .byofStepper {
    margin-bottom: 2rem;
  }

  .close_flow {
    position: absolute;
    right: 50px;
    top: 0;
    font-size: 20px;
    color: var(--s30);
  }

  h2 {
    margin-bottom: 0.75rem;
    font-size: 1.375rem;
    font-weight: 400;
    color: var(--s40);
  }

  p {
    font-size: 13px;
    color: var(--s30);
  }

  h4 {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
    &.reducedFontSize {
      font-size: 14.5px;
      @media (max-width: 1440px) {
        font-size: 14px;
      }
    }
    .requiredStar {
      color: #0d0c22;
    }
  }

  .infoIcon {
    margin-left: 0.25rem;
    font-size: 15px;
    color: var(--s30);
  }

  .investmentForm > .MuiGrid-container {
    padding: 1.25rem 0 3.25rem;
    border-top: 1px solid var(--s15);

    .error-box {
      margin-top: 0.5rem;
    }
  }

  .ctaContainer {
    position: sticky;
    z-index: 5;
    background-color: white;
    /*---Needed for IntersectionObserver to work---*/
    bottom: -1px;
    padding: 20px 0 21px;
    /* --- */
    &.ctaSticky {
      box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);
    }
  }

  .logoUpload {
    label {
      margin-right: 1.125rem;
      flex-shrink: 0;
      & > span {
        color: #3780c1;
        border-color: #3780c1;
        border-width: 2px;
        line-height: 1em;
        padding: 5px 14px;
      }
    }
  }

  .logoFile {
    font-weight: 400;
    font-size: 13px;
    color: var(--s40);
    svg {
      margin-right: 6px;
      color: var(--green);
      font-size: inherit;
    }
  }

  .logoPreview {
    width: 150px;
    height: 77px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .selectField svg {
    color: var(--s30) !important;
  }
`;
