import styled from '@emotion/styled';
import { Container } from '@mui/material';
import Integrations from './Integrations';
import EngineCard from './EngineCard';
import { ENGINES, HIGHLIGHTS } from './constants';
import { UserProfile } from 'store/user/types';
import { useAppSelector } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import Highlight from './cards/Highlight';
import { useTypedDispatch } from 'store/hooks';
import { useEffect } from 'react';
import {
  setClientAge,
  setEngine1Result,
  setEngine2Result,
  setEngineNumber,
  setIsLandingPage,
  setIsSelectedClientNonNaturalPerson,
  setMaxAllocation,
  setPortfolioValue,
  setRecommendationActiveStep,
  setRecommendationClient,
} from 'store/recommendation/slice';

const StyledContainer = styled(Container)`
  color: var(--s40);
  padding: 2rem 113px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .integrations {
    width: 292px;
    margin: 2.25rem 0;
  }

  .headline {
    text-align: center;
    margin-bottom: 42px;

    h2 {
      font-size: 30px;
      font-weight: 300;
      margin-bottom: 1rem;
      background: linear-gradient(95.96deg, #2667a1 -2.77%, #3e89cd 101.66%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  p {
    color: var(--s40);
    font-size: 0.875rem;
    line-height: 22px;
  }

  .highlights {
    width: 975px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 28px;
    margin-bottom: 3.5rem;

    .MuiCard-root {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 0.875rem;
      font-weight: 500;
      text-align: center;
      color: var(--s40);
      padding: 22px 1.5rem;
      min-height: unset;

      img {
        height: 5rem;
        margin-bottom: 1.25rem;
      }
    }
  }

  .engines {
    width: 100%;

    .MuiCard-root {
      border: none;
      color: var(--s40);
      padding: 25px 0;
      min-height: unset;
      margin-bottom: 1.5rem;
      box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);

      .MuiGrid-container > div {
        padding: 0 1.5rem;
        &:nth-of-type(2) {
          border-left: 1px solid var(--s15);
          border-right: 1px solid var(--s15);
        }
      }

      h3 {
        font-weight: 500;
        font-size: 1rem;
        color: var(--s50);
      }
    }

    h5 {
      padding: 3rem 0 5rem;
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--s30);
      text-align: center;
    }
  }
`;

type Props = {
  setLanding: Function;
};

const Landing = ({ setLanding }: Props) => {
  const dispatch = useTypedDispatch();
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: any = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setIsSelectedClientNonNaturalPerson({ isSelectedClientNonNaturalPerson: false }));
    dispatch(setEngineNumber({ engineNumber: 0 }));
    dispatch(setClientAge({ clientAge: null }));
    dispatch(setRecommendationActiveStep({ activeStep: 0 }));
    dispatch(setIsLandingPage({ isLandingPage: true }));
    dispatch(setRecommendationClient({ client: null }));
    dispatch(setPortfolioValue({ totalAmount: 0 }));
    dispatch(setMaxAllocation({ maxAlloc: null }));
    dispatch(setMaxAllocation({ maxAlloc: null }));
    dispatch(
      setEngine1Result({
        engine1Result: null,
      }),
    );
    dispatch(
      setEngine2Result({
        engine2Result: null,
      }),
    );
    //eslint-disable-next-line
  }, []);

  return (
    <StyledContainer maxWidth='xl' className='m-header container-lr-padding'>
      <section>
        <Integrations />
      </section>
      <section className='headline'>
        <h2>{user.firstName + ' ' + user.lastName}</h2>
        <p className='mb-0'>
          Tifin Private Markets generates recommendations for your clients based on their stage in
          life, <br />
          liquidity requirements and their traditional portfolio.
        </p>
      </section>
      <section className='highlights'>
        {HIGHLIGHTS.map((obj: any, index: number) => (
          <Highlight obj={obj} key={`highlight-${index}`} />
        ))}
      </section>
      <section className='engines'>
        {ENGINES.map((engine: any, index: number) => (
          <EngineCard
            engine={engine}
            index={index}
            key={`Engine${index}`}
            setLanding={() => setLanding(false)}
          />
        ))}
        <h5>More recommendation engines coming soon!</h5>
      </section>
    </StyledContainer>
  );
};

export default Landing;
