import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  marginBottom: 0.5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#D7E7F8',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#66A4DB',
  },
}));

type Props = {
  value: number;
};

export default function OrderStatusBar({ value }: Props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant='determinate' value={value} />
    </Box>
  );
}
