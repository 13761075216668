import GlobalStyle, { darkTheme, lightTheme } from 'styles/globalStyle';
import { Route, Routes } from 'react-router-dom';
import Toast from 'common/components/Toast';
import { useEffect, useState } from 'react';
import { PrivateRoute } from 'common/components/PrivateRoute';
import RegisterPage from 'Pages/Register';
import ForgotPasswordPage from 'Pages/ForgotPassword';
import HomePage from 'Pages/Home';
import { ClientsPage } from 'Pages/Clients/Loadable';
import { ClientDetailsPage } from 'Pages/ClientDetails/Loadable';
import { OrderDetailsPage } from 'Pages/OrderDetails/Loadable';
import { OrderCreationPage } from 'Pages/OrderCreation/Loadable';
import { MyOrderbooksPage, FundOrdersPage } from 'Pages/MyOrderbooks/Loadable';
import { OrdersPage } from 'Pages/Orders/Loadable';
import PageNotFound from 'Pages/PageNotFound';
import { ThemeProvider } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from '@emotion/react';
import theme from 'styles/MuiTheme';
import InvestmentList from 'components/investments/InvestmentList';
import { useLocation, useNavigate } from 'react-router-dom';
import { InvestmentDetailsPage } from 'Pages/InvestmentDetails/Loadable';
import {
  AdminPage,
  UsersPage,
  RolesPage,
  TeamsPage,
  AplsPage,
  OrganisationsPage,
  OrganisationDetailsPage,
} from 'Pages/Admin/Loadable';
import NewFundsPage from 'Pages/Admin/NewInvestmentPage';
import EditFundsPage from 'Pages/Admin/EditInvestmentPage';
import ApproveClientsPage from 'Pages/Admin/ApproveClients';
import BehalfOfModal from 'components/modals/BehalfOfModal';
import { initializeAnalytics } from 'analytics';
import EventsPage from 'Pages/Admin/Events';
import ActivityLogsPage from 'Pages/Admin/Activity';
import ChangePasswordPage from 'Pages/ChangePassword';
import SubscriptionMasterChecklistPage from 'Pages/Admin/SubscriptionMasterChecklist';
import { fetchPageBackgroundBool } from 'helpers';
import EditFundsPageV2 from 'Pages/Admin/EditInvestmentPageV2';
import NewFundsPageV2 from 'Pages/Admin/NewInvestmentPageV2';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuth } from 'common/utils/oktaConfig';
import ManageFundsPage from 'Pages/Admin/ManageFunds';
import { useDispatch } from 'react-redux';
import { selectIsLoading, selectUserProfile } from 'store/user/selectors';
import Loading from 'common/components/Loading';
import { useAppSelector } from 'store/hooks';
import LoginPage from 'Pages/Login';
import RecommendationsPage from 'Pages/Recommendations';
import Compare from 'components/investments/compare';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectIsLoading);
  const userProfile = useAppSelector(selectUserProfile);
  const [styledTheme, setStyledTheme] = useState<boolean>(false);
  useEffect(() => {
    if (!userProfile) dispatch({ type: 'INIT_SAGA_HANDLER' });
    // This will move the page to the top
    window.scrollTo(0, 0);
    try {
      initializeAnalytics();
    } catch (error) {
      console.log('Trouble initializing GA...', error);
    }
    const pathName = location.pathname;
    if (fetchPageBackgroundBool(pathName)) setStyledTheme(true);
    else setStyledTheme(false);
    // eslint-disable-next-line
  }, [location]);

  const customAuthHandler = () => {
    navigate('/login');
  };

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri?: string) => {
    navigate(toRelativeUrl(originalUri || '', window.location.origin), { replace: true });
  };

  if (isLoading) return <Loading />;
  return (
    <div className='App'>
      <StyledThemeProvider theme={styledTheme ? darkTheme : lightTheme}>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <Toast />
          <BehalfOfModal />
          <Security
            oktaAuth={oktaAuth}
            restoreOriginalUri={restoreOriginalUri}
            onAuthRequired={customAuthHandler}
          >
            <Routes>
              <Route path='/login' element={<LoginPage />} />
              <Route path='/login/callback' element={LoginCallback} />
              <Route path='/register' element={<RegisterPage />} />
              <Route path='/forgot-password' element={<ForgotPasswordPage />} />
              <Route element={<PrivateRoute />}>
                <Route path='/' element={<HomePage />} />
                <Route path='/clients' element={<ClientsPage />} />
                <Route path='/client/:id' element={<ClientDetailsPage />} />
                <Route path='/recommendations' element={<RecommendationsPage />} />
                <Route path='/orders' element={<OrdersPage />} />
                <Route path='/order/:id' element={<OrderDetailsPage />} />
                <Route path='/create-order' element={<OrderCreationPage />} />
                <Route path='/edit-order/:id' element={<OrderCreationPage />} />
                <Route path='/myorderbooks' element={<MyOrderbooksPage />} />
                <Route path='/myorderbooks/:id' element={<FundOrdersPage />} />
                <Route path='/investments' element={<InvestmentList />} />
                <Route path='/investments/compare' element={<Compare />} />
                <Route path='/investment/:id' element={<InvestmentDetailsPage />} />
                <Route path='/investment/edit/:id' element={<EditFundsPage />} />
                <Route path='/investment/add' element={<NewFundsPage />} />
                <Route path='/admin' element={<AdminPage />} />
                <Route path='/admin/approve-clients' element={<ApproveClientsPage />} />
                <Route path='/admin/manage-funds' element={<ManageFundsPage />} />
                <Route path='/admin/users' element={<UsersPage />} />
                <Route path='/admin/roles' element={<RolesPage />} />
                <Route path='/admin/teams' element={<TeamsPage />} />
                <Route path='/admin/apls' element={<AplsPage />} />
                <Route path='/admin/events' element={<EventsPage />} />
                <Route path='/admin/new-investment' element={<NewFundsPage />} />
                <Route path='/admin/activity-logs' element={<ActivityLogsPage />} />
                <Route path='/investment/editV2/:id' element={<EditFundsPageV2 />} />
                <Route path='/admin/new-investment-v2' element={<NewFundsPageV2 />} />
                <Route path='/admin/organizations' element={<OrganisationsPage />} />
                <Route path='/admin/organization/:id' element={<OrganisationDetailsPage />} />
                <Route
                  path='/admin/subscriptionMasterChecklist'
                  element={<SubscriptionMasterChecklistPage />}
                />
                <Route
                  path='/admin/organization/add-new-organization'
                  element={<OrganisationDetailsPage />}
                />
                <Route path='/change-password' element={<ChangePasswordPage />} />
                <Route path='*' element={<PageNotFound />} />
              </Route>
            </Routes>
          </Security>
        </ThemeProvider>
      </StyledThemeProvider>
    </div>
  );
}

export default App;
