export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '',
    label: 'Events',
  },
];

export const COLUMNS = [
  {
    id: 'id',
    label: 'Id',

    width: '5%',
  },
  {
    id: 'name',
    label: 'Name',

    width: '20%',
  },
  {
    id: 'start_time',
    label: 'Start Time',

    width: '15%',
  },
  {
    id: 'publish_status',
    label: 'Status',

    width: '10%',
  },
  {
    id: 'created_by',
    label: 'Created By',

    width: '10%',
  },
  {
    id: 'updated_at',
    label: 'Last Updated',

    width: '15%',
  },
];
