import styled from '@emotion/styled';

const PageNotFound = () => {
  return (
    <PageNotFoundWrapper>
      <div className='position'>
        <div className='fof'>
          <h1>404</h1>
        </div>
        <h3>Page Not Found</h3>
      </div>
    </PageNotFoundWrapper>
  );
};

export default PageNotFound;

const PageNotFoundWrapper = styled.div`
  .fof,
  h3 {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .sad-smilie {
    text-align: center;
    width: 100%;
    transform: scale(4);
  }

  h3 {
    color: grey;
  }

  .position {
    position: relative;
    top: 100px;
  }

  .fof h1 {
    font-size: 100px;
    display: inline-block;
    padding-right: 12px;
    margin-bottom: 20px;
    animation: type 0.5s alternate infinite;
  }

  @keyframes type {
    from {
      box-shadow: inset -3px 0px 0px #888;
    }
    to {
      box-shadow: inset -3px 0px 0px transparent;
    }
  }
`;
