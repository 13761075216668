import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import MuiModal from 'common/components/modal';
import InputField from 'common/components/inputField/Textbox';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppSelector, useReactQuery, useRQMutation } from 'store/hooks';
import { useEffect, useState } from 'react';

import SelectField from 'common/components/inputField/SelectField';
import { QueryType } from 'common/types';
import { format } from 'date-fns';

import { useQueryClient } from 'react-query';
import EventsDatePicker from 'common/components/inputField/EventsDatePicker';
import RCTimePicker from 'common/components/inputField/TimePicker';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';

type Props = {
  isEditEventModalOpen: any;
  handleEventEditClick: any;
  selectedEvent: any;
  handleCloseModals: any;
};

const CreateUpdateEventsModal = ({
  isEditEventModalOpen,
  handleEventEditClick,
  selectedEvent,
  handleCloseModals,
}: Props) => {
  const queryClient = useQueryClient();
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile: any = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const [selectedTz, setSelectedTz] = useState<any>(selectedEvent?.time_zone);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(
    selectedEvent
      ? new Date(new Date(selectedEvent?.starTime).setHours(0, 0, 0, 0))
      : (null as any),
  );
  const [startTime, setStartTime] = useState<Date>(
    selectedEvent ? new Date(selectedEvent?.starTime) : (null as any),
  );
  const [endDate, setEndDate] = useState<Date>(
    selectedEvent ? new Date(new Date(selectedEvent?.endTime).setHours(0, 0, 0, 0)) : (null as any),
  );
  const [endTime, setEndTime] = useState<Date>(
    selectedEvent ? new Date(selectedEvent?.endTime) : (null as any),
  );
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (selectedEvent) {
      reset(selectedEvent);
      setSelectedTz(selectedEvent?.time_zone);
      setValue('timeZone', selectedEvent?.time_zone);
      setValue('startTime', new Date(selectedEvent?.starTime));
      setValue('endTime', new Date(selectedEvent?.endTime));
    }
    //eslint-disable-next-line
  }, [selectedEvent]);

  const { data: timeZones = [] } = useReactQuery(['timezones'], {
    url: `qaip/v1/notificationmanagement/timezones`,
  });

  const { mutate: addNewEvent } = useRQMutation(
    {
      url: 'qaip/v1/notificationmanagement/events',
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries('events');
        queryClient.invalidateQueries(`dashboard${user?.userId}`);
        handleCloseModals();
        setDisableBtn(false);
      },
      onError: () => {
        setDisableBtn(false);
      },
    },
  );

  const { mutate: updateEvent } = useRQMutation(
    {
      url: `qaip/v1/notificationmanagement/events/${selectedEvent?.id}`,
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries('events');
        queryClient.invalidateQueries(`dashboard${user?.userId}`);
        handleCloseModals();
        setDisableBtn(false);
      },
      onError: () => {
        setDisableBtn(false);
      },
    },
  );

  const pastTimeCheck = (start: boolean) => {
    if (start) {
      return (
        startTime &&
        (startDate
          ? new Date(
              new Date(startDate).setHours(startTime.getHours(), startTime.getMinutes(), 0),
            ) < new Date()
          : startTime < new Date())
      );
    } else {
      return (
        endTime &&
        (endDate
          ? new Date(new Date(endDate).setHours(endTime.getHours(), endTime.getMinutes(), 0)) <
            new Date()
          : endTime < new Date())
      );
    }
  };

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    delete data.timeZone;
    delete data.startTime;
    delete data.endTime;
    delete data.startDate;
    delete data.endDate;
    setDisableBtn(true);

    const eventData = {
      ...data,
      time_zone: selectedTz,
      end_time: format(endDate as Date, 'yyyy-MM-dd') + ' ' + format(endTime as Date, 'HH:mm:ss'),
      start_time:
        format(startDate as Date, 'yyyy-MM-dd') + ' ' + format(startTime as Date, 'HH:mm:ss'),
      ...(selectedEvent && {
        endTime: format(endDate as Date, 'yyyy-MM-dd') + ' ' + format(endTime as Date, 'HH:mm:ss'),
      }),
      ...(selectedEvent && {
        starTime:
          format(startDate as Date, 'yyyy-MM-dd') + ' ' + format(startTime as Date, 'HH:mm:ss'),
      }),
    };

    if (selectedEvent) updateEvent(eventData);
    else addNewEvent(eventData);
  };

  return (
    <MuiModal
      title={selectedEvent ? 'Edit Event' : 'Add a New Event'}
      maxWidth='sm'
      isModalOpen={isEditEventModalOpen}
      handleClose={handleEventEditClick}
    >
      <form>
        <Grid container spacing={3} mt={4}>
          <Grid item xs={6}>
            <h4 className='font-wt-400 mb-1'>Event Type</h4>
            <InputField
              variant='outlined'
              type='text'
              register={register}
              name='type'
              fullWidth
              errorMessage='This field is required'
            />
            {errors.type && <ErrorMessage error='This field is required' />}
          </Grid>
          <Grid item xs={6}>
            <h4 className='font-wt-400 mb-1'>Event Host</h4>
            <InputField
              variant='outlined'
              type='text'
              register={register}
              name='host'
              fullWidth
              errorMessage='This field is required'
            />
            {errors.host && <ErrorMessage error='This field is required' />}
          </Grid>
          <Grid item xs={12}>
            <h4 className='font-wt-400 mb-1'>Event Name</h4>
            <InputField
              variant='outlined'
              type='text'
              register={register}
              name='name'
              fullWidth
              errorMessage='This field is required'
            />
            {errors.name && <ErrorMessage error='This field is required' />}
          </Grid>
          <Grid item xs={12}>
            <h4 className='font-wt-400 mb-1'>Description</h4>
            <InputField
              variant='outlined'
              type='text'
              register={register}
              name='description'
              fullWidth
              multiline
              rows={3}
              errorMessage='This field is required'
            />
            {errors.description && <ErrorMessage error='This field is required' />}
          </Grid>
          <Grid item xs={12} container columnSpacing={3}>
            <Grid item xs={6}>
              <h4 className='font-wt-400 mb-1'>Start Date</h4>
              <EventsDatePicker
                name='startDate'
                register={register}
                required
                value={startDate}
                setDate={(e: Date) => {
                  const newDate = new Date(e.setHours(0, 0, 0, 0));
                  setValue('startDate', newDate);
                  setStartDate(newDate);
                }}
                inputFormat={'MMM-dd-yyyy'}
                maxDate={endDate}
              />

              {errors.startDate && <ErrorMessage error='This field is required' />}
            </Grid>
            <Grid item xs={6}>
              <h4 className='font-wt-400 mb-1'>Start Time</h4>
              <RCTimePicker
                name='startTime'
                register={register}
                required
                value={startTime}
                onChange={(val: any) => {
                  setValue('startTime', val.format('HH:mm'));
                  setStartTime(new Date(val));
                }}
              />
              {pastTimeCheck(true) && <ErrorMessage error='Past times are not accepted' />}
              {errors.startTime && <ErrorMessage error='This field is required' />}
            </Grid>
          </Grid>
          <Grid item xs={12} container columnSpacing={3}>
            <Grid item xs={6}>
              <h4 className='font-wt-400 mb-1'>End Date</h4>
              <EventsDatePicker
                register={register}
                required
                name='endDate'
                value={endDate}
                inputFormat={'MMM-dd-yyyy'}
                setDate={(e: Date) => {
                  const newDate = new Date(e.setHours(0, 0, 0, 0));
                  setValue('endDate', newDate);
                  setEndDate(newDate);
                }}
                minDate={startDate ? startDate : new Date()}
              />
              {errors.endDate && <ErrorMessage error='This field is required' />}
            </Grid>
            <Grid item xs={6}>
              <h4 className='font-wt-400 mb-1'>End Time</h4>
              <RCTimePicker
                name='endTime'
                register={register}
                required
                value={endTime}
                onChange={(val: any) => {
                  setValue('endTime', val.format('HH:mm'));
                  setEndTime(new Date(val));
                }}
              />
              {pastTimeCheck(false) && <ErrorMessage error='Past times are not accepted' />}
              {errors.endTime && <ErrorMessage error='This field is required' />}
            </Grid>
            <Grid item xs={12}>
              {startTime &&
                endTime &&
                startDate &&
                endDate &&
                startDate >= endDate &&
                format(startTime, 'HHmmss') > format(endTime, 'HHmmss') && (
                  <ErrorMessage error='Start Date and Time cannot be set ahead of End Date and Time' />
                )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h4 className='font-wt-400 mb-1'>Time Zone</h4>
            <SelectField
              options={timeZones}
              setSelectedValue={(data: any) => setSelectedTz(data.abbr)}
              optionName='text'
              optionId='abbr'
              name='timeZone'
              register={register}
              defaultValue={selectedTz}
              value={selectedTz}
              onChange={(e) => setValue('timeZone', e.target.value)}
              required={!!!selectedTz}
              searchIcon={false}
            />
            {errors.timeZone && <ErrorMessage error='This field is required' />}
          </Grid>
          <Grid item xs={12}>
            <h4 className='font-wt-400 mb-1'>Link to Platform</h4>
            <InputField
              variant='outlined'
              type='text'
              register={register}
              name='platform_link'
              fullWidth
              errorMessage='This field is required'
            />
            {errors.platform_link && <ErrorMessage error='This field is required' />}
          </Grid>
          <Grid item xs={12} className='flex-center-end' mt={3}>
            {/* This will prevent the submission of form on pressing enter key */}
            <button type='submit' disabled className='d-none' aria-hidden='true'></button>
            <MuiButton
              variant='text'
              buttonClick={handleSubmit((data) => {
                if (!disableBtn) onSubmit({ ...data, is_published: false });
              })}
              type='submit'
              minWidth='150px'
              disabled={
                disableBtn ||
                (startTime &&
                  endTime &&
                  startDate &&
                  endDate &&
                  startDate >= endDate &&
                  format(startTime, 'HHmmss') > format(endTime, 'HHmmss')) ||
                pastTimeCheck(false) ||
                pastTimeCheck(true)
              }
            >
              Save and Unpublish
            </MuiButton>
            <MuiButton
              variant='contained'
              buttonClick={handleSubmit((data) => {
                if (!disableBtn) onSubmit({ ...data, is_published: true });
              })}
              disabled={
                disableBtn ||
                (startTime &&
                  endTime &&
                  startDate &&
                  endDate &&
                  startDate >= endDate &&
                  startTime > endTime) ||
                pastTimeCheck(false) ||
                pastTimeCheck(true)
              }
              type='submit'
              minWidth='150px'
              className='ml-3'
            >
              Save and Publish
            </MuiButton>
          </Grid>
        </Grid>
      </form>
    </MuiModal>
  );
};

export default CreateUpdateEventsModal;
