import { CheckBox, CheckBoxOutlineBlank, Circle } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';

type Props = {
  defaultValue: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  circleCheck?: boolean;
  className?: string;
  disabled?: boolean;
  disableRipple?: boolean;
};

export default function MuiCheckbox({
  defaultValue = false,
  onChange,
  name,
  circleCheck = false,
  disabled = false,
  className = '',
  disableRipple = false,
}: Props) {
  return (
    <div className={className}>
      <Checkbox
        onChange={onChange}
        checked={defaultValue}
        disabled={disabled}
        disableRipple={disableRipple}
        icon={
          circleCheck ? (
            <Circle sx={{ color: '#C4C4C4', fontSize: '12px' }} />
          ) : (
            <CheckBoxOutlineBlank sx={{ color: '#59A7ED', fontSize: '12px' }} />
          )
        }
        checkedIcon={
          circleCheck ? (
            <Circle sx={{ color: '#59A7ED', fontSize: '12px' }} />
          ) : (
            <CheckBox sx={{ color: '#59A7ED', fontSize: '12px' }} />
          )
        }
      />
    </div>
  );
}
