import {
  AccountHolderDetails,
  AuthorizedRepresentativeDetails,
  CustodianType,
  EntityTypeDetails,
  WireTransfferBankDetails,
} from 'store/investor/types';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
  error?: any;
}

// ENUMS

export enum QueryType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum RedemptionType {
  FULL_REDEMPTION = 'Full Redemption',
  VALUE = 'Value',
}

export const enum OrderStatusType {
  APPROVAL_REQUESTED = 1,
  SENT_TO_APPROVER = 2,
  SENT_TO_QUALIS = 3,
  SENT_TO_FUND_MANAGER = 4,
  ORDER_ACCEPTED = 5,
  FUNDS_TRANSFERRED = 6,
  MORE_INFORMATION = 7,
  NOT_SUBMITTED = 8,
  ORDER_REJECTED = 9,
  ORDER_COMPLETED = 10,
  ORDER_CANCELLED = 11,
  ALLOC_REQUESTED = 12,
  ALLOC_RECEIVED = 13,
}

export const enum UserRoleType {
  SUPER_USER = 'Super User',
  FINANCIAL_ADVISER = 'Financial Adviser',
  VIEW_ONLY = 'View Only',
}

export const enum EntityType {
  DEALERS_GROUP = 1,
  FUNDS_MANAGER = 2,
  QUALIS = 3,
}

export enum DocumentOwnerType {
  INVESTOR_STATEMENT = 'INVESTOR_STATEMENT',
  INVESTOR_TAX = 'INVESTOR_TAX',
  INVESTOR_PERSONAL = 'INVESTOR_PERSONAL',
  ORDER = 'ORDER',
  PERFORMANCE_SUMMERY = 'PERFORMANCE_SUMMERY',
}

export enum PrivilegeMenuType {
  FUND = 'Fund',
  ORGANISATION = 'Organisation',
  INVESTOR = 'Investor',
  HOLDINGS = 'Holdings',
  ORDERS = 'Orders',
  ACTIVITY_LOGS = 'Activity Logs',
  USER_MANAGEMENT = 'User Management',
  APL = 'APL',
  NOTIFICATION_MANAGEMENT = 'Notification Management',
}

export enum ClientType {
  QUALIFIED_PURCHASER = 'Qualified Purchaser',
  QUALIFIED_CLIENT = 'Qualified Client',
  ACCREDITED_INVESTOR = 'Accredited Investor',
}

export enum NaturalPersonType {
  QUALIFIED_PURCHASER = 'Individual',
  INDIVIDUAL_RETIREMENT_ACCOUNT_OF_THE_SUBSCRIBER = 'Individual Retirement Account of the Subscriber',
  JOINT_WITH_RIGHTS_OF_SURVIVORSHIP = 'Joint Tenants with Right of Survivorship',
  TENANTS_IN_COMMON = 'Tenants in Common',
}

export enum EntityPersonType {
  CORPORATION = 'Corporation',
  PARTNERSHIP = 'Partnership',
  LIMITED_LIABILITY_COMPANY = 'Limited Liability Company',
  FOUNDATION = 'Foundation',
  ENDOWMENT = 'Endowment',
  EMPLOYEE_BENEFIT_PLAN = 'Employee Benefit Plan',
  KEOGH_PLAN = 'Keogh Plan',
}

export enum HybridPersonType {
  OTHER = 'Other',
  TRUST = 'Trust',
}

// TYPES

export type ReactQueryDataFetch = {
  refetch: () => {} | undefined;
  isLoading: boolean;
  error: boolean;
  isFetching?: boolean;
};

export type OrderSummary = {
  orderId: number;
  accountName: string;
  fundName: string;
  orderStatusId: number;
  orderEventId: number;
  eventAmount: string;
  eventDate: number;
  lastUpdatedDate: number;
  assetClass: string;
  firstName: string;
  lastName: string;
  behalfOf?: string;
  watchlistId?: string;
  amountRequested?: string;
};

export type OrderDetails = Pick<
  OrderSummary,
  | 'orderId'
  | 'fundName'
  | 'orderStatusId'
  | 'orderEventId'
  | 'eventAmount'
  | 'eventDate'
  | 'lastUpdatedDate'
  | 'behalfOf'
  | 'amountRequested'
> & {
  fundId: string;
  fundClass: string;
  investorId: string;
  investorAccountName: string;
  teamId: number;
  accountNumber: string;
  primaryAdvisor: string;
  createdDate: number;
  createdBy: string;
  orderStatus: string;
  status: any;
  lastUpdatedBy: string;
  sentToFundManager: boolean;
  units: number;
  comments: string;
  userComments: UserComment[];
  behalfOf: string;
  organisation: string;
  cancelReason: string | null;
  watchlistEnabled: boolean;
};

export type OrderDocument = {
  orderId: string;
  documentId: string;
  docName: string;
  docClassificationName: string;
  docType: string;
  docTypeId?: string;
  docCategoryId: string;
  docCategory?: string;
  docLink: string;
  status: string;
  createdBy: string;
  createdDate: string;
  docConfigId?: string;
  lastUpdatedBy: string;
  lastUpdatedDate: string;
  downloadDisabled: string;
};

export type UserComment = {
  userName: string;
  comment: string;
};

export type ClientDetails = {
  investorIdSimple: string;
  investor_id: string;
  account_name: string;
  entity_type: string;
  account_number: string;
  accredited_investor: boolean;
  qualified_client: boolean;
  qualified_purchaser: boolean;
  team: number;
  team_name: string;
  primary_advisor_id: string;
  primary_advisor_name: string;
  secondary_advisor_id: string;
  secondary_advisor_name: string;
  status: string;
  accountHolderTypes: AccountHolderDetails[];
  authorizedRepresentativeTypes: AuthorizedRepresentativeDetails[];
  custodianTypes: CustodianType[];
  investorEntityTypes: EntityTypeDetails[];
  wireTransferBankTypes: WireTransfferBankDetails[];
};

export type HoldingDetails = {
  holdingsId: string;
  fundsId: string;
  fundName: string;
  investorId: string;
  team: string;
  accountNumber: string;
  investmentDate: string;
  priceDate: string;
  cost: number;
  units: number;
  value: number;
  organisationId: string;
  currentValue: number;
  subscription: number;
  income: number;
  roc: number;
  redemption: number;
  createdAt: string;
  periodToDate: string;
  yearToDate: string;
  inception: string;
  investorName: string;
};

export type DocumentDetails = {
  documentId: string;
  docName: string;
  docClassificationName: string;
  docType: string;
  docConfigTypeId: null;
  docCategoryId: string;
  docLink: string;
  status: string;
  createdBy: string;
  createdDate: Date;
  lastUpdatedBy: string;
  docConfigId: null;
  lastUpdatedDate: Date;
  downloadDisabled: boolean;
};

export type FundDetails = {
  strategy: string;
  rating: string;
  eligibility: string;
  status: string;
  logo: string;
  fund_id: string;
  fund_name: string;
  fund_manager_id: string;
  fund_manager_name: string;
  asset_class: string;
  sub_strategy: string;
  strategy_description: string;
  is_allocation_workflow_enabled?: string;
  class: string;
  min_investment: number;
  last_updated: Date;
  watchlist_enabled: boolean;
  target_fund_size: null;
  gp_catch_up_term: null;
  fund_template_id: number;
  fund_assets: string;
  preferred_return: null;
  tag_line: string;
  returns_since_inception: {
    '1_year': string;
    '3_year': string;
    '5_year': string;
    '10_year': string;
    since_inception: string;
  };
  fund_term: null;
  standard_deviation: number;
  compounded_rate_return: number;
  lockup: string;
  redemptions: string;
  final_close: string;
  correlation: null;
  next_close: null;
  funding_type: null;
  since_inception: string;
  s_and_p_500: string;
  lockup_for_summary: string;
  fund_status: string;
  liquidity_for_summary: string;
  iso_currency: null;
  fund_themes: string | null;
  fund_type: string | null;
  one_year_return: number;
};

export type Events = {
  starTime: Date | string;
  endTime: Date | string;
  createdAt: Date | string;
  updatedAt: Date | string;
  id: number;
  name: string;
  host: string;
  type: string;
  time_zone: string;
  description: string;
  platform_link: string;
  is_published: boolean;
  created_by: string;
  updated_by: string;
  watchlist_enabled: boolean;
};

export type FundsMasterData = {
  eligibility: string[];
  rating: string[];
  status: string[];
  strategy: string[];
  lockups: string[];
  liquidities: string[];
  wfStatus: string[];
  fundingType: string[];
  isoCurrency: string[];
  fundThemes: string[];
  asset_class: string[];
  class: string[];
};

export type Action = {
  type: string;
  payload: any;
};

export type Crumb = {
  link: string;
  label: string;
};
