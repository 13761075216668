import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import MuiTable from 'common/components/table/Table';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AllocationDetailsModal from './AllocationDetailsModal';
import { ReactComponent as ListIcon } from 'common/assets/images/CTA/List.svg';
import { format } from 'date-fns';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  rows: Array<any>;
};

const StyledModal = styled(MuiModal)`
  h2 {
    padding: 32px 24px 12px !important;
    font-size: 1.25rem;
  }
  h5 {
    font-size: 11px;
  }
  p {
    &.multi {
      color: var(--s30);
    }
    font-size: 14px;
    &.extraCount {
      color: #000000de;
      padding-left: 2rem;
      margin-top: 1rem;
    }
  }
  h4 {
    font-weight: 400;
  }
  span.howThisWorks {
    font-size: 0.875rem;
    color: var(--p300);
    cursor: pointer;
  }

  th {
    &:first-of-type {
      padding-left: 1.5rem !important;
    }
    &:last-of-type {
      padding-right: 1.5rem !important;
    }
  }

  td {
    &:first-of-type {
      padding-left: 1.5rem !important;
    }
    &:last-of-type {
      padding-right: 1.5rem !important;
    }
  }

  &.groupByFunds .MuiPaper-root {
    max-width: 656px;
  }
`;

const AllocationRequestedModal = ({ isModalOpen, handleClose, rows = [] }: Props) => {
  const navigate = useNavigate();
  const [howThisWorks, setHowThisWorks] = useState<boolean>(false);
  const [newRows, setNewRows] = useState<Array<any>>([]);

  useEffect(() => {
    if (rows.length > 0) {
      let uniqueFunds: any = [];
      let clubbedFunds: any = [];
      rows.forEach((row: any) => {
        if (!uniqueFunds.includes(row.fund)) {
          uniqueFunds.push(row.fund);
        }
      });
      uniqueFunds.forEach((fund: any) => {
        const filteredRows = rows.filter((row: any) => row.fund === fund);

        let fundAmount = filteredRows.reduce(function (prev, cur) {
          const amount = prev + Number(cur?.amount_requested);
          return amount;
        }, 0);
        clubbedFunds.push({ fund, amount_requested: fundAmount });
      });
      setNewRows(clubbedFunds);
    }
  }, [rows]);

  const columns = [
    {
      id: 'fund',
      label: 'Fund Name',

      width: '60%',
    },
    {
      id: 'amount_requested',
      label: 'Amount',
      needsFormatter: true,
      width: '40%',
      align: 'right' as const,
    },
  ];

  return (
    <StyledModal
      maxWidth={rows.length > 1 ? 'md' : 'xs'}
      title='New Allocation Requests'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      className={rows.length > 1 ? 'groupByFunds' : 'new-alloc'}
    >
      <Grid container>
        <Grid item xs={12} mb={2.5}>
          <p className={`mb-0 ${rows.length > 1 ? 'multi' : ''}`}>
            You have received the following allocation request{rows.length > 1 ? 's ' : ' '}
            for your funds. Click 'View {rows.length > 1 ? 'Orderbooks' : 'Details'}' to see
            details.
          </p>
        </Grid>
        {rows.length > 1 ? (
          <>
            <Grid item xs={12} mb={2.5}>
              <MuiTable
                rows={newRows?.slice(0, 4)}
                columns={columns}
                appendText={newRows.length > 4 ? `+ ${newRows.length - 4} more` : ''}
              />
            </Grid>
            <Grid item xs={12} className='flex-center-end'>
              <MuiButton
                variant='contained'
                minWidth='150px'
                buttonClick={() => {
                  navigate('/myorderbooks');
                  handleClose();
                }}
              >
                <ListIcon className='mr-2' /> View Orderbooks
              </MuiButton>
            </Grid>
          </>
        ) : (
          <>
            {newRows.length > 0 && (
              <>
                <Grid item xs={12} className='mb-2'>
                  <h5 className='subtitle'>Fund Name</h5>
                  <h4>{rows[0]['fund'] || '--'}</h4>
                </Grid>
                <Grid item xs={12} className='mb-2'>
                  <h5 className='subtitle'>Investor name</h5>
                  <h4>{rows[0]['investor'] || '--'}</h4>
                </Grid>
                <Grid item xs={12} className='mb-2'>
                  <h5 className='subtitle'>Allocation Requested</h5>
                  <h4>
                    {rows[0]['amount_requested']
                      ? '$' + Number(rows[0]['amount_requested']).toLocaleString()
                      : '--'}
                  </h4>
                </Grid>
                <Grid item xs={12}>
                  <h5 className='subtitle'>event date</h5>
                  <h4>
                    {rows[0]['created_date']
                      ? format(new Date(rows[0]['created_date']), 'MMM-dd-yyy')
                      : '--'}
                  </h4>
                </Grid>
                <Grid item xs={12} className='flex-center-between' mt={2}>
                  <span className='howThisWorks' onClick={() => setHowThisWorks(true)}>
                    How this works
                  </span>
                  <MuiButton
                    variant='contained'
                    minWidth='150px'
                    buttonClick={() => {
                      navigate(`/order/${rows[0]['order_id']}`);
                      handleClose();
                    }}
                  >
                    View Details
                  </MuiButton>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <AllocationDetailsModal
        isModalOpen={howThisWorks}
        handleClose={() => setHowThisWorks(false)}
        disableCTA
      />
    </StyledModal>
  );
};

export default AllocationRequestedModal;
