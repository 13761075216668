import styled from '@emotion/styled';
import { Grid, Skeleton } from '@mui/material';
import MuiCard from 'common/components/card';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useMutationQuery, useTypedDispatch } from 'store/hooks';
import { ReactComponent as CompareIcon } from 'common/assets/images/recommendation/Compare.svg';
import MuiButton from 'common/components/button';
import { useEffect } from 'react';
import { formatAumValues } from 'helpers';
import { selectFundsToCompare } from 'store/recommendation/selectors';
import { setFundsToCompare } from 'store/recommendation/slice';

const StyledWrapper = styled.div`
  .fundCard {
    padding: 1rem 24px;
    height: 100%;
    box-sizing: border-box;
    min-height: unset;
    position: relative;

    &:hover {
      border-color: var(--p150);
    }

    .recBanner {
      display: flex;
      align-items: center;
      position: absolute;
      inset: 0 0 auto 0;
      font-size: 12px;
      font-weight: 700;
      color: var(--s40);
      background: var(--p20);
      padding: 6px 12px;
      svg {
        margin-right: 10px;
      }
    }

    button {
      font-size: 0.875rem;
      padding: 0.25rem 0.75rem;

      svg path {
        stroke: currentColor;
      }
    }

    img,
    .MuiSkeleton-root {
      height: 32px;
      width: 96px;
      object-fit: contain;
      object-position: left center;
    }
    h3 {
      color: var(--p500);
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    h4 {
      margin-bottom: 0;
    }
  }
`;

const Engine4Card = ({ clientList, startIndex = 0, latestFund }: any) => {
  const selectedClient = clientList?.length > 0 ? clientList[startIndex] : null;
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const fundsToCompare = useAppSelector(selectFundsToCompare);

  const { mutate, data: fundLogo } = useMutationQuery({
    url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
    params: { object_key: latestFund.logo },
  });

  useEffect(() => {
    if (latestFund?.logo) mutate();
    // eslint-disable-next-line
  }, []);

  const handleAddToCart = () => {
    const fundIds = fundsToCompare?.length > 0 ? [...fundsToCompare] : [];
    const isFilter = fundIds?.includes(latestFund?.fund_id);
    if (isFilter) {
      navigate('/investments');
    } else if (fundsToCompare.length < 5) {
      const updatedCompareList = fundIds.concat(latestFund?.fund_id);
      dispatch(setFundsToCompare({ fundsToCompare: updatedCompareList }));
    }
    navigate('/investments', {
      state: {
        showCompareModal: true,
      },
    });
  };

  return (
    <StyledWrapper>
      <MuiCard className='fundCard'>
        <div className='recBanner'>
          <CompareIcon />
          Recommended for {selectedClient?.account_name}
        </div>
        <Grid container mt={'28px'}>
          <Grid item xs={12} mb={'12px'}>
            {fundLogo ? <img src={fundLogo?.url} alt='' /> : <Skeleton variant='rectangular' />}
          </Grid>
          <Grid item xs={12}>
            <h3>
              {latestFund?.fund_name.length > 20
                ? latestFund?.fund_name.substring(0, 15) +
                  '...' +
                  latestFund?.fund_name.substring(
                    latestFund?.fund_name.length - 5,
                    latestFund?.fund_name.length,
                  )
                : latestFund?.fund_name}
            </h3>
          </Grid>
          <Grid item container xs={12} mb={2}>
            <Grid item xs={4}>
              <h6 className='subtitle'>ASSET CLASS</h6>
              <h4 className='font-wt-400'>
                {latestFund.asset_class ? latestFund.asset_class.split(',')[0] : '--'}
              </h4>
            </Grid>
            <Grid item xs={4}>
              <h6 className='subtitle'>MIN INVESTMENT</h6>
              <h4 className='font-wt-400'>
                {latestFund?.min_investment && '$'}
                {latestFund?.min_investment
                  ? Number(latestFund.min_investment).toLocaleString()
                  : '--'}
              </h4>
            </Grid>
            {latestFund?.returns_since_inception &&
            latestFund?.returns_since_inception['1_year'] &&
            !isNaN(latestFund?.returns_since_inception['1_year']) ? (
              <Grid item xs={4}>
                <h6 className='subtitle'>1YR RETURN</h6>
                <h4 className='font-wt-400'>
                  {latestFund?.returns_since_inception &&
                  latestFund?.returns_since_inception['1_year']
                    ? latestFund?.returns_since_inception['1_year'] + '%'
                    : '--'}
                </h4>
              </Grid>
            ) : latestFund?.fund_assets && !isNaN(latestFund?.fund_assets) ? (
              <Grid item xs={4}>
                <h6 className='subtitle'>Fund AUM</h6>
                <h4 className='font-wt-400'>
                  {latestFund?.fund_assets
                    ? formatAumValues(Number(latestFund?.fund_assets), 0)
                    : '--'}
                </h4>
              </Grid>
            ) : (
              <Grid item xs={4}>
                <h6 className='subtitle'>Target Fund Size</h6>
                <h4 className='font-wt-400'>
                  {latestFund?.target_fund_size
                    ? formatAumValues(Number(latestFund?.target_fund_size?.replace(/,/g, '')), 0)
                    : '--'}
                </h4>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} display='flex' alignItems='flex-end'>
          <MuiButton variant='outlined' buttonClick={handleAddToCart}>
            <CompareIcon className='mr-2' />
            Compare Funds
          </MuiButton>
        </Grid>
      </MuiCard>
    </StyledWrapper>
  );
};

export default Engine4Card;
