import Theme1 from 'common/assets/images/Theme1.png';
import Theme2 from 'common/assets/images/Theme2.png';
import Theme3 from 'common/assets/images/Theme3.png';
import Theme4 from 'common/assets/images/Theme4.png';

export const THEMES_DATA = [
  {
    themeName: 'ESG',
    total_funds: 12,
    themeImageUrl: Theme1,
  },
  {
    themeName: 'Income',
    total_funds: 20,
    themeImageUrl: Theme2,
  },
  {
    themeName: 'Growth',
    total_funds: 21,
    themeImageUrl: Theme3,
  },
  {
    themeName: 'Diversification',
    total_funds: 19,
    themeImageUrl: Theme4,
  },
];
