import { useState } from 'react';
import { ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';

const FundCharacteristicsChart = ({
  data,
  xKey = 'Distance',
  yKey,
  hover,
  currentTheme,
  index,
  yAxisLabel,
  desc,
}: any) => {
  const [cellPositions, setCellPositions] = useState<Array<any>>([]);

  const CustomizedCell = (props: any) => {
    const coords = { id: props.ID, x: props.x, y: props?.y };
    if (!cellPositions.some((el: any) => el?.id === coords?.id))
      setCellPositions([...cellPositions, coords]);
    return <circle r={8} fill={hover?.fund === props.ID ? '#2E7CC2' : '#98C2E7'} {...props} />;
  };

  const XaxisLabel = (props: any) => {
    return (
      <>
        <text
          {...props.viewBox}
          x={props.viewBox.x + 30}
          y={props.viewBox.y + 20}
          offset={props.offset}
        >
          Low
        </text>
        <text
          {...props.viewBox}
          x={props.viewBox.width - 40}
          y={props.viewBox.y + 20}
          offset={props.offset}
        >
          High
        </text>
      </>
    );
  };

  const CustomTooltip = (props: any) => {
    let name = '';
    let value = '';
    if (hover?.fund) {
      const dataPoint = data?.find((obj: any) => obj?.ID === hover?.fund);
      name = dataPoint?.Name;
      value = (dataPoint && dataPoint[yKey]) ?? '';
    }
    return (
      <div className={`chartTooltip ${hover?.fund ? 'd-block' : 'd-none'}`}>
        {name}
        <br />
        {yAxisLabel}:{' '}
        {yKey === 'ESG' ? (value === '0' ? 'No' : 'Yes') : (+value * 100).toFixed(2) + '%'}
      </div>
    );
  };

  return (
    <div
      className={`themeChart ${hover?.theme === currentTheme ? 'd-block' : 'd-none'} ${
        index % 2 === 0 ? 'left' : 'right'
      }`}
    >
      <h5>{currentTheme} Fund Characteristics</h5>
      <h6>{desc}</h6>
      <ResponsiveContainer width={'100%'} height={160}>
        <ScatterChart width={568} height={160} margin={{ top: 0, left: 0 }}>
          <XAxis domain={[0, 'dataMax']} dataKey={xKey} tick={false} label={<XaxisLabel />} />
          <YAxis
            type='number'
            dataKey={yKey}
            tick={false}
            width={32}
            label={{ value: yAxisLabel, dx: 0, angle: -90 }}
            padding={{ top: 8, bottom: currentTheme === 'ESG' ? 16 : 0 }}
          />
          <Tooltip
            {...(hover?.fund
              ? {
                  position: {
                    x: cellPositions?.find((obj: any) => obj.id === hover?.fund)?.x - 100,
                    y: cellPositions?.find((obj: any) => obj.id === hover?.fund)?.y - 56,
                  },
                  wrapperStyle: {
                    visibility: 'visible',
                  },
                }
              : {})}
            cursor={{ stroke: 'none' }}
            content={<CustomTooltip />}
          />
          <Scatter data={data} shape={<CustomizedCell />}></Scatter>
        </ScatterChart>
      </ResponsiveContainer>
      <h6 className='xAxis2'>Similarity to client's portfolio</h6>
    </div>
  );
};

export default FundCharacteristicsChart;
