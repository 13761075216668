import { useState } from 'react';
import { useGraphQLQuery } from 'store/hooks';
import { GET_FUNDS_LIST, GET_FUNDS_FOR_DROPDOWN } from './queries';

export const useManageFundQuery = (
  getFundListVariables: Function,
  pageNo: number,
  searchQuery: string,
  currentFilters: any,
) => {
  const { data = {}, isLoading } = useGraphQLQuery(
    [`manageFunds`, searchQuery, currentFilters, pageNo],
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_FUNDS_LIST,
      variables: getFundListVariables(),
    },
    {
      enabled: true,
      refetchOnMount: true,
      select: (res) => res.getFunds,
    },
  );

  return { data, isLoading };
};

export const useFundsForDropDownQuery = (getFundListVariables: Function) => {
  const [rows, setRows] = useState<Array<any>>([]);

  const { refetch } = useGraphQLQuery(
    [`getFundsForDropDown`],
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_FUNDS_FOR_DROPDOWN,
      variables: getFundListVariables(),
    },
    {
      enabled: true,
      refetchOnMount: false,
      select: (res) => res.getFunds,
      onSuccess: ({ data }) => {
        setRows(data);
      },
    },
  );

  return { rows, refetch };
};
