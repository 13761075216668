import styled from '@emotion/styled';
import { Container } from '@mui/material';
import { useAppSelector, useReactQuery, useTypedDispatch } from 'store/hooks';
import {
  setEngineNumber,
  setIsLandingPage,
  setRecommendationClient,
} from 'store/recommendation/slice';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import Landing from './LandingV2';
import Step1 from './Step1';
import Step3V2 from './Step3V2';
import {
  isLandingPage,
  recEngineNumber,
  recommendationActiveStep,
  recommendationClient,
} from 'store/recommendation/selectors';
import { setRecommendationActiveStep } from 'store/recommendation/slice';

// REMOVE THIS WHEN NEW TABLE STYLES COME FROM MERGING development BRANCH
const StyledContainer = styled(Container)`
  && .tableWrapper {
    background: white;
    border-radius: 1rem;
    border: 1px solid #ddd;
    width: unset;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
    thead {
      border-bottom: none;
      th {
        border: 0;
        color: #525863;
        background-color: var(--s10);
        font-size: 13px;
        font-weight: 700;
        line-height: 17px;
        text-transform: uppercase;
        &:first-of-type {
          padding-left: 2rem;
        }
        &:last-of-type {
          padding-right: 2rem;
        }
      }
    }
    td {
      font-size: 13px;
      color: var(--s40);
      border-color: var(--s10);
      &:first-of-type {
        padding-left: 2rem;
      }
      &:last-of-type {
        padding-right: 2rem;
      }
    }
    .MuiTableRow-hover:hover {
      background-color: #dfe1e9 !important;
    }
    .pagination {
      padding: 1.2rem 2.5rem;
      border-top: 1px solid #d7d7d7;
    }
    .nullTable {
      background-color: white;
      height: 360px;
      color: #bdbdbd;
      text-align: center;
      vertical-align: center;
    }
  }
  .loader-wrapper {
    svg {
      color: var(--s30);
    }
  }
`;

const RecEngine = () => {
  const dispatch = useTypedDispatch();
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile: any = useAppSelector(selectUserProfile);
  const engineNumber: any = useAppSelector(recEngineNumber);
  const selectedClient: any = useAppSelector(recommendationClient);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const activeStep = useAppSelector(recommendationActiveStep);
  const landing = useAppSelector(isLandingPage);

  const { data: clientList } = useReactQuery([`clientList${user?.userId}`], {
    url: 'qaip/v1/investormanagement/investors',
  });

  return landing ? (
    <Landing setLanding={(bool: boolean) => dispatch(setIsLandingPage({ isLandingPage: bool }))} />
  ) : (
    <StyledContainer maxWidth='xl' className='m-header' sx={{ pt: 2 }}>
      {engineNumber === 1 ? (
        <>
          {activeStep === 0 && (
            <Step1
              clientList={clientList}
              selectedClient={selectedClient}
              setSelectedClient={(client: any) =>
                dispatch(setRecommendationClient({ client: client }))
              }
              buttonClick={() => dispatch(setRecommendationActiveStep({ activeStep: 1 }))}
              nextClick={() => dispatch(setRecommendationActiveStep({ activeStep: 2 }))}
            />
          )}
        </>
      ) : (
        activeStep === 0 && (
          <Step1
            clientList={clientList}
            selectedClient={selectedClient}
            setSelectedClient={(client: any) =>
              dispatch(setRecommendationClient({ client: client }))
            }
            buttonClick={() => dispatch(setRecommendationActiveStep({ activeStep: 0 }))}
            nextClick={() => dispatch(setRecommendationActiveStep({ activeStep: 1 }))}
          />
        )
      )}
      {activeStep === 1 && (
        <Step3V2
          selectedClient={selectedClient}
          clientList={clientList}
          backClick={() =>
            dispatch(setRecommendationActiveStep({ activeStep: engineNumber === 1 ? 1 : 0 }))
          }
          doneClick={() => {
            dispatch(setRecommendationActiveStep({ activeStep: 0 }));
            dispatch(setIsLandingPage({ isLandingPage: true }));
            dispatch(setEngineNumber({ engineNumber: 0 }));
          }}
        />
      )}
    </StyledContainer>
  );
};

export default RecEngine;
