import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import MuiTable from 'common/components/table/Table';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CircleArrowIcon } from 'common/assets/images/CTA/CircleArrow.svg';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  rows: Array<any>;
};

const StyledModal = styled(MuiModal)`
  h2 {
    font-size: 1.25rem;
  }
  h5 {
    font-size: 11px;
  }
  p {
    font-size: 14px;
    &.multi {
      color: var(--s30);
    }
    &.extraCount {
      color: #000000de;
      padding-left: 2rem;
      margin-top: 1rem;
    }
  }
  h4 {
    font-weight: 400;
  }
  .highlight-green {
    color: #309966;
  }

  th {
    &:first-of-type {
      padding-left: 1.5rem !important;
    }
    &:last-of-type {
      padding-right: 1.5rem !important;
    }
  }

  td {
    &:first-of-type {
      padding-left: 1.5rem !important;
    }
    &:last-of-type {
      padding-right: 1.5rem !important;
    }
  }
`;

const AllocationReceivedModal = ({ isModalOpen, handleClose, rows = [] }: Props) => {
  const navigate = useNavigate();

  const columns = [
    {
      id: 'fund',
      label: 'Fund Name',

      width: '35%',
    },
    {
      id: 'investor',
      label: 'Investor Name',

      width: '30%',
    },
    {
      id: 'amount_requested',
      label: 'Allocation Requested',
      needsFormatter: true,
      width: '15%',
      align: 'right' as const,
    },
    {
      id: 'amount',
      label: 'Allocation Received',

      width: '15%',
      align: 'right' as const,
      cellClassName: 'highlight-green',
    },
  ];

  return (
    <StyledModal
      maxWidth={rows.length > 1 ? 'md' : 'xs'}
      title='Allocations Received!'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      className={'new-alloc'}
    >
      <Grid container>
        <Grid item xs={12} mb={2.5}>
          <p className={`mb-0 ${rows.length > 1 ? 'multi' : ''}`}>
            Congratulations - you have received allocations against your order
            {rows.length > 1 ? 's' : ''}. Click on 'View {rows.length > 1 ? 'Orders' : 'Details'}'
            to see details!
          </p>
        </Grid>
        {rows.length > 1 ? (
          <>
            <Grid item xs={12} mb={2.5}>
              <MuiTable
                rows={rows.slice(0, 4)}
                columns={columns}
                canRedirect
                redirectURLPrefix='order/'
                redirectURLKey='order_id'
                onRowClick={handleClose}
                rowClassName={'cursor-pointer'}
                appendText={rows.length > 4 ? `+ ${rows.length - 4} more` : ''}
              />
            </Grid>
            <Grid item xs={12} className='flex-center-end'>
              <MuiButton
                variant='contained'
                minWidth='150px'
                buttonClick={() => {
                  navigate('/orders');
                  handleClose();
                }}
              >
                View Orders <CircleArrowIcon className='ml-2' />
              </MuiButton>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} className='mb-2'>
              <h5 className='subtitle'>Fund Name</h5>
              <h4>{rows[0]['fund'] || '--'}</h4>
            </Grid>
            <Grid item xs={12} className='mb-2'>
              <h5 className='subtitle'>Investor name</h5>
              <h4>{rows[0]['investor'] || '--'}</h4>
            </Grid>
            <Grid item xs={6} className='mb-2'>
              <h5 className='subtitle'>Allocation received</h5>
              <h4 className='highlight-green'>{rows[0]['amount'] || '--'}</h4>
            </Grid>
            <Grid item xs={6} className='mb-2'>
              <h5 className='subtitle'>Allocation requested</h5>
              <h4>
                {rows[0]['amount_requested']
                  ? '$' + Number(rows[0]['amount_requested']).toLocaleString()
                  : '--'}
              </h4>
            </Grid>
            <Grid item xs={6}>
              <h5 className='subtitle'>Event Date</h5>
              <h4>
                {rows[0]['created_date']
                  ? format(new Date(rows[0]['created_date']), 'MMM-dd-yyy')
                  : '--'}
              </h4>
            </Grid>
            <Grid item xs={12} className='flex-center-end'>
              <MuiButton
                variant='contained'
                minWidth='150px'
                buttonClick={() => {
                  navigate(`/order/${rows[0]['order_id']}`);
                  handleClose();
                }}
              >
                View Details
              </MuiButton>
            </Grid>
          </>
        )}
      </Grid>
    </StyledModal>
  );
};

export default AllocationReceivedModal;
