import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import SVG1 from 'common/assets/images/NewAllocRequest1.svg';
import SVG2 from 'common/assets/images/NewAllocRequest2.svg';
import SVG3 from 'common/assets/images/NewAllocRequest3.svg';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { ArrowForward } from '@mui/icons-material';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  buttonClick?: any;
  disableCTA?: boolean;
};

const StyledModal = styled(MuiModal)`
  p {
    font-size: 13px;
    text-align: center;
    &.desc {
      font-size: 14px;
      text-align: start;
    }
  }

  .arrow {
    font-size: 1.75rem;
    color: var(--s30);
    margin-bottom: 2rem;
  }
`;

const NewOrderDetailsModal = ({ isModalOpen, handleClose, buttonClick, disableCTA }: Props) => {
  return (
    <StyledModal
      title='New Order - How It Works'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='sm'
      className='new-alloc'
    >
      <Grid container>
        <p className='desc'>
          This is an evergreen fund that accepts subscriptions on an ongoing basis. Follow these
          steps to place a subscription in the fund.
        </p>
      </Grid>
      <Grid container columns={11} columnSpacing={2} alignItems='center' sx={{ mt: 5, mb: 6 }}>
        <Grid item xs={3} container className='flex-col flex-center-center'>
          <img src={SVG1} alt='' className='mb-3' />
          <p>Submit an order to the Fund through Tifin Private Markets</p>
        </Grid>
        <Grid item xs={1} className='flex-center-center'>
          <ArrowForward className='arrow' />
        </Grid>
        <Grid item xs={3} container className='flex-col flex-center-center'>
          <img src={SVG2} alt='' className='mb-3' />
          <p>Fund Manager reviews and approves your order</p>
        </Grid>
        <Grid item xs={1} className='flex-center-center'>
          <ArrowForward className='arrow' />
        </Grid>
        <Grid item xs={3} container className='flex-col flex-center-center'>
          <img src={SVG3} alt='' className='mb-3' />
          <p>You are notified through the platform when order is accepted</p>
        </Grid>
      </Grid>

      {!disableCTA && (
        <Grid container alignItems='center' justifyContent='flex-end'>
          <MuiButton variant='contained' buttonClick={buttonClick} minWidth='150px'>
            Start New Order
          </MuiButton>
        </Grid>
      )}
    </StyledModal>
  );
};

export default NewOrderDetailsModal;
