export const COLUMNS = [
  {
    id: 'dateFormatted',
    label: 'Month',
    width: '50%',
  },
  {
    id: 'Return',
    label: 'Return',
    width: '50%',
  },
];

export enum MONTHS {
  jan = 1,
  feb = 2,
  mar = 3,
  apr = 4,
  may = 5,
  jun = 6,
  jul = 7,
  aug = 8,
  sep = 9,
  oct = 10,
  nov = 11,
  dec = 12,
}

export const MANDATORY_FIELDS = ['Month', 'Year', 'Return'];
