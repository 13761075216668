import { Container, Grid } from '@mui/material';
import MuiCard from 'common/components/card';
import MuiSkeleton from 'common/components/skeleton/Skeleton';
import { StyledHero } from '../Hero';

function HeroSkeletonLoader() {
  return (
    <StyledHero>
      <Container maxWidth='xl'>
        <Grid container alignItems='center' justifyContent='space-between' rowGap={2}>
          <Grid item xs='auto'>
            <MuiSkeleton width={292} height={245} variant='rectangular' />
          </Grid>
          <Grid item sx={{ width: 648 }} container columnSpacing={4}>
            <Grid item xs={6}>
              <MuiCard minHeight='0' sx={{ px: 2, py: '12px', mb: 4 }}>
                <MuiSkeleton width='100%' height={106} variant='rectangular' />
              </MuiCard>
              <MuiCard minHeight='0' sx={{ px: 2, py: '12px' }}>
                <MuiSkeleton width='100%' height={106} variant='rectangular' />
              </MuiCard>
            </Grid>
            <Grid item xs={6} className='d-flex'>
              <MuiCard minHeight='0' sx={{ px: 2, pt: '12px', pb: '13px', width: '100%' }}>
                <MuiSkeleton width='100%' height={245} variant='rectangular' />
              </MuiCard>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </StyledHero>
  );
}

export default HeroSkeletonLoader;
