import styled from '@emotion/styled';
import { formatAumValues } from 'helpers';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';

const StyledAreaChart = styled(AreaChart)`
  border: 1px solid var(--s20);
  border-radius: 0.5rem;
  overflow: hidden;

  .custom-tooltip {
    background-color: white;
    padding: 0.5rem;
    min-width: 100px;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    h4 {
      font-size: 13px;
      font-weight: 500;
      color: var(--s30);
      span {
        color: var(--p200);
      }
    }
    h5 {
      color: var(--s30);
    }
  }
`;

const StyledAreaChartX = styled(AreaChart)`
  .recharts-xAxis {
    text {
      fill: var(--s30);
      font-weight: 600;
    }

    .recharts-cartesian-axis-tick {
      transform: translateX(-20px);
    }
    .recharts-cartesian-axis-tick:first-of-type {
      transform: translateX(-5px);
    }
    .recharts-cartesian-axis-tick:last-of-type {
      transform: translateX(-40px);
    }
    .recharts-label {
      fill: var(--s40) !important;
      font-size: 13px;
    }
  }

  .recharts-yAxis {
    text {
      fill: var(--s30);
      font-weight: 600;
    }
    .recharts-label {
      fill: var(--s40) !important;
      font-size: 13px;
    }
  }
`;

interface Props {
  data?: Array<any>;
}

const LiquidityImpact = ({ data }: Props) => {
  const domainStart = data && data.length > 0 ? data[data.length - 1].withAlts : 'dataMin';
  const domainEnd = data && data.length > 0 ? data[0].withoutAlts : 'dataMax';

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <h5 className='font-wt-400 mb-2'>
            Without Private Markets: ${payload[0].value.toLocaleString()}
          </h5>
          <h4 className='mb-0'>
            With Private Markets: <span>${payload[1].value.toLocaleString()}</span>
          </h4>
        </div>
      );
    }

    return null;
  };

  const yAxisTicks = () => {
    const start = Math.floor(Number(domainStart) / 1000000) * 1000000;
    const end = Math.ceil(Number(domainEnd) / 1000000) * 1000000;
    let val = Number(start);
    let arr = [];
    while (val <= end) {
      arr.push(val);
      val += 1000000;
    }
    return arr;
  };

  return (
    <>
      <div className='d-flex'>
        <ResponsiveContainer width={'7%'} height={280}>
          <StyledAreaChartX width={600} height={215} data={data} margin={{ top: 0, left: 10 }}>
            <XAxis fontSize={13} dataKey='fall' hide stroke='#bdbdbd' />
            <YAxis
              fontSize={13}
              dx={5}
              axisLine={false}
              tickLine={false}
              tick
              dataKey='withoutAlts'
              ticks={yAxisTicks()}
              domain={[domainStart, domainEnd]}
              label={{ value: 'Liquidity Available to Client', dx: -30, angle: -90 }}
              tickFormatter={(val: any, i: number) => {
                return formatAumValues(val, 0);
              }}
              padding={{ top: 30 }}
            />
            <Area
              // stackId={1}
              type='monotone'
              dataKey='withoutAlts'
              stroke='#FF934F'
              strokeWidth={1.5}
              opacity={0}
            />
            <Area
              // stackId={1}
              type='monotone'
              dataKey='withAlts'
              stroke='#FF934F'
              strokeWidth={1.5}
              opacity={0}
            />
          </StyledAreaChartX>
        </ResponsiveContainer>

        <ResponsiveContainer width={'93%'} height={280}>
          <StyledAreaChart width={600} height={215} data={data} margin={{ top: 0, left: 0 }}>
            <XAxis
              fontSize={13}
              dataKey='fall'
              stroke='#bdbdbd'
              tickLine={false}
              dy={10}
              hide
              tickFormatter={(val: any, i: number) => {
                return val;
              }}
            />
            <YAxis
              hide
              fontSize={13}
              dx={20}
              axisLine={false}
              tickLine={false}
              tick
              dataKey='withoutAlts'
              ticks={yAxisTicks()}
              domain={[domainStart, domainEnd]}
              label={{ value: 'Liquidity Available to Client', dx: -20, angle: -90 }}
              tickFormatter={(val: any, i: number) => {
                return formatAumValues(val, 4);
              }}
              padding={{ top: 30 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type='monotone'
              dot={false}
              dataKey='withoutAlts'
              stroke='var(--s20)'
              fill='#dce8f2'
              fillOpacity={1}
              strokeWidth={2}
            />
            <Area
              fillOpacity={1}
              type='monotone'
              stroke='var(--p150)'
              fill='#fff'
              dot={false}
              dataKey='withAlts'
              strokeWidth={2}
            />
          </StyledAreaChart>
        </ResponsiveContainer>
      </div>

      {/* TO SHOW THE X AXIS BELOW THE STYLED CHART */}
      <ResponsiveContainer width={'94%'} height={50} className='ml-auto'>
        <StyledAreaChartX width={600} height={10} data={data} margin={{ top: 0, left: 0 }}>
          <XAxis
            fontSize={13}
            dataKey='fall'
            domain={['dataMin', 'dataMax+5']}
            stroke='var(--s40)'
            tickLine={false}
            axisLine={false}
            interval={0}
            dy={-15}
            dx={20}
            label={{ value: 'Forecasted Market Drop', dy: 5 }}
            tickFormatter={(val: any, i: number) => {
              return +val.toFixed(2) + '%';
            }}
          />
          <Area hide type='monotone' dataKey='withoutAlts' stroke='var(--p150)' strokeWidth={1.5} />
          <Area hide type='monotone' dataKey='withAlts' stroke='var(--s20)' strokeWidth={1.5} />
        </StyledAreaChartX>
      </ResponsiveContainer>
    </>
  );
};

export default LiquidityImpact;
