import MuiButton from 'common/components/button';
import { EntityType } from 'common/types';
import HoldingsModal from 'components/modals/holdings/HoldingsModal';
import UploadHoldingsError from 'components/modals/holdings/UploadHoldingsError';
import { handleTemplateDownload } from 'components/modals/holdings/utils';
import { canDownloadHoldings, canUploadHoldings } from 'helpers/roles';
import { useState } from 'react';
import { useAppSelector, useGraphQLQuery } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { utils, writeFile } from 'xlsx';
import { GET_HOLDINGS } from './queries';
import { format } from 'date-fns';

const HoldingsDownloadUpload = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const userProfile: any = useAppSelector(selectUserProfile);
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isDGUser = user?.category === EntityType.DEALERS_GROUP;

  const { refetch, isLoading } = useGraphQLQuery(
    `getHoldings`,
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_HOLDINGS,
      variables: isDGUser ? { organisationId: user.organisationId } : null,
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if (data?.getHoldings.length > 0) {
          const fileName = 'Holdings Template.xlsx';
          const updatedData = data.getHoldings.map((holding: any) => ({
            Investor_Id: holding.investor_id,
            Investor_Name: holding.investor_name,
            Fund_Id: holding.fund_id_simple,
            Fund_Name: holding.fund_name,
            Current_Market_Value_US$: holding.current_value,
            Subscription_US$: holding.subscription,
            Redemption_US$: holding.redemption,
            Income_US$: holding.income,
            ROC_US$: holding.roc,
            As_Of_Date: format(new Date(holding.as_of), 'MMM-dd-yyyy'),
          }));
          const ws = utils.json_to_sheet(updatedData);
          const wb = utils.book_new();
          utils.book_append_sheet(wb, ws, 'Sheet1');
          writeFile(wb, fileName);
        } else {
          handleTemplateDownload();
        }
      },
    },
  );

  return (
    <div className='d-flex'>
      {user && canUploadHoldings(user) && (
        <MuiButton buttonClick={() => setIsModalOpen(true)}>Upload Holdings</MuiButton>
      )}
      {user && canDownloadHoldings(user) && (
        <MuiButton buttonClick={() => refetch()} disabled={isLoading} className='ml-3'>
          {isLoading ? 'Loading...' : 'Download Holdings'}
        </MuiButton>
      )}
      <HoldingsModal
        isModalOpen={isModalOpen}
        handleModalClick={() => {
          setIsModalOpen(false);
          setSelectedFile(null);
        }}
        retryUpload={() => setIsModalOpen(true)}
        onFileUpload={() => setIsModalOpen(false)}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        setUploadError={setUploadError}
        uploadError={uploadError}
      />
      <UploadHoldingsError
        isModalOpen={uploadError}
        handleClose={() => {
          setUploadError(false);
          setSelectedFile(null);
        }}
        fileName={selectedFile?.name}
        buttonClick={() => {
          setSelectedFile(null);
          setIsModalOpen(true);
          setUploadError(false);
        }}
      />
    </div>
  );
};

export default HoldingsDownloadUpload;
