import { Action, ResponseGenerator } from 'common/types';
import { getBehalfOf, getUserAuth } from 'helpers';
import { RegistrationHandler } from 'helpers/registeration';
import { requestPasswordReset, resetPassword } from 'helpers/resetPassword';
import { call, put, takeLatest } from 'redux-saga/effects';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import {
  initiateAuthFetch,
  setAuth,
  setAuthFailed,
  setBehalfOf,
  setForgotPasswordActiveStepper,
} from './slice';
import { AuthUser, UserProfile } from './types';

function* initAuth() {
  yield put(initiateAuthFetch());
  try {
    const auth: AuthUser = yield getUserAuth();
    if (!auth) yield put(setAuthFailed());
    else {
      yield put(setAuth(auth));
      if (auth.userDetailsResponseDTO?.username) {
        const username = auth.userDetailsResponseDTO.username;
        yield put({ type: 'FETCH_USER_PROFILE', payload: username });
      }
      const behalfOf: UserProfile = yield getBehalfOf();
      if (behalfOf) {
        yield put(setBehalfOf(behalfOf));
      }
    }
  } catch (error) {
    const toast: ToastMessage = {
      type: ToastType.ERROR,
      message: 'Something went wrong. Please try again.',
    };

    yield put(showToast(toast));
  }
}

function* userRegistration(action: Action) {
  yield put(initiateAuthFetch());
  try {
    const { username, tempPassword, newPassword, confirmPassword } = action.payload;
    yield call(RegistrationHandler, {
      username,
      password: tempPassword,
      newPassword,
      confirmPassword,
    } as {
      username: string;
      password: string;
      newPassword: string;
      confirmPassword: string;
    });
  } catch (error) {
    console.log(error);
    const toast: ToastMessage = {
      type: ToastType.ERROR,
      message: 'An error occured. Please try again later.',
    };
    yield put(showToast(toast));
  }
}

function* sendPasswordResetRequest(action: Action) {
  try {
    const { username } = action.payload;
    const response: ResponseGenerator = yield call(requestPasswordReset, username);
    if (response?.data?.error) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: response?.data?.error,
      };
      yield put(showToast(toast));
    } else {
      const toast: ToastMessage = {
        type: ToastType.SUCCESS,
        message: 'Password reset email has been sent successfully.',
      };
      yield put(showToast(toast));
      yield put(setForgotPasswordActiveStepper(1));
    }
  } catch (error) {
    console.log('Opps something went wrong!!', error);
  }
}
function* doResetPassword(action: Action) {
  try {
    const response: ResponseGenerator = yield call(resetPassword, action.payload);
    if (response?.data?.error) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Oops! Something went wrong. Please try again.',
      };

      yield put(showToast(toast));
    } else {
      const toast: ToastMessage = {
        type: ToastType.SUCCESS,
        message: 'Password reset successful. Login with new password.',
      };

      yield put(showToast(toast));
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
    }
  } catch (error) {
    const toast: ToastMessage = {
      type: ToastType.ERROR,
      message:
        'Unable to reset password. Please make sure that you have entered the correct confirmation code.',
    };

    yield put(showToast(toast));
    console.log('Opps something went wrong!!', error);
  }
}

function* doLogout() {
  yield [
    localStorage.removeItem('currentUser'),
    localStorage.removeItem('currentUser'),
    localStorage.removeItem('loginType'),
    localStorage.removeItem('onBehalfOf'),
    localStorage.removeItem('loginType'),
    localStorage.removeItem('isNotificationModalOpen'),
    localStorage.clear(),
    sessionStorage.clear(),
  ];
  yield put(setAuth(null));
  window.location.href = '/';
}

export default function* authSaga() {
  yield takeLatest('INIT_AUTH', initAuth);
  yield takeLatest('USER_REGISTRATION', userRegistration);
  yield takeLatest('SEND_PASSWORD_RESET_REQUEST', sendPasswordResetRequest);
  yield takeLatest('RESET_PASSWORD', doResetPassword);
  yield takeLatest('LOGOUT_ATTEMPT', doLogout);
}
