import FormControl from '@mui/material/FormControl';
import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField/TextField';
import { useState } from 'react';

export type AllTextFieldProps = TextFieldProps & {
  onChange?: any;
  register?: any;
  errorMessage?: string;
  control: any;
  startAdornment?: any;
  valueAsNumber?: boolean;
  minLength?: number;
  maxLength?: number;
  name: string;
  passwordPeek?: boolean;
  pattern?: any;
  keyDownFunc?: (e: any) => void;
  keyPressFunc?: (e: any) => void;
  onWheel?: any;
};

const StyledText = styled(TextField)`
  margin-top: 0.5rem;
  &.startAdornment {
    .MuiOutlinedInput-input {
      padding: 11px 18px 11px 0 !important;
    }
  }

  .MuiOutlinedInput-root {
    background-color: var(--s7);
  }

  .MuiOutlinedInput-input {
    padding: 11px 18px;
    font-size: 13px;
  }

  fieldset {
    border-radius: 0.5rem;
    border-color: var(--s7);
  }

  textarea {
    padding: 0 !important;
  }

  &:hover .MuiOutlinedInput-notchedOutline,
  &:active .MuiOutlinedInput-notchedOutline {
    border-color: #b3d1ed;
  }

  &.admin {
    background-color: white;
    border-radius: 0.25rem;
    margin-top: unset;
    input {
      font-size: 11px;
      color: #525863;
      &::placeholder {
        opacity: 1;
      }
    }
    fieldset {
      border-color: transparent;
      border-radius: 0.25rem;
    }
  }
`;

export default function ControlledTextBox({
  onChange,
  label,
  placeholder,
  value,
  defaultValue,
  variant,
  select,
  disabled,
  size,
  margin,
  name,
  type,
  fullWidth,
  multiline,
  startAdornment,
  register = () => {},
  errorMessage,
  className = '',
  control,
  valueAsNumber = false,
  rows = 1,
  minLength,
  maxLength,
  passwordPeek = false,
  pattern,
  onBlur,
  keyDownFunc,
  keyPressFunc,
  onWheel,
}: AllTextFieldProps) {
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  return (
    <>
      {label && <h4 className='font-wt-400 mb-2'>{label}</h4>}
      <FormControl fullWidth>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue && defaultValue}
          rules={{ required: errorMessage }}
          render={({ field }) => (
            <StyledText
              {...field}
              placeholder={placeholder}
              variant={variant}
              type={passwordPeek ? (passwordVisibility ? 'text' : 'password') : type}
              disabled={disabled}
              size={size}
              margin={margin}
              fullWidth={fullWidth}
              multiline={multiline}
              className={`${className} ${startAdornment ? 'startAdornment' : ''}`}
              rows={rows}
              onKeyPress={(event) => {
                keyDownFunc && keyDownFunc(event);
              }}
              onKeyDown={(event) => {
                keyPressFunc && keyPressFunc(event);
              }}
              inputProps={{ maxLength: maxLength }}
              InputProps={{
                startAdornment: startAdornment && (
                  <InputAdornment position='start'>{startAdornment}</InputAdornment>
                ),
                endAdornment: passwordPeek && (
                  <InputAdornment position='end'>
                    {passwordVisibility ? (
                      <Visibility
                        className='cursor-pointer'
                        onClick={() => setPasswordVisibility(false)}
                      />
                    ) : (
                      <VisibilityOff
                        className='cursor-pointer'
                        onClick={() => setPasswordVisibility(true)}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </>
  );
}
