import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { initialState } from "./slice";

const investmentSlice = (state: RootState) => state.investment || initialState;

export const
    selectIsListView = createSelector([investmentSlice], (state) => state.isListView);

export const
    selectActiveSort = createSelector([investmentSlice], (state) => state.activeSort);

export const
    selectPage = createSelector([investmentSlice], (state) => state.page);

export const
    selectSearchQuery = createSelector([investmentSlice], (state) => state.searchQuery);

export const
    selectDefaultFilters = createSelector([investmentSlice], (state) => state.defaultFilters);

export const
    selectCurrentFilters = createSelector([investmentSlice], (state) => state.currentFilters);

