import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import MuiButton from 'common/components/button';
import OrderStatusBar from 'common/components/orderStatusBar';
import { useEffect } from 'react';
import { useAppSelector, useGraphQLQuery, useMutationQuery, useTypedDispatch } from 'store/hooks';
import { ReactComponent as DeleteIcon } from 'common/assets/images/recommendation/Delete.svg';
import { selectFundsToCompare } from 'store/recommendation/selectors';
import { setFundsToCompare } from 'store/recommendation/slice';
import { useNavigate } from 'react-router-dom';
import { GET_FUND_DETAILS } from '../queries';

type Props = {
  confidenceScore: number;
  id: string;
  name: string;
};

const StyledCard = styled.div`
  padding: 1.25rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--s15);
  cursor: pointer;

  img,
  .MuiSkeleton-root {
    width: 88px;
    height: 40px;
    margin-bottom: 0.5rem;
    object-fit: contain;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 1.8125rem;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .MuiLinearProgress-root {
    width: 250px;
    margin-right: 0.5rem;
  }

  h6 {
    font-size: 0.75rem;
    font-weight: 400;
    color: #101828;
    margin-bottom: 0;
  }

  .delete {
    width: 36.5px;
    height: 36.5px;
    box-sizing: border-box;
    display: grid;
    place-items: center;
    border-radius: 6px;
    border: 1px solid var(--s15);
    margin-right: 0.75rem;
    cursor: pointer;
  }
`;

const FundCard = ({ confidenceScore, id, name }: Props) => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const fundsToCompare = useAppSelector(selectFundsToCompare);

  const { data: { getFundDetails: fundDetailsData } = {} } = useGraphQLQuery(
    ['getFundDetails', id],
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_FUND_DETAILS,
      variables: {
        fundId: `${id}`,
      },
    },
  );

  const { mutate, data: fundLogo } = useMutationQuery({
    url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
    params: { object_key: fundDetailsData?.fundDetails?.logo },
  });

  useEffect(() => {
    if (fundDetailsData?.fundDetails?.logo) mutate();
    // eslint-disable-next-line
  }, [fundDetailsData]);

  const handleOnDeleteClick = (e: any) => {
    e.stopPropagation();
    const fundIds = fundsToCompare?.length > 0 ? [...fundsToCompare] : [];
    const filteredFunds = fundIds.filter((fundId) => fundId !== id);
    dispatch(setFundsToCompare({ fundsToCompare: filteredFunds }));
  };
  return (
    <StyledCard onClick={() => navigate(`/investment/${id}`)}>
      {fundLogo ? <img src={fundLogo?.url} alt='' /> : <Skeleton variant='rectangular' />}
      <h4>{name}</h4>
      <section>
        <div>
          <h5 className='mb-2'>Confidence Score</h5>
          <div className='flex-center-between'>
            <OrderStatusBar value={confidenceScore} />
            <h6>{confidenceScore}%</h6>
          </div>
        </div>
        <div className='flex-center-end'>
          <div onClick={handleOnDeleteClick} className='delete'>
            <DeleteIcon />
          </div>
          <MuiButton
            buttonClick={(e) => {
              e.stopPropagation();
              navigate('/create-order', {
                state: {
                  fundDetails: { fundName: name, fundsId: id },
                },
              });
            }}
            variant='contained'
          >
            Add Order
          </MuiButton>
        </div>
      </section>
    </StyledCard>
  );
};

export default FundCard;
