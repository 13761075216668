import Button from '@mui/material/Button/Button';

type Props = {
  onFileUpload: any;
  children: any;
  disable?: boolean;
  error?: boolean;
  className?: string;
  variant?: 'text' | 'contained' | 'outlined' | undefined;
  accept?: string;
  id?: string;
};

const MuiUpload = ({
  onFileUpload,
  children,
  disable,
  className = '',
  error,
  variant = 'text',
  accept,
  id = 'contained-button-file',
}: Props) => {
  return (
    <>
      <input id={id} hidden onChange={onFileUpload} type='file' accept={accept} />
      <label htmlFor={id} style={{ pointerEvents: disable ? 'none' : 'initial' }}>
        <Button
          variant={variant}
          component='span'
          color={error ? 'error' : 'primary'}
          disableElevation
          disableRipple
          className={className}
          disabled={disable}
          sx={{ textTransform: 'none', color: 'var(--p300)' }}
        >
          {children}
        </Button>
      </label>
    </>
  );
};

export default MuiUpload;
