import { Grid, SelectChangeEvent, Skeleton, Switch } from '@mui/material';
import MuiButton from 'common/components/button';
import Textbox from 'common/components/inputField/Textbox';
import MuiUpload from 'common/components/inputField/Upload';
import React, { useEffect, useState, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { selectFundsDetails, selectFundDocuments, selectFundStatus } from 'store/funds/selectors';
import {
  setFundsDetails,
  setFundStructAndStats,
  setFundTermsAndServices,
  setFundPriceMonthlyReturns,
  setFundPriceAnnualReturns,
  setFundPriceInceptionReturns,
  setFundDocuments,
  setNewFundID,
  setFundPriceDetails,
  setFundStatus as setStoreFundStatus,
} from 'store/funds/slice';
import { useReactQuery, useAppSelector, useTypedDispatch, useMutationQuery } from 'store/hooks';
import { ErrorMessage } from 'common/components/errorMessageBox';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MultipleSelectField from 'common/components/inputField/MultipleSelectField';
import ControlledSelectField from 'common/components/inputField/ControlledSelectField';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import RichTextEditor from 'common/components/richTextEditor/RichEditor';

import GroupSelectField from 'common/components/inputField/GroupSelectField';
import statusOptions from './statusOptions.json';
import FundLogoPlaceholder from 'common/assets/images/FundLogoPlaceholder.svg';
import { valueFormatter, regexValidator } from 'helpers';

type Props = {
  handleSubmitBtnClick: any;
  editing?: boolean;
  status?: string;
};

const InvestmentDetialsForm = ({ handleSubmitBtnClick, editing, status }: Props) => {
  const [selectedAssetClass, setSelectedAssetClass] = useState<any>('');
  const [selectedAssetClassArr, setSelectedAssetClassArr] = useState<string[]>([]);
  const [selectedTheme, setSelectedTheme] = useState<any>('');
  const [selectedThemeArr, setSelectedThemeArr] = useState<string[]>([]);
  const [logoName, setLogoName] = useState<string>('');
  const [logoFile, setLogoFile] = useState<any>(null);
  const logoImage = useRef<HTMLImageElement>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [strategyDescription, setStrategyDescription] = useState(() => EditorState.createEmpty());
  const [strategyDescriptionText, setStrategyDescriptionText] = useState<string>('');
  const [allowDGPermissions, setAllowDGPermissions] = useState<boolean>(true);
  // error states
  const [strategyDescriptionError, setStrategyDescriptionError] = useState<boolean>(false);
  const [logoError, setLogoError] = useState<boolean>(false);
  const [fund, setFundStatus] = useState<string>('');
  const [minInvestment, setMinInvestment] = useState<any>('');
  const navigate = useNavigate();

  const storeFundDetails = useAppSelector(selectFundsDetails);
  const storeFundDocuments = useAppSelector(selectFundDocuments);
  const fundStatusFromStore = useAppSelector(selectFundStatus);
  const dispatch = useTypedDispatch();
  const [fundStatusError, setFundStatusError] = useState<boolean>(false);

  // Edit Funds state
  const location = useLocation();
  const id = location.pathname.split('/')[3];
  const { data } = useReactQuery(
    [`fundDetails-${id}`],
    {
      url: `qaip/v1/fundsmanagement/funds/id/${id}`,
    },
    {
      enabled: editing,
    },
  );

  const { data: documentsData } = useReactQuery(
    [`documents-${id}`],
    {
      url: `qaip/v1/documentmanagement/getList/fund?fund_id=${id}&document_type=file`,
    },
    {
      enabled: editing,
    },
  );

  const { data: allPrice } = useReactQuery(
    [`allPrices-${id}`],
    {
      url: `qaip/v1/holdingsmanagement/allprices/fund/${id}`,
    },
    {
      enabled: editing,
      refetchOnMount: true,
    },
  );

  const fundDetails = data?.fund_details;
  const monthlyReturns = data?.monthly_returns;
  const annualReturns = data?.annual_returns;
  const inceptionReturns = data?.returns_since_inception;
  const fundStats = data?.fund_stats;
  const isAllocationWorkflowEnabled =
    fundDetails?.isAllocationWorkflowEnabled === 'no' ? false : true;

  useEffect(() => {
    if (!storeFundDocuments && documentsData) {
      setFundDocumentsData();
    }
    // eslint-disable-next-line
  }, [documentsData]);

  useEffect(() => {
    const stratText = strategyDescription.getCurrentContent().getPlainText();
    setStrategyDescriptionText(stratText);
  }, [strategyDescription]);

  const setFundDocumentsData = async () => {
    await dispatch(setFundDocuments(documentsData));
    await dispatch(setNewFundID(id));
  };

  useEffect(() => {
    if (allPrice) {
      setAllPricesData();
    }
    // eslint-disable-next-line
  }, [allPrice]);

  const setAllPricesData = async () => {
    await dispatch(setFundPriceDetails(allPrice));
  };

  //* THIS IS THE USE=EFFECT TO SETUP INFORMARION FROM BACKEND
  useEffect(() => {
    if (data) {
      setSelectedAssetClass(fundDetails.asset_class);
      setSelectedAssetClassArr(fundDetails['asset_class'].split(','));
      setSelectedTheme(fundDetails?.fund_themes);
      setChecked(fundDetails.isAllocationWorkflowEnabled?.toLowerCase() === 'yes' ? true : false);
      setAllowDGPermissions(!!fundDetails?.add_to_all_dg);
      setStrategyDescription(
        EditorState.createWithContent(stateFromHTML(fundDetails.strategy_description)),
      );
      fundDetails['fund_themes'] && setSelectedThemeArr(fundDetails['fund_themes'].split(','));
      setLogoName(fundDetails.logo);
      setValue('iso_currency', fundDetails.iso_currency);
      setValue('class', fundDetails.class);
      setValue('eligibility', fundDetails.eligibility);
      setValue('strategy', fundDetails.strategy);
      setValue('rating', fundDetails.rating);
      setValue('fund_manager_id', fundDetails.fund_manager_id);
      setValue('fund_manager_title', fundDetails.fund_manager_title);
      setValue('strategy_description', fundDetails.strategy_description);
      setValue('fund_manager_contact', fundDetails.fund_manager_contact);
      setValue('fund_manager_phone', fundDetails.fund_manager_phone);
      setValue('fund_manager_email', fundDetails.fund_manager_email);
      setValue('fund_name', fundDetails.fund_name);
      setValue('sub_strategy', fundDetails.sub_strategy);
      setValue('final_close', fundDetails.final_close);
      setValue('min_investment', fundDetails.min_investment);
      setValue('fund_video', fundDetails.fund_video);
      setValue('tag_line', fundDetails.tag_line);
      setValue('fund_type', fundDetails.fund_type);

      setMinInvestment(fundDetails.min_investment);

      mutate();

      // Invesrment struct and stats form
      setStoreFundStructAndStats();

      // Investment Terms and services form
      setStoreFundTSP();

      // Investment historical returns form
      setInvestmentHistoricalReturns();
    }
    // eslint-disable-next-line
  }, [data]);

  const setStoreFundStructAndStats = async () => {
    const storeFundStructAndStats = {
      liquidity_for_summary: fundDetails.liquidity_for_summary,
      lockup_for_summary: fundDetails.lockup_for_summary,
      funding_type: fundDetails.funding_type,
      income_distribution: fundDetails.income_distribution,
      funds_transfer_due_date: fundDetails.funds_transfer_due_date,
      fund_assets: fundDetails.fund_assets,
      firm_assets: fundDetails.firm_assets,
      final_close: fundDetails.final_close,
      management_fees: fundDetails.management_fees,
      preferred_return: fundDetails.preferred_return,
      gp_catch_up_term: fundDetails.gp_catch_up_term,
      incentives_fees: fundDetails.incentives_fees,
      investment_period: fundDetails.investment_period,
      fund_term: fundDetails.fund_term,
      redemptions: fundDetails.redemptions,
      lockup: fundDetails.lockup,
      bank_details: fundDetails.bank_details,
      compounded_rate_of_return: fundDetails.compounded_rate_of_return,
      standard_deviation: fundDetails.standard_deviation,
      sharpe_ratio: fundDetails.sharpe_ratio,
      profitable_months_percentage: fundDetails.profitable_months_percentage,
      maximum_drawdown: fundDetails.maximum_drawdown,
      next_close: fundDetails.next_close,
      s_and_p_500: fundDetails.s_and_p_500,
      target_fund_size: fundDetails.target_fund_size,
      cash_yield: fundDetails.cash_yield,
      target_irr: fundDetails.target_irr,
      impact_score: fundDetails.impact_score,
      fund_stats: fundStats ? fundStats : [{}],
    };

    dispatch(setFundStructAndStats(storeFundStructAndStats));
  };

  const setStoreFundTSP = async () => {
    const storeFundTSP = {
      auditor: fundDetails.auditor,
      administrator: fundDetails.administrator,
      fund_counsel: fundDetails.fund_counsel,
      prime_broker: fundDetails.prime_broker,
      disclaimer: fundDetails.disclaimer,
      managers_bio: fundDetails.managers_bio,
    };

    await dispatch(setFundTermsAndServices(storeFundTSP));
  };

  const setInvestmentHistoricalReturns = async () => {
    await dispatch(setFundPriceMonthlyReturns(monthlyReturns));
    await dispatch(setFundPriceAnnualReturns(annualReturns));
    await dispatch(setFundPriceInceptionReturns(inceptionReturns));
  };

  //* THIS IS THE USE=EFFECT TO SETUP INFORMARION FROM REDUX
  useEffect(() => {
    if (storeFundDetails) {
      setSelectedAssetClass(storeFundDetails.asset_class);
      setSelectedAssetClassArr(storeFundDetails['asset_class'].split(','));
      setSelectedTheme(storeFundDetails.fund_themes);
      setSelectedThemeArr(storeFundDetails['fund_themes'].split(','));
      setStrategyDescription(
        EditorState.createWithContent(stateFromHTML(storeFundDetails.strategy_description)),
      );
      !editing && setLogoName(storeFundDetails.logo_file.name);
      setValue('iso_currency', storeFundDetails.iso_currency);
      setValue('class', storeFundDetails.class);
      setValue('eligibility', storeFundDetails.eligibility);
      setValue('strategy', storeFundDetails.strategy);
      setValue('rating', storeFundDetails.rating);

      setValue('fund_manager_id', storeFundDetails.fund_manager_id);
      setValue('fund_manager_title', storeFundDetails.fund_manager_title);
      setValue('strategy_description', storeFundDetails.strategy_description);
      setValue('fund_manager_contact', storeFundDetails.fund_manager_contact);
      setValue('fund_manager_phone', storeFundDetails.fund_manager_phone);
      setValue('fund_manager_email', storeFundDetails.fund_manager_email);
      setValue('fund_name', storeFundDetails.fund_name);
      setValue('sub_strategy', storeFundDetails.sub_strategy);
      setValue('final_close', storeFundDetails.final_close);
      setValue('min_investment', storeFundDetails.min_investment);
      setValue('fund_video', storeFundDetails.fund_video);
      setValue('tag_line', storeFundDetails.tag_line);
      setValue('fund_type', storeFundDetails.fund_type);

      setMinInvestment(storeFundDetails.min_investment);

      if (!editing && logoImage.current) {
        logoImage.current.src = URL.createObjectURL(storeFundDetails.logo_file);
        setLogoFile(storeFundDetails.logo_file);
      }
    }
    // eslint-disable-next-line
  }, [storeFundDetails]);

  const { data: organisationsList } = useReactQuery(['organisationsList'], {
    url: 'qaip/v1/usermanagement/organisations?categoryId=2',
  });

  const { data: masterData } = useReactQuery(['masterData'], {
    url: 'qaip/v1/fundsmanagement/funds/masterData',
  });

  const {
    mutate,
    data: logo,
    isLoading: logoLoading,
  } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
      params: { object_key: fundDetails?.logo },
    },
    {
      enabled: false,
    },
  );

  const handleOnFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogoError(false);
    const uploadedFile: any = e.target.files;
    if (logoImage.current) {
      logoImage.current.src = URL.createObjectURL(uploadedFile[0]);
    }
    setLogoFile(uploadedFile[0]);
    const uploadedFileName: string = uploadedFile.item(0).name;
    setLogoName(uploadedFileName);
  };

  const handleDiscardChanges = () => {
    dispatch(setFundsDetails(null));
    if (editing) {
      navigate(`/investment/${id}`);
    } else {
      navigate(`/investments`);
    }
  };

  const handleChangeStrategyDescription = (newEditorState: any) => {
    setStrategyDescription(newEditorState);
  };

  const handleCheckStatusError = () => {
    const fundstatus = fund.replace('Active,', '');
    if (fund === '' || fundstatus === '' || fundstatus === 'null') {
      setFundStatusError(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (fund) {
      const fundstatus = fund.replace('Active,', '');
      if (fund === '' || fundstatus === '' || fundstatus === 'null') {
        setFundStatusError(true);
      } else {
        setFundStatusError(false);
      }
    }
  }, [fund]);

  const onSubmit: SubmitHandler<any> = async (data) => {
    const isValid = handleCheckStatusError();
    if (!isValid) {
      window.scrollTo(0, 0);
      return;
    }
    if (!editing && logoName === '') {
      setLogoError(true);
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Please upload the logo.',
      };
      dispatch(showToast(toast));
      window.scrollTo(0, 0);
      return;
    }

    const newFundDetails = {
      ...data,
      asset_class: selectedAssetClass,
      fund_themes: selectedTheme,
      logo: logoName,
      logo_file: logoFile,
      strategy_description: `${stateToHTML(strategyDescription.getCurrentContent())}`,
      isAllocationWorkflowEnabled: checked ? 'yes' : 'no',
      min_investment: minInvestment,
      add_to_all_dg: !!allowDGPermissions,
    };

    if (strategyDescriptionText.length > 0) {
      dispatch(setFundsDetails(newFundDetails));
      handleSubmitBtnClick();
    }
  };

  const handleChangeMultipleSelect = (event: SelectChangeEvent, name: string) => {
    const {
      target: { value },
    } = event;

    if (name === 'asset_class') {
      setSelectedAssetClassArr(value as any);
      setSelectedAssetClass((value as any).join(','));
    } else {
      setSelectedThemeArr(value as any);
      setSelectedTheme((value as any).join(','));
    }
  };

  const handleKeyDownOnlyNumbers = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleKeyDownOnlyText = (e: any) => {
    if (!/^[a-zA-Z ]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const URLRegex =
    // eslint-disable-next-line
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  useEffect(() => {
    if (fundStatusFromStore) {
      setFundStatus(fundStatusFromStore);
      return;
    }
    if (fundDetails) {
      let fundStatusToBeUsed =
        fundDetails.status === 'Active'
          ? `Active,${fundDetails.fund_status}`
          : fundDetails.status === 'InActive'
          ? 'InActive'
          : 'Onboarding - Awaiting Approval';
      setFundStatus(fundStatusToBeUsed);
    }
  }, [fundDetails, fundStatusFromStore, editing]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Fund Manager Details */}
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={4}>
          <h2 className='sub-heading'>Fund Manager Details</h2>
          <p>
            Select Fund Manager (if already created) or click here to create Fund Manager entity
            first.
          </p>
        </Grid>
        <Grid item xs={7} container rowSpacing={4}>
          <Grid item xs={12} container>
            <Grid item xs={9} className='flex-col flex-start-between'>
              <h4>
                Fund Logo Preview<span className='requiredStar'>*</span>
              </h4>
              <div className='flex-center-start'>
                <MuiUpload
                  variant='outlined'
                  onFileUpload={handleOnFileUpload}
                  accept='image/*'
                  className='logoUpload'
                >
                  {logoName === '' ? 'Upload' : 'Re-upload'}
                </MuiUpload>
                <h5 className='logoFile mb-0'>{logoName}</h5>
              </div>
              {logoError && <ErrorMessage error='Please upload the logo' />}
            </Grid>
            <Grid item xs={3} className='flex-center-end'>
              <div className='logoPreview'>
                {logoLoading ? (
                  <Skeleton variant='rectangular' sx={{ height: '100%' }} />
                ) : (
                  <img src={logo?.url || FundLogoPlaceholder} alt='logo' ref={logoImage} />
                )}
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <h4>
              Funds Manager<span className='requiredStar'>*</span>
            </h4>
            <ControlledSelectField
              options={
                organisationsList &&
                organisationsList.map((el: any) => ({
                  id: el.organisationId,
                  title: el.organisationName,
                }))
              }
              defaultValue={storeFundDetails && storeFundDetails?.fund_manager_id}
              name='fund_manager_id'
              control={control}
              errorMessage='This field is required'
            />
            {errors.fund_manager_id && <ErrorMessage error='This field is required' />}
          </Grid>

          <Grid item xs={12} container columnSpacing={4}>
            <Grid item xs={6}>
              <h4 className='font-wt-400 label'>Title</h4>
              <ControlledSelectField
                control={control}
                options={['Mr.', 'Mrs.', 'Miss']}
                name='fund_manager_title'
                searchIcon={false}
                defaultValue={storeFundDetails && storeFundDetails?.fund_manager_title}
              />
            </Grid>
            <Grid item xs={6}>
              <h4 className='font-wt-400 label'>
                Manager Name<span className='requiredStar'>*</span>
              </h4>
              <Textbox
                fullWidth
                register={register}
                name='fund_manager_contact'
                errorMessage='This field is required'
                required={true}
                keyDownFunc={handleKeyDownOnlyText}
              />
              {errors.fund_manager_contact && <ErrorMessage error='This field is required' />}
            </Grid>
          </Grid>

          <Grid item xs={12} container columnSpacing={4}>
            <Grid item xs={6}>
              <h4>
                Manager's Phone Number<span className='requiredStar'>*</span>
              </h4>
              <Textbox
                fullWidth
                type='text'
                register={register}
                name='fund_manager_phone'
                maxLength={11}
                minLength={11}
                errorMessage='This field is required'
                startAdornment={'+'}
                keyDownFunc={handleKeyDownOnlyNumbers}
              />
              {errors.fund_manager_phone && <ErrorMessage error='Enter a valid phone number' />}
            </Grid>
            <Grid item xs={6}>
              <h4>
                Manager's Email<span className='requiredStar'>*</span>
              </h4>
              <Textbox
                fullWidth
                type='email'
                register={register}
                name='fund_manager_email'
                errorMessage='This field is required'
                pattern={{
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email',
                }}
              />
              {errors.fund_manager_email?.message && (
                <ErrorMessage error='Please enter valid email' />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent='space-between'>
        <Grid item xs={4}>
          <h2>Fund Details</h2>
          <p>Add fund details. Required fields are marked with *</p>
        </Grid>
        <Grid item xs={7} container rowSpacing={4}>
          <Grid item xs={12}>
            <h4>
              Fund Name<span className='requiredStar'>*</span>
            </h4>
            <Textbox
              fullWidth
              register={register}
              name='fund_name'
              errorMessage='This field is required'
            />
            {errors.fund_name && <ErrorMessage error='This field is required' />}
          </Grid>

          <Grid item xs={12} container columnSpacing={4}>
            <Grid item xs={6}>
              <h4>
                Asset Class<span className='requiredStar'>*</span>
              </h4>
              <MultipleSelectField
                register={register}
                name='asset_class'
                options={masterData?.asset_class}
                onChange={(e: any) => {
                  handleChangeMultipleSelect(e, 'asset_class');
                }}
                required={selectedAssetClass === '' ? true : false}
                value={selectedAssetClassArr}
                valueArr={selectedAssetClassArr}
              />
              {errors.asset_class && !selectedAssetClass && (
                <ErrorMessage error='This field is required' />
              )}
            </Grid>
            <Grid item xs={6}>
              <h4>
                Class<span className='requiredStar'>*</span>
              </h4>
              <ControlledSelectField
                control={control}
                options={masterData?.class}
                defaultValue={storeFundDetails && storeFundDetails?.class}
                name='class'
                searchIcon={false}
                errorMessage='This field is required'
              />
              {errors.class && <ErrorMessage error='This field is required' />}
            </Grid>
          </Grid>

          <Grid item xs={12} container columnSpacing={4}>
            <Grid item xs={6}>
              <h4>
                Fund Type<span className='requiredStar'>*</span>
              </h4>
              <ControlledSelectField
                control={control}
                options={['Evergreen', 'Closed-ended']}
                defaultValue={storeFundDetails && storeFundDetails?.fund_type}
                name='fund_type'
                searchIcon={false}
                errorMessage='This field is required'
              />
              {errors.fund_type && <ErrorMessage error='This field is required' />}
            </Grid>
            <Grid item xs={6}>
              <h4>
                Status<span className='requiredStar'>*</span>
              </h4>
              <GroupSelectField
                searchIcon={false}
                setSelectedValue={async (val: any) => {
                  await setFundStatus(val);
                }}
                value={fund}
                defaultValue={fund}
                name='fund_status'
                statusOptions={statusOptions}
                className={`pt-0 groupSelect ${isAllocationWorkflowEnabled ? 'allocReq' : ''}`}
                errorMessage='This field is required'
              />
              {fundStatusError && <ErrorMessage error='This field is required' />}
            </Grid>
          </Grid>

          <Grid item xs={12} container columnSpacing={4}>
            <Grid item xs={6}>
              <h4>
                Eligibility<span className='requiredStar'>*</span>
              </h4>
              <ControlledSelectField
                control={control}
                defaultValue={storeFundDetails && storeFundDetails?.eligibility}
                options={masterData?.eligibility}
                name='eligibility'
                errorMessage='This field is required'
                searchIcon={false}
              />
              {errors.eligibility && <ErrorMessage error='This field is required' />}
            </Grid>
            <Grid item xs={6}>
              <h4>
                Themes<span className='requiredStar'>*</span>
              </h4>
              <MultipleSelectField
                register={register}
                required={selectedThemeArr.length === 0 ? true : false}
                options={masterData?.fundThemes}
                name='fund_themes'
                onChange={(e: any) => {
                  handleChangeMultipleSelect(e, 'fund_themes');
                }}
                setSelectedValue={setSelectedTheme}
                value={selectedThemeArr}
                searchIcon={false}
                valueArr={selectedThemeArr}
              />
              {errors.fund_themes && selectedThemeArr.length === 0 && (
                <ErrorMessage error='This field is required' />
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} container columnSpacing={4}>
            <Grid item xs={6}>
              <h4>ISO</h4>
              <ControlledSelectField
                control={control}
                defaultValue={storeFundDetails && storeFundDetails?.iso_currency}
                options={masterData?.isoCurrency}
                name='iso_currency'
                searchIcon={false}
              />
            </Grid>
            <Grid item xs={6}>
              <h4>
                Strategy<span className='requiredStar'>*</span>
              </h4>
              <ControlledSelectField
                control={control}
                options={masterData?.strategy}
                defaultValue={storeFundDetails && storeFundDetails?.strategy}
                name='strategy'
                errorMessage='This field is required'
                searchIcon={false}
              />

              {errors.strategy && <ErrorMessage error='This field is required' />}
            </Grid>
          </Grid>

          <Grid item xs={12} container columnSpacing={4}>
            <Grid item xs={6}>
              <h4>
                Sub Strategy<span className='requiredStar'>*</span>
              </h4>
              <Textbox
                fullWidth
                required={true}
                register={register}
                name='sub_strategy'
                errorMessage='This field is required'
              />
              {errors.sub_strategy && <ErrorMessage error='This field is required' />}
            </Grid>
            <Grid item xs={6}>
              <h4>
                Minimum Investment<span className='requiredStar'>*</span>
              </h4>
              <Textbox
                fullWidth
                register={register}
                required={true}
                name='min_investment'
                errorMessage='This field is required'
                startAdornment={'$'}
                value={minInvestment ? valueFormatter(minInvestment) : ''}
                onChange={(e: any) => {
                  if (regexValidator(e.target.value.replace(/,/g, ''), /^[0-9,]*$/i)) return;
                  setMinInvestment(e.target.value.replace(/,/g, ''));
                }}
                keyDownFunc={handleKeyDownOnlyNumbers}
              />
              {errors.min_investment && <ErrorMessage error='This field is required' />}
            </Grid>
          </Grid>
          <Grid item xs={12} container columnSpacing={4}>
            <Grid item xs={6}>
              <h4>
                Rating<span className='requiredStar'>*</span>
              </h4>
              <ControlledSelectField
                control={control}
                options={masterData?.rating}
                name='rating'
                defaultValue={storeFundDetails && storeFundDetails?.rating}
                errorMessage='This field is required'
                searchIcon={false}
              />
              {errors.rating && <ErrorMessage error='This field is required' />}
            </Grid>
            <Grid item xs={12}>
              <h4>Fund Video Link</h4>
              <Textbox
                fullWidth
                register={register}
                name='fund_video'
                pattern={{
                  value: URLRegex,
                  message: 'Please enter a valid URL',
                }}
              />
              {errors.fund_video?.message && <ErrorMessage error='Please enter valid URL' />}
            </Grid>
          </Grid>

          <Grid item xs={12} display='flex' alignItems='center'>
            <h4>Enable Request Allocation Workflow</h4>
            <Switch
              checked={checked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChecked(!checked)}
              disabled={editing}
            />
          </Grid>

          <Grid item xs={12} display='flex' alignItems='center'>
            <h4>Permission all Dealer Groups for this fund when Fund status is 'Open'</h4>
            <Switch
              checked={allowDGPermissions}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAllowDGPermissions(!allowDGPermissions)
              }
              disabled={editing}
            />
          </Grid>

          <Grid item xs={12}>
            <h4>
              Strategy Description<span className='requiredStar'>*</span>
            </h4>
            <RichTextEditor
              editorState={strategyDescription}
              handleChangeEditorState={(newEditorState: any) => {
                setStrategyDescriptionError(strategyDescriptionText.length === 0);
                handleChangeStrategyDescription(newEditorState);
              }}
            />
            {strategyDescriptionError && <ErrorMessage error='This field is required' />}
            {strategyDescriptionText.length > 5000 && (
              <ErrorMessage error='Input limit exceeded. Character Limit is 5000' />
            )}
          </Grid>
          <Grid item xs={12}>
            <h4>
              Tag Line<span className='requiredStar'>*</span>
            </h4>
            <Textbox
              fullWidth
              multiline
              rows={4}
              register={register}
              required={true}
              name='tag_line'
              errorMessage='This field is required'
              maxLength={400}
            />
            {errors.tag_line && errors.tag_line.type === 'required' && (
              <ErrorMessage error='This field is required' />
            )}
            {errors.tag_line && errors.tag_line.type === 'maxLength' && (
              <ErrorMessage error='Input limit exceeded. Character Limit is 400' />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent='flex-end' className='ctaContainer'>
        <MuiButton variant='text' color='error' buttonClick={handleDiscardChanges}>
          Discard Changes
        </MuiButton>
        <MuiButton
          minWidth='150px'
          variant='contained'
          type='submit'
          className='ml-3'
          buttonClick={() => {
            handleCheckStatusError();
            dispatch(setStoreFundStatus(fund as string));
            if (strategyDescriptionText.length === 0) setStrategyDescriptionError(true);
          }}
        >
          Next
        </MuiButton>
      </Grid>
    </form>
  );
};

export default InvestmentDetialsForm;
