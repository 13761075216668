import styled from '@emotion/styled';
import Button, { ButtonProps } from '@mui/material/Button';

export type AllButtonProps = ButtonProps & {
  buttonClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  formName?: string;
  minWidth?: string;
  component?: string;
};

const StyledButton = styled(Button)`
  text-transform: none;
  min-width: ${(props) => props.minWidth};

  /* Overriding default disabled state */
  &.Mui-disabled {
    pointer-events: initial !important;
  }

  &.filterBtn {
    background-color: transparent;
    color: #3f3f3f;
    border: 1px solid var(--s15);
    border-radius: 3px;
    padding: 6px 10px;
    font-size: 11px;
    &.active {
      color: white;
      background-color: var(--p400);
      border-color: var(--p400);
    }
  }

  &.filterMainBtn {
    background-color: var(--s7);
    color: var(--s30);
    padding: 6px 14px;
    font-size: 13px;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid var(--s7);
    &:hover {
      background-color: var(--s7);
      border-color: var(--p150);
    }
  }

  /* Primary Contained Button */
  ${({ variant, color }: AllButtonProps) =>
    variant === 'contained' &&
    color === 'primary' &&
    `
        border-radius: 6px;
      background: linear-gradient(96.99deg, var(--p500) 2.96%, var(--p300) 104.56%);
      &:hover {
        background: linear-gradient(90deg, var(--p600) 0%, var(--p400) 100%);
      }
      &:active {
        background: linear-gradient(90deg, var(--p500) 0%, var(--p300) 100%);
      }
      &:disabled {
        background: #C4C4C4;
        color: white;
      }
    `}

  /* Primary Outlined Button */
  ${({ variant, color }: AllButtonProps) =>
    variant === 'outlined' &&
    color === 'primary' &&
    `
      border-radius: 6px;
      border: 2px solid var(--p500);
      &:hover {
        border-width: 2px !important;
        border-color: #3E89CD;
      }
      &:disabled {
        border-color: #C4C4C4;
        border-width: 2px !important;
      }
      &:disabled:hover {
        background-color: transparent;
      }
    `}

  /* Primary Text Button */
  ${({ variant, color }: AllButtonProps) =>
    (variant === 'text' || (variant !== 'outlined' && variant !== 'contained')) &&
    color === 'primary' &&
    `
      color: var(--p300);
      &:hover {
        background-color: var(--p15);
      }
      &:active {
        background-color: var(--p20);
      }
      &:disabled:hover {
        background-color: transparent;
      }
    `}
`;

const MuiButton = ({
  children,
  component,
  fullWidth,
  variant,
  size,
  disabled,
  color = 'primary',
  buttonClick,
  type,
  className = '',
  disableElevation = true,
  minWidth = 'initial',
  sx,
  id,
}: AllButtonProps) => {
  return (
    <StyledButton
      disableRipple
      fullWidth={fullWidth}
      component={component}
      type={type}
      color={color}
      onClick={buttonClick}
      disabled={disabled}
      size={size}
      variant={variant}
      className={className}
      disableElevation={disableElevation}
      sx={{ ...sx, minWidth: minWidth }}
      id={id}
    >
      {children}
    </StyledButton>
  );
};

export default MuiButton;
