import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  buttonClick: any;
};

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 426px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 28px !important;
  }
  ol,
  p {
    font-size: 0.875rem;
    color: var(--s40) !important;
  }
`;

const UploadHoldingsConfirm = ({ isModalOpen, handleClose, buttonClick }: Props) => {
  return (
    <StyledModal
      title={'Confirm Update'}
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='xs'
      className='new-alloc'
      disableCloseIcon
    >
      <Grid container mb={5}>
        <ol className='mt-0 pl-3'>
          <li>Platform will be updated with parsed data</li>
          <li>Holdings missing from parsed data will be removed from the platform</li>
        </ol>
        <p className='mb-0'>Are you ready to update?</p>
      </Grid>

      <Grid container alignItems='center' justifyContent='flex-end'>
        <MuiButton buttonClick={handleClose}>Go Back</MuiButton>
        <MuiButton variant='contained' className='ml-3' buttonClick={buttonClick} minWidth='150px'>
          Update
        </MuiButton>
      </Grid>
    </StyledModal>
  );
};

export default UploadHoldingsConfirm;
