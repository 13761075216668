import Grid from '@mui/material/Grid/Grid';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import InputField from 'common/components/inputField/Textbox';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { selectAuthUser } from 'store/user/selectors';
import { RegisterType } from './types';
import Logo from 'common/assets/images/QualisLogo.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

const validationSchema = Yup.object({
  username: Yup.string().required('E-mail is required').email('Please enter a valid e-mail'),
  tempPassword: Yup.string().required('Password is required'),
  newPassword: Yup.string()
    .required('Password is required')
    .matches(
      REGEX,
      'Password must contain at least 8 characters, one uppercase, one lowercase, one numeric and one special character',
    ),
  confirmPassword: Yup.string()
    .required('Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterType>({ resolver: yupResolver(validationSchema), mode: 'onSubmit' });
  const authUser = useAppSelector(selectAuthUser);
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const onSubmit: SubmitHandler<RegisterType> = async (data) => {
    dispatch({ type: 'USER_REGISTRATION', payload: data });
  };

  useEffect(() => {
    if (authUser && authUser.accessToken) navigate('/');
    // eslint-disable-next-line
  }, [authUser]);

  return (
    <div className='authBG'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent='center'>
          <Grid item xs={10} mb={15}>
            <img src={Logo} alt='Tifin Private Markets' />
          </Grid>
          <Grid item xs={12}>
            <InputField
              fullWidth
              variant='standard'
              label='Enter your email'
              type='text'
              register={register}
              name='username'
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            {errors?.username?.message && <ErrorMessage error={errors.username.message} />}
          </Grid>
          <Grid item xs={12}>
            <InputField
              fullWidth
              variant='standard'
              label='Enter temporary password'
              type='password'
              register={register}
              name='tempPassword'
              errorMessage='Temporary password entered is incorrect.'
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            {errors.tempPassword && <ErrorMessage error={errors.tempPassword.message} />}
          </Grid>
          <Grid item xs={12}>
            <InputField
              fullWidth
              variant='standard'
              label='Enter your new password'
              type='password'
              register={register}
              name='newPassword'
              minLength={8}
              errorMessage='Please enter a valid password.'
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            {errors.newPassword && <ErrorMessage error={errors?.newPassword.message} />}
          </Grid>
          <Grid item xs={12}>
            <InputField
              fullWidth
              variant='standard'
              label='Confirm new password'
              type='password'
              register={register}
              name='confirmPassword'
              minLength={8}
              errorMessage='Please enter a valid password.'
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            {errors.confirmPassword && <ErrorMessage error={errors?.confirmPassword.message} />}
          </Grid>
          <Grid item xs={12} mt={8}>
            {/* This will prevent the submission of form on pressing enter key */}
            <button type='submit' disabled className='d-none' aria-hidden='true'></button>
            <MuiButton fullWidth disableElevation type='submit' variant='contained' color='primary'>
              Register
            </MuiButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Register;
