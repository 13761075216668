export const SUB_DOC_COLUMNS = [
  {
    id: 'docClassificationName',
    label: 'Document Type',
    width: '30%',
  },
  {
    id: 'docName',
    label: 'File Name',
    width: '60%',
  },
];

export const PREFILL_ENABLED_SUB_DOC_COLUMNS = [
  {
    id: 'docClassificationName',
    label: 'Document Type',
    width: '32%',
  },
  {
    id: 'docName',
    label: 'File Name',
    width: '43%',
  },
  {
    id: '_prefill',
    label: 'Document is pre-fillable',
    align: 'center' as const,
    width: '20%',
    tooltip:
      "We use account details stored in the client's profile to create a client-specific pre-filled version of this document.",
  },
];

export const MKT_DOCS_COLUMNS = [
  {
    id: 'docClassificationName',
    label: 'Document Type',
    width: '32%',
  },
  {
    id: 'docName',
    label: 'File Name',
    width: '63%',
  },
];
