export type AuthSlice = {
  authUser: AuthUser | null;
  requestStatus: string;
  behalfOf: UserProfile | null;
  userProfile: UserProfile | null;
  forgotPasswordActiveStepper: number;
};

export type UserDetailsResponseDTO = {
  cognitoUserId: string;
  firstName: string;
  email: string;
  userId: string;
  username: string;
};

export type Privilege = {
  approvalaccess: boolean;
  createaccess: boolean;
  menu: string;
  modifyaccess: boolean;
  viewaccess: boolean;
}

export type TeamsRolesDetails = {
  privilege: Array<Privilege> | string;
  roleId: string;
  roleName: string;
  teamId: string | null;
  teamName: string | null;
}

export type UserProfile = Pick<UserDetailsResponseDTO,
  "firstName" |
  "userId" |
  "username"> & {
    active: boolean;
    category: number;
    lastName: string;
    userName: string;
    organizationId: string;
    termsAndConditions: boolean;
    phone: string;
    userStatus: string;
    teamsRolesDetails: Array<TeamsRolesDetails>;
    totalClients: number;
    aum: number;
  };

export enum RequestLoader {
  NOT_STARTED = 'NOT_STARTED',
  LOADING = 'LOADING',
  LOAD_COMPLETE = 'LOAD_COMPLETE',
  ERROR = 'ERROR',
}

export type OktaAuth = {
  accessToken?: string;
  expiresIn?: number;
  idToken?: string;
  refreshToken?: string;
  tokenType?: string;
}

export type AuthUser = {
  accessToken?: string;
  expiresIn?: number;
  idToken?: string;
  refreshToken?: string;
  tokenType?: string;
  userDetailsResponseDTO: UserDetailsResponseDTO;
};


