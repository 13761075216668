import ChatBotFundCard from './ChatBotFundCard';
import ChatBotLinks from './ChatBotLinks';

type Props = {
  handleUserResponseClick: (value: any) => void;
  responseDetails: any;
  interactionType: string;
  delay: number;
  chatbotData: any;
  i: number;
  toggleDrawer: any;
  journeyTitle: any;
  isLoading: Boolean;
  fundData: Object;
};

enum ComponentType {
  INVESTMENT_CARD = 'INVESTMENT_CARD',
  LINK = 'LINK',
}

export const InputWidgetFactory = ({
  responseDetails,
  delay,
  toggleDrawer,
  journeyTitle,
  isLoading,
  fundData,
}: Props) => {
  const InputWidgetMap: any = {
    [ComponentType.INVESTMENT_CARD]: (
      <ChatBotFundCard
        toggleDrawer={toggleDrawer}
        initDelay={delay + 400}
        responseDetails={responseDetails}
        journeyTitle={journeyTitle}
        isLoading={isLoading}
        fundData={fundData}
      />
    ),
    [ComponentType.LINK]: (
      <ChatBotLinks initDelay={delay + 400} responseDetails={responseDetails} />
    ),
  };

  return InputWidgetMap[responseDetails.componentType] ?? <div></div>;
};
