import { Container } from '@mui/material';
import MuiStepper from 'common/components/stepper';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { selectFundsActiveStep } from 'store/funds/selectors';
import { setFundsActiveStep } from 'store/funds/slice';
import InvestmentDetialsForm from './investmentForms/InvestmentDetailsForm';
import InvestmentStructAndStatsForm from './investmentForms/InvestmentStructAndStatsForm';
import InvestmentTSPForm from './investmentForms/InvestmentTSPForm';
import InvestmentHistoricalReturnsForm from './investmentForms/InvestmentHistorialReturnsForm';
import InvestmentDocumentForm from './investmentForms/InvestmentDocumentForm';
import InvestmentChecklistForm from './investmentForms/investmentCheckList/InvestmentChecklistForm';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)`
  .MuiStepLabel-label {
    color: black;
    font-size: 11px;
  }

  .MuiStepLabel-iconContainer svg {
    font-size: 20px;
  }
`;

const NewFunds = () => {
  const dispatch = useTypedDispatch();

  const navigate = useNavigate();

  const activeStep = useAppSelector(selectFundsActiveStep);
  const steps = [
    'Fund Details',
    'Fund Structure and Stats',
    'Terms & Service Providers',
    'Historical Returns',
    'Subscription Checklist',
    'Fund Documents',
  ];

  const handleSubmitBtnClick = async () => {
    await dispatch(setFundsActiveStep(activeStep + 1));
    window.scrollTo(0, 0);
  };

  const handleBackBtnClick = async () => {
    await dispatch(setFundsActiveStep(activeStep - 1));
    window.scrollTo(0, 0);
  };

  return (
    <StyledContainer maxWidth='xl' className='container-lr-padding container-t-margin'>
      <Container maxWidth='xl'>
        <div className='d-flex'>
          <MuiStepper steps={steps} activeStep={activeStep} />
          <CloseIcon
            style={{ marginLeft: 10, cursor: 'pointer' }}
            onClick={() => navigate(`/investments`)}
          />
        </div>
        {activeStep === 0 && (
          <InvestmentDetialsForm
            handleSubmitBtnClick={handleSubmitBtnClick}
            editing={false}
            status='Inactive'
          />
        )}
        {activeStep === 1 && (
          <InvestmentStructAndStatsForm
            handleSubmitBtnClick={handleSubmitBtnClick}
            handleBackBtnClick={handleBackBtnClick}
          />
        )}
        {activeStep === 2 && (
          <InvestmentTSPForm
            handleSubmitBtnClick={handleSubmitBtnClick}
            handleBackBtnClick={handleBackBtnClick}
            editing={false}
          />
        )}
        {activeStep === 3 && (
          <InvestmentHistoricalReturnsForm
            handleSubmitBtnClick={handleSubmitBtnClick}
            handleBackBtnClick={handleBackBtnClick}
            editing={false}
          />
        )}
        {activeStep === 4 && (
          <InvestmentChecklistForm
            handleSubmitBtnClick={handleSubmitBtnClick}
            handleBackBtnClick={handleBackBtnClick}
            editing={false}
          />
        )}
        {activeStep === 5 && (
          <InvestmentDocumentForm
            handleSubmitBtnClick={handleSubmitBtnClick}
            handleBackBtnClick={handleBackBtnClick}
            editing={false}
          />
        )}
      </Container>
    </StyledContainer>
  );
};

export default NewFunds;
