import { useState } from 'react';
import PreviewModal from './PreviewModal';
import UploadModal from './UploadModal';
import { errorValidation, processExcel } from './utils';

type Props = {
  isModalOpen: boolean;
  handleModalClick: any;
  setSelectedFile?: any;
  selectedFile?: any;
  retryUpload?: any;
  setUploadError: any;
  uploadError: any;
};

const HistoricalReturnsModal = ({
  isModalOpen,
  handleModalClick,
  setSelectedFile,
  selectedFile,
  setUploadError,
}: Props) => {
  const [fileUploadError, setFileUploadError] = useState<string>('');
  const [acceptedFileData, setAcceptedFileData] = useState<any>([]);
  const [incorrectDataList, setIncorrectDataList] = useState<any>([]);
  const [isValidFile, setIsValidFile] = useState<boolean>(false);

  const closeModal = () => {
    setFileUploadError('');
    setSelectedFile(null);
    handleModalClick();
    setIsValidFile(false);
  };

  async function importFile(evt: any) {
    if (evt) {
      var r = new FileReader();
      r.readAsBinaryString(evt);
      r.onload = async (e: any) => {
        if (!e.target.result) {
          setUploadError(true);
          return;
        }
        const json = await processExcel(e.target.result);
        const data = await errorValidation(json);
        setIsValidFile(data.isHeadersValid);
        if (!data.isHeadersValid) {
          setUploadError(true);
        } else {
          setAcceptedFileData(data.validData);
          setIncorrectDataList(data.invalidData);
        }
      };
    }
  }

  const onHandleFileDrop = async (fileRejections: any, acceptedFiles: any) => {
    if (fileRejections.length > 0) {
      setFileUploadError(fileRejections[0].file.name);
      setSelectedFile(null);
    } else {
      await closeModal();
      setFileUploadError('');
      setSelectedFile(acceptedFiles[0]);
      await importFile(acceptedFiles[0]);
    }
  };

  return (
    <>
      <UploadModal
        isModalOpen={isModalOpen}
        handleModalClick={handleModalClick}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        closeModal={closeModal}
        onHandleFileDrop={onHandleFileDrop}
        fileUploadError={fileUploadError}
      />
      <PreviewModal
        isModalOpen={isValidFile}
        selectedFile={selectedFile}
        incorrectDataList={incorrectDataList}
        acceptedFileData={acceptedFileData}
        handleModalClose={closeModal}
      />
    </>
  );
};

export default HistoricalReturnsModal;
