import { RootStateOrAny } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React from 'react';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { dismissToast, ToastMessage } from 'store/toast/slice';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const Toast = () => {
  const toast: ToastMessage = useAppSelector((state: RootStateOrAny) => state.toast.queue[0]);
  const dispatch = useTypedDispatch();

  const handleClose = () => {
    dispatch(dismissToast());
  };

  if (!toast) return null;

  return (
    <Snackbar
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleClose}
      open={!!toast}
    >
      <Alert severity={toast?.type} onClose={handleClose}>
        {toast?.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
