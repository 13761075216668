import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiCard from 'common/components/card';
import { useNavigate, Link } from 'react-router-dom';
import { ReactComponent as Icon } from 'common/assets/images/recommendation/Home1.svg';
import { useTypedDispatch } from 'store/hooks';
import {
  setEngineNumber,
  setIsLandingPage,
  setRecommendationActiveStep,
  setRecommendationClient,
  setRecommendedAllocation,
} from 'store/recommendation/slice';
import { ReactComponent as ClientIcon } from 'common/assets/images/recommendation/Client.svg';

const StyledWrapper = styled.div`
  .fundCard {
    padding: 1rem 24px;
    height: 100%;
    box-sizing: border-box;
    min-height: unset;
    cursor: pointer;
    position: relative;

    &:hover {
      border-color: var(--p150);
    }

    .recBanner {
      display: flex;
      align-items: center;
      position: absolute;
      inset: 0 0 auto 0;
      font-size: 12px;
      font-weight: 700;
      color: var(--s40);
      background: var(--p20);
      padding: 6px 12px;
      svg {
        margin-right: 10px;
      }
    }

    .primary-text {
      font-size: 22px;
      font-weight: 500;
      color: var(--s50);
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    .subtitle {
      font-size: 11px;
    }

    .secondary-text {
      &--span2 {
        font-size: 1rem;
        color: var(--s50);
        font-weight: 400;
      }
    }

    .link {
      text-decoration: none;
      font-size: 14px;
      color: var(--p300);
    }

    img,
    .MuiSkeleton-root {
      height: 80px;
      max-width: 40%;
      object-fit: contain;
      object-fit: left center;
      margin-bottom: 1rem;
    }
    h3 {
      color: var(--p500);
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    h4 {
      margin-bottom: 0;
    }
  }
`;

const Engine1Card = ({
  clientList,
  startIndex = 0,
  stats = { portfolioSize: '$10.0M', alloc: '26.00%' },
}: any) => {
  const selectedClient = clientList?.length > 0 ? clientList[startIndex] : null;
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  return (
    <StyledWrapper>
      <MuiCard
        className='fundCard'
        onClick={() => {
          dispatch(setRecommendedAllocation({ recommendedAllocation: 26 }));
          dispatch(setRecommendationActiveStep({ activeStep: 2 }));
          dispatch(setIsLandingPage({ isLandingPage: false }));
          dispatch(setEngineNumber({ engineNumber: 1 }));
          dispatch(setRecommendationClient({ client: selectedClient }));
          navigate(`/recommendations`);
        }}
      >
        <div className='recBanner'>
          <Icon />
          Recommended Allocation to Private Markets
        </div>
        <div className='flex-col flex-start-between h-100'>
          <Grid container>
            <Grid item xs={12} mt={'30px'} mb={2} className='flex-center-start'>
              <ClientIcon className='mr-2' />
              <h3 className='primary-text'>
                {selectedClient?.account_name.length > 20
                  ? selectedClient?.account_name.substring(0, 15) +
                    '...' +
                    selectedClient?.account_name.substring(
                      selectedClient?.account_name.length - 5,
                      selectedClient?.account_name.length,
                    )
                  : selectedClient?.account_name}
              </h3>
            </Grid>
            <Grid item xs={6}>
              <div className='subtitle'>PORTFOLIO SIZE</div>
              <div className='secondary-text--span2'>{stats.portfolioSize}</div>
            </Grid>
            <Grid item xs={6}>
              <div className='subtitle'>ALLOCATION TO PRIVATE MARKETS</div>
              <div className='secondary-text--span2'>{stats.alloc}</div>
            </Grid>
          </Grid>
          <Grid item xs={12} display='flex' alignItems='flex-end'>
            <Link className='link' to='#'>
              View Results
            </Link>
          </Grid>
        </div>
      </MuiCard>
    </StyledWrapper>
  );
};

export default Engine1Card;
