import { CircularProgress, TableCell } from '@mui/material';
import { canCreateOrder } from 'helpers/roles';
import { useNavigate } from 'react-router-dom';
import { useTypedDispatch } from 'store/hooks';
import { resetOrderData } from 'store/order/slice';
import MuiButton from '../button';

type Props = {
  ctaType: string;
  user: any;
  clientData?: any;
  row: any;
  finalRows: any;
  handleCtaClick?: any;
  setIndex: any;
  setDocTitle?: any;
  handleDocViewOnclickOpen?: any;
  isDocumentLinkLoading?: boolean;
  index: number;
  i: number;
};

const CTAgenerator = ({
  ctaType,
  user,
  clientData,
  row,
  finalRows,
  handleCtaClick,
  setIndex,
  setDocTitle,
  handleDocViewOnclickOpen,
  isDocumentLinkLoading,
  index,
  i,
}: Props) => {
  //Row End CTA generator
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  return (
    <TableCell className='flex-center-center'>
      {(() => {
        switch (ctaType) {
          case 'newOrder':
            return finalRows[i] && finalRows[i]['status'] === 'Active' ? (
              <MuiButton
                variant='outlined'
                disabled={user && !canCreateOrder(user)}
                buttonClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  dispatch(resetOrderData());
                  navigate('/create-order', {
                    state: {
                      clientDetails: finalRows[i],
                    },
                  });
                }}
                sx={{ p: '5px 14px', fontSize: '13px' }}
              >
                New Order
              </MuiButton>
            ) : (
              <span>
                {finalRows && finalRows[i] && finalRows[i]['status']
                  ? finalRows[i]['status']
                  : '--'}
              </span>
            );
          case 'addOrRedeem':
            return (
              <>
                <MuiButton
                  disabled={user && !canCreateOrder(user)}
                  buttonClick={(e: any) => {
                    e.stopPropagation();
                    dispatch(resetOrderData());
                    navigate('/create-order', {
                      state: {
                        fundDetails: finalRows[i],
                        clientDetails: clientData,
                        isRedeemOrder: true,
                      },
                    });
                  }}
                  sx={{
                    p: '5px 12px',
                    fontSize: '13px',
                    mr: 0.5,
                    color: `${user && !canCreateOrder(user) ? '' : 'var(--p500) !important'}`,
                  }}
                >
                  Redeem
                </MuiButton>
                <MuiButton
                  variant='outlined'
                  disabled={user && !canCreateOrder(user)}
                  buttonClick={(e: any) => {
                    e.stopPropagation();
                    dispatch(resetOrderData());
                    navigate('/create-order', {
                      state: {
                        fundDetails: finalRows[i],
                        clientDetails: clientData,
                      },
                    });
                  }}
                  sx={{ p: '5px 14px', fontSize: '13px' }}
                >
                  Add
                </MuiButton>
              </>
            );
          case 'viewNow':
            return (
              <>
                {index === i && isDocumentLinkLoading ? (
                  <CircularProgress />
                ) : (
                  <MuiButton
                    variant='outlined'
                    buttonClick={() => {
                      setIndex(i);
                      setDocTitle(finalRows[i]?.fundName || finalRows[i]?.docName);
                      handleDocViewOnclickOpen(row.docLink, row.documentId);
                    }}
                    sx={{ p: '5px 14px', fontSize: '13px' }}
                  >
                    View Now
                  </MuiButton>
                )}
              </>
            );
          case 'updateUserProfile':
            return (
              <MuiButton
                variant='outlined'
                buttonClick={() => handleCtaClick(finalRows[i])}
                sx={{ p: '5px 14px', fontSize: '13px' }}
              >
                Update
              </MuiButton>
            );
          case 'events':
            return (
              <MuiButton
                variant='outlined'
                buttonClick={() => handleCtaClick(finalRows[i])}
                sx={{ p: '5px 14px', fontSize: '13px' }}
              >
                View Now
              </MuiButton>
            );
          default:
            return false;
        }
      })()}
    </TableCell>
  );
};

export default CTAgenerator;
