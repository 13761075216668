import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { useAppSelector, useReactQuery, useRQMutation } from 'store/hooks';
import { useEffect, useState } from 'react';
import { PdfDownloadCheckboxOptions } from 'components/PDF/PdfDownloadCheckboxOptions';
import { ClientType, ReactQueryDataFetch } from 'common/types';
import { UserProfile } from 'store/user/types';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import MuiAutocomplete from 'common/components/inputField/ControlledAutocomplete';
import { downloadAllDocuments, downloadBlobDoc } from 'helpers/document';
import LoadingButton from 'common/components/button/LoadingButton';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  selectedClient?: any;
  prefilledDocs?: any;
  allDocs?: any;
  // buttonClick?: any;
};

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 730px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 0.5rem !important;
    line-height: 26px;
    & > div {
      flex-wrap: nowrap;
      align-items: start;
    }
  }
  h5 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
  }
  .MuiCheckbox-root {
    padding: 0;
    margin-right: 0.5rem;
    svg {
      font-size: 18px;
    }
  }

  .disclaimer {
    font-size: 0.875rem;
    font-weight: 400;
  }

  .prefill {
    font-weight: 500;
    font-size: 16px;
    color: var(--p300);
  }
  .selectAllBtn {
    cursor: pointer;
    color: var(--p300);
    font-weight: 600;
    font-size: 14px;
  }
`;

const PrefillDownloadModal = ({
  isModalOpen,
  handleClose,
  selectedClient,
  prefilledDocs = [],
  allDocs,
}: Props) => {
  const [downloadDetails, setDownloadDetails] = useState<any>([]);
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const [selectAllState, setSelectAllState] = useState<boolean>(false);
  const user: UserProfile | null = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const [prefillDocName, setPrefillDocName] = useState<any>('');
  const [nonPrefillDocName, setNonPrefillDocName] = useState<any>('');
  const defaultClient = selectedClient
    ? {
        clientName: selectedClient.account_name,
        clientId: selectedClient.investor_id,
      }
    : null;
  const [client, setClient] = useState<any>(defaultClient);
  const prefillWorkflowEnabled =
    process.env.REACT_APP_SHOW_PDFTRON_FEATURES?.toString().toLowerCase() === 'true';

  const { mutate: downloadPdf, isLoading: isPrefillLoading } = useRQMutation(
    {
      url: '/api/document/createpdf/v2',
      baseURL: process.env.REACT_APP_NODE_DOCUMENT_SERVER_BASE_URL as string,
      responseType: 'blob',
    },
    {
      onSuccess: (data: any) => {
        downloadBlobDoc(data, prefillDocName);
      },
    },
  );
  const { data = [] } = useReactQuery(
    [`clientList${user?.userId}`],
    {
      url: 'qaip/v1/investormanagement/investors',
    },
    {
      refetchOnMount: true,
    },
  ) as { data: ClientType[] } & ReactQueryDataFetch;

  const { mutate: fetchPreSignedUrl, isLoading: isNonPrefillLoading } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
    },
    {
      onSuccess: (data: any) => {
        downloadAllDocuments([{ url: data.url, docName: nonPrefillDocName }] as any);
      },
    },
  );

  const handleDownload = async () => {
    const prefillDocs = downloadDetails?.filter((doc: any) => doc.prefill);
    const nonPrefillDocs = downloadDetails?.filter((doc: any) => !doc.prefill);
    for (let i = 0; i < prefillDocs.length; i++) {
      await setPrefillDocName(prefillDocs[i]?.docName);
      downloadPdf({
        document_id: prefillDocs[i]?.documentId,
        document_object_key: prefillDocs[i]?.docLink,
        investor_id: client?.id,
      });
    }

    for (let i = 0; i < nonPrefillDocs.length; i++) {
      const object_key = nonPrefillDocs[i].docLink;
      await setNonPrefillDocName(nonPrefillDocs[i].docName);
      fetchPreSignedUrl({ object_key });
    }
  };

  useEffect(() => {
    // pushAllDocsToArray
    if (prefilledDocs.length > 0) setDownloadDetails([...prefilledDocs]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefilledDocs]);

  const handleSelectAll = () => {
    if (selectAllState) {
      setSelectAllState(false);
      setDownloadDetails([...prefilledDocs]);
    } else {
      setSelectAllState(true);
      setDownloadDetails([]);
    }
  };

  return (
    <StyledModal
      title='Download Subscription Documents'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='md'
      className='new-alloc'
    >
      <Grid container mt={3}>
        {/* If a client is selected from create order flow, do not display this select field */}
        {!selectedClient && prefillWorkflowEnabled && (
          <Grid item xs={12} mb={4}>
            <MuiAutocomplete
              name='clientName'
              label='Pre-fill Client Information for:'
              defaultValue={client?.clientId}
              options={data
                ?.map((client: any) => ({
                  label: client.account_name,
                  id: client.investor_id,
                }))
                .sort((a: any, b: any) =>
                  a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : -1,
                )}
              setSelectedValue={setClient}
              placeholder='Select Client Name'
            />
          </Grid>
        )}

        <Grid item xs={12} mb={2}>
          <span className='selectAllBtn' onClick={() => handleSelectAll()}>
            {selectAllState === true ? 'Select All' : 'Deselect All'}
          </span>
        </Grid>
        <Grid item xs={12} mb={8}>
          {prefilledDocs?.map((doc: any, i: number) => (
            <PdfDownloadCheckboxOptions
              doc={doc}
              i={i}
              key={doc?.documentId}
              setDownloadDetails={setDownloadDetails}
              downloadDetails={downloadDetails}
              selectAll={selectAllState}
              prefilledDocs={prefilledDocs}
              setSelectAllState={setSelectAllState}
            />
          ))}
        </Grid>
      </Grid>

      <Grid container alignItems='center' justifyContent='space-between'>
        <Grid item xs='auto'>
          {prefillWorkflowEnabled && (
            <div className='disclaimer'>
              <span className='prefill'>*</span> Documents will be pre-filled with client data
            </div>
          )}
        </Grid>
        <Grid item xs='auto'>
          {isNonPrefillLoading || isPrefillLoading ? (
            <LoadingButton variant='contained' className='ml-3' minWidth='150px' />
          ) : (
            <MuiButton
              buttonClick={() => handleDownload()}
              variant='contained'
              className='ml-3'
              minWidth='150px'
              disabled={downloadDetails.length === 0 && true}
            >
              Download
            </MuiButton>
          )}
        </Grid>
      </Grid>
    </StyledModal>
  );
};

export default PrefillDownloadModal;
