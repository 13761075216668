import {
  DPI_TOOLTIP_CONTENT,
  IRR_TOOLTIP_CONTENT,
  TVPI_TOOLTIP_CONTENT,
} from 'common/constants/tooltipContent';
import { TSortBy } from 'store/investor/types';

export const LIST_CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '',
    label: 'Investments',
  },
];

export const MONTHLY_COLUMNS = [
  {
    id: 'Year',
    label: 'Year',
  },
  {
    id: '1',
    label: 'Jan',

    width: '5%',
  },
  {
    id: '2',
    label: 'Feb',

    width: '5%',
  },
  {
    id: '3',
    label: 'Mar',

    width: '5%',
  },
  {
    id: '4',
    label: 'Apr',

    width: '5%',
  },
  {
    id: '5',
    label: 'May',

    width: '5%',
  },
  {
    id: '6',
    label: 'June',

    width: '5%',
  },
  {
    id: '7',
    label: 'July',

    width: '5%',
  },
  {
    id: '8',
    label: 'Aug',

    width: '5%',
  },
  {
    id: '9',
    label: 'Sep',

    width: '5%',
  },
  {
    id: '10',
    label: 'Oct',

    width: '5%',
  },
  {
    id: '11',
    label: 'Nov',

    width: '5%',
  },
  {
    id: '12',
    label: 'Dec',

    width: '5%',
  },
];

export const ANNUAL_COLUMNS = [
  {
    id: 'return_year',
    label: 'Year',
  },
  {
    id: 'return_value',
    label: 'Total',

    percent: true,
    align: 'right' as const,
  },
];

export const MANAGER_CHART = [
  {
    id: 'fund',
    label: 'Fund',

    width: '11%',
  },
  {
    id: 'vintage',
    label: 'Vintage',

    align: 'right' as const,
    width: '15.67%',
  },
  {
    id: 'size',
    label: 'Size',

    width: '15.67%',
    align: 'right' as const,
  },
  {
    id: 'netdpi',
    label: 'Net DPI',

    align: 'right' as const,
    width: '17.67%',
    tooltip: DPI_TOOLTIP_CONTENT,
  },
  {
    id: 'nettvpi',
    label: 'Net TVPI',

    align: 'right' as const,
    width: '17.67%',
    tooltip: TVPI_TOOLTIP_CONTENT,
  },
  {
    id: 'netirr',
    label: 'Net IRR',

    width: '17.67%',
    align: 'right' as const,
    tooltip: IRR_TOOLTIP_CONTENT,
  },
];

export const ADD_EDIT_FUND_STEPS = [
  'Fund Details',
  'Fund Structure & Stats',
  'Terms & Service Providers',
  'Historical Returns',
  'Fund Documents',
  'Subscription Checklist',
];

export const sortByTypes: TSortBy[] = [
  {
    key: 'fund_name',
    value: 'Fund Name',
  },
  {
    key: 'last_updated_date',
    value: 'Last Updated Fund',
  },
];

export const FundTypeClosedEnded = 'Closed-Ended';
