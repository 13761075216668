import MuiCard from 'common/components/card';

const Highlight = ({ obj }: any) => {
  return (
    <MuiCard>
      <img src={obj.img} alt='' />
      {obj.text}
    </MuiCard>
  );
};

export default Highlight;
