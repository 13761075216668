import { FieldErrors } from 'react-hook-form';
import styled from '@emotion/styled';
import { ReactComponent as WarningIcon } from 'common/assets/images/Warning.svg';

export const ErrorMessage = (message: FieldErrors) => {
  if (!message) return null;
  return (
    <Wrapper className='error-box'>
      <span className='d-flex'>
        <WarningIcon className='error-icon' />
        <span className='error-message justify-center'>{message.error}</span>
      </span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .error-icon {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    margin-right: 0.25rem;

    path {
      stroke: var(--d400);
      &:last-of-type {
        stroke: initial;
        fill: var(--d400);
      }
    }
  }

  .error-message {
    color: var(--d400);
    font-size: 13px;
  }
`;
