import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { PieChart, Pie, ResponsiveContainer, Label, Cell, Tooltip } from 'recharts';
import Loading from '../Loading';

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  border-left: 1px solid #c8c8c8;

  svg {
    transform: scale(1.1);
  }

  .label1 {
    fill: #8d8d8d;
    font-size: 10px;
    transform: translateY(-10px);
  }
  .label2 {
    font-size: 24px;
    font-weight: 300;
    transform: translateY(10px);
  }

  .label1,
  .label2 {
    animation: fadeIn 1s ease-in;
  }

  .custom-tooltip {
    background: white;
    border-radius: 0.25rem;
    border: 1px solid #ddd;
    padding: 0.5rem;
    h4 {
      font-size: 13px;
      color: var(--p500);
      font-weight: 500;
    }
    h5 {
      color: var(--s30);
    }
  }
`;

interface Props {
  data: Array<any>;
  dataKey: string;
  nameKey: string;
  innerRadius?: string;
  outerRadius?: string;
  label1?: string;
  label2?: string;
  isLoading?: boolean;
  error?: boolean;
  disableTooltip?: boolean;
  height?: number;
  isPercentageValue?: boolean;
  label1Styles?: any;
}

const RecEngineDonut = ({
  data,
  dataKey,
  nameKey,
  innerRadius = '70%',
  outerRadius = '100%',
  label1,
  label2,
  isLoading,
  error,
  disableTooltip = false,
  height = 245,
  isPercentageValue = false,
  label1Styles,
}: Props) => {
  const [nullCheck, setNullCheck] = useState<boolean>(false);
  useEffect(() => {
    if (data && Array.isArray(data)) {
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i][nameKey] === 'test' || data[i][dataKey] === null) {
          setNullCheck(true);
          break;
        }
      }
    }
  }, [data, dataKey, nameKey]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <h4 className='fundName subtitle mb-3'>{payload[0].name}:</h4>
          <h5 className='fundValue font-wt-400 mb-0'>
            {' '}
            {isPercentageValue
              ? `${payload[0].value.toFixed(2)}%`
              : `$${payload[0].value.toLocaleString()}`}
          </h5>
        </div>
      );
    }

    return null;
  };

  if (isLoading) return <Loading />;
  if (error) return <div>Error!</div>;

  const COLORS = ['#CADFF3', '#98C2E7', '#77AEDF', '#4590D3'];
  return (
    <StyledResponsiveContainer width={'100%'} height={height}>
      <PieChart>
        {!nullCheck && !disableTooltip && <Tooltip content={<CustomTooltip />} />}
        <Pie
          data={nullCheck ? [{ [nameKey]: 'test', [dataKey]: 1 }] : data}
          dataKey={dataKey}
          nameKey={nameKey}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
        >
          {data.map((entry: any, index: number) => (
            <Cell fill={COLORS[index % COLORS.length]} key={`donutCell${index}`} />
          ))}
          {label1 && (
            <Label
              style={label1Styles}
              value={label1}
              dy={12}
              position='center'
              className='label1'
            />
          )}
          {label2 && <Label value={label2} position='center' className='label2' />}
        </Pie>
      </PieChart>
    </StyledResponsiveContainer>
  );
};

export default RecEngineDonut;
