import styled from '@emotion/styled';
import MuiModal from 'common/components/modal';

export const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 656px;
    min-height: unset;
  }

  &.overrideMaxWidth {
    .MuiTable-root {
      min-width: 1700px;
      td {
        font-weight: 500;
      }
    }
    .MuiPaper-root {
      max-width: 1200px;
      min-height: 50%;
    }
  }

  h2 {
    font-size: 22px;
    padding: 2rem 28px 1.5rem;
    line-height: 26px;
    color: var(--s50);
  }

  .MuiDialogContent-root {
    padding-left: 28px;
    padding-right: 28px;
  }

  h4 {
    font-size: 14px;
    font-weight: 500;
    color: var(--s40);
  }

  .user-note {
    font-size: 14px;
    color: var(--s30);
  }

  .fileName {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    svg {
      font-size: 13px;
      color: var(--green);
      margin-right: 0.5rem;
    }
    span {
      font-size: 13px;
      color: #8e8e8e;
    }
  }

  .delete {
    cursor: pointer;
    font-size: 18px;
    color: var(--s30);
  }

  .warn {
    color: var(--d400);
    path {
      stroke: var(--d400);
      &.circle {
        fill: var(--d400);
        stroke: initial;
      }
    }
  }

  .dropzone {
    background-color: var(--s7);
    border-radius: 0.5rem;
    font-size: 14px;
    padding: 30px;
    font-weight: 500;
    color: var(--s20);
    svg {
      width: 64px;
      height: 64px;
      margin-bottom: 1.5rem;
      color: var(--s15);
    }
  }

  .errorTable > .tableWrapper {
    border: 1px solid var(--d400);
  }

  .leftShadow > .tableWrapper {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 10px;
      height: 100%;
    }
    &:before {
      left: 0;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  .rightShadow > .tableWrapper {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 10px;
      height: 100%;
    }
    &:after {
      right: 0;
      background: linear-gradient(to left, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
`;
