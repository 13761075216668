import getAxiosInstance from "common/utils/axios";
import { ResetPasswordType } from "components/forgotPassword/types";

export const requestPasswordReset = async (username: string) => {
    const axios = await getAxiosInstance();
    return await axios.post('qaip/v1/usermanagement/resetPwd', { username });
}

export const resetPassword = async (data: ResetPasswordType) => {
    const axios = await getAxiosInstance();
    return await axios.post('qaip/v1/usermanagement/confirmResetPwd', data);
}