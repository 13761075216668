import axios from 'axios';
import { getAccessToken, getUserId, getBehalfOf } from 'helpers';

type AxiosHeaderType = {
  Authorization?: string;
  'x-idToken'?: string;
  'x-userid'?: string;
  'x-buserid'?: string;
  'Access-Control-Allow-Origin'?: string;
  isOkta?: boolean;
  isWealth?: boolean;
};

const getAxiosInstance = async (
  buserId?: string,
  queryWithoutToken?: boolean | undefined,
  baseURL?: string | undefined,
  isTickerCheck?: boolean,
) => {
  const accessToken = await getAccessToken();
  const userId = await getUserId();
  const behalfOf = await getBehalfOf();
  // Validates if token exist or not
  // If not returns an empty object
  const headers: AxiosHeaderType = accessToken
    ? isTickerCheck
      ? {
          Authorization: process.env.REACT_APP_MAGNIFI_TICKER_VALIDATOR_URL,
          'Access-Control-Allow-Origin': '*',
        }
      : {
          Authorization: `Bearer ${accessToken}`,
          'x-idToken': accessToken,
          'x-userid': userId,
          'x-buserid': behalfOf?.userId ?? '',
          'Access-Control-Allow-Origin': '*',
          isOkta: true,
          isWealth: false,
        }
    : {};

  return axios.create({
    baseURL: baseURL ?? process.env.REACT_APP_SERVER_BASE_URL,
    headers: queryWithoutToken ? {} : headers,
  });
};

export default getAxiosInstance;
