import { useEffect, useRef, useState } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { config, oktaAuth } from 'common/utils/oktaConfig';
import { Tokens } from '@okta/okta-auth-js';
import { OktaAuth } from 'store/user/types';
import { useTypedDispatch } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import Logo from 'common/assets/images/QualisLogo.svg';
import { Close } from '@mui/icons-material';

const OktaSignInWidget = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [header, setHeader] = useState<boolean>(true);
  const onSuccess = async (tokens: Tokens) => {
    const oktaAuthObject: OktaAuth = {
      accessToken: tokens.accessToken?.accessToken,
      expiresIn: 3600,
      idToken: tokens.idToken?.idToken,
      refreshToken: tokens.refreshToken?.refreshToken,
      tokenType: 'Bearer',
    };
    const username: string | undefined = tokens.accessToken?.claims.sub;
    await localStorage.setItem('currentUser', JSON.stringify(oktaAuthObject));
    await localStorage.setItem('isNotificationModalOpen', 'true');
    await dispatch({ type: 'FETCH_USER_PROFILE', payload: username });
    await oktaAuth.handleLoginRedirect(tokens);
    await navigate('/');
  };

  const onError = async (error: any) => {
    console.log('Sign in error:', error);
    await oktaAuth.signOut();
    await dispatch({ type: 'LOGOUT_ATTEMPT' });
  };

  const widgetRef = useRef<any>();
  useEffect(() => {
    const currentRef = widgetRef.current;
    const widget = currentRef && new OktaSignIn(config.widget);

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);
    return () => {
      currentRef && widget.remove();
    };
    // eslint-disable-next-line
  }, [config, onSuccess, onError]);

  return (
    <div className='authBG'>
      {header && (
        <header>
          Tifin Private Markets now uses Okta for username and password authentication. Please check
          your email for instructions on how to reset your password, or contact the Tifin Private
          Markets team.
          <Close className='cursor-pointer' onClick={() => setHeader(false)} />
        </header>
      )}
      <Grid container className='form' justifyContent='center'>
        <Grid item xs={10} mb={15}>
          <img src={Logo} alt='Tifin Private Markets' />
        </Grid>
        <Grid item xs={12}>
          <div ref={widgetRef} />
        </Grid>
      </Grid>
    </div>
  );
};
export default OktaSignInWidget;
