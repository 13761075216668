type Props = {
  dir: 'up' | 'down' | 'upDown';
};

function CompareArrow({ dir }: Props) {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {dir === 'upDown' ? (
        <>
          <path
            d='M8.75 14.082L6.25 16.582L3.75 14.082'
            stroke='#4F4F4F'
            stroke-width='1.6'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M6.25 4.08203V16.582'
            stroke='#4F4F4F'
            stroke-width='1.6'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M11.25 6.58203L13.75 4.08203L16.25 6.58203'
            stroke='#4F4F4F'
            stroke-width='1.6'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M13.75 16.582V4.08203'
            stroke='#4F4F4F'
            stroke-width='1.6'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </>
      ) : dir === 'down' ? (
        <>
          <path
            d='M15.8334 14.082L13.3334 16.582L10.8334 14.082'
            stroke='#4F4F4F'
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M13.3334 4.08203V16.582'
            stroke='#4F4F4F'
            strokeWidth='1.6'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </>
      ) : (
        <>
          <path
            d='M7.5 6.58203L10 4.08203L12.5 6.58203'
            stroke='#4F4F4F'
            stroke-width='1.6'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M10 16.582V4.08203'
            stroke='#4F4F4F'
            stroke-width='1.6'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </>
      )}
    </svg>
  );
}

export default CompareArrow;
