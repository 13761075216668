import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiAccordion from 'common/components/accordion';
import MuiButton from 'common/components/button';
import MuiTooltip from 'common/components/tooltip';
import {
  ELIGIBILITY_TOOLTIP_CONTENT,
  LIQUIDITY_TOOLTIP_CONTENT,
  LOCKUP_TOOLTIP_CONTENT,
} from 'common/constants/tooltipContent';
import { getEmoji } from 'helpers/investments';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { selectCurrentFilters } from 'store/investment/selectors';
import { setCurrentFilters, setPage } from 'store/investment/slice';

const StyledGrid = styled(Grid)`
  background-color: white;
  padding: 1.5rem 1.5rem 1.5rem 0;
  min-height: 100vh;

  h6 {
    color: var(--p600);
    cursor: pointer;
  }

  .infoIcon {
    font-size: 1.25rem;
    margin-left: 0.25rem;
  }
  .label-color {
    color: var(--s40);
  }
  .filter-button-hover {
    &:hover {
      border-color: var(--p150);
    }
  }
`;

interface Props {
  list: Record<string, String[]>;
  currentFilters: Record<string, String[]>;
}

const InvestmentsFilterTitle: Record<string, string> = {
  fund_themes: 'Themes',
  asset_class: 'Asset Class',
  class: 'Class',
  eligibility: 'Eligibility',
  fundingType: 'Funding Type',
  isoCurrency: 'ISO Currency',
  liquidities: 'Early Liquidity',
  lockups: 'Minimum Lockup',
  rating: 'Rating',
  status: 'Status',
  strategy: 'Strategy',
  wfStatus: 'WF Status',
};

const Filters = ({ list = {} }: Props) => {
  const dispatch = useTypedDispatch();
  const currentFilters = useAppSelector(selectCurrentFilters);
  let activeFiltersSchema: { [id: string]: any } = {};
  Object.keys(list).forEach((id: string) => (activeFiltersSchema[id] = []));

  const addFilter = (id: string, filter: String) => {
    dispatch(setPage(0));
    const oldFilterObj = { ...currentFilters };
    let oldArray: any[] = [];
    if (Object.keys(oldFilterObj).includes(id)) {
      oldArray = [...oldFilterObj[id]];
    }
    //Remove if filter is already active. Else, add
    if (oldArray && oldArray.some((f: String) => f === filter)) {
      oldArray = oldArray.filter((f: String) => f !== filter);
    } else {
      if (id === 'class') {
        const isOffshore = filter === 'US Offshore';
        oldArray = oldArray.filter(
          (f: String) => f !== (isOffshore ? 'US Onshore' : 'US Offshore'),
        );
      }
      oldArray.push(filter);
    }
    dispatch(setCurrentFilters({ ...currentFilters, [id]: oldArray }));
  };

  const clearFilters = () => {
    dispatch(setPage(0));
    dispatch(setCurrentFilters(activeFiltersSchema));
  };

  return (
    <StyledGrid container direction='column'>
      <Grid item className='flex-center-between' mb={2}>
        <h5 className='mb-0 label-color'>FILTER BY</h5>
        <MuiButton buttonClick={clearFilters}>
          <h5 className='font-wt-500 mb-0'>Clear All</h5>
        </MuiButton>
      </Grid>
      {Object.keys(list).map((key: string, i: number) => (
        <MuiAccordion
          title={
            <span className='flex-center-start'>
              {InvestmentsFilterTitle[key]}
              {InvestmentsFilterTitle[key] === 'Eligibility' && (
                <MuiTooltip content={ELIGIBILITY_TOOLTIP_CONTENT} />
              )}
              {InvestmentsFilterTitle[key] === 'Minimum Lockup' && (
                <MuiTooltip content={LOCKUP_TOOLTIP_CONTENT} />
              )}
              {InvestmentsFilterTitle[key] === 'Early Liquidity' && (
                <MuiTooltip content={LIQUIDITY_TOOLTIP_CONTENT} />
              )}
            </span>
          }
          key={`filterGroup${i}`}
          defaultExpanded
        >
          <Grid container spacing={1}>
            {list[key].map((filter: String, j: number) => (
              <Grid item xs={'auto'} key={`filterGroupBtn${j}`}>
                <MuiButton
                  key={`filter${i}${j}`}
                  className={`filterBtn ${
                    currentFilters[key]?.some((f: String) => f === filter)
                      ? 'active'
                      : 'filter-button-hover'
                  }`}
                  buttonClick={() => addFilter(key, filter)}
                >
                  {key === 'lockups' && filter !== 'No Lockup' && filter !== 'Fund Term' ? (
                    `${filter} Months`
                  ) : key === 'fund_themes' ? (
                    <>
                      {getEmoji(filter as string)}
                      {filter}
                    </>
                  ) : (
                    filter
                  )}
                </MuiButton>
              </Grid>
            ))}
          </Grid>
        </MuiAccordion>
      ))}
    </StyledGrid>
  );
};

export default Filters;
