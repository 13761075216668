import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepIconProps } from '@mui/material/StepIcon';
import { CheckCircle, CircleOutlined } from '@mui/icons-material';

const StyledStepper = styled(Stepper)`
  margin-bottom: 2rem;

  .MuiStep-root {
    flex: 1 1 0;
    color: #66a4db;
    margin-bottom: 0.5rem;
    svg {
      color: #66a4db;
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 5px;
      border-radius: 50px;
      background-color: #d7e7f8;
      margin-bottom: 0.5rem;
    }
    &.active svg {
      stroke: #66a4db;
      stroke-width: 2;
    }
    &.Mui-completed svg {
      color: #5eb734;
    }
    &.active:before,
    &.Mui-completed:before {
      background-color: #66a4db;
    }

    .MuiStepLabel-label {
      color: black;
      font-size: 12px;
    }
  }

  .MuiStep-root + .MuiStep-root {
    margin-left: auto;
  }
`;

// Replace icons later when design is finalized
const StepIcon = ({ completed }: StepIconProps) => {
  return completed ? <CheckCircle /> : <CircleOutlined />;
};

type Props = {
  steps: string[];
  activeStep: number;
};

export default function MuiStepper({ steps, activeStep }: Props) {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <StyledStepper activeStep={activeStep} connector={null} className='flex-center-between'>
        {steps.map((label: string, l: number) => (
          <Step key={label} className={activeStep === l ? 'active' : ''}>
            <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </StyledStepper>
    </Stack>
  );
}
