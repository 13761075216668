import Highlight1 from 'common/assets/images/recommendation/Highlight1.svg';
import Highlight2 from 'common/assets/images/recommendation/Highlight2.svg';
import Highlight3 from 'common/assets/images/recommendation/Highlight3.svg';

// DATA
export const ENGINES = [
  {
    title: `How much of my client's portfolio should be in private markets?`,
    desc: `Upload a client's traditional portfolio and get recommendations on how much you should allocate to Private Market investments`,
  },
  {
    title: `How should I allocate to different Private Market investment styles?`,
    desc: `Get recommended weights for different styles to align Private Markets investments with your traditional portfolio`,
  },
  {
    title: `What funds should my client invest in?`,
    desc: `View fund specific recommendations that enhance client's portfolio return and risk metrics`,
  },
];

export const HIGHLIGHTS = [
  {
    text: `Get Private Market investment recommendations for your clients`,
    img: Highlight1,
  },
  { text: `Get closer to achieving your goals with Invesment Optimization`, img: Highlight2 },
  {
    text: `Understand willingness and capacity for Risk with TIFIN Risk`,
    img: Highlight3,
  },
];

export const ENGINE_1_STEPS = [`Input Client Parameters`, `Results`];

export const ENGINE_2_STEPS = [`Client Details and Portfolio`, `Results`];

export const PORTFOLIO_STATS = {
  Returns_without_Alts: 3.78,
  Risk_without_Alts: 11.55,
  Maximum_Drawdown_without_Alts: -35,
  Returns_with_Alts: 3.96,
  Risk_with_Alts: 10.54,
  Maximum_Drawdown_with_Alts: -25,
};

export const SECTOR_DATA = [
  { id: 'Information Technology', val: 32 },
  { id: 'Financials', val: 14.5 },
  { id: 'Healthcare', val: 9.05 },
  { id: 'Consumer Staples', val: 4.18 },
  { id: 'Consumer Discretionary', val: 8.46 },
  { id: 'Industrials', val: 7.37 },
  { id: 'Materials', val: 1.93 },
  { id: 'Energy', val: 3.08 },
  { id: 'Real Estate', val: 1.78 },
  { id: 'Utilities', val: 2.22 },
  { id: 'Communication Services', val: 3.75 },
  { id: 'Others', val: 11.7 },
];

export const ENGINE_2_INFO_MODAL_ROWS = [
  {
    _first: 'Diversification',
    div: '100%',
  },
  {
    _first: 'Income',
    div: '-7%',
    income: '100%',
  },
  {
    _first: 'Growth',
    div: '31%',
    income: '39%',
    growth: '100%',
  },
  {
    _first: 'ESG',
    div: '10%',
    income: '4%',
    growth: '23%',
    esg: '100%',
  },
  {
    _first: 'S&P 500',
    div: '0%',
    income: '0%',
    growth: '0%',
    esg: '0%',
    's&p': '100%',
  },
];

export const PROJ_ROWS = [
  {
    _type: 'Returns',
    withAlts: `${PORTFOLIO_STATS.Returns_with_Alts}%`,
    withoutAlts: `${PORTFOLIO_STATS.Returns_without_Alts}%`,
  },
  {
    _type: 'Volatility (Standard Deviation)',
    withAlts: `${PORTFOLIO_STATS.Risk_with_Alts}%`,
    withoutAlts: `${PORTFOLIO_STATS.Risk_without_Alts}%`,
  },
  {
    _type: 'Maximum Drawdown',
    withAlts: `${PORTFOLIO_STATS.Maximum_Drawdown_with_Alts}%`,
    withoutAlts: `${PORTFOLIO_STATS.Maximum_Drawdown_without_Alts}%`,
  },
];

// COLUMNS

export const NOT_SUPPORTED_HOLDING_COLUMNS = [
  { needsFormatter: false, id: 'Ticker', label: 'TICKER', width: '10%' },
  {
    needsFormatter: true,
    id: 'Amount',
    label: 'AMOUNT',
    align: 'right' as const,
    width: '20%',
  },
];

export const HOLDING_COLUMNS = [
  { needsFormatter: false, id: 'Ticker', label: 'TICKER' },
  { needsFormatter: false, id: 'name', label: 'NAME' },
  // { needsFormatter: false, id: 'id', label: 'FUND NAME', width: '50%' },
  {
    needsFormatter: true,
    id: 'market-value',
    label: 'AMOUNT',
    align: 'right' as const,
  },
  {
    needsFormatter: false,
    id: 'tableWeight',
    label: 'Weight',
    width: '20%',
    align: 'right' as const,
  },
  // {
  //   needsFormatter: false,
  //   id: 'tableValFormatted',
  //   label: 'ALLOCATION (%)',
  //   align: 'right' as const,
  //   width: '20%',
  // },
];

export const HOLDING_COLUMNS_EXCLUDING_FUNDNAME = [
  { needsFormatter: false, id: 'Ticker', label: 'TICKER' },
  {
    needsFormatter: true,
    id: 'market-value',
    label: 'AMOUNT',
    align: 'right' as const,
  },
];

export const ENGINE_2_INFO_MODAL_COLUMNS = [
  {
    needsFormatter: false,
    id: '_first',
    label: '',
    width: '18%',
  },
  {
    needsFormatter: false,
    id: 'div',
    label: 'Diversification',
    width: '15%',
    align: 'right' as const,
  },
  {
    needsFormatter: false,
    id: 'income',
    label: 'Income',
    width: '15%',
    align: 'right' as const,
  },
  {
    needsFormatter: false,
    id: 'growth',
    label: 'Growth',
    width: '15%',
    align: 'right' as const,
  },
  {
    needsFormatter: false,
    id: 'esg',
    label: 'ESG',
    width: '15%',
    align: 'right' as const,
  },
  {
    needsFormatter: false,
    id: 's&p',
    label: 'S&P 500',
    width: '17%',
    align: 'right' as const,
  },
];

export const PROJ_COLUMNS = [
  {
    id: '_type',
    label: 'Measure',
    needsFormatter: false,
    width: '55%',
  },
  {
    id: 'withAlts',
    label: 'With Alts',
    needsFormatter: false,
    align: 'right' as const,
    width: '20%',
  },
  {
    id: 'withoutAlts',
    label: 'Without Alts',
    needsFormatter: false,
    align: 'right' as const,
    width: '25%',
  },
];

export const PROJ_PERF = [
  { age: 40, withoutAlts: 10000000.0, withAlts: 10000000.0 },
  { age: 41, withoutAlts: 10378000.0, withAlts: 10396000.0 },
  { age: 42, withoutAlts: 10770288.4, withAlts: 10807681.6 },
  { age: 43, withoutAlts: 11177405.3, withAlts: 11235665.79 },
  { age: 44, withoutAlts: 11599911.22, withAlts: 11680598.16 },
  { age: 45, withoutAlts: 12038387.87, withAlts: 12143149.84 },
  { age: 46, withoutAlts: 12493438.93, withAlts: 12624018.58 },
  { age: 47, withoutAlts: 12965690.92, withAlts: 13123929.71 },
  { age: 48, withoutAlts: 13455794.04, withAlts: 13643637.33 },
  { age: 49, withoutAlts: 13964423.05, withAlts: 14183925.37 },
  { age: 50, withoutAlts: 14492278.24, withAlts: 14745608.81 },
  { age: 51, withoutAlts: 15040086.36, withAlts: 15329534.92 },
  { age: 52, withoutAlts: 15608601.62, withAlts: 15936584.5 },
  { age: 53, withoutAlts: 16198606.76, withAlts: 16567673.25 },
  { age: 54, withoutAlts: 16810914.1, withAlts: 17223753.11 },
  { age: 55, withoutAlts: 17446366.65, withAlts: 17905813.73 },
  { age: 56, withoutAlts: 18105839.31, withAlts: 18614883.96 },
  { age: 57, withoutAlts: 18790240.04, withAlts: 19352033.36 },
  { age: 58, withoutAlts: 19500511.11, withAlts: 20118373.88 },
  { age: 59, withoutAlts: 20237630.43, withAlts: 20915061.49 },
  { age: 60, withoutAlts: 21002612.86, withAlts: 21743297.93 },
  { age: 61, withoutAlts: 21796511.63, withAlts: 22604332.52 },
  { age: 62, withoutAlts: 22620419.77, withAlts: 23499464.09 },
  { age: 63, withoutAlts: 23475471.64, withAlts: 24430042.87 },
  { age: 64, withoutAlts: 24362844.46, withAlts: 25397472.57 },
  { age: 65, withoutAlts: 25283759.98, withAlts: 26403212.48 },
];

export const PROJ_PERF_E1_R1 = [
  { age: 40, withoutAlts: 10000000.0, withAlts: 10000000.0 },
  { age: 41, withoutAlts: 10378000.0, withAlts: 10425000.0 },
  { age: 42, withoutAlts: 10770288.4, withAlts: 10868062.5 },
  { age: 43, withoutAlts: 11177405.3, withAlts: 11329955.15 },
  { age: 44, withoutAlts: 11599911.22, withAlts: 11811478.25 },
  { age: 45, withoutAlts: 12038387.86, withAlts: 12313466.07 },
  { age: 46, withoutAlts: 12493438.92, withAlts: 12836788.38 },
  { age: 47, withoutAlts: 12965690.91, withAlts: 13382351.89 },
  { age: 48, withoutAlts: 13455794.03, withAlts: 13951101.84 },
  { age: 49, withoutAlts: 13964423.05, withAlts: 14544023.67 },
  { age: 50, withoutAlts: 14492278.24, withAlts: 15162144.68 },
  { age: 51, withoutAlts: 15040086.35, withAlts: 15806535.82 },
  { age: 52, withoutAlts: 15608601.62, withAlts: 16478313.6 },
  { age: 53, withoutAlts: 16198606.76, withAlts: 17178641.93 },
  { age: 54, withoutAlts: 16810914.1, withAlts: 17908734.21 },
  { age: 55, withoutAlts: 17446366.65, withAlts: 18669855.41 },
  { age: 56, withoutAlts: 18105839.31, withAlts: 19463324.27 },
  { age: 57, withoutAlts: 18790240.03, withAlts: 20290515.55 },
  { age: 58, withoutAlts: 19500511.11, withAlts: 21152862.46 },
  { age: 59, withoutAlts: 20237630.43, withAlts: 22051859.11 },
  { age: 60, withoutAlts: 21002612.86, withAlts: 22989063.13 },
  { age: 61, withoutAlts: 21796511.62, withAlts: 23966098.31 },
  { age: 62, withoutAlts: 22620419.76, withAlts: 24984657.49 },
  { age: 63, withoutAlts: 23475471.63, withAlts: 26046505.43 },
  { age: 64, withoutAlts: 24362844.46, withAlts: 27153481.91 },
  { age: 65, withoutAlts: 25283759.98, withAlts: 28307504.89 },
];

export const PROJ_PERF_E1_R2 = [
  { age: 40, withoutAlts: 10000000.0, withAlts: 10000000.0 },
  { age: 41, withoutAlts: 10378000.0, withAlts: 10385000.0 },
  { age: 42, withoutAlts: 10770288.4, withAlts: 10784822.5 },
  { age: 43, withoutAlts: 11177405.3, withAlts: 11200038.16 },
  { age: 44, withoutAlts: 11599911.22, withAlts: 11631239.63 },
  { age: 45, withoutAlts: 12038387.86, withAlts: 12079042.36 },
  { age: 46, withoutAlts: 12493438.92, withAlts: 12544085.49 },
  { age: 47, withoutAlts: 12965690.91, withAlts: 13027032.78 },
  { age: 48, withoutAlts: 13455794.03, withAlts: 13528573.54 },
  { age: 49, withoutAlts: 13964423.05, withAlts: 14049423.62 },
  { age: 50, withoutAlts: 14492278.24, withAlts: 14590326.43 },
  { age: 51, withoutAlts: 15040086.35, withAlts: 15152054.0 },
  { age: 52, withoutAlts: 15608601.62, withAlts: 15735408.08 },
  { age: 53, withoutAlts: 16198606.76, withAlts: 16341221.29 },
  { age: 54, withoutAlts: 16810914.1, withAlts: 16970358.31 },
  { age: 55, withoutAlts: 17446366.65, withAlts: 17623717.11 },
  { age: 56, withoutAlts: 18105839.31, withAlts: 18302230.21 },
  { age: 57, withoutAlts: 18790240.03, withAlts: 19006866.08 },
  { age: 58, withoutAlts: 19500511.11, withAlts: 19738630.42 },
  { age: 59, withoutAlts: 20237630.43, withAlts: 20498567.69 },
  { age: 60, withoutAlts: 21002612.86, withAlts: 21287762.55 },
  { age: 61, withoutAlts: 21796511.62, withAlts: 22107341.41 },
  { age: 62, withoutAlts: 22620419.76, withAlts: 22958474.05 },
  { age: 63, withoutAlts: 23475471.63, withAlts: 23842375.3 },
  { age: 64, withoutAlts: 24362844.46, withAlts: 24760306.75 },
  { age: 65, withoutAlts: 25283759.98, withAlts: 25713578.56 },
];

export const WG_E2_R1 = [
  { age: 40, withoutAlts: 10000000.0, withAlts: 10000000.0 },
  { age: 41, withoutAlts: 10378000.0, withAlts: 10453000.0 },
  { age: 42, withoutAlts: 10770288.4, withAlts: 10926520.9 },
  { age: 43, withoutAlts: 11177405.3, withAlts: 11421492.29 },
  { age: 44, withoutAlts: 11599911.22, withAlts: 11938885.89 },
  { age: 45, withoutAlts: 12038387.86, withAlts: 12479717.42 },
  { age: 46, withoutAlts: 12493438.92, withAlts: 13045048.62 },
  { age: 47, withoutAlts: 12965690.91, withAlts: 13635989.33 },
  { age: 48, withoutAlts: 13455794.03, withAlts: 14253699.64 },
  { age: 49, withoutAlts: 13964423.05, withAlts: 14899392.24 },
  { age: 50, withoutAlts: 14492278.24, withAlts: 15574334.71 },
  { age: 51, withoutAlts: 15040086.35, withAlts: 16279852.07 },
  { age: 52, withoutAlts: 15608601.62, withAlts: 17017329.37 },
  { age: 53, withoutAlts: 16198606.76, withAlts: 17788214.39 },
  { age: 54, withoutAlts: 16810914.1, withAlts: 18594020.5 },
  { age: 55, withoutAlts: 17446366.65, withAlts: 19436329.63 },
  { age: 56, withoutAlts: 18105839.31, withAlts: 20316795.36 },
  { age: 57, withoutAlts: 18790240.03, withAlts: 21237146.19 },
  { age: 58, withoutAlts: 19500511.11, withAlts: 22199188.91 },
  { age: 59, withoutAlts: 20237630.43, withAlts: 23204812.17 },
  { age: 60, withoutAlts: 21002612.86, withAlts: 24255990.16 },
  { age: 61, withoutAlts: 21796511.62, withAlts: 25354786.52 },
  { age: 62, withoutAlts: 22620419.76, withAlts: 26503358.35 },
  { age: 63, withoutAlts: 23475471.63, withAlts: 27703960.48 },
  { age: 64, withoutAlts: 24362844.46, withAlts: 28958949.89 },
  { age: 65, withoutAlts: 25283759.98, withAlts: 30270790.32 },
];

export const WG_E2_R2 = [
  { age: 40, withoutAlts: 10000000.0, withAlts: 10000000.0 },
  { age: 41, withoutAlts: 10378000.0, withAlts: 10390000.0 },
  { age: 42, withoutAlts: 10770288.4, withAlts: 10795210.0 },
  { age: 43, withoutAlts: 11177405.3, withAlts: 11216223.19 },
  { age: 44, withoutAlts: 11599911.22, withAlts: 11653655.89 },
  { age: 45, withoutAlts: 12038387.86, withAlts: 12108148.47 },
  { age: 46, withoutAlts: 12493438.92, withAlts: 12580366.26 },
  { age: 47, withoutAlts: 12965690.91, withAlts: 13071000.54 },
  { age: 48, withoutAlts: 13455794.03, withAlts: 13580769.57 },
  { age: 49, withoutAlts: 13964423.05, withAlts: 14110419.58 },
  { age: 50, withoutAlts: 14492278.24, withAlts: 14660725.94 },
  { age: 51, withoutAlts: 15040086.35, withAlts: 15232494.25 },
  { age: 52, withoutAlts: 15608601.62, withAlts: 15826561.53 },
  { age: 53, withoutAlts: 16198606.76, withAlts: 16443797.43 },
  { age: 54, withoutAlts: 16810914.1, withAlts: 17085105.53 },
  { age: 55, withoutAlts: 17446366.65, withAlts: 17751424.65 },
  { age: 56, withoutAlts: 18105839.31, withAlts: 18443730.21 },
  { age: 57, withoutAlts: 18790240.03, withAlts: 19163035.69 },
  { age: 58, withoutAlts: 19500511.11, withAlts: 19910394.08 },
  { age: 59, withoutAlts: 20237630.43, withAlts: 20686899.45 },
  { age: 60, withoutAlts: 21002612.86, withAlts: 21493688.53 },
  { age: 61, withoutAlts: 21796511.62, withAlts: 22331942.38 },
  { age: 62, withoutAlts: 22620419.76, withAlts: 23202888.13 },
  { age: 63, withoutAlts: 23475471.63, withAlts: 24107800.77 },
  { age: 64, withoutAlts: 24362844.46, withAlts: 25048005.0 },
  { age: 65, withoutAlts: 25283759.98, withAlts: 26024877.19 },
];

export const LIQ_COLUMNS = [
  {
    id: 'mktMove',
    label: 'Market Move',
    needsFormatter: false,
    width: '55%',
  },
  {
    id: 'withAlts',
    label: 'With Alts',
    needsFormatter: true,
    align: 'right' as const,
    width: '20%',
  },
  {
    id: 'withoutAlts',
    label: 'Without Alts',
    needsFormatter: true,
    align: 'right' as const,
    width: '25%',
  },
];

export const LIQ_ROWS = [
  {
    mktMove: '1.0 Standard Deviation Lower',
    withAlts: 6623000,
    withoutAlts: 9223000,
    visibleOnTable: true,
    year: 1,
  },
  {
    mktMove: '1.5 Standard Deviation Lower',
    withAlts: 6045500,
    withoutAlts: 8645500,
    year: 1.5,
  },
  {
    mktMove: '2.0 Standard Deviation Lower',
    withAlts: 5468000,
    withoutAlts: 8068000,
    visibleOnTable: true,
    year: 2,
  },
  {
    mktMove: '2.5 Standard Deviation Lower',
    withAlts: 4890500,
    withoutAlts: 7490500,
    year: 2.5,
  },
  {
    mktMove: '3.0 Standard Deviation Lower',
    withAlts: 4313000,
    withoutAlts: 6913000,
    visibleOnTable: true,
    year: 3,
  },
];

export const LIQUIDITY_IMPACT = [
  { fall: 5, withoutAlts: 9500000, withAlts: 7030000, year: 1 },
  { fall: 10, withoutAlts: 9000000, withAlts: 6660000, year: 1.5 },
  { fall: 15, withoutAlts: 8500000, withAlts: 6290000, year: 2 },
  { fall: 20, withoutAlts: 8000000, withAlts: 5920000, year: 2.5 },
  { fall: 25, withoutAlts: 7500000, withAlts: 5550000, year: 3 },
];
export const LIQUIDITY_IMPACT_E1_R1 = [
  { fall: 5, withoutAlts: 9500000, withAlts: 5225000, year: 1 },
  { fall: 10, withoutAlts: 9000000, withAlts: 4950000, year: 1.5 },
  { fall: 15, withoutAlts: 8500000, withAlts: 4675000, year: 2 },
  { fall: 20, withoutAlts: 8000000, withAlts: 4400000, year: 2.5 },
  { fall: 25, withoutAlts: 7500000, withAlts: 4125000, year: 3 },
];
export const LIQUIDITY_IMPACT_E1_R2 = [
  { fall: 5, withoutAlts: 9500000, withAlts: 8075000, year: 1 },
  { fall: 10, withoutAlts: 9000000, withAlts: 7650000, year: 1.5 },
  { fall: 15, withoutAlts: 8500000, withAlts: 7225000, year: 2 },
  { fall: 20, withoutAlts: 8000000, withAlts: 6800000, year: 2.5 },
  { fall: 25, withoutAlts: 7500000, withAlts: 6375000, year: 3 },
];

export const PORTFOLIO_COLS = [
  {
    id: 'ticker',
    label: 'Ticker',
    needsFormatter: false,
  },
  {
    id: 'id',
    label: 'Fund Name',
    needsFormatter: false,
  },
  {
    id: 'tableAltsValueFormatted',
    label: 'With Alts',
    needsFormatter: false,
    align: 'right' as const,
  },
  {
    id: 'tableValFormatted',
    label: 'Without Alts',
    needsFormatter: false,
    align: 'right' as const,
  },
];

export const PORTFOLIO_ROWS = [
  { ticker: 'SPY', fundName: 'SPRD S&P 500 ETF Trust', withoutAlts: '30.0%', withAlts: '22.2%' },
  { ticker: 'EFA', fundName: '	iShares MSCI EAFE ETF', withoutAlts: '10.0%', withAlts: '7.4%' },
  {
    ticker: 'VUG',
    fundName: '	Vangauard Growth Index Fund ETF',
    withoutAlts: '	10.0%',
    withAlts: '		7.4%',
  },
  {
    ticker: 'PHDG',
    fundName: '	Invesco S&P 500 Downside Hedged ETF',
    withoutAlts: '	10.0%',
    withAlts: '		7.4%',
  },
  {
    ticker: 'BND',
    fundName: '	Vanguard Total Bond Market Index Fund ETF',
    withoutAlts: '	20.0%',
    withAlts: '		14.8%',
  },
  { ticker: 'TIP', fundName: '	iShares TIPS Bond ETF', withoutAlts: '	10.0%', withAlts: '		7.4%' },
  {
    ticker: 'IGOV',
    fundName: '	iShares International Treasury Bond ETF',
    withoutAlts: '	5.0%',
    withAlts: '		3.7%',
  },
  {
    ticker: 'DBC',
    fundName: '	Invesco DB Commodity Index Tracking Fund',
    withoutAlts: '	3.0%',
    withAlts: '		2.2%',
  },
  {
    ticker: 'BIL',
    fundName: '	SPDR Bloomberg 1-3 Month T-Bill ETF',
    withoutAlts: '	2.0%',
    withAlts: '		1.5%',
  },
  { ticker: 'Alts', fundName: '	Private Markets	', withoutAlts: '-', withAlts: '26.00%' },
  { ticker: 'Total', fundName: '', withoutAlts: '100.0%', withAlts: '100.0%' },
];

export const REGIONAL = [
  {
    id: 'Developed Markets',
    val: 92.6,
  },
  {
    id: 'Emerging Markets',
    val: 7.4,
  },
];

export const ASSET = [
  { id: 'Equities', val: 44.4 },
  { id: 'Fixed Income', val: 22.2 },
  { id: 'Cash', val: 5.2 },
  { id: 'Other', val: 2.2 },
  { id: 'Alts	', val: 26.0 },
];

export const THEMES_PIE_CHART = [
  { id: 'Growth', val: 43, dollar: '$1.11M' },
  { id: 'Downside Protection', val: 29, dollar: '$0.74M' },
  { id: 'Income', val: 29, dollar: '$0.74M' },
  { id: 'Impact', val: 0, dollar: '$0.00M' },
];

export const THEMES_PIE_CHART_E2_R1 = [
  { id: 'Growth', val: 55, dollar: '$1.43M' },
  { id: 'Downside Protection', val: 35, dollar: '$0.91M' },
  { id: 'Income', val: 5, dollar: '$0.13M' },
  { id: 'Impact', val: 5, dollar: '$0.13M' },
];

export const THEMES_PIE_CHART_E2_R2 = [
  { id: 'Growth', val: 25, dollar: '$0.65M' },
  { id: 'Downside Protection', val: 45, dollar: '$1.17M' },
  { id: 'Income', val: 25, dollar: '$0.65M' },
  { id: 'Impact', val: 5, dollar: '$0.13M' },
];

export const THEMES_PIE_CHART_ALTS_BASE = [
  {
    id: 'Growth',
    withAltsVal: 43,
    withoutAltsVal: 42,
    dollarWithoutAlts: '$4.20M',
    dollarWithAlts: '$4.25M',
  },
  {
    id: 'Downside Protection',
    withAltsVal: 29,
    withoutAltsVal: 28,
    dollarWithoutAlts: '$2.80M',
    dollarWithAlts: '$2.85M',
  },
  {
    id: 'Income',
    withAltsVal: 28,
    withoutAltsVal: 28,
    dollarWithoutAlts: '$2.80M',
    dollarWithAlts: '$2.81M',
  },
  {
    id: 'Impact',
    withAltsVal: 0,
    withoutAltsVal: 2,
    dollarWithoutAlts: '$0.20M',
    dollarWithAlts: '$0.00M',
  },
];

export const THEMES_PIE_CHART_ALTS_E2_R1 = [
  {
    id: 'Growth',
    withAltsVal: 44,
    withoutAltsVal: 40,
    dollarWithoutAlts: '$4.00M',
    dollarWithAlts: '$4.39M',
  },
  {
    id: 'Downside Protection',
    withAltsVal: 24,
    withoutAltsVal: 20,
    dollarWithoutAlts: '$2.00M',
    dollarWithAlts: '$2.39M',
  },
  {
    id: 'Income',
    withAltsVal: 16,
    withoutAltsVal: 20,
    dollarWithoutAlts: '$2.00M',
    dollarWithAlts: '$1.61M',
  },
  {
    id: 'Impact',
    withAltsVal: 16,
    withoutAltsVal: 20,
    dollarWithoutAlts: '$2.00M',
    dollarWithAlts: '$1.61M',
  },
];

export const THEMES_PIE_CHART_ALTS_E2_R2 = [
  {
    id: 'Growth',
    withAltsVal: 36,
    withoutAltsVal: 40,
    dollarWithoutAlts: '$4.00M',
    dollarWithAlts: '$3.61M',
  },
  {
    id: 'Downside Protection',
    withAltsVal: 27,
    withoutAltsVal: 20,
    dollarWithoutAlts: '$2.00M',
    dollarWithAlts: '$2.65M',
  },
  {
    id: 'Income',
    withAltsVal: 21,
    withoutAltsVal: 20,
    dollarWithoutAlts: '$2.00M',
    dollarWithAlts: '$2.13M',
  },
  {
    id: 'Impact',
    withAltsVal: 16,
    withoutAltsVal: 10,
    dollarWithoutAlts: '$2.00M',
    dollarWithAlts: '$1.61M',
  },
];

export enum EntityPersonType {
  CORPORATION = 'Corporation',
  PARTNERSHIP = 'Partnership',
  LIMITED_LIABILITY_COMPANY = 'Limited Liability Company',
  FOUNDATION = 'Foundation',
  ENDOWMENT = 'Endowment',
  EMPLOYEE_BENEFIT_PLAN = 'Employee Benefit Plan',
  KEOGH_PLAN = 'Keogh Plan',
}

export enum HybridPersonType {
  OTHER = 'Other',
  TRUST = 'Trust',
}

export const NonNaturalClientTypeList = [
  'Corporation',
  'Partnership',
  'Limited Liability Company',
  'Foundation',
  'Endowment',
  'Employee Benefit Plan',
  'Keogh Plan',
  'Other',
  'Trust',
];
