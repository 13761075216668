export const headerLinks = {
  qualis: [
    {
      label: 'Home',
      value: 1,
      href: '/',
    },
    {
      label: 'Clients',
      value: 2,
      href: '/clients',
    },
    {
      label: 'Investments',
      value: 3,
      href: '/investments',
    },
    {
      label: 'Activity',
      value: 4,
      href: '/orders',
    },
    {
      label: 'Admin',
      value: 4,
      href: '/admin',
    },
    {
      label: 'My Recommendations',
      value: 5,
      href: '/recommendations',
    },
  ],
  fm: [
    {
      label: 'Investments',
      value: 3,
      href: '/investments',
    },
    {
      label: 'My Orderbooks',
      value: 4,
      href: '/myorderbooks',
    },
    {
      label: 'Admin',
      value: 4,
      href: '/admin',
    },
  ],
  dg: [
    {
      label: 'Home',
      value: 1,
      href: '/',
    },
    {
      label: 'Clients',
      value: 2,
      href: '/clients',
    },
    {
      label: 'Investments',
      value: 3,
      href: '/investments',
    },
    {
      label: 'Activity',
      value: 4,
      href: '/orders',
    },
    {
      label: 'Admin',
      value: 5,
      href: '/admin',
    },
    {
      label: 'My Recommendations',
      value: 5,
      href: '/recommendations',
    },
  ],
};
