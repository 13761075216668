export const getHoldingsData = (demoData: any, portfolioVal: any, recAllocation: any) => {
  const data = [...demoData];
  const updatedData = data.map((item: any) => {
    const withoutAltsValue = (item.amount / portfolioVal) * 100;
    return {
      ...item,
      val: (item.amount / portfolioVal) * 100,
      tableVal: `${withoutAltsValue}`,
      tableValFormatted: `${withoutAltsValue}.0%`,
      tableAltsValue: `${(withoutAltsValue * (1 - recAllocation / 100)).toFixed(1)}`,
      tableAltsValueFormatted: `${(withoutAltsValue * (1 - recAllocation / 100)).toFixed(1)}%`,
    };
  });
  return updatedData;
};
