import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import Textbox from 'common/components/inputField/Textbox';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  selectFundID,
  selectFundStructAndStats,
  selectInvestmentStats,
} from 'store/funds/selectors';
import { setFundsDetails, setFundStructAndStats, setInvestmentStats } from 'store/funds/slice';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { ErrorMessage } from 'common/components/errorMessageBox';
import MuiDatePicker from 'common/components/inputField/DatePicker';
import LoadingButton from 'common/components/button/LoadingButton';
import { format } from 'date-fns';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { useLocation, useNavigate } from 'react-router-dom';
import ControlledSelectField from 'common/components/inputField/ControlledSelectField';
import { handleGraphqlMutation, valueFormatter } from 'helpers';
import InvestmentManagerRecordsTable from '../../tables/investmentManagerRecords/InvestmentManagerRecordsTable';
import { INVESTMENT_MANAGER_RECORDS_COLUMNS } from './constants';
import { MUTATE_FUND_SECOND_STEP_QUERY, MUATATE_MANAGER_TRACK_RECORD } from './queries';
import { keysToCamel } from './utils';
import { useMutation, useQueryClient } from 'react-query';

type Props = {
  handleSubmitBtnClick: any;
  handleBackBtnClick: any;
  editing?: boolean;
};

const InvestmentStructAndStatsFormGQL = ({
  handleSubmitBtnClick,
  handleBackBtnClick,
  editing,
}: Props) => {
  const [selectedNextClose, setSelectedNextClose] = useState<any>(null);
  const [selectedFinalClose, setSelectedFinalClose] = useState<any>(null);
  const [investmentStatsArr, setInvestmentStatsArr] = useState<any[]>([{}]);
  const [fundAssets, setFundAssets] = useState<string | null>(null);
  const [firmAssets, setFirmAssets] = useState<string | null>(null);
  const [targetFundSize, setTargetFundSize] = useState<string | null>(null);
  const [managementFees, setManagementFees] = useState<string | null>(null);
  const [incentiveFees, setIncentiveFees] = useState<string | null>(null);
  const [preferredReturn, setPreferredReturn] = useState<string | null>(null);
  const [investmentPeriod, setInvestmentPeriod] = useState<string | null>(null);
  const [fundTerm, setFundTerm] = useState<string | null>(null);
  const [compoundedRateOfReturn, setCompoundedRateOfReturn] = useState<number | null>(null);
  const [standardDeviation, setStandardDeviation] = useState<string | null>(null);
  const [sharpeRatio, setSharpeRatio] = useState<number | null>(null);
  const [profitableMonths, setProfitableMonths] = useState<string | null>(null);
  const [maximumDrawdown, setMaximumDrawdown] = useState<string | null>(null);
  const [sp500, setSp500] = useState<string | null>(null);
  const [cashYield, setCashYield] = useState<string | null>(null);
  const [targetIrr, setTargetIrr] = useState<string | null>(null);
  const [impactScore, setImpactScore] = useState<string | null>(null);
  // Edit Funds state
  const location = useLocation();
  const id = location.pathname.split('/')[3];
  const newFundId = useAppSelector(selectFundID);

  const queryClient = useQueryClient();
  const storeFundStructAndStats = useAppSelector(selectFundStructAndStats);
  const storeFundInvestmentStats = useAppSelector(selectInvestmentStats);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const appendToList = () => {
    setInvestmentStatsArr([...investmentStatsArr, {}]);
  };

  const deleteFromList = (index: number) => {
    const newArr = investmentStatsArr.filter((x, i) => i !== index);
    setInvestmentStatsArr(newArr.length === 0 ? [] : newArr);
  };

  const fillUpDefaultDetails = (currentDefaultDetails: any) => {
    setSelectedFinalClose(
      currentDefaultDetails?.final_close &&
        new Date(currentDefaultDetails?.final_close) > new Date()
        ? currentDefaultDetails?.final_close
        : null,
    );

    setSelectedNextClose(
      currentDefaultDetails?.next_close && new Date(currentDefaultDetails?.next_close) > new Date()
        ? currentDefaultDetails?.next_close
        : null,
    );

    setFundAssets(currentDefaultDetails?.fund_assets?.toString());
    setFirmAssets(currentDefaultDetails?.firm_assets?.toString());
    setPreferredReturn(currentDefaultDetails?.preferred_return?.toString());
    setInvestmentPeriod(currentDefaultDetails?.investment_period?.toString());
    setFundTerm(currentDefaultDetails?.fund_term?.toString());
    setTargetFundSize(currentDefaultDetails?.target_fund_size?.toString());
    setCompoundedRateOfReturn(currentDefaultDetails?.compounded_rate_of_return);
    setStandardDeviation(currentDefaultDetails?.standard_deviation?.toString());
    setSharpeRatio(currentDefaultDetails?.sharpe_ratio);
    setCashYield(currentDefaultDetails?.cash_yield?.toString());
    setTargetIrr(currentDefaultDetails?.target_irr?.toString());
    setImpactScore(currentDefaultDetails?.impact_score?.toString());
    setProfitableMonths(currentDefaultDetails?.profitable_months_percentage?.toString());
    setIncentiveFees(currentDefaultDetails?.incentives_fees?.toString());
    setManagementFees(currentDefaultDetails?.management_fees?.toString());
    setMaximumDrawdown(currentDefaultDetails?.maximum_drawdown);
    setSp500(currentDefaultDetails?.s_and_p500?.toString());

    reset(currentDefaultDetails);
  };

  const handleFundManagerTrackRecord = (investmentStats: any[]) => {
    const filteredFundStats =
      investmentStats?.length > 0
        ? investmentStats?.filter((item: any) => {
            const values: any[] = Object.values(item);
            for (let i = 0; i < values.length; i++) {
              if (values[i]) {
                return true;
              }
            }
            return false;
          })
        : [{}];

    const clonedFilteredFundStats = filteredFundStats.map((el: any) => ({
      fund: el.fund,
      netdpi: el.netdpi,
      netirr: el.netirr,
      nettvpi: el.nettvpi,
      size: el.size,
      vintage: el.vintage,
    }));

    setInvestmentStatsArr(clonedFilteredFundStats.length === 0 ? [{}] : clonedFilteredFundStats);
  };

  //* THIS IS THE USE=EFFECT TO SETUP INFORMARION FROM REDUX
  useEffect(() => {
    if (storeFundStructAndStats !== null) {
      fillUpDefaultDetails(storeFundStructAndStats);
    }
    // eslint-disable-next-line
  }, [storeFundStructAndStats]);

  //* THIS IS THE USE=EFFECT TO SETUP FUND STATS FROM REDUX
  useEffect(() => {
    if (storeFundInvestmentStats) {
      handleFundManagerTrackRecord(storeFundInvestmentStats);
    }
    // eslint-disable-next-line
  }, [storeFundInvestmentStats]);

  const updateToList = (value: any, index: number) => {
    const oldList = [...investmentStatsArr];
    let current = { ...oldList[index] };
    current = { ...value };
    oldList[index] = current;
    setInvestmentStatsArr(oldList);
  };

  const { mutate: mutateFundStructAndStats, isLoading: mutatingFundSandS } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
        query: data.query,
        variables: { ...data.variables },
      });
    },
    {
      onSuccess(response: any) {
        if (response?.status === 500 || response?.status === 503) {
          const toast: ToastMessage = {
            type: ToastType.ERROR,
            message: 'Something went wrong. Please try again.',
          };
          dispatch(showToast(toast));
          return;
        }
        mutateManagerTrackRecord({
          query: MUATATE_MANAGER_TRACK_RECORD,
          variables: {
            fundId: id ? id : newFundId,
            fundStats: investmentStatsArr,
          },
        });
      },
      onError(error: any) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Something went wrong. Please try again.',
        };
        dispatch(showToast(toast));
        return;
      },
    },
  );

  const { mutate: mutateManagerTrackRecord, isLoading: mutatingManagerTrackRecord } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
        query: data.query,
        variables: { ...data.variables },
      });
    },
    {
      onSuccess(response: any) {
        if (response?.status === 500 || response?.status === 503) {
          const toast: ToastMessage = {
            type: ToastType.ERROR,
            message: 'Something went wrong. Please try again.',
          };
          dispatch(showToast(toast));
          return;
        }
        queryClient.invalidateQueries(`getFundDetails-${id ?? newFundId}`, {
          refetchInactive: true,
        });
        queryClient.invalidateQueries(`fundDetailsGQl${id ?? newFundId}`, {
          refetchInactive: true,
        });
        handleSubmitBtnClick();
      },
      onError(error: any) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Something went wrong. Please try again.',
        };
        dispatch(showToast(toast));
        return;
      },
    },
  );

  const handleSaveChanges = (data: any) => {
    const currentDate = new Date();

    if (selectedFinalClose === null && selectedNextClose !== null) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Final Close date cannot be null.',
      };
      dispatch(showToast(toast));
      window.scrollTo(0, 2200);
      return null;
    }

    if (selectedFinalClose !== null && selectedNextClose === null) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Next Close date cannot be null.',
      };
      dispatch(showToast(toast));
      window.scrollTo(0, 2200);
      return null;
    }

    if (selectedFinalClose !== null || selectedNextClose !== null) {
      if (selectedFinalClose < currentDate || selectedNextClose < currentDate) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Next close or Final close can only be future dates.',
        };
        dispatch(showToast(toast));
        window.scrollTo(0, 2200);
        return null;
      }
    }

    const nextClose = selectedNextClose ? format(new Date(selectedNextClose), 'yyyy-MM-dd') : null;
    const finalClose = selectedFinalClose
      ? format(new Date(selectedFinalClose), 'yyyy-MM-dd')
      : null;

    if (nextClose && finalClose) {
      if (nextClose > finalClose) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Final Close date cannot be earlier than next close date.',
        };
        dispatch(showToast(toast));
        return null;
      }
    }
    dispatch(setInvestmentStats(investmentStatsArr));

    const { liquidity, ...reqData } = data;

    const fundStructAndStats: any = {
      ...reqData,
      next_close: nextClose,
      final_close: finalClose,
      investment_period: investmentPeriod ? Number(investmentPeriod) : null,
      fund_assets: fundAssets,
      fund_term: fundTerm ? Number(fundTerm) : null,
      firm_assets: firmAssets,
      target_fund_size: targetFundSize,
      management_fees: managementFees,
      incentives_fees: incentiveFees,
      preferred_return: preferredReturn ? preferredReturn : '',
      compounded_rate_of_return: compoundedRateOfReturn ? Number(compoundedRateOfReturn) : null,
      standard_deviation: standardDeviation,
      sharpe_ratio: sharpeRatio ? Number(sharpeRatio) : null,
      cash_yield: cashYield,
      target_irr: targetIrr,
      impact_score: impactScore,
      profitable_months_percentage: profitableMonths,
      maximum_drawdown: maximumDrawdown ? Number(maximumDrawdown) : null,
      s_and_p500: sp500,
      fundId: id ? id : newFundId,
    };

    return fundStructAndStats;
  };

  const handleGoBack = () => {
    handleSubmit(async (data) => {
      const requiredFundStructAndStats = handleSaveChanges(data);
      if (requiredFundStructAndStats === null) {
        return;
      }
      requiredFundStructAndStats && dispatch(setFundStructAndStats(requiredFundStructAndStats));
      handleBackBtnClick();
    })();
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    const requiredFundStructAndStats = handleSaveChanges(data);
    if (requiredFundStructAndStats === null) {
      return;
    }
    const camelCaseFundStructAndStats = keysToCamel(requiredFundStructAndStats);
    dispatch(setFundStructAndStats(requiredFundStructAndStats));
    mutateFundStructAndStats({
      query: MUTATE_FUND_SECOND_STEP_QUERY,
      variables: camelCaseFundStructAndStats,
    });
  };

  const handleKeyDown = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleKeyDownForRealNumber = (e: any) => {
    if (!/^[-]?\d*\.?[0-9]?[0-9]?$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleDiscardChanges = () => {
    dispatch(setFundsDetails(null));
    dispatch(setFundStructAndStats(null));
    navigate(`/investment/${id ?? newFundId}`);
  };

  const checkKeyDown = (e: any) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const handleKeyDownNumber = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  // Sticky ctaContainer handling
  const ctaRef = useRef(null);
  const [ctaSticky, setCtaSticky] = useState<boolean>(false);
  useLayoutEffect(() => {
    const el = ctaRef?.current;
    const observer = new IntersectionObserver(([e]) => setCtaSticky(!e.isIntersecting), {
      threshold: [1],
    });
    if (el) {
      observer.observe(el);
    }

    // Cleanup
    return () => {
      if (el) observer.unobserve(el);
    };
  }, [ctaRef]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
      <Container maxWidth='xl' className='container-lr-padding investmentForm'>
        {/* Fund Structure */}
        <Grid container justifyContent='space-between'>
          <Grid item xs={4}>
            <h2>Fund Structure</h2>
            <p>Add actual or anticipated details related to fund structure here.</p>
          </Grid>
          <Grid item xs={7.2} container rowSpacing={4}>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Fund AUM</h4>
                <Textbox
                  fullWidth
                  startAdornment='$'
                  register={register}
                  name='fund_assets'
                  value={fundAssets ? valueFormatter(fundAssets) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setFundAssets(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDownNumber}
                />
              </Grid>
              <Grid item xs={6}>
                <h4>Firm AUM</h4>
                <Textbox
                  fullWidth
                  type='text'
                  keyDownFunc={handleKeyDown}
                  startAdornment='$'
                  register={register}
                  name='firm_assets'
                  value={firmAssets ? valueFormatter(firmAssets) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setFirmAssets(e.target.value.replace(/,/g, ''));
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Target Fund Size</h4>
                <Textbox
                  fullWidth
                  register={register}
                  startAdornment='$'
                  name='target_fund_size'
                  value={targetFundSize ? valueFormatter(targetFundSize) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setTargetFundSize(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Management Fee</h4>
                <Textbox
                  fullWidth
                  startAdornment='%'
                  register={register}
                  name='management_fees'
                  value={managementFees ? valueFormatter(managementFees) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setManagementFees(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
              <Grid item xs={6}>
                <h4>Incentive Fee</h4>
                <Textbox
                  fullWidth
                  startAdornment='%'
                  register={register}
                  name='incentives_fees'
                  value={incentiveFees ? valueFormatter(incentiveFees) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setIncentiveFees(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Preferred Return</h4>
                <Textbox
                  fullWidth
                  register={register}
                  startAdornment='%'
                  name='preferred_return'
                  value={preferredReturn ? valueFormatter(preferredReturn) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setPreferredReturn(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
              <Grid item xs={6}>
                <h4>GP Catch-Up Term</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='gp_catch_up_term'
                  onChange={(e: any) => setValue('gp_catch_up_term', e.target.value)}
                  type='text'
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Investment Period (Yrs)</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='investment_period'
                  value={investmentPeriod ? valueFormatter(investmentPeriod) : ''}
                  pattern={{
                    value: /^([0-9]|[1-9][0-9]|100)$/g,
                    message: 'Decimals are not allowed',
                  }}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setInvestmentPeriod(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
                {errors.investment_period?.message && (
                  <ErrorMessage error='Decimals are not allowed. Please enter valid value' />
                )}
              </Grid>
              <Grid item xs={6}>
                <h4>Fund Term (Yrs)</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='fund_term'
                  pattern={{
                    value: /^([0-9]|[1-9][0-9]|100)$/g,
                    message: 'Decimals are not allowed',
                  }}
                  value={fundTerm ? valueFormatter(fundTerm) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setFundTerm(e.target.value);
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
                {errors?.fund_term?.message && (
                  <ErrorMessage error='Decimals are not allowed. Please enter valid value' />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Funding Type</h4>
                <ControlledSelectField
                  control={control}
                  options={['Full Funded', 'Capital Call']}
                  name='funding_type'
                  searchIcon={false}
                  defaultValue={storeFundStructAndStats && storeFundStructAndStats?.funding_type}
                />
              </Grid>
              <Grid item xs={6}>
                <h4>Income Distribution</h4>
                <ControlledSelectField
                  control={control}
                  options={['None', 'Monthly', 'Quarterly', 'Semi-Annual', 'Annual']}
                  name='income_distribution'
                  searchIcon={false}
                  defaultValue={
                    storeFundStructAndStats && storeFundStructAndStats?.income_distribution
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Liquidity (Select one)</h4>
                <ControlledSelectField
                  control={control}
                  options={['No Early Liquidity', 'Monthly', 'Quarterly', 'Annually']}
                  name='liquidity_for_summary'
                  searchIcon={false}
                  defaultValue={
                    storeFundStructAndStats && storeFundStructAndStats?.liquidity_for_summary
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <h4>Lockup (Select one)</h4>
                <ControlledSelectField
                  control={control}
                  options={[
                    'No Lockup',
                    '3 Months',
                    '12 Months',
                    '18 Months',
                    '36 Months',
                    '60 Months',
                    'Fund Term',
                  ]}
                  name='lockup_for_summary'
                  searchIcon={false}
                  defaultValue={
                    storeFundStructAndStats && storeFundStructAndStats?.lockup_for_summary
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Liquidity (Details)</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='redemptions'
                  onChange={(e: any) => setValue('liquidity', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <h4>Lockup (Details)</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='lockup'
                  onChange={(e: any) => setValue('lockup', e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h4>Funds Transfer Due Date</h4>
              <Textbox
                fullWidth
                register={register}
                name='funds_transfer_due_date'
                onChange={(e: any) => setValue('funds_transfer_due_date', e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Fund Stats */}
        <Grid container justifyContent='space-between'>
          <Grid item xs={4}>
            <h2>Fund Stats</h2>
            <p>
              Add computed or target fund statistics here. Update stats on monthly basis for
              evergreen funds.
            </p>
          </Grid>
          <Grid item xs={7.2} container rowSpacing={4}>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Compound Rate of Return</h4>
                <Textbox
                  fullWidth
                  startAdornment='%'
                  register={register}
                  value={compoundedRateOfReturn ? valueFormatter(compoundedRateOfReturn) : ''}
                  name='compounded_rate_of_return'
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setCompoundedRateOfReturn(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
              <Grid item xs={6}>
                <h4>Standard Deviation</h4>
                <Textbox
                  fullWidth
                  register={register}
                  startAdornment='%'
                  value={standardDeviation ? valueFormatter(standardDeviation) : ''}
                  name='standard_deviation'
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setStandardDeviation(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Sharpe Ratio</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='sharpe_ratio'
                  value={sharpeRatio ? valueFormatter(sharpeRatio) : ''}
                  onChange={(e: any) => {
                    if (/^[-]?\d*\.?[0-9]?[0-9]?$/.test(e.target.value.replace(/,/g, ''))) {
                      setSharpeRatio(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDownForRealNumber}
                />
              </Grid>
              <Grid item xs={6}>
                <h4>Profitable Months</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='profitable_months_percentage'
                  value={profitableMonths ? valueFormatter(profitableMonths) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setProfitableMonths(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Maximum Drawdown</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='maximum_drawdown'
                  value={maximumDrawdown ? valueFormatter(maximumDrawdown) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setMaximumDrawdown(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  startAdornment='%'
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
              <Grid item xs={6}>
                <h4>S&P 500 Correlation </h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='s_and_p500'
                  pattern={{
                    value: /^[-]?(0+\.?|0*\.\d+|0*1(\.0*)?)$/g,
                    message: 'Range should be from "-1.0" to "+1.0',
                  }}
                  value={sp500 || ''}
                  onChange={(e: any) => {
                    if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                      setSp500(e.target.value);
                    }
                  }}
                  type='text'
                />
                {errors?.s_and_p500?.message && (
                  <ErrorMessage error='Range should be from "-1.0" to "+1.0"' />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Cash Yield </h4>
                <Textbox
                  fullWidth
                  startAdornment='%'
                  register={register}
                  name='cash_yield'
                  value={cashYield ? valueFormatter(cashYield) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setCashYield(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
              <Grid item xs={6}>
                <h4>Target IRR </h4>
                <Textbox
                  fullWidth
                  startAdornment='%'
                  register={register}
                  name='target_irr'
                  value={targetIrr ? valueFormatter(targetIrr) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setTargetIrr(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Impact Score </h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='impact_score'
                  value={impactScore ? valueFormatter(impactScore) : ''}
                  onChange={(e: any) => {
                    if (/^(\d+(\.)?[0-9]*?)?$/.test(e.target.value.replace(/,/g, ''))) {
                      setImpactScore(e.target.value.replace(/,/g, ''));
                    }
                  }}
                  type='text'
                  keyDownFunc={handleKeyDown}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={4}>
              <Grid item xs={6}>
                <h4>Next close</h4>
                <MuiDatePicker
                  views={['year', 'month', 'day']}
                  inputFormat={'MMM-dd-yyyy'}
                  value={selectedNextClose}
                  setDate={setSelectedNextClose}
                  fullWidth
                  name='next_close'
                  minDate={new Date(new Date().getTime() + 86400000)}
                  disableKeyDown={false}
                />
              </Grid>
              <Grid item xs={6}>
                <h4>Final Close</h4>
                <MuiDatePicker
                  views={['year', 'month', 'day']}
                  inputFormat={'MMM-dd-yyyy'}
                  value={selectedFinalClose}
                  setDate={setSelectedFinalClose}
                  fullWidth
                  name='final_close'
                  minDate={new Date(new Date().getTime() + 86400000)}
                  disableKeyDown={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Manager Track Record */}
        <Grid container justifyContent='space-between'>
          <Grid item xs={3}>
            <h2>Manager Track Record</h2>
            <p>
              Add manager track record for Closed-Ended funds where the Fund Manager has prior
              funds. This information is useful to give investors an idea of the fund manager's
              prior performance.
            </p>
          </Grid>
          <Grid item xs={8.2}>
            <InvestmentManagerRecordsTable
              columns={INVESTMENT_MANAGER_RECORDS_COLUMNS}
              rows={investmentStatsArr}
              setUpdateRow={updateToList}
              setDeleteRow={deleteFromList}
              appendToList={appendToList}
            />
          </Grid>
        </Grid>
      </Container>

      {/* CTAs */}
      <div className={`ctaContainer ${ctaSticky ? 'ctaSticky' : ''}`} ref={ctaRef}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <Grid container justifyContent='flex-end'>
            <MuiButton variant='text' buttonClick={handleGoBack}>
              Back
            </MuiButton>
            <MuiButton
              variant='text'
              color='error'
              buttonClick={handleDiscardChanges}
              className='ml-3'
            >
              Exit Without Saving
            </MuiButton>
            {mutatingFundSandS || mutatingManagerTrackRecord ? (
              <LoadingButton minWidth='150px' className='ml-3' />
            ) : (
              <MuiButton minWidth='150px' variant='contained' type='submit' className='ml-3'>
                Save & Next
              </MuiButton>
            )}
          </Grid>
        </Container>
      </div>
    </form>
  );
};

export default InvestmentStructAndStatsFormGQL;
