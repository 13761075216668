import { PayloadAction } from '@reduxjs/toolkit';

const reducers = {
  setInvestorsActiveStep: (state: any, action: PayloadAction<any | null>) => {
    state.investorActiveStep = action.payload;
  },
  setInvestorProfileStep: (state: any, action: PayloadAction<any | null>) => {
    state.investorProfileStep = action.payload;
  },
  setNewInvestorId: (state: any, action: PayloadAction<any | null>) => {
    state.newInvestorId = action.payload;
  },
  setClientDetails: (state: any, action: PayloadAction<any | null>) => {
    state.clientDetails = action.payload;
  },
  setInvestorAccountHolderDetails: (state: any, action: PayloadAction<any | null>) => {
    state.investorAccountHolderDetails = action.payload;
  },
  setAuthRepresentativeDetails: (state: any, action: PayloadAction<any | null>) => {
    state.authRepresentativeDetails = action.payload;
  },
  setWireTransfferBankDetails: (state: any, action: PayloadAction<any | null>) => {
    state.wireTransfferBankDetails = action.payload;
  },
  setCustodianDetails: (state: any, action: PayloadAction<any | null>) => {
    state.custodianDetails = action.payload;
  },
  setEntityDetails: (state: any, action: PayloadAction<any | null>) => {
    state.entityDetails = action.payload;
  },
  setStoreAawceArr: (state: any, action: PayloadAction<any | null>) => {
    state.storeAawceArr = action.payload;
  },
  setClientModalOpen: (state: any, action: PayloadAction<boolean | null>) => {
    state.clientModalOpen = action.payload;
  },
  setMasterData: (state: any, action: PayloadAction<any | null>) => {
    state.masterData = action.payload;
  },
};
export default reducers;
