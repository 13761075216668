import { Close } from '@mui/icons-material';
import { Dialog, Grid, styled } from '@mui/material';
import MuiButton from 'common/components/button';
import EventCalendar from 'common/components/icon/EventCalendar';
import { Events } from 'common/types';
import { format } from 'date-fns';

type Props = {
  modalOpen: boolean;
  isEventsFromAdmin?: boolean;
  handleEventModalClick: any;
  selectedEvent: Events;
  handleEventEditClick?: any;
};

const EventsModal = ({
  modalOpen = false,
  handleEventModalClick,
  selectedEvent,
  isEventsFromAdmin = false,
  handleEventEditClick,
}: Props) => {
  return (
    <StyledDialog maxWidth='md' open={modalOpen} onClose={handleEventModalClick}>
      <Close className='closeBtn' onClick={handleEventModalClick} />
      <Grid container>
        <Grid item xs={2} mb={4}>
          <EventCalendar date={selectedEvent.starTime as string | undefined} />
        </Grid>
        <Grid item xs={12} mb={5}>
          <h5 className='mb-2'>Hosted by {selectedEvent.host}</h5>
          <h2 className='font-wt-500'>{selectedEvent.name}</h2>
          <p className='d-linebreak'>{selectedEvent.description}</p>
        </Grid>
        <Grid item xs={6}>
          <h6 className='subtitle'>START TIME</h6>
          <h4 className='font-wt-300'>
            {selectedEvent?.starTime &&
              format(
                new Date((selectedEvent?.starTime as string).replace(/-/g, '/')),
                'MMMM dd, yyyy, pp',
              )}
            {' ' + selectedEvent.time_zone}
          </h4>
        </Grid>
        <Grid item xs={6}>
          <h6 className='subtitle'>END TIME</h6>
          <h4 className='font-wt-300'>
            {selectedEvent?.endTime &&
              format(
                new Date((selectedEvent?.endTime as string).replace(/-/g, '/')),
                'MMMM dd, yyyy, pp',
              )}
            {' ' + selectedEvent.time_zone}
          </h4>
        </Grid>
        {selectedEvent?.platform_link && (
          <Grid item xs={12} mt={4}>
            <a href={selectedEvent.platform_link} target='_blank' rel='noreferrer noopener'>
              View Event Details
            </a>
          </Grid>
        )}
        {isEventsFromAdmin && (
          <Grid item xs={12} mt={4} className='flex-center-end'>
            <MuiButton buttonClick={handleEventModalClick}>Cancel</MuiButton>
            <MuiButton
              className='ml-3'
              variant='contained'
              minWidth='150px'
              buttonClick={handleEventEditClick}
            >
              Edit Details
            </MuiButton>
          </Grid>
        )}
      </Grid>
    </StyledDialog>
  );
};

export default EventsModal;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 0.5rem;
    padding: 1.5rem;
    position: relative;

    .closeBtn {
      color: #949aa6;
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      cursor: pointer;
    }
    h5 {
      color: #32353c;
      font-size: 14px;
      font-weight: 300;
    }

    p {
      font-size: 13px;
      color: #0e0e0e;
    }

    h6 {
      color: #525863;
    }

    a {
      font-size: 14px;
      color: #3c64b1;
      text-decoration: none;
      font-style: italic;
    }
  }
`;
