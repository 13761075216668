import React from 'react';
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import { CheckCircle, Close } from '@mui/icons-material';
import MuiCheckbox from 'common/components/inputField/Checkbox';
import SelectField from 'common/components/inputField/SelectField';

interface Props {
  row: any;
  columns: Array<any>;
  deleteRow: any;
  isDeleting: boolean;
  clickedDocId: any;
  handlePrefillChange: Function;
  subscriptionChecklistList: any;
  handleMapsToChange: Function;
}

const SubscriptionDocTableRow = ({
  row,
  columns,
  deleteRow,
  isDeleting = false,
  clickedDocId,
  handlePrefillChange,
  subscriptionChecklistList,
  handleMapsToChange,
}: Props) => {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {React.Children.toArray(
        columns.map((col: any) => (
          <TableCell
            align={col.align}
            className={`
        ${col.cellClassName ? col.cellClassName : ''}
      `}
            sx={{ width: `${col.width ? col.width : 'initial'}` }}
          >
            {col.id === '_prefill' && (
              <MuiCheckbox
                name='prefill'
                defaultValue={row.prefill}
                disableRipple
                className={`checkbox`}
                onChange={() => handlePrefillChange(row.documentId)}
              />
            )}
            {col.id === '_mapsTo' && row.prefill && row.docName?.split('.')?.pop() === 'pdf' && (
              <SelectField
                className='mapsToSelect'
                fullWidth
                options={subscriptionChecklistList}
                setSelectedValue={() => {}}
                onChange={handleMapsToChange(row.documentId)}
                optionName='title'
                optionId='id'
                value={row.prefillMapTo}
                searchIcon={false}
              />
            )}
            {col.id === 'docName' ? (
              <span className='flex-center-start'>
                <CheckCircle className='checkCircle' />
                {row.docName.length > 35
                  ? row.docName.substring(0, 30) +
                    '...' +
                    row.docName.substring(row.docName.length - 8, row.docName.length)
                  : row.docName}
              </span>
            ) : (
              row[col.id]
            )}
          </TableCell>
        )),
      )}
      <TableCell align='right'>
        {isDeleting && row?.documentId === clickedDocId ? (
          <CircularProgress className='close' />
        ) : (
          <Close
            className='close cursor-pointer'
            onClick={() => {
              deleteRow(row.documentId);
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default SubscriptionDocTableRow;
