import { OktaAuth } from '@okta/okta-auth-js';

const SCOPES = ['openid', 'profile', 'email'];
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_OKTA_REDIRECT_URI;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const LABELS = {
  en: {
    // Labels
    'primaryauth.username.placeholder': 'Email',
    'error.username.required': 'Please enter an email',
    remember: 'Keep me signed in',
  },
};

export const config = {
  oidc: {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    scopes: SCOPES,
    redirectUri: REDIRECT_URI,
  },
  widget: {
    useInteractionCodeFlow: false,
    issuer: ISSUER,
    i18n: LABELS,
    clientId: CLIENT_ID,
    scopes: SCOPES,
    redirectUri: REDIRECT_URI,
    logo: '/QualisLogo.svg',
  },
};

export const oktaAuth = new OktaAuth(config.oidc);
