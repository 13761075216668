import { TableContainer } from '@mui/material';
import styled from '@emotion/styled';

export const MainStyle = styled(TableContainer)`
  .tableWrapper {
    background: white;
    border-radius: 1rem;
    border: 1px solid #ddd;
    width: unset;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
  }

  thead {
    th {
      border: 0;
      color: #525863;
      background-color: var(--s10);
      font-size: 13px;
      font-weight: 700;
      line-height: 17px;
      text-transform: uppercase;
      &:first-of-type {
        padding-left: 2rem;
      }
      &:last-of-type {
        padding-right: 2rem;
      }
    }
  }

  td {
    font-size: 13px;
    color: var(--s40);
    border-color: var(--s10);
    &:first-of-type {
      padding-left: 2rem;
    }
    &:last-of-type {
      padding-right: 2rem;
    }
  }

  .MuiTableRow-hover:hover {
    background-color: #dfe1e9 !important;
  }

  .pagination {
    padding: 1.2rem 2.5rem;
    border-top: 1px solid #d7d7d7;
  }

  .nullTable {
    background-color: white;
    height: 360px;
    color: #bdbdbd;
    text-align: center;
    vertical-align: center;
  }

  .infoIcon {
    font-size: 1.25rem;
    margin-left: 0.5rem;
  }

  .alloc {
    font-style: italic;
    color: var(--s20);
  }

  .allocTooltip {
    font-size: 1rem;
    margin-left: 0.25rem;
    position: absolute;
    top: 50%;
  }
`;

export const ChecklistStyle = styled(MainStyle)`
  .penIcon.edit path {
    fill: var(--p200);
  }

  .textInput {
    .MuiInputBase-root {
      color: var(--p500);
      background-color: transparent !important;
      input {
        padding: 0.5rem 0 !important;
        &.Mui-disabled {
          color: var(--s50);
          -webkit-text-fill-color: var(--s50);
        }
      }
      fieldset {
        border: none;
      }
    }

    &.empty input {
      border-bottom: 1px solid var(--s15);
    }

    &.edit input {
      border-bottom: 1px solid var(--p200) !important;
    }
  }

  .selectField {
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--s15) !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--s15) !important;
    }
    .MuiSelect-select {
      padding: 0.5rem 2rem 0.5rem 0.875rem;
    }
    div.Mui-disabled {
      color: var(--s50);
      -webkit-text-fill-color: var(--s50);
    }
    svg {
      color: var(--p500);
      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
      }
    }
    &.edit {
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: var(--p200) !important;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--p200) !important;
      }
    }
  }

  svg.disabled path{
    stroke: rgba(0, 0, 0, 0.3);
  }
`;

export const InputSelectStyle = styled(MainStyle)`
  .close-icon {
    opacity: 0;
    font-size: 22px;
  }

  .check {
    font-size: 22px;
    color: var(--green);
    &:hover {
      color: var(--green) !important;
      stroke: initial !important;
    }
  }

  .loading {
    width: 1rem !important;
    height: 1rem !important;
    color: var(--s30);
  }

  .visibility {
    font-size: 22px;
    color: var(--s30);
    &:hover {
      color: var(--p200);
    }
  }

  .docUpload {
    &.disable {
      opacity: 0.5;
      cursor: initial;
    }
    &:hover path {
      stroke: var(--p200);
    }
    &.disable:hover path {
      stroke: #828282;
    }
  }
  .docDelete {
    &:hover path {
      stroke: #b43a3a;
    }
  }

  svg + svg {
    margin-left: 0.5rem;
  }

  tr:hover .close-icon {
    opacity: 1;
  }

  .grey {
    color: var(--s30);
  }

  .addNew {
    color: var(--p300);
    cursor: pointer;
    &.disable {
      color: var(--s30);
    }
  }

  .active {
    color: var(--p300);
  }

  .selectField.active .MuiOutlinedInput-root {
    color: var(--p300) !important;
  }

  .infoIcon {
    top: unset;
    color: #626262;
  }
`;
