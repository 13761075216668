import { lazyLoad } from 'common/utils/loadable';

export const AdminPage = lazyLoad(
  () => import('./index'),
  module => module.default,
);

export const RolesPage = lazyLoad(
  () => import('./Roles'),
  module => module.default,
);

export const TeamsPage = lazyLoad(
  () => import('./Teams'),
  module => module.default,
);

export const UsersPage = lazyLoad(
  () => import('./Users'),
  module => module.default,
);

export const AplsPage = lazyLoad(
  () => import('./Apls'),
  module => module.default,
);

export const OrganisationsPage = lazyLoad(
  () => import('./Organisations'),
  module => module.default,
);

export const OrganisationDetailsPage = lazyLoad(
  () => import('./OrganisationDetails'),
  module => module.default,
);