import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import {
  setEngineNumber,
  setRecommendationNumber,
  setRecommendedAllocation,
} from 'store/recommendation/slice';
import { ReactComponent as Bulb } from 'common/assets/images/Bulb2.svg';
import { engine1Result } from 'store/recommendation/selectors';
import { formatAumValues } from 'helpers';

const StyledContainer = styled(Grid)`
  .container-style {
    padding: 20px 24px;
    background: #fafafa;
    border-radius: 8px;
  }

  .cta {
    width: 150px;
  }

  .hr-line {
    height: 110px;
    border: 0.5px solid #dddddd;
  }

  p {
    color: var(--s40);
  }

  svg {
    position: relative;
    top: -3px;
    margin-left: 0.5rem;
  }
`;

const Recommendation = ({ handleClick, setIsRec1, setIsRec2, isRec1, isRec2 }: any) => {
  const engine1Data = useAppSelector(engine1Result);
  const dispatch = useTypedDispatch();

  const handleRec1Click = async () => {
    handleClick();
    await dispatch(setEngineNumber({ engineNumber: 1 }));
    await dispatch(setRecommendationNumber({ recommendationNumber: 1 }));
    await dispatch(
      setRecommendedAllocation({
        recommendedAllocation: engine1Data?.engine_1_stats?.recommendations?.Addl_alloc?.alt_alloc,
      }),
    );
    setIsRec1(false);
    setIsRec2(true);
    window.scrollTo(0, 0);
  };

  const resetRec = async () => {
    handleClick();
    await dispatch(setEngineNumber({ engineNumber: 1 }));
    await dispatch(setRecommendationNumber({ recommendationNumber: 0 }));
    await dispatch(
      setRecommendedAllocation({ recommendedAllocation: engine1Data?.engine_1_stats?.alt_alloc }),
    );
    setIsRec1(true);
    setIsRec2(true);
    window.scrollTo(0, 0);
  };

  const handleRec2Click = async () => {
    handleClick();
    await dispatch(setEngineNumber({ engineNumber: 1 }));
    await dispatch(setRecommendationNumber({ recommendationNumber: 2 }));
    await dispatch(
      setRecommendedAllocation({
        recommendedAllocation:
          engine1Data?.engine_1_stats?.recommendations?.Improve_liquidity?.alt_alloc,
      }),
    );
    setIsRec2(false);
    setIsRec1(true);
    window.scrollTo(0, 0);
  };
  return (
    <StyledContainer>
      <Grid item xs={12} container className='container-style' columnGap={10}>
        <Grid item xs={12} mb={2} className='flex-center-start'>
          <h3 className='mb-0'>Recommendations</h3>
          <Bulb />
        </Grid>
        <Grid item xs={5} display='flex' flexDirection='column' justifyContent='space-between'>
          {isRec1 ? (
            <p>1. {engine1Data?.engine_1_stats?.recommendations?.Addl_alloc?.str}</p>
          ) : (
            <p>
              1. Your portfolio has been optimized, increasing expected portfolio returns by{' '}
              {formatAumValues(
                +engine1Data?.engine_1_stats?.recommendations?.Addl_alloc?.port_value_diff,
              )}
            </p>
          )}
          {isRec1 ? (
            <MuiButton
              buttonClick={() => {
                handleRec1Click();
              }}
              className='cta'
              variant='contained'
            >
              Make Changes
            </MuiButton>
          ) : (
            <MuiButton
              buttonClick={() => {
                resetRec();
              }}
              className='cta'
              variant='outlined'
            >
              Undo Changes
            </MuiButton>
          )}
        </Grid>
        <div className='hr-line'></div>

        <Grid item xs={5} display='flex' flexDirection='column' justifyContent='space-between'>
          {isRec2 ? (
            <p>2. {engine1Data?.engine_1_stats?.recommendations?.Improve_liquidity.str}</p>
          ) : (
            <p>
              2. Portfolio has been optimized to reduce liquidity impact to{' '}
              {formatAumValues(
                +engine1Data?.engine_1_stats?.recommendations?.Improve_liquidity
                  .alt_decreased_liquidiy,
              )}
            </p>
          )}
          {isRec2 ? (
            <MuiButton
              buttonClick={() => {
                handleRec2Click();
              }}
              className='cta'
              variant='contained'
            >
              Make Changes
            </MuiButton>
          ) : (
            <MuiButton
              buttonClick={() => {
                resetRec();
              }}
              className='cta'
              variant='outlined'
            >
              Undo Changes
            </MuiButton>
          )}
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Recommendation;
