import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { DatePickerView } from '@mui/lab/DatePicker/shared';
import styled from '@emotion/styled';

const StyledText = styled(TextField)`
  .MuiOutlinedInput-root {
    background-color: var(--s7);
  }
  input {
    padding: 10px 18px;
    font-size: 13px;
  }

  fieldset {
    border-radius: 0.5rem;
    border-color: var(--s7);
  }

  &:hover .MuiOutlinedInput-notchedOutline,
  &:active .MuiOutlinedInput-notchedOutline {
    border-color: #b3d1ed;
  }
`;

type Props = {
  views: DatePickerView[];
  label?: string;
  inputFormat: string;
  value: Date;
  setDate: any;
  fullWidth?: boolean;
  name?: string;
  disabledYears?: any[];
  minDate?: Date;
  maxDate?: Date;
  disableKeyDown?: boolean;
  disablePast?: boolean;
};

const MuiDatePicker = ({
  views,
  label,
  inputFormat,
  value,
  setDate,
  fullWidth,
  minDate,
  maxDate,
  disableKeyDown = true,
  disablePast = false,
}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={views}
        label={label}
        value={value}
        inputFormat={inputFormat}
        onChange={setDate}
        minDate={minDate}
        maxDate={maxDate}
        disablePast={disablePast}
        renderInput={(params) => <StyledText {...params} fullWidth={fullWidth} sx={{ mt: 1 }} />}
        InputProps={{ onKeyDown: (e) => disableKeyDown && e.preventDefault() }}
      />
    </LocalizationProvider>
  );
};

export default MuiDatePicker;
