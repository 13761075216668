import styled from '@emotion/styled';
import { Box, Grid, Skeleton } from '@mui/material';
import { formatAumValues } from 'helpers';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useMutationQuery, useReactQuery } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { UserProfile } from 'store/user/types';
import { journeyLabels } from './constants';

const StyledWrapper = styled(Box)`
  padding: 1.375rem 3.5rem 1.375rem 1.5rem;
  margin-bottom: 0.75rem;
  cursor: pointer;

  .fundContainer {
    padding: 13px 1rem;
    border-radius: 1rem;
    border: 1px solid #e8e8e8;

    .fund + .fund {
      margin-top: 13px;
      padding-top: 13px;
      border-top: 1px solid var(--s15);
    }

    .fund {
      flex-wrap: nowrap;
      margin-left: unset;
      .image {
        width: 100px;
        height: 88px;
        object-fit: contain;
        border-radius: 0.5rem;
      }
      .fundDetails {
        flex: 1 1 auto;
      }
    }
  }

  .fund__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2667a1;
    margin-bottom: 1rem;
  }

  .fund_info {
    font-weight: 656;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    color: #4f4f4f;
    margin-bottom: 0.25rem;
  }

  .fund_info__value {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 0;
  }
`;

type Props = {
  responseDetails: any;
  initDelay: number;
  toggleDrawer: any;
  journeyTitle: string;
  isLoading: any;
  fundData: any;
};

type CardProps = {
  option: any;
  index: number;
  toggleDrawer: any;
};

const Fund = ({ option, index, toggleDrawer }: CardProps) => {
  const navigate = useNavigate();
  const data = option;

  const {
    mutate,
    data: logo,
    isLoading: logoLoading,
  } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
      params: { object_key: data?.logo },
    },
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (data) {
      mutate();
    }
    //eslint-disable-next-line
  }, [data]);

  const fromatedValue = formatAumValues(Number(option?.min_investment), 0);

  return (
    <Grid
      container
      className='fund'
      onClick={() => {
        toggleDrawer();
        navigate(`/investment/${data?.fund_id}`);
      }}
      key={`fundCard${index}`}
      alignItems='center'
    >
      <Grid item xs='auto'>
        {!logoLoading ? (
          <img src={logo?.url} alt='' className='image' />
        ) : (
          <Skeleton variant='rectangular' className='image' />
        )}
      </Grid>
      <Grid item xs='auto' className='fundDetails' sx={{ pl: '20px' }}>
        <p className='fund__name'>{data?.fund_name}</p>
        <Grid display='flex' item xs={12}>
          <Grid item xs={4}>
            <p className='fund_info'>ASSET CLASS</p>
            <p className='fund_info__value'>
              {data?.asset_class ? data?.asset_class.split(',')[0] : data.assetClass}
            </p>
          </Grid>
          <Grid item xs={4}>
            <p className='fund_info'>Min Investment</p>
            <p className='fund_info__value'>{fromatedValue}</p>
          </Grid>
          <Grid item xs={4}>
            <p className='fund_info'>1yr Return</p>
            <p className='fund_info__value'>
              {data?.returns_since_inception && data.returns_since_inception['1_year']
                ? data.returns_since_inception['1_year'] + '% '
                : '--%'}
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ChatBotFundCard = ({ responseDetails, toggleDrawer, journeyTitle }: Props) => {
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user: any = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const { data: fundData = {}, isLoading } = useReactQuery([`fundData-${journeyTitle}`], {
    url: `qaip/v1/fundsmanagement/chatbot/${user.organisationId}`,
  });

  if (isLoading) {
    return <></>;
  }

  return (
    <StyledWrapper px={3}>
      <Box className='fundContainer'>
        {journeyTitle === journeyLabels.MARKETS_SELL_OFF ? (
          fundData?.marketDrop?.map((option: any, i: number) => (
            <Fund key={`fundType${i}`} option={option} index={i} toggleDrawer={toggleDrawer} />
          ))
        ) : journeyTitle === journeyLabels.TARGETS_LOWERED ? (
          fundData?.growthTargetsLowered?.map((option: any, i: number) => (
            <Fund key={`fundType${i}`} option={option} index={i} toggleDrawer={toggleDrawer} />
          ))
        ) : journeyTitle === journeyLabels.RATE_SPIKE ? (
          fundData?.treasurySpike?.map((option: any, i: number) => (
            <Fund key={`fundType${i}`} option={option} index={i} toggleDrawer={toggleDrawer} />
          ))
        ) : journeyTitle === journeyLabels.IMPACT_POWERS ? (
          fundData?.impact?.map((option: any, i: number) => (
            <Fund key={`fundType${i}`} option={option} index={i} toggleDrawer={toggleDrawer} />
          ))
        ) : (
          <></>
        )}
      </Box>
    </StyledWrapper>
  );
};

export default ChatBotFundCard;
