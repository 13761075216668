export const CLIENT_CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '/',
    label: 'Client Approval',
  },
];

export const FUNDS_CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '/',
    label: 'Manage Funds',
  },
];

export const CLIENTS_COLUMNS = [
  {
    id: 'account_name',
    label: 'Account Name',

    width: '20%',
  },
  {
    id: 'account_number',
    label: 'Account Number',

    width: '20%',
  },
  {
    id: 'entity_type',
    label: 'Entity Type',

    width: '25%',
  },
  {
    id: 'status',
    label: 'Status',

    width: '15%',
  },
  {
    id: 'lastUpdatedFormatted',
    label: 'Last Updated',

    isDate: true,
    width: '20%',
  },
];

export const FUNDS_COLUMNS = [
  {
    id: 'fund_id_simple',
    label: 'Fund Id',
    width: '10%',
  },
  {
    id: 'fund_name',
    label: 'Fund',
    width: '30%',
  },
  {
    id: 'asset_class',
    label: 'Asset Class',
    width: '13%',
  },
  {
    id: 'class',
    label: 'Class',
    width: '10%',
  },
  {
    id: 'eligibility',
    label: 'eligibility',
    width: '12%',
  },
  {
    id: 'fund_status',
    label: 'status',
    width: '10%',
  },
  {
    id: 'created_date',
    label: 'Created Date',
    isDate: true,
    width: '20%',
  },
];

export const STATUS_FILTERS = [
  'All',
  'Inactive',
  'Approval Requested',
  'Open',
  'Coming Soon',
  'Closed for Subscription',
  'Closed',
];

export const STATUS_LIST = [
  'Inactive',
  'InActive',
  'Approval Requested',
  'Open',
  'Coming Soon',
  'Closed for Subscription',
  'Closed to New Investment',
  'Onboarding - Awaiting Approval',
  'Closed',
];
