import { Grid } from '@mui/material';
import RecEngineDonut from 'common/components/charts/RecEngineDonut';
import { getEmoji } from 'helpers/investments';

type Props = {
  isInput?: boolean;
  toggle?: string;
  data?: any;
};
const ThemeCard = ({ isInput = false, toggle = 'withoutAlts', data = [] }: Props) => {
  // const emoji = [];
  const getThemeDetails = () => {
    const themes = ['Diversification', 'Income', 'Growth', 'ESG'];
    const themeData = data.filter((el: any) => themes.includes(el.Ticker));
    return themeData;
  };

  return (
    <Grid item xs={12} container>
      <Grid item xs={6}>
        {getThemeDetails().map((el: any) => (
          <>
            <div className='theme-data mb-3'>
              <p className='theme-emoji'>{getEmoji(el?.Ticker)}</p>
              <p className='theme-name'>{el?.Ticker}</p>
              <div className={`value-styles ${isInput ? 'fixedWidth' : 'theme-value-box'}`}>
                <span className='theme-value allocation-value value-box'>
                  <span className='perctage-value'>{(el?.Weight * 100).toFixed(2)}%</span>
                </span>
              </div>
            </div>
            <hr className='bottom-border' />
          </>
        ))}
      </Grid>

      <Grid item xs={6} className='mt-5 position-relative'>
        <span className='donut-label'>ALLOCATION TO STYLES</span>
        <RecEngineDonut
          dataKey='Weight'
          nameKey='Ticker'
          height={220}
          isPercentageValue
          data={getThemeDetails().map((el: any) => ({ ...el, Weight: el.Weight * 100 }))}
        />
      </Grid>
    </Grid>
  );
};

export default ThemeCard;
