import ReactGA from 'react-ga4'

export const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID as string);
}

export const gaFileDownloadTrack = (property: any) => {
  (window as any).gtag('event', 'file_download', {
    fileName: property.fileName,
    fundName: property.fundName,
    fileUrl: property.fileUrl,
  })
}

export const gaCustomEventTrack = (name: string, property: any) => {
  console.log(name, property);
}