import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '.';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import getAxiosInstance from 'common/utils/axios';
import { EntityType, QueryType } from 'common/types';
import { selectBehalfOf, selectCurrentUser, selectUserProfile } from './user/selectors';
import { UserProfile } from './user/types';
import { gql, GraphQLClient } from 'graphql-request';
import { getAccessToken, getUserId, handleGraphqlMutation } from '../helpers';
// Refer: https://react-redux.js.org/using-react-redux/usage-with-typescript
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type FetchApiOptions = {
  url: string;
  params?: any;
  method?: QueryType;
  isFileUpload?: boolean;
  responseType?: 'json' | 'blob';
};

const fetchApiData = async (url: string, params: any, method: string, buserId: string) => {
  const axios = await getAxiosInstance(buserId);
  switch (method) {
    case QueryType.GET: {
      const { data } = await axios.get(url, {
        params,
      });
      return data;
    }
    case QueryType.PUT: {
      const { data } = await axios.put(url, Array.isArray(params) ? params : { ...params });
      return data;
    }
    case QueryType.DELETE: {
      const { data } = await axios.delete(url, {
        ...params,
      });
      return data;
    }
    default:
      return null;
  }
};

export const fileUploadMutation = async (url: string, file: File, buserId: string) => {
  const axios = await getAxiosInstance(buserId);
  const formData: FormData = new FormData();
  formData.append('file', file);
  const { data } = await axios.post(url, formData);
  return data;
};

export const createMutation = async (
  url: string,
  params: any,
  buserId: string,
  isFormData?: boolean,
) => {
  const axios = await getAxiosInstance(buserId);
  const formData: FormData = new FormData();
  if (isFormData) formData.append('documentData', JSON.stringify(params.documentData));
  const { data } = await axios.post(
    url,
    isFormData ? formData : Array.isArray(params) ? params : { ...params },
  );
  return data;
};

export const createRQMutation = async (
  url: string,
  params: any,
  buserId: string,
  method = QueryType.POST,
  isFormData?: boolean,
  baseURL?: string | undefined,
  responseType?: 'json' | 'blob',
  isTickerCheck?: boolean,
) => {
  const axios = await getAxiosInstance(buserId, undefined, baseURL, isTickerCheck);
  const formData: FormData = new FormData();
  if (isFormData) formData.append('documentData', JSON.stringify(params.documentData));
  switch (method) {
    case QueryType.POST: {
      const { data } = await axios.post(
        url,
        isFormData ? formData : Array.isArray(params) ? params : { ...params },
        {
          ...(responseType && { responseType }),
        },
      );
      return data;
    }
    case QueryType.PUT: {
      const { data } = await axios.put(url, Array.isArray(params) ? params : { ...params });
      return data;
    }
    case QueryType.DELETE: {
      const { data } = await axios.delete(url, {
        data: Array.isArray(params) ? params : { ...params },
      });
      return data;
    }
    default:
      return null;
  }
};

export const useReactQuery = (
  identifier: (string | number | undefined)[],
  { url, params = {}, method = QueryType.GET }: FetchApiOptions,
  options?: UseQueryOptions<any, unknown, any, (string | number | undefined)[]> | undefined,
) => {
  const dispatch = useTypedDispatch();
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isQualisUser = user?.category === EntityType.QUALIS;
  const buserId = !isQualisUser ? user?.userId : '';
  return useQuery(identifier, () => fetchApiData(url, params, method, buserId as string), {
    ...options,
    refetchOnWindowFocus: false,
    retry: 1,
    onError(err: any) {
      if (err?.request.status === 401) dispatch({ type: 'LOGOUT_ATTEMPT' });
    },
  });
};

export const useMutationQuery = (
  {
    url,
    params = {},
    isFormData,
    isFileUpload = false,
  }: FetchApiOptions & { isFormData?: boolean },
  options?: UseQueryOptions<any, unknown, any, (string | number | undefined)[]> | undefined,
) => {
  const dispatch = useTypedDispatch();
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isQualisUser = user?.category === EntityType.QUALIS;
  const buserId = !isQualisUser ? user?.userId : '';
  const resp = useMutation(
    () =>
      isFileUpload
        ? fileUploadMutation(url, params, buserId as string)
        : createMutation(url, params, buserId as string, isFormData as any),
    options,
  );
  if ((resp as any)?.error?.response?.status === 401) dispatch({ type: 'LOGOUT_ATTEMPT' });
  return resp;
};

// useMutation - Modified Mutation Hook,
// we need to move to this mutation gradually.
export const useRQMutation = (
  {
    url,
    method = QueryType.POST,
    isFormData,
    baseURL,
    responseType,
    isTickerCheck = false,
  }: FetchApiOptions & {
    isFormData?: boolean;
    baseURL?: string | undefined;
    isTickerCheck?: boolean;
  },
  options?: UseQueryOptions<any, unknown, any, (string | number | undefined)[]> | undefined,
) => {
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isQualisUser = user?.category === EntityType.QUALIS;
  const buserId = !isQualisUser ? user?.userId : '';
  return useMutation(
    (params: any) =>
      createRQMutation(
        url,
        params,
        buserId as string,
        method,
        isFormData,
        baseURL,
        responseType,
        isTickerCheck,
      ),
    options,
  );
};

// useFileUploadMutation - Modified File Upload Mutation Hook,
// we need to move to this mutation whereever we are using the file upload feature.
export const useFileUploadMutation = ({ url }: FetchApiOptions & { isFormData?: boolean }) => {
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isQualisUser = user?.category === EntityType.QUALIS;
  const buserId = !isQualisUser ? user?.userId : '';
  return useMutation((params: File) => fileUploadMutation(url, params, buserId as string));
};

export const createGraphqlHeaders = (user: any) => {
  const token = getAccessToken();
  const userId = getUserId();
  const isQualisUser = user?.category === EntityType.QUALIS;
  const buserId = !isQualisUser ? user?.userId : '';
  const headerConfig = token
    ? {
        Authorization: `Bearer ${token}`,
        'x-idToken': token,
        'x-userid': userId,
        'x-buserid': buserId ? buserId : '',
        'Access-Control-Allow-Origin': '*',
        isOkta: true,
      }
    : {};
  return headerConfig;
};

export interface GraphQlConfig {
  baseURL: string;
  query: string;
  variables?: any;
  headers?: any;
}

export const useGraphQLQuery = (
  queryIdentifier: any,
  graphqlConfig: GraphQlConfig,
  options?: Omit<UseQueryOptions<any, unknown, any, any>, 'queryKey' | 'queryFn'> | undefined,
) => {
  const graphQLClient = new GraphQLClient(`${graphqlConfig.baseURL}/graphql`, {});
  const currentUser = useAppSelector(selectCurrentUser);
  const graphqlHeaders = createGraphqlHeaders(currentUser);
  return useQuery(
    queryIdentifier,
    async () => {
      const { query, variables = {}, headers = graphqlHeaders } = graphqlConfig;
      const response = await graphQLClient.request(
        gql`
          ${query}
        `,
        { ...variables },
        { ...headers },
      );
      return response;
    },
    { ...options, refetchOnWindowFocus: false },
  );
};

export const useGraphQlMutation = (onSuccess?: Function, onError?: Function) => {
  const { mutate, mutateAsync, isLoading, data } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: data.url as string,
        query: data.query,
        variables: data.variables,
      });
    },
    {
      onSuccess(response: any) {
        onSuccess && onSuccess(response);
      },
      onError(error: any) {
        onError && onError(error);
      },
    },
  );

  return { mutate, mutateAsync, isLoading, data };
};
