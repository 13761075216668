import { useEffect, useState } from 'react';
import { useAppSelector, useGraphQLQuery, useTypedDispatch } from 'store/hooks';
import MenuIcon from '@mui/icons-material/Menu';
import { Container, Fade, Grid, Menu, MenuItem, Pagination, ToggleButton } from '@mui/material';
import MuiToggle from 'common/components/button/MuiToggle';
import InvestmentCard from 'components/investments/InvestmentCard';
import SearchBar from 'common/components/inputField/SearchBar';
import SubHeader from 'common/components/layout/SubHeader';
import Loading from 'common/components/Loading';
import Filters from './Filters';
import { FundDetails } from 'common/types';
import styled from '@emotion/styled';
import { ReactComponent as CardViewIcon } from 'common/assets/images/investment_card.svg';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import MuiButton from 'common/components/button';
import { Done, KeyboardArrowDown } from '@mui/icons-material';
import {
  selectActiveSort,
  selectDefaultFilters,
  selectCurrentFilters,
  selectIsListView,
  selectPage,
  selectSearchQuery,
} from 'store/investment/selectors';
import {
  setActiveSort,
  setCurrentFilters,
  setDefaultFilterList,
  setIsListView,
  setPage,
  setSearchQuery,
} from 'store/investment/slice';
import React from 'react';
import { LIST_CRUMBS, sortByTypes } from './constants';
import { UserProfile } from 'store/user/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { canCreateEditApproveFund } from 'helpers/roles';
import {
  setFundsActiveStep,
  setNewFundID,
  setFundsDetails,
  setFundStructAndStats,
  setFundTermsAndServices,
  setFundPriceMonthlyReturns,
  setFundPriceInceptionReturns,
  setFundPriceAnnualReturns,
  setFundDocuments,
  setInvestmentStats,
  setFundStatus,
  setNewFundLogo,
} from 'store/funds/slice';
import { GET_FUNDS_LIST } from './queries';
import { GET_MASTER_DATA } from 'common/hooks/investments/queries';
import { TSortBy } from 'store/investor/types';
import CompareInfoModal from 'components/modals/CompareInfoModal';

const Wrapper = styled.div`
  .no-results {
    color: var(--s30);
    font-weight: 400;
  }
`;

const InvestmentList = () => {
  const dispatch = useTypedDispatch();
  const location = useLocation();
  const showCompareModalFromRoute = !!location?.state?.showCompareModal;
  const [showCompareModal, setShowCompareModal] = useState<boolean>(false);
  const [isCompareModalShownOnce, setIsCompareModalShownOnce] = useState<boolean>(false);
  const isListView = useAppSelector(selectIsListView);
  const page = useAppSelector(selectPage);
  const searchQuery = useAppSelector(selectSearchQuery);
  const currentFilters = useAppSelector(selectCurrentFilters);
  const currentDefaultFilters = useAppSelector(selectDefaultFilters);
  const activeSort = useAppSelector(selectActiveSort);
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user: UserProfile | null = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const [filterList, setFilterList] = useState<Record<string, string[]>>({});
  const FUNDS_PER_PAGE = 10;
  const navigate = useNavigate();

  useEffect(() => {
    console.log('wasd', showCompareModalFromRoute);
    if (showCompareModalFromRoute) setShowCompareModal(true);
  }, [showCompareModalFromRoute]);

  useEffect(() => {
    if (
      Object.keys(currentDefaultFilters).length > 0 &&
      currentDefaultFilters.constructor === Object
    ) {
      setFilterList(currentDefaultFilters);
    }
  }, [currentDefaultFilters]);

  const getFundListVariables = () => {
    return {
      payload: {
        pageSize: FUNDS_PER_PAGE,
        pageNumber: page,
        search: {
          assetClass: currentFilters?.asset_class?.length ? currentFilters?.asset_class : null,
          class: currentFilters?.class?.length ? currentFilters?.class : null,
          strategy: currentFilters?.strategy?.length ? currentFilters?.strategy : null,
          subStrategy: null,
          rating: null,
          eligibility: currentFilters?.eligibility?.length ? currentFilters?.eligibility : null,
          fund_status: null,
          fundThemes: currentFilters?.fund_themes?.length ? currentFilters?.fund_themes : null,
          liquidities: currentFilters?.liquidities?.length ? currentFilters?.liquidities : null,
          lockups: currentFilters?.lockups?.length ? currentFilters?.lockups : null,
          nextClose: currentFilters?.nextClose?.length ? currentFilters?.nextClose : null,
          query: searchQuery,
        },
        isManageFunds: false,
        isDropdownValue: null,
        sortBy: sortByTypes[activeSort]?.key,
      },
    };
  };

  //GraphQlQuery
  const {
    data: {
      getFunds: { data: fundData, totalCount: totalFunds } = { data: [], totalCount: '' },
    } = {},
    isLoading: isqueryLoading,
  } = useGraphQLQuery(
    [`getFundList-${page}`, currentFilters, searchQuery, activeSort],
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_FUNDS_LIST,
      variables: getFundListVariables(),
    },
    {
      enabled: !!currentFilters.class,
      refetchOnWindowFocus: false,
    },
  );

  //---Sort By---
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const sortByOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { isLoading: isMasterDataLoading } = useGraphQLQuery(
    'getMasterData',
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_MASTER_DATA,
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const masterData = data.getMasterData;
        const eligibility = masterData.eligibility.filter(
          (value: string) => value !== 'Qualified Client',
        );
        const defaultFilters = {
          fund_themes: ['ESG', 'Income', 'Growth', 'Diversification'],
          asset_class: masterData.assetClass,
          strategy: masterData.strategy,
          eligibility: eligibility,
          class: masterData.class,
          lockups: masterData.lockups,
          liquidities: masterData.liquidities,
        };
        setFilterList(defaultFilters);
        dispatch(setDefaultFilterList(defaultFilters));
      },
    },
  );

  useEffect(() => {
    const key = Object.keys(currentFilters);
    const hasFilters = key.some((f: string) => currentFilters[f].length > 0);
    if (!hasFilters) {
      let activeFiltersSchema: { [id: string]: any } = {};
      Object.keys(filterList).forEach((id: string) => {
        activeFiltersSchema[id] = [];
        if (id === 'class') {
          activeFiltersSchema['class'] = ['US Onshore'];
        }
      });
      dispatch(setCurrentFilters(activeFiltersSchema));
    }
    //eslint-disable-next-line
  }, [filterList]);

  const handleAddNewFundButtonClicked = (e: any) => {
    e.stopPropagation();
    dispatch(setFundsActiveStep(0));
    dispatch(setFundsDetails(null));
    dispatch(setFundStructAndStats(null));
    dispatch(setFundTermsAndServices(null));
    dispatch(setFundPriceAnnualReturns(null));
    dispatch(setFundPriceMonthlyReturns(null));
    dispatch(setFundPriceInceptionReturns(null));
    dispatch(setFundDocuments(null));
    dispatch(setNewFundID(null));
    dispatch(setNewFundLogo(null));
    dispatch(setInvestmentStats([{}]));
    dispatch(setFundStatus(''));
    navigate(`/admin/new-investment-v2`);
  };

  return (
    <Wrapper>
      <Grid container sx={{ mt: '4rem' }}>
        <Grid item xs={12}>
          <SubHeader crumbs={LIST_CRUMBS} perfSummary compare>
            <Grid container justifyContent='space-between' alignItems='flex-end' columns={14}>
              <Grid item xs='auto'>
                <h1>Explore Funds</h1>
              </Grid>
              <Grid
                item
                xs={7}
                container
                alignItems='center'
                justifyContent='flex-end'
                flexWrap='nowrap'
              >
                <Grid item xs='auto'>
                  <SearchBar
                    fullWidth
                    searchQuery={searchQuery}
                    setSearchQuery={(data) => dispatch(setSearchQuery(data))}
                  />
                </Grid>
                <Grid item xs='auto' sx={{ ml: '20px' }}>
                  <MuiButton className='filterMainBtn' buttonClick={handleClick}>
                    Sort By <KeyboardArrowDown sx={{ fontSize: '16px', ml: '11px' }} />
                  </MuiButton>
                  <Menu
                    id='fade-menu'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={sortByOpen}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    {React.Children.toArray(
                      sortByTypes.map((type: TSortBy, t: number) => (
                        <MenuItem
                          className='flex-center-start'
                          key={`sortByType${t}`}
                          onClick={() => dispatch(setActiveSort(t))}
                        >
                          <Done
                            fontSize='small'
                            className={`mr-2`}
                            sx={{ opacity: activeSort === t ? 1 : 0 }}
                          />{' '}
                          {type.value}
                        </MenuItem>
                      )),
                    )}
                  </Menu>
                </Grid>
                <Grid item xs='auto' ml={'20px'}>
                  <MuiToggle value={isListView}>
                    <ToggleButton value={false} onClick={() => dispatch(setIsListView(false))}>
                      <MenuIcon />
                    </ToggleButton>
                    <ToggleButton value={true} onClick={() => dispatch(setIsListView(true))}>
                      <CardViewIcon />
                    </ToggleButton>
                  </MuiToggle>
                </Grid>
                {user && canCreateEditApproveFund(user, 'create') && (
                  <Grid item xs='auto' ml={'20px'}>
                    <MuiButton
                      variant='outlined'
                      buttonClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                        handleAddNewFundButtonClicked(e)
                      }
                    >
                      Add new Fund
                    </MuiButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </SubHeader>
        </Grid>
        <Container maxWidth='xl' className='container-lr-padding'>
          <Grid container>
            <Grid item xs={3}>
              <Filters list={filterList} currentFilters={currentFilters} />
            </Grid>

            {isqueryLoading || isMasterDataLoading ? (
              <Loading />
            ) : (
              <Grid item container xs={9}>
                <Grid
                  item
                  container
                  columnSpacing={3}
                  rowSpacing={isListView ? 3 : 0}
                  xs={12}
                  sx={{
                    padding: '2rem 0 2rem 2rem',
                    flexDirection: isListView ? 'row' : 'column',
                    flexWrap: isListView ? 'wrap' : 'nowrap',
                  }}
                >
                  {fundData?.length > 0 ? (
                    fundData?.map((fund: FundDetails, i: number) => (
                      <InvestmentCard
                        fund={fund}
                        cardView={isListView}
                        key={`fund${i}`}
                        setShowCompareModal={setShowCompareModal}
                      />
                    ))
                  ) : (
                    <h4 className='no-results mx-auto'>No results found</h4>
                  )}
                  <Grid item xs={12}>
                    {FUNDS_PER_PAGE && totalFunds > FUNDS_PER_PAGE && (
                      <Pagination
                        className='pagination'
                        count={Math.ceil(totalFunds / FUNDS_PER_PAGE)}
                        page={page}
                        onChange={async (event: React.ChangeEvent<unknown>, value: number) => {
                          await dispatch(setPage(value));
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      </Grid>
      <CompareInfoModal
        isModalOpen={!isCompareModalShownOnce && showCompareModal}
        handleClose={() => {
          setShowCompareModal(false);
          setIsCompareModalShownOnce(true);
        }}
      />
    </Wrapper>
  );
};

export default InvestmentList;
