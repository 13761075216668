import { Grid, styled } from '@mui/material';
import portfolioAmount from 'common/assets/images/recommendation/portfolioValue.svg';
import fileDollar from 'common/assets/images/recommendation/FileDollar.svg';
import { formatAumValues } from 'helpers';
import { useAppSelector } from 'store/hooks';
import { engine1Result } from 'store/recommendation/selectors';

const StyledContainer = styled(Grid)`
  .card-heading {
    color: var(--s40);
    font-size: 1.125rem;
    margin-bottom: 1.25rem;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }

  .amount-style {
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 51px;
    background: linear-gradient(95.96deg, #2667a1 -2.77%, #3e89cd 101.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 0.75rem;
  }

  .card-border {
    border-radius: 0.5rem;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);
    padding: 24px 28px;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: var(--s40);
  }

  .profit-loss-percentage {
    font-weight: 600;
    color: #3bbb7d;

    svg {
      width: 1em;
      height: 1em;
      font-size: 0.875rem;
      fill: #3bbb7d;
    }
  }

  .percentage-return {
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--s40);
  }
`;

const RecIntroCards = ({ liquidityAvailable, additionalReturns }: any) => {
  const engine1Data = useAppSelector(engine1Result);

  return (
    <StyledContainer item xs={12} container mb={7}>
      <Grid item xs={5.7} className='card-border' container>
        <Grid item xs={2} display='flex' justifyContent='center'>
          <img src={portfolioAmount} alt='portfolio value' className='w-100' />
        </Grid>
        <Grid item xs={10} pl={5}>
          <div className='displayCard-right'>
            <div className='displayCard-right-head'>
              <p className='card-heading'>
                Generate additional returns from portfolio by allocating to Private Markets
              </p>
              <p className='amount-style'>{formatAumValues(additionalReturns)}</p>
            </div>
            <div className='d-flex'>
              <p className='description mb-0'>
                Projected annualized rate of return:{' '}
                <span className='percentage-return mb-0'>
                  {(engine1Data?.engine_1_stats?.alt_port_ret * 100).toFixed(2)}%
                </span>{' '}
                <span className='profit-loss-percentage mb-0'>
                  (
                  <svg viewBox='0 0 20 20'>
                    <path d='m5 14 5-5 5 5z'></path>
                  </svg>
                  {(
                    (engine1Data?.engine_1_stats?.alt_port_ret -
                      engine1Data?.engine_1_stats?.current_port_ret) *
                    100
                  )?.toFixed(2)}
                  %)
                </span>
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={0.6}>
        {' '}
      </Grid>
      <Grid item xs={5.7} className='card-border' display='flex'>
        <Grid item xs={2} display='flex' justifyContent='center'>
          <img src={fileDollar} alt='portfolio value' className='w-100' />
        </Grid>
        <Grid item xs={10} pl={5} display='flex' flexDirection='column' justifyContent='flex-start'>
          <div className='displayCard-right-head'>
            <p className='card-heading'>
              If markets move down by 15%, liquidity available to the client is:
            </p>
            <p className='amount-style'>{formatAumValues(liquidityAvailable.withAlts)}</p>
            <p className='description mb-0'>
              Liquidity is reduced by{' '}
              <span className='percentage-return mb-0'>
                {formatAumValues(liquidityAvailable.withAlts - liquidityAvailable.withoutAlts)}
              </span>{' '}
              as a result of allocation to Private Markets
            </p>
          </div>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default RecIntroCards;
