import { Box } from '@mui/system';
import { Button, Grid } from '@mui/material';
import MuiDrawer from 'common/components/drawer';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useEffect, useRef, useState } from 'react';
import HeaderChatBot from './HeaderChatBot';
import Journey1JSON from './Journeys/Journey1.json';
import Journey2JSON from './Journeys/Journey2.json';
import Journey3JSON from './Journeys/Journey3.json';
import Journey4JSON from './Journeys/Journey4.json';
import SenderBubble from './SenderBubble';
import ReceiverBubble from './ReceiverBubble';
import { InputWidgetFactory } from './Widgets/InputWidgetFactory';
import styled from '@emotion/styled';
import ComponentDelayer from './ComponentDelayer';
// import Themes from './Themes';
import LastLoggedIn from './LastLoggedIn';
import OtherInsights from './OtherInsights';
import JourneyMenu from './JourneyMenu';
import ChatBotBulb from 'common/assets/images/ChatBotBulb.svg';
import Responses from './Responses';
import { UserProfile } from 'store/user/types';
import { useAppSelector, useReactQuery } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { journeyLabels } from './Widgets/constants';

const StyledWrapper = styled(Box)`
  .chatbot__drawer-btn {
    display: flex;
    align-items: center;
    padding: 16px 12px;
    position: fixed;
    z-index: 5;
    height: 43px;
    width: 250px;
    right: -105px;
    bottom: 200px;
    background: linear-gradient(95.96deg, #2667a1 -2.77%, #3e89cd 101.66%);
    border-radius: 8px 8px 0px 0px;
    transform: rotate(-90deg);
    text-transform: capitalize;

    svg {
      transform: rotate(-90deg);
      margin-left: 0.5rem;
    }
  }
`;

const StyledDrawer = styled(MuiDrawer)`
  .chatbotBody {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
  }
  .chatSpace {
    display: flex;
    flex-direction: column;
    .headline {
      display: flex;
      align-items: flex-end;
      margin: 0 1.75rem 0.75rem;
      img {
        margin-right: 0.5rem;
      }
    }
    .chatBubble {
      opacity: 0;
      max-width: 70%;
      padding: 1rem 1.25rem;
      margin: 0 1.5rem 0.75rem;
    }
  }
`;

interface Props {
  name: string;
}

const ChatBot = ({ name }: Props) => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);
  const [chatbotData, setChatbotData] = useState<Array<any>>([]);
  const [scrollCount, setScrollCount] = useState<number>(0);
  const [displayState, setDisplayState] = useState<number>(0);
  const [journeyNo, setJourneyNo] = useState<number>(0);
  const JOURNEY_ARR = [Journey1JSON, Journey2JSON, Journey3JSON, Journey4JSON];
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user: any = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const { data: fundData = {}, isLoading } = useReactQuery([`fundData`], {
    url: `qaip/v1/fundsmanagement/chatbot/${user.organisationId}`,
  });

  const toggleDrawer = () => {
    setIsChatBotOpen(!isChatBotOpen);
  };

  useEffect(() => {
    setTimeout(() => {
      messagesEndRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 100);
    setTimeout(() => {
      messagesEndRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 1000);
  }, [chatbotData, scrollCount]);

  useEffect(() => {
    if (displayState === 2) {
      setChatbotData([
        { ...(JOURNEY_ARR[journeyNo] as any)['GREETINGS'], userResponse: 'GREETINGS' },
      ]);
    }
    //eslint-disable-next-line
  }, [displayState, journeyNo]);

  const handleUserResponseClick = (value: any) => {
    (async () => {
      const dialogueFlow = [...chatbotData].concat({
        ...(JOURNEY_ARR[journeyNo] as any)[value],
        userResponse: value,
      });
      setChatbotData(dialogueFlow);
    })();
  };

  const handleQuestionString = (question: String, journey: String) => {
    let count = 0;
    let arrayLength = 0;

    if (fundData) {
      switch (journey) {
        case journeyLabels.MARKETS_SELL_OFF:
          count = fundData?.countMarketDrop;
          arrayLength = fundData.marketDrop.length;
          if (count !== arrayLength) {
            return `I found ${count} investments for you with low correlation to public markets. Here are the top ${arrayLength} results.`;
          }
          return `I found ${count} investments for you with low correlation to public markets.`;

        case journeyLabels.TARGETS_LOWERED:
          count = fundData.countGrowthTargetsLowered;
          arrayLength = fundData.growthTargetsLowered.length;
          if (count !== arrayLength) {
            return `I found ${count} growth-focused investments on Tifin Private Markets. Here are the top ${arrayLength} results.`;
          }
          return `I found ${count} growth-focused investments on Tifin Private Markets`;

        case journeyLabels.RATE_SPIKE:
          count = fundData.countTreasurySpike;
          arrayLength = fundData.treasurySpike.length;
          if (count !== arrayLength) {
            return `I found ${count} Private Credit funds for you to consider. Here are the top ${arrayLength} results.`;
          }
          return `I found ${count} Private Credit funds for you to consider.`;

        case journeyLabels.IMPACT_POWERS:
          count = fundData.countImpact;

          arrayLength = fundData.impact.length;
          if (count !== arrayLength) {
            return `I found ${count} sustainable funds for you to consider. Here are the top ${arrayLength} results.`;
          }
          return `I found ${count} sustainable funds for you to consider.`;
        default:
          return question;
      }
    }
  };

  return (
    <StyledWrapper>
      <Button className='chatbot__drawer-btn' variant='contained' onClick={toggleDrawer}>
        Personal Investing Assistant <NavigateNextIcon />
      </Button>
      <StyledDrawer
        className='chatbot__drawer'
        anchor='right'
        isOpen={isChatBotOpen}
        toggleDrawer={toggleDrawer}
      >
        <Grid item xs={12} className='d-flex flex-col'>
          <HeaderChatBot
            toggleDrawer={toggleDrawer}
            displayState={displayState}
            setDisplayState={setDisplayState}
          />
          <div className='chatbotBody'>
            <Box py={4} className='chatSpace'>
              {displayState === 0 && (
                <>
                  {/* <ReceiverBubble delay={0} isLast={true}>
                    Welcome back, {name}
                  </ReceiverBubble> */}
                  <LastLoggedIn
                    setDisplayState={() => setDisplayState(2)}
                    setJourneyNo={setJourneyNo}
                  />
                  <OtherInsights setDisplayState={() => setDisplayState(1)} />
                  {/* <Themes toggleDrawer={toggleDrawer} /> */}
                </>
              )}
              {displayState === 1 && (
                <JourneyMenu setJourney={setJourneyNo} setDisplayState={() => setDisplayState(2)} />
              )}

              {displayState === 2 && (
                <>
                  <div className='headline'>
                    <img src={ChatBotBulb} alt='' className='insightBulb' />
                    <h3 className='font-wt-400 mb-0'>{(JOURNEY_ARR[journeyNo] as any)['TITLE']}</h3>
                  </div>
                  {chatbotData.map((data: any, i: number) => (
                    <>
                      {data?.label && <SenderBubble delay={0}>{data?.label}</SenderBubble>}
                      {data?.questions?.map((question: string, j: number) => (
                        <ComponentDelayer
                          scrollCount={scrollCount}
                          setScrollCount={setScrollCount}
                          waitBeforeShow={1000 * (j + 1)}
                        >
                          <ReceiverBubble delay={0} isLast={j === data?.questions.length - 1}>
                            {data?.responseDetails?.componentType === 'INVESTMENT_CARD'
                              ? handleQuestionString(question, JOURNEY_ARR[journeyNo].TITLE)
                              : question}
                          </ReceiverBubble>
                        </ComponentDelayer>
                      ))}
                      {data.responseDetails.componentType && (
                        <ComponentDelayer
                          scrollCount={scrollCount}
                          setScrollCount={setScrollCount}
                          waitBeforeShow={
                            (chatbotData[chatbotData.length - 1].questions.length + 1) * 1000
                          }
                        >
                          <InputWidgetFactory
                            toggleDrawer={toggleDrawer}
                            handleUserResponseClick={handleUserResponseClick}
                            responseDetails={data.responseDetails}
                            interactionType={data.userResponse}
                            delay={0}
                            chatbotData={chatbotData}
                            i={i}
                            journeyTitle={JOURNEY_ARR[journeyNo].TITLE}
                            fundData={fundData}
                            isLoading={isLoading}
                          />
                        </ComponentDelayer>
                      )}
                    </>
                  ))}
                </>
              )}
              <div ref={messagesEndRef} id='test' />
            </Box>
          </div>
          {displayState === 2 && chatbotData && chatbotData.length > 0 && (
            <Responses
              index={chatbotData.length - 1}
              onClick={handleUserResponseClick}
              initDelay={750 * chatbotData[chatbotData.length - 1].questions.length}
              scrollCount={scrollCount}
              setScrollCount={setScrollCount}
              goBack={() => setDisplayState(0)}
              responseDetails={chatbotData[chatbotData.length - 1].responseDetails}
            />
          )}
          {/* <AskInputField /> */}
        </Grid>
      </StyledDrawer>
    </StyledWrapper>
  );
};

export default ChatBot;
