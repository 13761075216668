import styled from '@emotion/styled';
import { Container, Divider, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import RecIntroCards from './cards/RecIntroCards';
import InputDrivingRec from './cards/InputDrivingRec';
import { LIQUIDITY_IMPACT } from './constants';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import {
  clientAge,
  engine1Result,
  isInputDrivingRecButtonVisible,
  isSelectedClientNonNaturalPerson,
  maxAlloc,
  portfolioValue,
  recEngineNumber,
  recommendationClient,
  recommendationNumber,
  recommendedAllocation,
} from 'store/recommendation/selectors';
import { useEffect, useState } from 'react';
import RecommendationE1 from './cards/RecommendationE1';
import {
  setEngine1Result,
  setEngine2Result,
  setEngineNumber,
  setIsInputDrivingRecButtonVisible,
  setRecommendationActiveStep,
  setRecommendedAllocation,
} from 'store/recommendation/slice';
import LiquidityImpact from 'common/components/charts/LiquidityImpact';
import ProjectedPerformance from 'common/components/charts/ProjectedPerformance';
import { formatAumValues } from 'helpers';
import { useMutation } from 'react-query';
import {
  createHoldingPayload,
  getRecommendationData,
  getThemesData,
} from 'components/modals/utils';
import Loading from 'common/components/Loading';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';

const StyledContainer = styled(Container)`
  color: var(--s50);

  h2 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 27px;
  }

  .MuiButton-outlined {
    color: #3b85c7;
    border-color: currentColor;
    &.editPortfolio {
      color: #c4c4c4;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 1.125rem;
  }

  .stylesBanner {
    background-color: var(--s7);
    border-radius: 0.5rem;
    padding: 1rem 1.25rem;
    h4 {
      font-weight: 500;
      color: var(--s40);
      margin-bottom: 0;
    }
  }

  .graph-container {
    background: #ffffff;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 28px 26px;
    position: relative;

    aside {
      margin: 0 7% 10px 0;
      background: var(--s5);
      font-size: 13px;
      color: var(--s40);

      div {
        padding: 0.5rem;
      }

      hr {
        border-width: 1px;
        width: 72px;
        margin: 0 auto 6px 0;
        border-color: var(--s20);

        &.blue {
          border-color: var(--p300);
        }
      }
    }

    &.liquidityImpact aside {
      margin-right: 0;
    }
  }

  .amount_chip {
    padding: 0.5rem 0.75rem;
    background: #f8f8f8;
    border: 1px solid var(--s15);
    border-radius: 0.5rem;
  }

  .gradient-text {
    font-weight: 700;
    font-size: inherit;
    background: linear-gradient(95.96deg, #2667a1 -2.77%, #3e89cd 101.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .wealth-growth {
    background: #ffffff;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .custom-tooltip {
    background-color: white;
    padding: 0.5rem;
    min-width: 100px;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    h4 {
      font-size: 13px;
      font-weight: 500;
      color: var(--s30);
      span {
        color: var(--p200);
      }
    }
    h5 {
      color: var(--s30);
    }
  }
`;

type Props = {
  backClick: any;
  doneClick: any;
  selectedClient: any;
  clientList: any;
};

const Engine1 = ({ backClick, doneClick, selectedClient, clientList }: Props) => {
  const dispatch = useTypedDispatch();
  const portfolioVal = useAppSelector(portfolioValue);
  const storedClient = useAppSelector(recommendationClient);
  const engine1Data = useAppSelector(engine1Result);
  const recommendationNo = useAppSelector(recommendationNumber);
  const alt_alloc = useAppSelector(recommendedAllocation);
  const max_alt_alloc = useAppSelector(maxAlloc);
  const engineNo = useAppSelector(recEngineNumber);
  const age = useAppSelector(clientAge);
  const isApplyButtonVisible = useAppSelector(isInputDrivingRecButtonVisible);
  const isNonNaturalPerson = useAppSelector(isSelectedClientNonNaturalPerson);
  const [isRec1, setIsRec1] = useState(true);
  const [isRec2, setIsRec2] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);

  const triggerLoader = () => {
    setTimeout(() => {
      setLoading(false);
      window.scrollTo(0, 0);
    }, 4000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getWealthGrowth = () => {
    let prevValueWithAlts = 0;
    let prevValueWithoutAlts = 0;
    let altPortReturn = +engine1Data?.engine_1_stats?.alt_port_ret;
    if (recommendationNo === 1)
      altPortReturn = +engine1Data?.engine_1_stats?.recommendations?.Addl_alloc?.alt_port_ret;
    if (recommendationNo === 2)
      altPortReturn =
        +engine1Data?.engine_1_stats?.recommendations?.Improve_liquidity?.alt_port_ret;
    const chartData = [...new Array(66 - age)].map((item: any, i: number) => {
      let valWithAlts = i === 0 ? +portfolioVal : +prevValueWithAlts;
      let valWithoutAlts = i === 0 ? +portfolioVal : +prevValueWithoutAlts;
      const updatedValueWithAlts = i === 0 ? valWithAlts : valWithAlts * (1 + altPortReturn);
      const updatedValueWithoutAlts =
        i === 0
          ? valWithoutAlts
          : valWithoutAlts * (1 + +engine1Data?.engine_1_stats?.current_port_ret);
      prevValueWithoutAlts = updatedValueWithoutAlts;
      prevValueWithAlts = updatedValueWithAlts;
      return {
        ...item,
        age: i + +age,
        withoutAlts: updatedValueWithoutAlts.toFixed(2),
        withAlts: updatedValueWithAlts.toFixed(2),
      };
    });
    return chartData;
  };

  const getLiquidityImpact = () => {
    const liquidityImpactData = LIQUIDITY_IMPACT.map((item: any) => {
      return {
        ...item,
        withoutAlts: portfolioVal * (1 - item.fall / 100),
        withAlts: portfolioVal * (1 - alt_alloc) * (1 - item.fall / 100),
      };
    });
    return liquidityImpactData;
  };

  const { mutate: triggerEngine1, isLoading } = useMutation(
    () =>
      getRecommendationData({
        data: engine1Data?.uploadedFileData,
        age,
        portfolioVal,
        max_alt_alloc,
        isNonNaturalPerson,
      }),
    {
      onSuccess: async (data: any) => {
        if (engineNo === 1) {
          if (recommendationNo === 1) {
            setIsRec1(false);
            setIsRec2(true);
          }

          if (recommendationNo === 0) {
            setIsRec1(true);
            setIsRec2(true);
          }

          if (recommendationNo === 2) {
            setIsRec2(false);
            setIsRec1(true);
          }
        }
        await dispatch(
          setEngine1Result({
            engine1Result: {
              ...data,
              uploadedFileData: createHoldingPayload(engine1Data?.uploadedFileData, portfolioVal),
            },
          }),
        );
        await dispatch(
          setRecommendedAllocation({
            recommendedAllocation: engine1Data?.engine_1_stats?.alt_alloc,
          }),
        );
        dispatch(setRecommendationActiveStep({ activeStep: 1 }));
        window.scrollTo(0, 0);
      },
    },
  );

  const { mutate: triggerEngine2, isLoading: isThemesLoading } = useMutation(
    () =>
      getThemesData({ data: engine1Data?.uploadedFileData, age, portfolioVal, isNonNaturalPerson }),
    {
      onSuccess: async (resp: any) => {
        const engine2Data = {
          ...resp,
          uploadedFileData: createHoldingPayload(engine1Data?.uploadedFileData, portfolioVal),
        };
        await dispatch(
          setEngine2Result({
            engine2Result: engine2Data,
          }),
        );
        await dispatch(
          setEngine1Result({
            engine1Result: null,
          }),
        );
        await dispatch(setEngineNumber({ engineNumber: 2 }));
        await dispatch(setRecommendationActiveStep({ activeStep: 1 }));
        window.scrollTo(0, 0);
      },
      onError: () => {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Something went wrong. Please try again.',
        };
        dispatch(showToast(toast));
      },
    },
  );

  if (isLoading || isThemesLoading || loading)
    return (
      <Loading
        message={
          isThemesLoading
            ? `Analyzing ${
                selectedClient?.account_name || storedClient?.account_name || 'client'
              }'s portfolio...`
            : `Making changes to ${
                selectedClient?.account_name || storedClient?.account_name
              } portfolio`
        }
      />
    );
  return (
    <StyledContainer maxWidth='lg' className='m-header'>
      <Grid container>
        <Grid item xs={12}>
          <h2>
            Based on your inputs for {selectedClient?.account_name || storedClient?.account_name},
            consider allocating &nbsp;
            <span className='amount_chip'>
              <span className='gradient-text'>
                {formatAumValues(Number(portfolioVal * alt_alloc))}
              </span>
            </span>
            &nbsp; ({(alt_alloc * 100).toFixed(1)}% of portfolio) to Private Markets
          </h2>
        </Grid>

        <RecIntroCards
          additionalReturns={
            getWealthGrowth()[getWealthGrowth().length - 1].withAlts -
            getWealthGrowth()[getWealthGrowth().length - 1].withoutAlts
          }
          liquidityAvailable={getLiquidityImpact()[2]}
        />

        <Grid item xs={12} container className='flex-center-between stylesBanner' mb={5}>
          <Grid item xs={9}>
            <h4>
              Get recommended weights for different styles to align Private Markets investments with
              your traditional portfolio
            </h4>
          </Grid>
          <Grid item xs={3} className='flex-center-end'>
            <MuiButton
              variant='contained'
              buttonClick={() => {
                triggerEngine2();
              }}
            >
              View Style Recommendations
            </MuiButton>
          </Grid>
        </Grid>

        <Grid item xs={12} mb={5}>
          <h3>Wealth Growth</h3>
          <Grid className='graph-container wealthGrowth'>
            <section className='flex-center-end'>
              <aside className='flex-center-between'>
                <div>
                  <Divider className='blue' />
                  Wealth Growth After Allocation to Private Markets
                </div>
                <div>
                  <Divider />
                  Wealth Growth Before Allocation to Private Markets
                </div>
              </aside>
            </section>
            <ProjectedPerformance data={getWealthGrowth()} />
          </Grid>
        </Grid>
        <Grid item xs={12} mb={5}>
          <h3 className='liquidity-impact'>Liquidity Impact Under Various Market Scenarios</h3>
          <Grid className='graph-container liquidityImpact'>
            <section className='flex-center-end'>
              <aside className='flex-center-between'>
                <div>
                  <Divider className='blue' />
                  Portfolio Liquidity After Allocation to Private Markets
                </div>
                <div>
                  <Divider />
                  Portfolio Liquidity Before Allocation to Private Markets
                </div>
              </aside>
            </section>
            <LiquidityImpact data={getLiquidityImpact()} />
          </Grid>
        </Grid>
        <Grid item xs={12} mb={5}>
          <RecommendationE1
            handleClick={() => {
              setLoading(true);
              triggerLoader();
            }}
            setIsRec1={setIsRec1}
            setIsRec2={setIsRec2}
            isRec1={isRec1}
            isRec2={isRec2}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} display='flex' justifyContent='space-between'>
            <h3>Inputs driving these recommendations</h3>
            {isApplyButtonVisible && (
              <MuiButton
                minWidth={'120px'}
                buttonClick={async () => {
                  await triggerEngine1();
                  dispatch(
                    setIsInputDrivingRecButtonVisible({ isInputDrivingRecButtonVisible: false }),
                  );
                }}
                className='mb-2'
                variant='contained'
              >
                Apply Changes
              </MuiButton>
            )}
          </Grid>
          <InputDrivingRec
            clientList={clientList}
            selectedClient={selectedClient || storedClient}
            isCapAllocationCardVisible
          />
        </Grid>
        <Grid item xs={12} className='flex-center-end' mt={'122px'}>
          <MuiButton buttonClick={backClick}>Back</MuiButton>
          <MuiButton buttonClick={doneClick} minWidth='150px' variant='contained' className='ml-3'>
            Done
          </MuiButton>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Engine1;
