import Grid from '@mui/material/Grid/Grid';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import InputField from 'common/components/inputField/Textbox';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTypedDispatch } from 'store/hooks';
import { ResetPasswordType } from './types';
import { validationSchema } from './validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';

type Props = {
  email: string;
};

const ResetPassword = ({ email }: Props) => {
  const dispatch = useTypedDispatch();

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordType>(formOptions);

  const onSubmit: SubmitHandler<ResetPasswordType> = async (data) => {
    dispatch({ type: 'RESET_PASSWORD', payload: { ...data, username: email } });
  };

  const handleKeyDownNumber = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12}>
          <InputField
            variant='standard'
            label='Confirmation code'
            type='text'
            keyDownFunc={handleKeyDownNumber}
            register={register}
            name='confirmationCode'
            errorMessage='Please enter your 6 digit confirmation code'
            fullWidth
          />
          {errors.confirmationCode && <ErrorMessage error={errors?.confirmationCode.message} />}
        </Grid>
        <Grid item xs={12}>
          <InputField
            variant='standard'
            label='Enter your password'
            type='password'
            register={register}
            name='password'
            errorMessage='Please enter a valid password.'
            fullWidth
          />
          {errors.password && (
            <ErrorMessage
              error={
                errors?.password?.type === 'min'
                  ? 'Password must contain at least 8 characters, one uppercase, one lowercase, one numeric and one special character'
                  : errors?.password.message
              }
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <InputField
            fullWidth
            variant='standard'
            label='Confirm Password'
            type='password'
            register={register}
            name='confPassword'
            errorMessage='Please enter a valid password.'
          />
          {errors.confPassword && <ErrorMessage error={errors?.confPassword.message} />}
        </Grid>
        <Grid item xs={12} mt={4}>
          <MuiButton type='submit' variant='contained' color='primary' fullWidth>
            Submit
          </MuiButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default ResetPassword;
