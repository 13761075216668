import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';

export const initialState: any = {
  investorActiveStep: 2,
  investorProfileStep: 0,
  clientDetails: null,
  investorAccountHolderDetails: null,
  authRepresentativeDetails: null,
  wireTransfferBankDetails: null,
  custodianDetails: null,
  entityDetails: null,
  storeAawceArr: null,
  newInvestorId: null,
  clientModalOpen: false,
  masterData: []
};

const slice = createSlice({
  name: 'investor',
  initialState,
  reducers: reducers,
});

export const {
  setInvestorsActiveStep,
  setInvestorProfileStep,
  setClientDetails,
  setInvestorAccountHolderDetails,
  setAuthRepresentativeDetails,
  setWireTransfferBankDetails,
  setCustodianDetails,
  setEntityDetails,
  setStoreAawceArr,
  setNewInvestorId,
  setClientModalOpen,
  setMasterData
} = slice.actions;

export default slice.reducer;
