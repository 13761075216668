import MuiCard from 'common/components/card';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { setEngineNumber } from 'store/recommendation/slice';
import { useTypedDispatch } from 'store/hooks';

const EngineCard = ({ engine, index, setLanding }: any) => {
  const dispatch = useTypedDispatch();

  return (
    <MuiCard>
      <Grid container>
        <Grid item xs={4}>
          <h3 className='mb-0'>{engine.title}</h3>
        </Grid>
        <Grid item xs={6}>
          <p className='mb-0'>{engine.desc}</p>
        </Grid>
        <Grid item xs={'auto'} className='flex-center-start'>
          <MuiButton
            variant='contained'
            minWidth='150px'
            buttonClick={() => {
              dispatch(setEngineNumber({ engineNumber: index + 1 }));
              setLanding(false);
            }}
          >
            Start
          </MuiButton>
        </Grid>
      </Grid>
    </MuiCard>
  );
};

export default EngineCard;
