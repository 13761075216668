import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import Chips from 'common/components/chips';
import CRMIntegrationModal from 'components/modals/recommendations/CRMIntegrationModal';
import CustodianIntegrationModal from 'components/modals/recommendations/CustodianIntegrationModal';
import { useState } from 'react';

const StyledWrapper = styled(Grid)`
  .integrations {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0.75rem 1rem;
    font-size: 13px;
    color: var(--s40);
    font-weight: 600;
    border-radius: 0.5rem;
    border: 1px solid var(--s15);
  }
`;

type Props = {
  gap?: number;
};

const Integrations = ({ gap = 2 }: Props) => {
  const [custodianOpen, setCustodianOpen] = useState<boolean>(false);
  const [CRMopen, setCRMopen] = useState<boolean>(false);

  return (
    <StyledWrapper container columnSpacing={gap}>
      <Grid item xs={6}>
        <div className='integrations' onClick={() => setCRMopen(true)}>
          CRM INTEGRATIONS
          <Chips label='Inactive' bg='transparent' color='#EC4444' border='1px solid #EC4444' />
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className='integrations' onClick={() => setCustodianOpen(true)}>
          CUSTODIAN INTEGRATIONS
          <Chips label='Active' bg='transparent' color='#3BBB7D' border='1px solid #3BBB7D' />
        </div>
      </Grid>
      <CustodianIntegrationModal
        isModalOpen={custodianOpen}
        handleClose={() => setCustodianOpen(false)}
      />
      <CRMIntegrationModal isModalOpen={CRMopen} handleClose={() => setCRMopen(false)} />
    </StyledWrapper>
  );
};

export default Integrations;
